import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpdateClientPartnerTransactionInput, UpdateClientPartnerTransactionPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPDATE_CLIENT_PARTNER_TRANSACTION = gql`
    mutation updateClientPartnerTransaction($input: UpdateClientPartnerTransactionInput!){
        updateClientPartnerTransaction(input: $input){
            message
            errors {
                code
                message
            }
            result
            clientPartnerTransaction {
                id
            }
        }
    }
`;

export const mutationUpdateClientPartnerTransaction = async (
    client: ApolloClient<NormalizedCacheObject>,
    clientPartnerTransactionData: UpdateClientPartnerTransactionInput
    
): Promise<UpdateClientPartnerTransactionPayload | undefined> => {
    const {
        id, effectivePartnerInterchangeId, isPipelineInherited, pipelineId, destinationPath, functionalReconciliationMode, skipTargetDocumentCreation, outboundAcknowledgementOption
    } = clientPartnerTransactionData;

    const { data, errors } = await client.mutate<{ updateClientPartnerTransaction: UpdateClientPartnerTransactionPayload }>({
        variables: {
            input: {
                id, 
                effectivePartnerInterchangeId, 
                isPipelineInherited, 
                pipelineId, 
                destinationPath,
                functionalReconciliationMode,
                skipTargetDocumentCreation,
                outboundAcknowledgementOption
            }
        },
        mutation: MUTATION_UPDATE_CLIENT_PARTNER_TRANSACTION
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the client partner transaction save.');
    }
    if (data && data.updateClientPartnerTransaction) {
        return data.updateClientPartnerTransaction;
    }
    return undefined;
};