import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertNotePayload, UpsertNoteInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_CLIENT_CONTACT_NOTE = gql`
    mutation upsertClientContactNote($input: UpsertNoteInput!){
        upsertClientContactNote(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertClientContactNote = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        upsertNoteData: UpsertNoteInput
    }
): Promise<UpsertNotePayload | undefined> => {
    const { parentId, id, header, note } = args.upsertNoteData;
    const { data, errors } = await client.mutate<{ upsertClientContactNote: UpsertNotePayload }>({
        variables: {
            input: {
                parentId,
                id,
                header,
                note
            }
        },
        mutation: MUTATION_UPSERT_CLIENT_CONTACT_NOTE
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the client contact note save.');
    }
    if (data && data.upsertClientContactNote) {
        return data.upsertClientContactNote;
    }
    return undefined;
};