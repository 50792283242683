import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import { RequestResult, PagingResultModelOfBlobStorageModel, BlobUploadRequestInput } from '../../gql-types.generated';
import { queryStandardExchangeFormatFileList } from '../../gql/QueryStandardExchangeFormatFileList';
import { MutationUploadBlogStorage } from '../../gql/MutationExecuteBlobStorageUpload';
import { fetchError, captureUploadFileVersionStatus, fetchFileVersionsSuccess } from './StandardExchangeFormatFileDetailsSlice';

export const fetchFileVersionList = (
    blobDocumentId?: string
) => async (dispatch: AppDispatch): Promise<void> => {
    try {
        const fileList = await queryStandardExchangeFormatFileList(gqlClient, {blobDocumentId: blobDocumentId});
        if (fileList) {
            dispatch(fetchFileVersionsSuccess(fileList as PagingResultModelOfBlobStorageModel))
        }
    } catch (e) {
        dispatch(fetchError(e as Error));
    }
};

export const uploadFileVersion = (fileUploadData: BlobUploadRequestInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const uploadStatus = await MutationUploadBlogStorage(gqlClient, fileUploadData);
           
            if (uploadStatus) {
                if (uploadStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: uploadStatus.message } as Error));
                    getErrorMessage(uploadStatus.message as string, uploadStatus.errors, true, true);
                }
                dispatch(captureUploadFileVersionStatus(uploadStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };