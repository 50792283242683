import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';

import { GetTradingPartnerByIdQuery, PagingResultModelOfPartnerModel, GetPartnersInput, Scalars } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_TRADING_PARTNER_BY_ID = gql`
query getTradingPartnerById($input: GetPartnersInput!) {
    partners (input: $input){
        nodes {
            id
            internalPartnerCode
            isaQualifierCode
            localeCode
            name
            partnerCode
            statusCode
            functionalReconciliationMode
            website
            totalTransactions
            addresses {
                addressLine1
                addressLine2
                addressLine3
                addressLine4
                city
                countryCode
                id
                isPrimary
                postalCode
                stateId
                state {
                    stateCode
                    description
                    id
                }
            }
            businessArea {
                name
                description
                id
            }
            partnerAKAs {
                id
                partnerCode
                isaQualifierCode
                isaQualifier {
                    isaQualifierCode
                    description
                }
            }
            partnerContacts {
                id
                partnerId
                name
                description
                isActive
                isPrimary
                jobTitle
                addresses {
                    addressLine1
                    addressLine2
                    addressLine3
                    addressLine4
                    city
                    countryCode
                    id
                    isPrimary
                    postalCode
                    stateId
                    state {
                        stateCode
                        description
                        id
                    }
                }
                emails {
                    email
                    id
                    isPrimary
                }
                phones {
                    description
                    id
                    phoneNumber
                    phoneTypeCode
                }
                notes {
                    createdBy
                    createdByName
                    createdTime
                    header
                    id
                    lastModifiedBy
                    lastModifiedByName
                    lastModifiedTime
                    note
                    parentId
                }
            }
            clients {
                id
                name
                clientCode
                statusCode
                totalTransactions
                businessArea {
                    id
                    name
                }
                erpProduct {
                    id
                    name
                }
            }
            ediStandards {
                description
                id
                name
            }
            emails {
                email
                id
                isPrimary
            }
            phones {
                phoneNumber
                phoneTypeCode
                description
                id
            }
            notes {
                createdBy
                createdByName
                createdTime
                header
                id
                lastModifiedBy
                lastModifiedByName
                lastModifiedTime
                note
                parentId
            }
            partnerTransactions {
                id
                transaction {
                    id
                    name
                    description
                    direction
                    isActive
                }
                clientPartnerTransactions {
                    id
                }
                partnerTransactionSchemas {
                    id
                    partnerTransactionId
                    standardVersion
                    description
                    documentString
                    sourceFileName
                }
                pipeline {
                    description
                    id
                }
                pipelineId
                outboundAcknowledgementOption
            }
            attachments {
                id
                fileName
                isLink
                description
                createdBy
                createdByName
                createdTime
                lastModifiedBy
                lastModifiedByName
                lastModifiedTime
                fileLastModifiedTime
            }
        }
    }
}`;

export const queryTradingPartnerById = async (
    client: ApolloClient<NormalizedCacheObject>,
    partnerId: Scalars['UUID']
): Promise<PagingResultModelOfPartnerModel | undefined> => {
    const { data, errors } = await client.query<GetTradingPartnerByIdQuery>({
        variables: {
            input: {
                id: partnerId
            }
        },
        query: QUERY_TRADING_PARTNER_BY_ID,
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.partners as PagingResultModelOfPartnerModel;
};