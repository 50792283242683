import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';

import { AttachmentType, PagingResultModelOfAttachmentModel, RequestResult, UpsertAttachmentInput, UpsertClientPartnerTransactionSchemaInput, UpdateClientPartnerTransactionInput, UpsertClientPartnerTransactionPostProcessingInput, GetPostProcessingBlobInput, PagingResultModelOfPostProcessingBlobModel, UpsertClientPartnerTransactionAliasInput, UpdateClientPartnerInput } from '../../gql-types.generated';
import { getErrorMessage } from '../../util/Common';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';
import { queryClientPartnerById } from '../../gql/QueryClientPartnerById';
import { mutationAddClientPartnerTransactions } from '../../gql/MutationAddClientPartnerTransactions';
import { mutationUpsertClientPartnerAttachments } from '../../gql/MutationUpsertClientPartnerAttachments';
import {
    fetchClientPartnerByIdSuccess,
    fetchError,
    captureDeleteClientPartnerStatus,
    captureUpsertClientPartnerAttachmentsStatus,
    captureDeleteClientPartnerAttachmentStatus,
    captureDeleteClientPartnerTransactionStatus,
    captureAddClientPartnerTransactionsStatus,
    fetchClientPartnerAttachmentSuccess,
    captureUpdateClientPartnerTransactionStatus,
    captureAddTransactionMapToClientPartnerTransactionStatus,
    captureDeleteClientPartnerTransactionMapStatus,
    captureAddPartnerTransactionSchemaToClientPartnerTransactionStatus,
    captureDeleteClientPartnerTransactionSchemaStatus,
    captureUpsertClientPartnerTransactionPostProcessingStatus,
    captureDeleteClientPartnerTransactionPostProcessingStatus,
    captureUpsertClientPartnerTransactionAliasStatus,
    captureDeleteClientPartnerTransactionAliasStatus,
    fetchPostProcessingBlobsSuccess,
    captureUpdateClientPartnerStatus
} from './ClientPartnerDetailsSlice';
import { queryAttachments } from '../../gql/QueryAttachment';
import { mutationUpdateClientPartnerTransaction } from '../../gql/MutationUpdateClientPartnerTransaction';
import { mutationUpsertClientPartnerTransactionMap } from '../../gql/MutationUpsertClientPartnerTransactionMap';
import { mutationUpsertClientPartnerTransactionSchema } from '../../gql/MutationUpsertClientPartnerTransactionSchema';
import { MutationUpsertClientPartnerTransactionPostProcessing } from '../../gql/MutationUpsertClientPartnerTransactionPostProcessing';
import { mutationUpsertClientPartnerTransactionAlias } from '../../gql/MutationUpsertClientPartnerTransactionAlias';
import { mutationUpdateClientPartner } from '../../gql/MutationUpdateClientPartner';
import { queryPostProcessingBlobs } from '../../gql/QueryPostProcessingBlobs';

export const fetchClientPartnerById = (id: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const clientPartner = await queryClientPartnerById(gqlClient, id);
            if (clientPartner) {
                dispatch(fetchClientPartnerByIdSuccess(clientPartner))
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteClientPartnerData = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteClientPartnerStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "ClientPartner",
            });
            if (deleteClientPartnerStatus) {
                if (deleteClientPartnerStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteClientPartnerStatus.message as string, deleteClientPartnerStatus.errors, true, true);
                }
                dispatch(captureDeleteClientPartnerStatus(deleteClientPartnerStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteClientPartnerTransaction = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteClientPartnerTransactionStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "ClientPartnerTransaction",
            });
            if (deleteClientPartnerTransactionStatus) {
                if (deleteClientPartnerTransactionStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteClientPartnerTransactionStatus.message as string, deleteClientPartnerTransactionStatus.errors, true, true);
                }
                dispatch(captureDeleteClientPartnerTransactionStatus(deleteClientPartnerTransactionStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const addClientPartnerTransactions = (
    clientPartnerId: string,
    partnerTransactionIds: string[],
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const addTransactionsStatus = await mutationAddClientPartnerTransactions(gqlClient, {
                clientPartnerId,
                partnerTransactionIds,
            });
            if (addTransactionsStatus) {
                if (addTransactionsStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: addTransactionsStatus.message } as Error));
                    getErrorMessage(addTransactionsStatus.message as string, addTransactionsStatus.errors, true, true);
                }
                dispatch(captureAddClientPartnerTransactionsStatus(addTransactionsStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertClientPartnerAttachments = (
    clientPartnerId: string,
    attachments?: UpsertAttachmentInput[],
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertClientPartnerAttachments(gqlClient, {
                attachments,
                clientPartnerId
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertClientPartnerAttachmentsStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };


export const deleteClientPartnerAttachment = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "ClientPartnerAttachment",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteClientPartnerAttachmentStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchClientPartnerAttachment =
    (id: string) =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const attachment = await queryAttachments(gqlClient, [id], AttachmentType.ClientPartner);
                if (attachment) {
                    dispatch(fetchClientPartnerAttachmentSuccess(attachment as PagingResultModelOfAttachmentModel));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

export const updateClientPartnerTransaction = (
    clientPartnerTransactionData: UpdateClientPartnerTransactionInput
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const updateStatus = await mutationUpdateClientPartnerTransaction(gqlClient, clientPartnerTransactionData);
            if (updateStatus) {
                if (updateStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: updateStatus.message } as Error));
                    getErrorMessage(updateStatus.message as string, updateStatus.errors, true, true);
                }
                dispatch(captureUpdateClientPartnerTransactionStatus(updateStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const addTransactionMapToClientPartnerTransaction = (
    clientPartnerTransactionId: string,
    transactionMapId: string
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const addStatus = await mutationUpsertClientPartnerTransactionMap(gqlClient, {
                clientPartnerTransactionId, transactionMapId
            });
            if (addStatus) {
                if (addStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: addStatus.message } as Error));
                    getErrorMessage(addStatus.message as string, addStatus.errors, true, true);
                }
                dispatch(captureAddTransactionMapToClientPartnerTransactionStatus(addStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

    export const deleteTransactionMapFromClientPartnerTransaction = (clientPartnerTransactionMapId: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id: clientPartnerTransactionMapId,
                entityName: "ClientPartnerTransactionMap",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteClientPartnerTransactionMapStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

    export const addPartnerTransactionSchemaToClientPartnerTransaction = (
        clientPartnerTransactionSchemaInputData: UpsertClientPartnerTransactionSchemaInput
    ): AppThunk =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const addStatus = await mutationUpsertClientPartnerTransactionSchema(gqlClient, clientPartnerTransactionSchemaInputData);
                if (addStatus) {
                    if (addStatus.result === RequestResult.Fail) {
                        dispatch(fetchError({ message: addStatus.message } as Error));
                        getErrorMessage(addStatus.message as string, addStatus.errors, true, true);
                    }
                    dispatch(captureAddPartnerTransactionSchemaToClientPartnerTransactionStatus(addStatus));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };
    
    export const deleteClientPartnerTransactionSchema = (clientPartnerTransactionSchemaId: string): AppThunk =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const deleteStatus = await mutationDeleteEntity(gqlClient, {
                    id: clientPartnerTransactionSchemaId,
                    entityName: "ClientPartnerTransactionSchema",
                });
                if (deleteStatus) {
                    if (deleteStatus.result === RequestResult.Fail) {
                        getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                    }
                    dispatch(captureDeleteClientPartnerTransactionSchemaStatus(deleteStatus));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

        export const fetchPostProcessingBlobs = (
            filters?: GetPostProcessingBlobInput
        ) => async (dispatch: AppDispatch): Promise<void> => {
            try {
                const postBlobPage = await queryPostProcessingBlobs(gqlClient, filters);
                if (postBlobPage) {
                    dispatch(fetchPostProcessingBlobsSuccess(postBlobPage as PagingResultModelOfPostProcessingBlobModel));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        }
    
    export const upsertClientPartnerTransactionPostProcessing = (
        clientPartnerTransactionPostProcessingInputData: UpsertClientPartnerTransactionPostProcessingInput
    ): AppThunk =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const upsertStatus = await MutationUpsertClientPartnerTransactionPostProcessing(gqlClient, clientPartnerTransactionPostProcessingInputData);
                if (upsertStatus) {
                    if (upsertStatus.result === RequestResult.Fail) {
                        dispatch(fetchError({ message: upsertStatus.message } as Error));
                        getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                    }
                    dispatch(captureUpsertClientPartnerTransactionPostProcessingStatus(upsertStatus));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };
    
    export const deleteClientPartnerTransactionPostProcessing = (clientPartnerTransactionPostProcessingId: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id: clientPartnerTransactionPostProcessingId,
                entityName: "ClientPartnerTransactionPostProcessing",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteClientPartnerTransactionPostProcessingStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

    export const upsertClientPartnerTransactionAlias = (
        clientPartnerTransactionAliasInputData: UpsertClientPartnerTransactionAliasInput
    ): AppThunk =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const upsertStatus = await mutationUpsertClientPartnerTransactionAlias(gqlClient, { clientPartnerTransactionAliasInputData });
                if (upsertStatus) {
                    if (upsertStatus.result === RequestResult.Fail) {
                        dispatch(fetchError({ message: upsertStatus.message } as Error));
                        getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                    }
                    dispatch(captureUpsertClientPartnerTransactionAliasStatus(upsertStatus));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };
    
    export const deleteClientPartnerTransactionAlias = (clientPartnerTransactionAliasId: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id: clientPartnerTransactionAliasId,
                entityName: "ClientPartnerTransactionAlias",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteClientPartnerTransactionAliasStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

    export const updateClientPartner = (
        clientPartnerData: UpdateClientPartnerInput
    ): AppThunk =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const updateStatus = await mutationUpdateClientPartner(gqlClient, { clientPartnerData });
                if (updateStatus) {
                    if (updateStatus.result === RequestResult.Fail) {
                        dispatch(fetchError({ message: updateStatus.message } as Error));
                        getErrorMessage(updateStatus.message as string, updateStatus.errors, true, true);
                    }
                    dispatch(captureUpdateClientPartnerStatus(updateStatus));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

        