import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagingResultModelOfBlobStorageModel, BlobStorageModel, ActionPayload, DeleteByIdPayload } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    sefFilesPagingResult?: PagingResultModelOfBlobStorageModel;
    sefFileList?: BlobStorageModel[];
    sefFileRequestsInFlight: number;
    uploadSefFileStatus?: ActionPayload;
    downloadSefFileStatus?: ActionPayload;
    deleteSefFileStatus?: DeleteByIdPayload;
}

const initialState: SliceState = {
    error: undefined,
    sefFilesPagingResult: undefined,
    sefFileList: undefined,
    sefFileRequestsInFlight: 0,
    uploadSefFileStatus: undefined,
    downloadSefFileStatus: undefined,
    deleteSefFileStatus: undefined,    
};

export const slice = createSlice({
    name: 'standardExchangeFormatFiles',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.sefFilesPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.sefFileList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.sefFileRequestsInFlight = 0;
            // eslint-disable-next-line no-param-reassign
            state.uploadSefFileStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.downloadSefFileStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteSefFileStatus = undefined;
        },

        fetchSefFileListSuccess: (state, action: PayloadAction<PagingResultModelOfBlobStorageModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.sefFilesPagingResult = action.payload;
            let files = state.sefFileList;
            if (!files || !state.sefFilesPagingResult.cursor?.previousPage) {
                files = [];
            }
            if (action.payload.nodes) {
                action.payload.nodes.map(node => {
                    if (!files?.some(i => i.id === node?.id)) {
                        files?.push(node as BlobStorageModel)
                    }
                    return node;
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.sefFileList = files;
        },

        incrementSefFileRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            state.sefFileRequestsInFlight += 1;
        },

        decrementSefFileRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.sefFileRequestsInFlight > 0) state.sefFileRequestsInFlight -= 1;
        },

        captureUploadSefFileStatus: (state, action: PayloadAction<ActionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.uploadSefFileStatus = action.payload;
        },

        captureDownloadSefFileStatus: (state, action: PayloadAction<ActionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.downloadSefFileStatus = action.payload;
        },

        captureDeleteSefFileStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteSefFileStatus = action.payload;
        },

        
    }
});

export const selectError = (state: RootState): Error | undefined => state.standardExchangeFormatFiles.error;
export const selectSefFilePagingResult = (state: RootState): PagingResultModelOfBlobStorageModel | undefined => state.standardExchangeFormatFiles.sefFilesPagingResult;
export const selectSefFileList = (state: RootState): BlobStorageModel[] | undefined => state.standardExchangeFormatFiles.sefFileList;
export const selectRequestsInFlight = (state: RootState): number => state.standardExchangeFormatFiles.sefFileRequestsInFlight;
export const selectUploadSefFileStatus = (state: RootState): ActionPayload | undefined => state.standardExchangeFormatFiles.uploadSefFileStatus;
export const selectDownloadSefFileStatus = (state: RootState): ActionPayload | undefined => state.standardExchangeFormatFiles.downloadSefFileStatus;
export const selectDeleteSefFileStatus = (state: RootState): DeleteByIdPayload | undefined => state.standardExchangeFormatFiles.deleteSefFileStatus;

export const { 
    fetchError, 
    clearError,
    clearState,
    fetchSefFileListSuccess, 
    incrementSefFileRequestsInFlight,
    decrementSefFileRequestsInFlight,
    captureUploadSefFileStatus,
    captureDownloadSefFileStatus,
    captureDeleteSefFileStatus,
} = slice.actions;

export default slice.reducer;