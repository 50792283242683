import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpdateTransactionErrorCategoryInput, UpdateTransactionErrorCategoryPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPDATE_TRANSACTION_ERROR_CATEGORY = gql`
    mutation updateTransactionErrorCategory($input: UpdateTransactionErrorCategoryInput!){
        updateTransactionErrorCategory(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpdateTransactionErrorCategory = async (
    client: ApolloClient<NormalizedCacheObject>,
    categoryData: UpdateTransactionErrorCategoryInput
): Promise<UpdateTransactionErrorCategoryPayload | undefined> => {
    const { transactionErrorId, categoryId } = categoryData;

    const { data, errors } = await client.mutate<{ updateTransactionErrorCategory: UpdateTransactionErrorCategoryPayload }>({
        variables: {
            input: {
                transactionErrorId,
                categoryId: categoryId ?? null
            }
        },
        mutation: MUTATION_UPDATE_TRANSACTION_ERROR_CATEGORY
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the saving of the category for the transaction error.');
    }
    if (data && data.updateTransactionErrorCategory) {
        return data.updateTransactionErrorCategory;
    }
    return undefined;
};