import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector } from "../../app/hooks";
import CloseIcon from '@mui/icons-material/Close';
import { Viewer } from "../../util/Constants";
import { selectProcessList } from '../../features/Processes/ProcessesSlice';

import FilterBar from "./FilterBar";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../util/ViewerUtility';

interface ProcessesFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    onClose: () => void;
    onFilterChanged: (filterProcessName: string | undefined, filterProcessDescription: string | undefined) => void;
}

const ProcessesFilterBar: React.FC<ProcessesFilterBarProps> = props => {
    const { id, loading = false, visible = false, viewer, onFilterChanged } = props;
    const [filterName, setFilterName] = useState<string | undefined>();
    const [filterDescription, setFilterDescription] = useState<string | undefined>();
    const fieldsDisabled = visible && loading;
    const searchFieldMinLength = 3;

    useEffect(() => {
        // filters from local storage to be remembered across sessions
        let processName = getLocalStorageItem(viewer, "processFilterEdiProcessName");
        if (processName != null) {
            setFilterName(processName);
        }
        let processDescription = getLocalStorageItem(viewer, "processFilterEdiProcessDescription");
        if (processDescription != null) {
            setFilterDescription(processDescription);
        }
        // because processes are currently not paged lets send out an initial state to kick a fetch 
        onFilterChanged(processName as string | undefined, processDescription as string | undefined);
    }, []);
   
    const processes = useAppSelector(selectProcessList);

    const onSearchClick = () => {
        onFilterChanged(filterName, filterDescription);
    };

    const onCloseClick = () => {
        clearFilters();
        props.onClose();
        // trigger a new search with filters undefined since state of filter values might not be updated yet
        onFilterChanged(undefined, undefined);
    };

    const clearFilters = () => {
        clearNameFilter();
        clearDescriptionFilter();
    };

    const clearNameFilter = () => {
        removeLocalStorageItem(viewer, "processFilterEdiProcessName");
        setFilterName(undefined);
    };

    const clearDescriptionFilter = () => {
        removeLocalStorageItem(viewer, "processFilterEdiProcessDescription");
        setFilterDescription(undefined);
    };

    const onNameFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            // only set local storage if minimum is met
            if (changedValue?.length >= event.target.minLength) {
                setLocalStorageItem(viewer, "processFilterEdiProcessName", changedValue);
            }
        }
        else {
            removeLocalStorageItem(viewer, "processFilterEdiProcessName");
        }
        
        setFilterName(changedValue);
    };

    const onDescriptionFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            // only set local storage if minimum is met
            if (changedValue?.length >= event.target.minLength) {
                setLocalStorageItem(viewer, "processFilterEdiProcessDescription", changedValue);
            }
        }
        else {
            removeLocalStorageItem(viewer, "processFilterEdiProcessDescription");
        }

        setFilterDescription(changedValue);
    };

    const nameFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear name filter"
                    onClick={clearNameFilter}
                    disabled={fieldsDisabled}
                >
                    {filterName && filterName.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const descriptionFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear description filter"
                    onClick={clearDescriptionFilter}
                    disabled={fieldsDisabled}
                >
                    {filterDescription && filterDescription.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick} onSearch={onSearchClick}>
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="process-filter-description"
                    fullWidth
                    value={filterDescription ?? ''}
                    label="Description"
                    title="Enter at least 3 characters"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'description', 'maxLength': 50, 'minLength': {minLengthSearchField: searchFieldMinLength} }}
                    InputProps={descriptionFilterProps}
                    onChange={onDescriptionFilterChanged}
                    autoComplete="off"
                    data-cy="process-description-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="process-filter-name"
                    fullWidth
                    value={filterName ?? ''}
                    label="Name"
                    title="Enter at least 3 characters"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'name', 'maxLength': 50, 'minLength': {minLengthSearchField: searchFieldMinLength} }}
                    InputProps={nameFilterProps}
                    onChange={onNameFilterChanged}
                    autoComplete="off"
                    data-cy="process-name-filter"
                    variant="standard"
                />
            </Grid>
            
        </FilterBar>
    );
};

export default ProcessesFilterBar;