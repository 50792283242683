import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { capitalize } from "lodash";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Stack, styled, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { BlankMenuItem, CardChip } from "../../util/SharedStyles";
import { RequestResult, ClientPartnerTransactionModel, ClientPartnerTransactionMapModel, PipelineModel, TransactionMapModel, UserRole, ClientPartnerTransactionSchemaModel, PartnerTransactionSchemaModel, UpdateClientPartnerTransactionInput, FunctionalReconciliationMode, ClientPartnerTransactionPostProcessingModel, PostProcessingBlobModel, ClientPartnerTransactionAliasModel, OutboundAcknowledgementType, TransactionDirection } from "../../gql-types.generated";
import AddEditDialog from "./AddEditDialog";
import PartnerTransactionSchemaPickerDialog from "./PartnerTransactionSchemaPickerDialog";
import TransactionMapPickerDialog from "./TransactionMapPickerDialog";
import TransactionPipelineDialog from "./TransactionPipelineDialog";
import DialogClientPartnerTransactionPostProcessingList from "../lists/transactions/DialogClientPartnerTransactionPostProcessingList";
import DialogClientPartnerTransactionAliasList from "../lists/transactions/DialogClientPartnerTransactionAliasList";
import { downloadDocument } from "../../util/Common";

/**
 * IsPipelineInherited
 *  Defaults True
 * PipelineId
 *  Picklist containing all pipelines associated with transaction
 *  should this be disabled when pipeline inherited is true?
 * EffectivePartnerInterchangeId
 */

const DownloadIconButton = styled(IconButton)((props) => ({
    padding: '0 !important',
    marginLeft: '8px',
}));

export enum SkipTargetCreationOptions {
    Yes = 'Yes',
    No = 'No',
    Inherit = 'Inherit'
}

interface EditTransactionDialogProps {
    isOpen: boolean;
    viewerRole?: UserRole | undefined;
    isReadOnly: boolean;
    clientPartnerTransaction?: ClientPartnerTransactionModel;
    availableMaps: TransactionMapModel[];
    existingMapIds: string[];
    mapAddedStatus?: RequestResult;
    mapDeletedStatus?: RequestResult;
    availableSchemas: PartnerTransactionSchemaModel[];
    existingSchemaIds: string[];
    schemaAddedStatus?: RequestResult;
    schemaDeletedStatus?: RequestResult;
    availablePostProcessingBlobs?: PostProcessingBlobModel[];
    onClose: () => void;
    onSave: (
        updatedClientPartnerTransactionData: UpdateClientPartnerTransactionInput
    ) => void;
    refreshData?: () => void;
    onMapAdded: (transactionId: string, mapId: string) => void;
    onMapDeleted: (clientPartnerTransactionMapId: string) => void;
    onSchemaAdded: (transactionId: string, schemaId: string) => void;
    onSchemaDeleted: (clientPartnerTransactionSchemaId: string) => void;
    error?: Error | undefined;
}

const EditClientPartnerTransactionDialog: React.FC<EditTransactionDialogProps> = props => {
    const { 
        isOpen, isReadOnly,
        viewerRole, clientPartnerTransaction, 
        availableMaps, existingMapIds, mapAddedStatus, mapDeletedStatus, 
        availableSchemas, existingSchemaIds, schemaAddedStatus, schemaDeletedStatus, 
        availablePostProcessingBlobs,
        onClose, onSave, refreshData,
        onMapAdded, onMapDeleted,
        onSchemaAdded, onSchemaDeleted,
        error 
    } = props;
    
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [transactionId, setTransactionId] = useState<string>('');
    const [transactionDirection, setTransactionDirection] = useState<TransactionDirection | undefined>(undefined);
    const [effectiveInterchangeIdValue, setEffectiveInterchangeIdValue] = useState<string | undefined>(undefined);
    const [destinationPathValue, setDestinationPathValue] = useState<string | undefined>(undefined);
    const [partnerTransactionPipeline, setPartnerTransactionPipeline] = useState<PipelineModel | undefined>(undefined);
    const [isPipelineInheritedValue, setIsPipelineInheritedValue] = useState<boolean>(false);
    const [pipelineIdValue, setPipelineIdValue] = useState<string | undefined>(undefined);
    const [pipelineDescription, setPipelineDescription] = useState<string | undefined>(undefined);
    const [acknowledgementMode, setAcknowledgementMode] = useState<FunctionalReconciliationMode | undefined>(undefined);
    const [outboundAcknowledgementOption, setOutboundAcknowledgementOption] = useState<OutboundAcknowledgementType | undefined>(undefined);
    const [skipTargetDocumentCreationSelectedOption, setSkipTargetDocumentCreationSelectedOption] = useState<SkipTargetCreationOptions>(SkipTargetCreationOptions.Inherit);
    const [skipTargetDocumentCreationFlag, setSkipTargetDocumentCreationFlag] = useState<boolean | null>(null);
    const [transactionMaps, setTransactionMaps] = useState<ClientPartnerTransactionMapModel[] | undefined>(undefined);
    const [transactionSchemas, setTransactionSchemas] = useState<ClientPartnerTransactionSchemaModel[] | undefined>(undefined);
    const [postProcessings, setPostProcessings] = useState<ClientPartnerTransactionPostProcessingModel[] | undefined>(undefined);
    const [aliases, setAliases] = useState<ClientPartnerTransactionAliasModel[] | undefined>(undefined);
    const [isViewMode, setIsViewMode] = useState(false);
    const [pipelineDialogOpen, setPipelineDialogOpen] = useState(false);
    const [mapPickerOpen, setMapPickerOpen] = useState(false);
    const [schemaPickerOpen, setSchemaPickerOpen] = useState(false);
    const [mapAddDeleteLoading, setMapAddDeleteLoading] = useState(false);
    const [schemaAddDeleteLoading, setSchemaAddDeleteLoading] = useState(false);
    
    const setFromClientPartnerTransaction = useCallback(() => {
        setTransactionId(clientPartnerTransaction?.id ?? '');
        setTransactionDirection(clientPartnerTransaction?.partnerTransaction?.transaction?.direction as TransactionDirection);
        setEffectiveInterchangeIdValue(clientPartnerTransaction?.effectivePartnerInterchangeId as string);
        setDestinationPathValue(clientPartnerTransaction?.destinationPath as string);
        setTransactionMaps(clientPartnerTransaction?.clientPartnerTransactionMaps as ClientPartnerTransactionMapModel[]);
        setTransactionSchemas(clientPartnerTransaction?.clientPartnerTransactionSchemas as ClientPartnerTransactionSchemaModel[]);
        setPostProcessings(clientPartnerTransaction?.clientPartnerTransactionPostProcessings as ClientPartnerTransactionPostProcessingModel[]);  
        setAliases(clientPartnerTransaction?.clientPartnerTransactionAliases as ClientPartnerTransactionAliasModel[]);  
       
        if (clientPartnerTransaction?.functionalReconciliationMode) {
            setAcknowledgementMode(clientPartnerTransaction.functionalReconciliationMode as FunctionalReconciliationMode);
        } else {
            // default and enable save if not readOnly
            if (!isReadOnly) {
                setAcknowledgementMode(FunctionalReconciliationMode.Acknowledgement);
                setIsFormDirty(true);
            } else {
                setAcknowledgementMode(undefined);
            }
        }

        setOutboundAcknowledgementOption(clientPartnerTransaction?.outboundAcknowledgementOption as OutboundAcknowledgementType);

        if (clientPartnerTransaction?.isPipelineInherited !== null && clientPartnerTransaction?.isPipelineInherited !== undefined) {
            setIsPipelineInheritedValue(clientPartnerTransaction?.isPipelineInherited as boolean);
        }
        setPartnerTransactionPipeline(clientPartnerTransaction?.partnerTransaction?.pipeline as PipelineModel);
        setPipelineIdValue(clientPartnerTransaction?.pipelineId as string);
        setPipelineDescription(clientPartnerTransaction?.pipeline?.description as string);
 
        if (clientPartnerTransaction?.skipTargetDocumentCreation === true) {
            setSkipTargetDocumentCreationSelectedOption(SkipTargetCreationOptions.Yes);
            setSkipTargetDocumentCreationFlag(true);
        } else if (clientPartnerTransaction?.skipTargetDocumentCreation === false) {
            setSkipTargetDocumentCreationSelectedOption(SkipTargetCreationOptions.No);
            setSkipTargetDocumentCreationFlag(false);
        } else {
            setSkipTargetDocumentCreationSelectedOption(SkipTargetCreationOptions.Inherit);
            setSkipTargetDocumentCreationFlag(null);
        }
      
    },[clientPartnerTransaction, isReadOnly]);

    useEffect(() => {
        if (!isOpen) {
            setSubmitted(false);
            setIsFormDirty(false);
            setIsViewMode(false);
            setToDefaults();
        } else {
            setIsViewMode(isReadOnly);
            setFromClientPartnerTransaction();
        }
    }, [isOpen, isReadOnly, setFromClientPartnerTransaction]);

    useEffect(() => {
        if (mapAddedStatus || mapDeletedStatus) {
            // we don't care what the result was, we just want to know that it came back and the refresh should have been triggered
            setMapAddDeleteLoading(false);
        }
    }, [mapAddedStatus, mapDeletedStatus]);

    useEffect(() => {
        if (schemaAddedStatus || schemaDeletedStatus) {
            // we don't care what the result was, we just want to know that it came back and the refresh should have been triggered
            setSchemaAddDeleteLoading(false);
        }
    }, [schemaAddedStatus, schemaDeletedStatus]);

    const setToDefaults = () => {
        setTransactionDirection(undefined);
        setEffectiveInterchangeIdValue(undefined);
        setDestinationPathValue(undefined);
        setAcknowledgementMode(undefined);
        setOutboundAcknowledgementOption(undefined);
        setTransactionMaps(undefined);
        setTransactionSchemas(undefined);
        setPostProcessings(undefined);
        setAliases(undefined);
        setIsPipelineInheritedValue(false);
        setPipelineIdValue(undefined);
        setPipelineDescription(undefined);
        setSkipTargetDocumentCreationSelectedOption(SkipTargetCreationOptions.Inherit);
        setSkipTargetDocumentCreationFlag(null);
    };

    const submitForm = () => {
        if (isFormDirty) {
            setSubmitted(true);
            onSave({
                id: transactionId,
                effectivePartnerInterchangeId: effectiveInterchangeIdValue,
                destinationPath: destinationPathValue,
                functionalReconciliationMode: acknowledgementMode,
                skipTargetDocumentCreation: skipTargetDocumentCreationFlag,
                isPipelineInherited: isPipelineInheritedValue,
                pipelineId: pipelineIdValue,
                outboundAcknowledgementOption: outboundAcknowledgementOption
            });
        }
    }

    const closeEditDialog = () => {
        onClose();
    }

    const onEditCancel = () => {
        if (isReadOnly) {
            setIsViewMode(true);
            setFromClientPartnerTransaction();
        } else {
            closeEditDialog();
        }
    }

    const viewModeEditForm = () => {
        // turn off viewMode to allow for editing
        setIsViewMode(false);
        // set default if no value
        if (!clientPartnerTransaction?.functionalReconciliationMode) {
            setAcknowledgementMode(FunctionalReconciliationMode.Acknowledgement);
            setIsFormDirty(true);
        } 
    };

    const validation = () => {
        if (isFormDirty) {
            return true;
        }
        return false;
    }

    const onError = () => {
        setSubmitted(false);
    }

    const onNameEPIIChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setEffectiveInterchangeIdValue(event.target.value);
    };

    const onDestinationPathChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDestinationPathValue(event.target.value);
    };

    const onAcknowledgementModeChange = (event: SelectChangeEvent<string | null>) => {
        setIsFormDirty(true);
        if (event.target.value) {
            setAcknowledgementMode(event.target.value as FunctionalReconciliationMode);
        } else {
            setAcknowledgementMode(undefined);
        }
    };

    const onOutboundAcknowledgementOptionChange = (event: SelectChangeEvent<string | null>) => {
        setIsFormDirty(true);
        if (event.target.value !== "Inherit") {
            setOutboundAcknowledgementOption(event.target.value as OutboundAcknowledgementType);
        } else {
            setOutboundAcknowledgementOption(undefined);
        }
    };

    const onIsPipelineInheritedChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setIsPipelineInheritedValue(event.target.checked);
        if (event.target.checked) {
            //if it is inherited, set it to partner pipeline value
            setPipelineIdValue(partnerTransactionPipeline?.id as string);
            setPipelineDescription(partnerTransactionPipeline?.description as string);
        }
    };

    const onPipelinePickerClose = () => {
        setPipelineDialogOpen(false);
    }


    const onSkipTargetDocumentCreationChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        let option = event.target.value as SkipTargetCreationOptions;
        setSkipTargetDocumentCreationSelectedOption(option);
        if (option === SkipTargetCreationOptions.Yes) {
            setSkipTargetDocumentCreationFlag(true);
        } else if (option === SkipTargetCreationOptions.No) {
            setSkipTargetDocumentCreationFlag(false);
        } else {
            setSkipTargetDocumentCreationFlag(null);
        }
    };

    const onMapPickerClose = () => {
        setMapPickerOpen(false)
    };

    const onSchemaPickerClose = () => {
        setSchemaPickerOpen(false)
    };
   
       
    const savePipelineFromPicker = (selectedPipeline?: PipelineModel) => {
        setIsFormDirty(true);
        if (selectedPipeline) {
            setPipelineIdValue(selectedPipeline.id);
            setPipelineDescription(selectedPipeline.description as string)
        } else {
            setPipelineIdValue('');
            setPipelineDescription('');
        }
        // close the dialog after setting values
        onPipelinePickerClose();
    };

    const saveMapFromPicker = (selectedMapId: string) => {
        if (selectedMapId) {
            onMapAdded(transactionId, selectedMapId);
            setMapAddDeleteLoading(true);
        }
    };

    const onMapChipDelete = (mapId: string) => {
        // for advanced logic around maintaining the file list
        if (mapId) {
            // call parent to dispatch delete of the map
            onMapDeleted(mapId);
            setMapAddDeleteLoading(true);
        }
    };

    const onDownloadMap = (mapFileString: string, mapFileName: string | undefined) => {
        downloadDocument(mapFileString, mapFileName ?? "map.xsl", "text/xml", true, false);
    };

    const onMapChipClick = (mapId: string) => {
        if (mapId && transactionMaps) {
            // get the transactionMap documentString based on the mapId
            const cpTransactionMap = transactionMaps.find((x) => { return x.id === mapId });
            if (cpTransactionMap && cpTransactionMap.transactionMap?.documentString) {
                // download the map document in a new window
                onDownloadMap(cpTransactionMap.transactionMap.documentString as string, cpTransactionMap.transactionMap.sourceFileName as string);
            }

        }
    };

    const saveSchemaFromPicker = (selectedPartnerTransactionSchemaId: string) => {
        if (selectedPartnerTransactionSchemaId) {
            onSchemaAdded(transactionId, selectedPartnerTransactionSchemaId);
            setSchemaAddDeleteLoading(true);
        }
    };

    const onSchemaChipDelete = (schemaId: string) => {
        if (schemaId) {
            // call parent to dispatch delete of the schema
            onSchemaDeleted(schemaId);
            setSchemaAddDeleteLoading(true);
        }
    };

    const onEditPipeline = () => {
        setPipelineDialogOpen(true);
    };

    const onAddMap = () => {
        setMapPickerOpen(true);
    };

    const onAddSchema = () => {
        setSchemaPickerOpen(true);
    };

    const acknowledgementModes = Object.values(FunctionalReconciliationMode);
    const outboundAcknowledgementOptions = Object.values(OutboundAcknowledgementType);

    const getAcknowledgementModeOptions = () => {
        if (acknowledgementModes && acknowledgementModes.length) {
            let items = [];
            const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
            const mappedItems = (
                acknowledgementModes.map((mode: FunctionalReconciliationMode) => (
                    <MenuItem
                        key={mode}
                        value={mode}
                    >
                        {capitalize(mode)}
                    </MenuItem>
                ))
            );
            items.push(blankItem);
            items.push(...mappedItems);
            return items;
        }
        return null;
    };

    const getOutboundAcknowledgementOptions = () => {
        if (outboundAcknowledgementOptions && outboundAcknowledgementOptions.length) {
            let items = [];
            const blankItem = <BlankMenuItem key="0" value="Inherit">Inherit</BlankMenuItem>;
            const mappedItems = (
                outboundAcknowledgementOptions.map((option: OutboundAcknowledgementType) => (
                    <MenuItem
                        key={option}
                        value={option}
                    >
                        {capitalize(option)}
                    </MenuItem>
                ))
            );
            items.push(blankItem);
            items.push(...mappedItems);
            return items;
        }
        return null;
    };

    // all the chips and buttons outside of the main save should be disabled when in view mode or something else is processing
    const disableChipsAndButtons = isViewMode || submitted || schemaAddDeleteLoading || mapAddDeleteLoading;// || postProcessingLoading;
    const disableDownloadButton = submitted || schemaAddDeleteLoading || mapAddDeleteLoading;

    const ePIIProps = {
        'aria-label': 'effective partner interchange id',
        'maxLength': 36,
    };

    const destPathProps = {
        'aria-label': 'destination path',
        'maxLength': 255,
    };


    const isPipelineInheritedProps = {
        'aria-label': 'is pipeline inherited'
    };

    const modeProps = {
        'aria-label': 'functional acknowledgement mode',
    };


    const getPipelineContent = () => {
        let pipelineText = pipelineDescription ? pipelineDescription : "Pipeline Not Set";
        
        return (
            <Grid>
                <Typography variant='caption' aria-label='selected-pipeline' justifyContent='flex-start'>Selected Pipeline</Typography>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Typography variant='body1' >{pipelineText}</Typography>
                    <IconButton 
                        color="primary" 
                        aria-label="edit-Pipeline" 
                        onClick={onEditPipeline}
                        disabled={isPipelineInheritedValue || disableChipsAndButtons}
                    >
                        <EditIcon />
                    </IconButton>
                </Stack>
            </Grid>
        );
    };

    const getFilteredMapList = () => {
        if (transactionMaps && transactionMaps.length > 0) {
            // filter out any maps from available maps that already exist to determine if
            // any still are available to choose from
            let filteredMaps = availableMaps.filter(m => existingMapIds.indexOf(m.id) < 0);
            return filteredMaps;
        }
        else {
            return availableMaps;
        }
    }

    const getMapLabelIcon = () => {
        const filtered = getFilteredMapList();
        if (mapAddDeleteLoading) {
            return (
                <CircularProgress size={"1.5rem"} />
            );
        }
        if (availableMaps && filtered && filtered.length > 0) {
            return (
                <IconButton color="primary" aria-label="add map" onClick={onAddMap} disabled={disableChipsAndButtons}>
                    <AddCircleIcon />
                </IconButton>
            );
        }
        return (
            <Tooltip title="No Maps Available to Add">
                <span>
                    <IconButton color="primary" aria-label="add map" disabled={true}>
                        <AddCircleIcon />
                    </IconButton>
                </span>
            </Tooltip>
        );
    }

    const getMapContent = () => {
        return (
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Typography variant='caption' aria-label='maps'>Selected Maps</Typography>
                    {getMapLabelIcon()}
                </Stack>
                <Stack>
                    {getMapList()}
                </Stack>
            </Grid>
        );
    }
                
    const getMapList = () => {
        if (transactionMaps) {
            if (transactionMaps.length) {
                return (
                    transactionMaps.map((tMap: ClientPartnerTransactionMapModel) => (
                        <Grid key={tMap.id} marginBottom={'12px'}>
                            <CardChip label={tMap.transactionMap?.description}  disabled={disableChipsAndButtons} onDelete={() => onMapChipDelete(tMap.id)}></CardChip>
                            {tMap.transactionMap?.documentString &&
                            <DownloadIconButton color="primary" aria-label="download map" onClick={() => onMapChipClick(tMap.id)} disabled={disableDownloadButton}>
                                <DownloadIcon />
                            </DownloadIconButton>
                            }
                        </Grid>
                    ))
                )
            }
            else {
                <Typography variant='body1' >Maps Not Selected</Typography>
            }
        }
    }

    const getFilteredSchemaList = () => {
        if (transactionSchemas && transactionSchemas.length > 0) {
            // filter out any schemas from available schemas that already exist to determine if
            // any still are available to choose from
            let filteredSchemas = availableSchemas.filter(s => existingSchemaIds.indexOf(s.id) < 0);
            return filteredSchemas;
        }
        else {
            return availableSchemas;
        }
    }

    const getSchemaLabelIcon = () => {
        const filtered = getFilteredSchemaList();
        if (schemaAddDeleteLoading) {
            return (
                <CircularProgress size={"1.5rem"} />
            );
        }
        if (availableSchemas && filtered && filtered.length > 0) {
            return (
                <IconButton color="primary" aria-label="add schema" onClick={onAddSchema} disabled={disableChipsAndButtons}>
                    <AddCircleIcon />
                </IconButton>
            );
        }
        return (
            <Tooltip title="No Schema Available to Add">
                <span>
                    <IconButton color="primary" aria-label="add schema" disabled={true}>
                        <AddCircleIcon />
                    </IconButton>
                </span>
            </Tooltip>
        );
    }

    const getSchemaContent = () => {
        return (
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Typography variant='caption' aria-label='schemas'>Selected EDI Rules Schemas</Typography>
                    {getSchemaLabelIcon()}
                </Stack>
                <Stack>
                    {getSchemaList()}
                </Stack>
            </Grid>
        );
    }

    const getPostProcessingContent = (editMode: boolean) => {
        return (
            <DialogClientPartnerTransactionPostProcessingList
                parentId={transactionId ?? ''}
                postProcessings={postProcessings}
                viewerRole={viewerRole}
                availablePostProcessingBlobs={availablePostProcessingBlobs}
                isLoading={submitted}
                isReadOnly={!editMode}
                refreshClientPartnerTransactionData={refreshData}
            />
        );
    };

    const getAliasList = (editMode: boolean) => {
        return (
            <DialogClientPartnerTransactionAliasList
                parentId={transactionId ?? ''}
                clientPartnerTransactionAliases={aliases}
                viewerRole={viewerRole}
                isLoading={submitted}
                isReadOnly={!editMode}
                refreshClientPartnerTransactionData={refreshData}
            />
        );
    };

    const getSchemaList = () => {
        if (transactionSchemas) {
            if (transactionSchemas.length) {
                return (
                    transactionSchemas.map((schema: ClientPartnerTransactionSchemaModel) => (
                        <Grid key={schema.id} marginBottom={'12px'}>
                            <CardChip label={schema.partnerTransactionSchema?.description} disabled={disableChipsAndButtons} onDelete={() => onSchemaChipDelete(schema.id)}></CardChip>
                        </Grid>
                    ))
                )
            }
            else {
                <Typography variant='body1' >Schemas Not Selected</Typography>
            }
        }
    };

    const getViewFields = () => {
        let inherited = isPipelineInheritedValue ?
            (<Typography variant='body1'>Inherited</Typography>) :
            (<Typography variant='body1'>Not Inherited</Typography>);
        let pipelineText = pipelineDescription ? pipelineDescription : "Pipeline Not Set";


        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='pipeline-inherited'>Pipeline Inherited From Partner</Typography>
                    {inherited}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='selected-pipeline'>Selected Pipeline</Typography>
                    <Typography variant='body1' >{pipelineText}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='maps'>Selected Maps</Typography>
                    {getMapList()}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='schemas'>Selected Schemas</Typography>
                    {getSchemaList()}
                </Grid>                
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='effective-partner-interchange-id'>Effective Partner Interchange Id</Typography>
                    <Typography variant='body1' >{effectiveInterchangeIdValue}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='destination path'>Destination Path</Typography>
                    <Typography variant='body1' >{destinationPathValue}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='functional acknowledgement mode'>Functional Acknowledgement Mode</Typography>
                    <Typography variant='body1' >{capitalize(acknowledgementMode)}</Typography>
                </Grid>
                {transactionDirection === TransactionDirection.Inbound && (
                    <Grid item xs={12}>
                        <Typography variant='caption' aria-label='outbound-acknowledgement-option'>Outbound Acknowledgment Option</Typography>
                        <Typography variant='body1' >{capitalize(outboundAcknowledgementOption ?? 'Inherit')}</Typography>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='skip-target-document-creation'>Skip Target Document Creation</Typography>
                    <Typography variant='body1'>{skipTargetDocumentCreationSelectedOption}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {getPostProcessingContent(false)}
                </Grid>
                <Grid item xs={12}>
                    {getAliasList(false)}
                </Grid>
            </Grid>
        );
    };

    const getEditFields = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                itemID="dialog-edit-transaction-is-pipeline-inherited"
                                disabled={submitted}
                                checked={isPipelineInheritedValue}
                                inputProps={isPipelineInheritedProps}
                                onChange={onIsPipelineInheritedChange}
                                data-cy="dialog-edit-transaction-is-pipeline-inherited"
                            />
                        }
                        label="Pipeline Inherited From Partner"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    {getPipelineContent()}
                </Grid>
                {getMapContent()}
                {getSchemaContent()}
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-edit-transaction-effective-partner-change-id"
                        fullWidth
                        disabled={submitted}
                        autoFocus
                        value={effectiveInterchangeIdValue ?? ""}
                        label="Effective Partner Interchange Id"
                        inputProps={ePIIProps}
                        onChange={onNameEPIIChange}
                        autoComplete="off"
                        data-cy="dialog-edit-transaction-effective-partner-change-id"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-edit-transaction-destination-path"
                        fullWidth
                        disabled={submitted}
                        autoFocus
                        value={destinationPathValue ?? ""}
                        label="Destination Path"
                        inputProps={destPathProps}
                        onChange={onDestinationPathChange}
                        autoComplete="off"
                        data-cy="dialog-edit-transaction-destination-path"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="dialog-edit-transaction-acknowledgement-mode-label">Functional Acknowledgement Mode</InputLabel>
                        <Select
                            labelId="dialog-edit-transaction-acknowledgement-mode-label"
                            aria-labelledby="dialog-edit-transaction-acknowledgement-mode-label"
                            value={acknowledgementMode ?? ""}
                            onChange={onAcknowledgementModeChange}
                            disabled={submitted}
                            MenuProps={modeProps}
                            data-cy="dialog-edit-transaction-acknowledgement-mode"
                        >
                            {getAcknowledgementModeOptions()}
                        </Select>
                    </FormControl>
                </Grid>
                {transactionDirection === TransactionDirection.Inbound && (
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="dialog-edit-transaction-outbound-acknowledgement-option-label">Outbound Acknowledgement Option</InputLabel>
                        <Select
                            labelId="dialog-edit-transaction-outbound-acknowledgement-option-label"
                            aria-labelledby="dialog-edit-transaction-outbound-acknowledgement-option-label"
                            value={outboundAcknowledgementOption ?? "Inherit"}
                            onChange={onOutboundAcknowledgementOptionChange}
                            disabled={submitted}
                            MenuProps={{
                                'aria-label': 'outbound acknowledgement option',
                            }}
                            data-cy="dialog-edit-transaction-outbound-acknowledgement-option"
                        >
                            {getOutboundAcknowledgementOptions()}
                        </Select>
                    </FormControl>
                </Grid>
                )}
                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel id="dialog-edit-transaction-skip-create-doc-label"><Typography variant="caption">Skip Target Document Creation</Typography></FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="dialog-edit-transaction-skip-create-doc-label"
                            name="notification-type-radio-buttons-group"
                            value={skipTargetDocumentCreationSelectedOption}
                            onChange={onSkipTargetDocumentCreationChange}
                            data-cy="dialog-edit-transaction-skip-create-doc"
                        >
                            <FormControlLabel value={SkipTargetCreationOptions.Yes} control={<Radio />} label={SkipTargetCreationOptions.Yes} disabled={submitted} aria-label={SkipTargetCreationOptions.Yes} />
                            <FormControlLabel value={SkipTargetCreationOptions.No} control={<Radio />} label={SkipTargetCreationOptions.No} disabled={submitted} aria-label={SkipTargetCreationOptions.No} />
                            <FormControlLabel value={SkipTargetCreationOptions.Inherit} control={<Radio />} label={SkipTargetCreationOptions.Inherit} disabled={submitted} aria-label={SkipTargetCreationOptions.Inherit} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    {getPostProcessingContent(true)}
                </Grid>
                <Grid item xs={12}>
                    {getAliasList(true)}
                </Grid>
            </Grid>
        )
    };

    const getContent = () => {
        return isViewMode ? getViewFields() : getEditFields();
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted || schemaAddDeleteLoading || mapAddDeleteLoading}
            id={transactionId}
            entityName="Transaction"
            maxWidth='md'
            validate={validation}
            onCancel={onEditCancel}
            onClose={closeEditDialog}
            onSave={submitForm}
            onError={onError}
            error={error}
            isReadOnly={isViewMode}
            onViewModeEdit={viewModeEditForm}
            canEditFromReadOnly={true}
            viewerRole={viewerRole}
        >
            {getContent()}
            <TransactionPipelineDialog
                isOpen={pipelineDialogOpen}
                pipeline={clientPartnerTransaction?.pipeline as PipelineModel}
                onClose={onPipelinePickerClose}
                onSave={savePipelineFromPicker}
                error={error}
            />
            <TransactionMapPickerDialog
                isOpen={mapPickerOpen}
                availableMaps={availableMaps}
                existingMapIds={existingMapIds}
                onClose={onMapPickerClose}
                onSave={saveMapFromPicker}
            />
            <PartnerTransactionSchemaPickerDialog
                isOpen={schemaPickerOpen}
                availableSchemas={availableSchemas}
                existingSchemaIds={existingSchemaIds}
                onClose={onSchemaPickerClose}
                onSave={saveSchemaFromPicker}
            />
        </AddEditDialog>
    );
}

export default EditClientPartnerTransactionDialog;







