import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { Viewer, ToastSeverity } from "../../util/Constants";
import { GridActionsCellItem, GridColumns, GridOverlay, GridRowModel, GridRowParams, GridSelectionModel, GridSortItem, GridSortModel, useGridApiContext } from "@mui/x-data-grid-pro";
import { CrossReferenceModel, CrossReferenceOrderByInput, GetCrossReferenceInput, OrderDirection, RequestResult, UpsertCrossReferenceInput } from "../../gql-types.generated";
import { useSelector } from "react-redux";
import { captureDeleteCrossReferenceStatus, captureUpsertCrossReferenceStatus, clearError, clearState, selectCrossReferencePagingResult, selectCrossReferences, selectDeleteCrossReferenceStatus, selectError, selectRequestsInFlight, selectUpsertCrossReferenceStatus } from "./CrossReferenceTableSlice";
import { useTitle } from "../../util/Common";
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { debounce } from "lodash";
import { deleteCrossReference, fetchCrossReferenceTable, upsertCrossReference } from "./CrossReferenceTableActions";
import ErrorMessage from "../../components/ErrorMessage";
import { defaultPageSize } from "../../util/Constants";
import { CircularProgress, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MainDataGridLoadingSkeleton from "../../components/MainDataGridLoadingSkeleton";
import NoResultsMessage from "../../components/NoResultsMessage";
import CreateNewButton from '../../components/buttons/CreateNewButton';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import { viewerCanAddDelete, viewerCanEdit } from "../../util/ViewerUtility";
import { DataGridListScrollBox, MainContentBox, MainDataGridNoRowHover, PageTitleToolbarGrid } from "../../util/SharedStyles";
import PageTitleBar from "../../components/PageTitleBar";
import FiltersButton from "../../components/buttons/FiltersButton";
import CrossReferenceDialog from "../../components/dialogs/CrossReferenceDialog";
import CrossReferenceFilterBar from "../../components/filters/CrossReferenceFilterBar";




interface CrossReferenceTableProps {
    viewer: Viewer | undefined;
}

const CrossReferenceTable: React.FC<CrossReferenceTableProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [contentAreaHeight, setContentAreaHeight] = useState('200px'); //need an actual default height so grid doesn't error on load
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [selectedCrossReference, setSelectedCrossReference] = useState<CrossReferenceModel | undefined>(undefined);
    const [filtersOpen, setFiltersOpen] = useState(true);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterCode, setFilterCode] = useState<string | undefined>(undefined);
    const [filterDescription, setFilterDescription] = useState<string | undefined>(undefined);
    const [filterVal, setFilterVal] = useState<string | undefined>(undefined);
    const [filterVersion, setFilterVersion] = useState<string | undefined>(undefined);
    const [filterCount, setFilterCount] = useState(0);
    const [crossReferenceTableRows, setCrossReferenceTableRows] = useState<GridRowModel[] | undefined>(undefined);
    const [debounceOn, setDebounceOn] = useState(false);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
    const [sortFirstLoad, setSortFirstLoad] = useState(true);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'code',
            sort: 'asc',
        },
    ]);
    const canEdit = viewerCanEdit(viewer);
    const canAddOrDelete = viewerCanAddDelete(viewer);
    const [serverSortModel, setServerSortModel] = useState<CrossReferenceOrderByInput[] |undefined>([{
        code: OrderDirection.Asc
    } as CrossReferenceOrderByInput]);

    const pageSize = defaultPageSize;

    const error = useSelector(selectError);
    const requestsInFlight = useSelector(selectRequestsInFlight);
    const crossReferences = useSelector(selectCrossReferences);
    const pagingResult = useSelector(selectCrossReferencePagingResult);
    const upsertCrossReferenceStatus = useSelector(selectUpsertCrossReferenceStatus);
    const deleteCrossReferenceStatus = useSelector(selectDeleteCrossReferenceStatus);

    useTitle("Cross Reference Table");

    const debounceOnSortingChanged = useRef(
        debounce((servSortModel) => {
            onSortModelChanged(servSortModel);
        }, 1000)
    ).current;

    useEffect(() => {
        dispatch(clearState());
        setTimeout(() => {
            setDebounceOn(true);
        }, 200);
    },[]);

    useEffect(() => {
        setCrossReferenceTableRows(getCrossReferenceTableRows());

        if (!crossReferences && requestsInFlight > 0) {
            setIsLoading(true);
        } else if (requestsInFlight > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [crossReferences, requestsInFlight]);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const titleHeight = document.getElementById('cross-reference-title-comp')?.clientHeight || 0;
        // if filters are open, include that in the calculation
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('cross-reference-filter-bar')?.clientHeight || 0;
        }
        let totalHeaderAreaHeight = titleHeight + filterBarHeight;
        if (totalHeaderAreaHeight > 0) {
            setContentAreaHeight(`calc(100% - ${totalHeaderAreaHeight}px)`);
        }
    }, [crossReferences, filtersOpen]);

    useEffect(() => {
        if (upsertCrossReferenceStatus?.result === RequestResult.Success) {
            onCrossReferenceDialogClose();
            dispatch(setToastConfig({
                message: upsertCrossReferenceStatus.message as string,
                severity: ToastSeverity.Success
            }));
            dispatch(captureUpsertCrossReferenceStatus());
        }
    }, [upsertCrossReferenceStatus?.result]);

    useEffect(() => {
        if (deleteCrossReferenceStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteCrossReferenceStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteCrossReferenceStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteCrossReferenceStatus.message as string);
        }
    }, [deleteCrossReferenceStatus?.result]);

    useEffect(() => {
        if (crossReferences) {
            let numFilters =0;
            if (filterCode && filterCode.length > 0) {
                numFilters += 1;
            }
            if (filterDescription && filterDescription.length > 0) {
                numFilters += 1;
            }
            if (filterVal && filterVal.length > 0) {
                numFilters += 1;
            }
            if (filterVersion && filterVersion.length > 0) {
                numFilters += 1;
            }
            setFilterCount(numFilters);
        }
    }, [crossReferences, filterCode, filterDescription, filterVal, filterVersion]);

    useEffect(() => {
        return () => {
            debounceOnSortingChanged.cancel();
        };
    }, [debounceOnSortingChanged]);

    const getSortRefreshInput = () => {
        return {
            after: undefined,
            limit: pageSize,
            code: filterCode,
            description: filterDescription,
            order: serverSortModel,
            val: filterVal,
            version: filterVersion
        } as GetCrossReferenceInput
    }

    useEffect(() => {
        if (!sortFirstLoad) {
            const sortRefreshData = getSortRefreshInput();
            if (debounceOn !== true) {
                onSortModelChanged(sortRefreshData);
            } else {
                debounceOnSortingChanged(sortRefreshData);
            }
        } else {
            setSortFirstLoad(false);
        }
    }, [serverSortModel]);

    const onSortModelChanged = (refreshInput: GetCrossReferenceInput) => {
        dispatch(clearState());
        dispatch(fetchCrossReferenceTable(refreshInput));
    }

    const onSelectionModelChange = (currentSelectionModel: GridSelectionModel) => {
        setSelectionModel(currentSelectionModel);
    };


    const GetApiRef = () => {
        return useGridApiContext();
    }

    const loadingOverlay = () => {

        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the Cross Reference Table' error={error}></ErrorMessage>
                )}
                {!error && (crossReferences && crossReferences.length > 0 && requestsInFlight > 0) && (
                    <CircularProgress aria-label={'progress spinner'} key={'logs-spinner'} size={42} sx={{ zIndex: 1 }} />
                )}
                {!error && (!crossReferences) && (
                    <MainDataGridLoadingSkeleton apiRef={GetApiRef()} rowBottomMargin={6} />
                )}
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the Cross Reference Table' error={error}></ErrorMessage>
                )}
                {!error && (crossReferences && crossReferences.length <= 0 && filterCount > 0) && (
                    <NoResultsMessage topMargin={6} message="Try different filters to see results." />
                )}
            </GridOverlay>
        );
    };    

    const getSelectedRowCrossReference = useCallback((rowId: string) => () => {
        if (rowId && crossReferences?.length) {
            let crossRef = crossReferences.find(crossRef => crossRef.id === rowId);
            return crossRef;
        }
        return undefined;
    }, [crossReferences]);

    const editCrossReferenceHandler = useCallback((rowId: string) => () => {
        let crossRef = getSelectedRowCrossReference(rowId);
        if (crossRef) {
            setSelectedCrossReference(crossRef);
            dispatch(clearError());
            setOpenModify(true);
        }
    }, [getSelectedRowCrossReference, dispatch]);

    const deleteCrossReferenceHandler = useCallback((rowId: string) => () => {
        let crossRef = getSelectedRowCrossReference(rowId);
        if (crossRef) {
            setSelectedCrossReference(crossRef);
            dispatch(captureDeleteCrossReferenceStatus());
            setDeleteErrorMessage('');
            setOpenDelete(true);
        }
    }, [getSelectedRowCrossReference, dispatch]);

    const getCrossReferenceTableRows = () => {
        if (crossReferences && crossReferences.length > 0) {
            return crossReferences.map((crossRef) => {
                const {
                    id,
                    code,
                    description,
                    val,
                    version
                } = crossRef;

                return {
                    _raw: crossRef,
                    id,
                    code,
                    description,
                    val,
                    version
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return[];
        }
    };

    const crossReferenceColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                // Code
                headerName: "CODE",
                field: 'code',
                minWidth: 200,                
                flex: 2,
                sortable: true,
            }, {
                // Val
                headerName: "VAL",
                field: 'val',
                minWidth: 200,                
                flex: 2,
                sortable: true,
            }, {
                // Description
                headerName: "DESCRIPTION",
                field: 'description',
                minWidth: 200,                
                flex: 2,
                sortable: true,
            }, {
                // Version
                headerName: "VERSION",
                field: 'version',
                minWidth: 200,                
                flex: 2,
                sortable: true,
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                resizable: false,
                headerName: '',
                width: 100,
                headerAlign: 'right',
                align: 'center',
                hide: !canEdit, // hide column for reader role
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editCrossReferenceHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        hidden={!canAddOrDelete}
                        onClick={deleteCrossReferenceHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],
            }
        ],
        [editCrossReferenceHandler, deleteCrossReferenceHandler],
    );

    const getServerSortEntryFromGridSortEntry = (entry: GridSortItem) => {
        let newModel = {} as CrossReferenceOrderByInput;
        switch (entry.field) {
            case 'code':
                newModel.code = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'description':
                newModel.description = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'val':
                newModel.val = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'version':
                newModel.version = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
        }
        return newModel;
    }

    const createdAndSetServerSortModel = (model: GridSortModel) => {
        if (model && model.length > 0) {
            let newArray = [] as CrossReferenceOrderByInput[];
            let i = 0;
            for (i; i < model.length; ++i) {
                newArray.push(getServerSortEntryFromGridSortEntry(model[i]));
            }
            setServerSortModel(newArray);
        } else {
            setServerSortModel(undefined);
        }

    };

    const onSortModelChange = (model: GridSortModel) => {
        createdAndSetServerSortModel(model);
        setSortModel(model);
    };

    const onFiltersClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onAddCrossReferenceClick = () => {
        // Clear error and open dialog
        dispatch(clearError());
        // ensure no previously selected standard is set
        if (selectedCrossReference) {
            setSelectedCrossReference(undefined);
        }
        setOpenModify(true);
    };

    const onCrossReferenceDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteCrossReferenceStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        dispatch(clearError());
        setSelectedCrossReference(undefined);
        dispatch(fetchCrossReferenceTable({
            after: undefined,
            limit: pageSize,
            code: filterCode,
            description: filterDescription,
            order: serverSortModel,
            val: filterVal,
            version: filterVersion
        }));
    };

    const onCrossReferenceDialogSave = (
        crossRef: UpsertCrossReferenceInput
    ) => {
        dispatch(upsertCrossReference(crossRef));
    };
    
    const onDeleteDialogConfirm = (id: string) => {
        dispatch(deleteCrossReference(id));
    }

    const onFilterBarClose = () => {
        // set filters as cleared
        setFiltersCleared(true);
        setFilterCount(0);
        // hide filter bar
        setFiltersOpen(false);
    };

    const loadPage = (endEdge: string) => {
        dispatch(fetchCrossReferenceTable({
            after: endEdge,
            limit: pageSize,
            code: filterCode,
            description: filterDescription,
            order: serverSortModel,
            val: filterVal,
            version: filterVersion
        }));
    };

    const handlePageLoad = () => {
        if (!pagingResult) {
            return;
        }
        if (!pagingResult.cursor?.nextPage) {
            return;
        }

        loadPage(pagingResult.cursor.nextPage);
    };

    const refreshFilters = (
        filterCode: string | undefined, 
        filterDescription: string | undefined,
        filterVal: string | undefined,
        filterVersion: string | undefined) => {
        setFilterCode(filterCode);
        setFilterDescription(filterDescription);
        setFilterVal(filterVal);
        setFilterVersion(filterVersion);
        dispatch(clearState());
        dispatch(fetchCrossReferenceTable({
            after: undefined,
            limit: pageSize,
            code: filterCode,
            description: filterDescription,
            order: serverSortModel,
            val: filterVal,
            version: filterVersion
        }));
    }

    return (
        <MainContentBox>
            <PageTitleBar text='Cross Reference Table' id="cross-reference-title-comp">
                <PageTitleToolbarGrid item>
                    <FiltersButton 
                        onClick={onFiltersClick}
                        filterCount={filterCount}
                        filtersCleared={filtersCleared}
                        disabled={isLoading}
                        aria-label="filter button"
                        data-cy="filters"
                    />
                    {canAddOrDelete &&
                        <CreateNewButton
                            text="New Cross Reference Entry"
                            onClick={onAddCrossReferenceClick}
                            data-cy="add-new-cross-reference-entry"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <CrossReferenceFilterBar
                id="cross-reference-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}
            />
            <DataGridListScrollBox scrollheight={contentAreaHeight}>
                <MainDataGridNoRowHover
                    loading={isLoading}
                    rowHeight={52}
                    aria-label="Cross Reference Table"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    rows={crossReferenceTableRows ?? []}
                    columns={crossReferenceColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    selectionModel={selectionModel}
                    onSelectionModelChange={onSelectionModelChange}
                    onRowsScrollEnd={handlePageLoad}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />
            </DataGridListScrollBox>
            <CrossReferenceDialog
                isOpen={openModify}
                crossReference={selectedCrossReference}
                onClose={onCrossReferenceDialogClose}
                onSave={onCrossReferenceDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedCrossReference?.id}
                heading={'Delete Cross Reference Entry'}
                message={'Are you sure you want to delete \'' + selectedCrossReference?.val + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </MainContentBox>
    )
};

export default CrossReferenceTable;