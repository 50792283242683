import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { GetStandardExchangeFormatInput, StandardExchangeFormatFileListQuery, PagingResultModelOfBlobStorageModel } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_SEF_FILE_LIST = gql`
    query standardExchangeFormatFileList($input: GetStandardExchangeFormatInput!) {
        standardExchangeFormatStorageFiles (input: $input) {
            cursor {
                nextPage
                previousPage
              }
            nodes {
                id
                blobDocumentType
                blobFileName
                comment
                fileName
                isActive
                version
                createdBy
                createdByName
                createdTime
                lastModifiedBy
                lastModifiedByName
                lastModifiedTime
                
                blobStorageReference {
                    id
                    blobStorageId
                    blobDocumentId
                }
                
            }
        }
    }
`;

export const queryStandardExchangeFormatFileList = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters?: GetStandardExchangeFormatInput
): Promise<PagingResultModelOfBlobStorageModel | undefined> => {
    const { after, limit, order, id, blobDocumentId, blobStorageFileName, blobStorageDateFrom, blobStorageDateTo } = filters ?? {};
    const { data, errors } = await client.query<StandardExchangeFormatFileListQuery>({
        variables: {
            input: {
                after,
                limit,
                id,
                blobDocumentId,
                blobStorageFileName, 
                blobStorageDateFrom, 
                blobStorageDateTo,
                order
            }
        },
        query: QUERY_SEF_FILE_LIST,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.standardExchangeFormatStorageFiles as PagingResultModelOfBlobStorageModel;
}