import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertPartnerPayload, UpsertPartnerInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_TRADING_PARTNER = gql`
    mutation upsertTradingPartner($input: UpsertPartnerInput!){
        upsertPartner(input: $input){
            message
            errors {
                code
                message
            }
            result
            partner {
                id
            }
        }
    }
`;

export const mutationUpsertTradingPartner = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        partnerInput: UpsertPartnerInput
    }
): Promise<UpsertPartnerPayload | undefined> => {
    const {
        addresses,
        businessAreaId,
        ediStandardIds,
        emails,
        id,
        internalPartnerCode,
        isaQualifierCode,
        name,
        partnerCode,
        phones,
        statusCode,
        website,
        functionalReconciliationMode,
        localeCode
    } = args.partnerInput;
    const { data, errors } = await client.mutate<{ upsertPartner: UpsertPartnerPayload }>({
        variables: {
            input: {
                addresses,
                businessAreaId,
                ediStandardIds,
                emails,
                id,
                internalPartnerCode,
                isaQualifierCode,
                name,
                partnerCode,
                phones,
                statusCode,
                website,
                functionalReconciliationMode,
                localeCode
            }
        },
        mutation: MUTATION_UPSERT_TRADING_PARTNER
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the trading partner save.');
    }
    if (data && data.upsertPartner) {
        return data.upsertPartner;
    }
    return undefined;
};