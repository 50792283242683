import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CircularProgress, Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridOverlay, GridRowModel, GridSortModel, GridRowParams } from '@mui/x-data-grid-pro';
import { DialogDynamicListToolbar, DialogDynamicListContainer, DialogDynamicListContent, DialogDynamicListHeader, DialogDynamicListDataGrid } from '../../../util/SharedStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ClientPartnerTransactionAliasModel, RequestResult, UserRole, UpsertClientPartnerTransactionAliasInput } from "../../../gql-types.generated";
import { selectError, selectDeleteClientPartnerTransactionAliasStatus, selectUpsertClientPartnerTransactionAliasStatus, captureDeleteClientPartnerTransactionAliasStatus, captureUpsertClientPartnerTransactionAliasStatus } from "../../../features/ClientPartnerDetails/ClientPartnerDetailsSlice";
import { upsertClientPartnerTransactionAlias, deleteClientPartnerTransactionAlias } from '../../../features/ClientPartnerDetails/ClientPartnerDetailsActions';
import CreateNewButton from '../../buttons/CreateNewButton';
import DeleteDialog from '../../dialogs/DeleteDialog';
import ClientPartnerTransactionAliasDialog from '../../dialogs/ClientPartnerTransactionAliasDialog';
import { setToastConfig } from '../../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../../util/Constants';
import { viewerCanEdit } from '../../../util/ViewerUtility';

interface ClientPartnerTransactionAliasListProps {
    parentId: string;
    clientPartnerTransactionAliases: ClientPartnerTransactionAliasModel[] | undefined;
    viewerRole: UserRole | undefined;
    isLoading?: boolean;
    isReadOnly?: boolean;    
    refreshClientPartnerTransactionData?: () => void;
}

const DialogClientPartnerTransactionAliasList: React.FC<ClientPartnerTransactionAliasListProps> = props => {
    const { parentId, clientPartnerTransactionAliases, viewerRole, refreshClientPartnerTransactionData, isLoading = false, isReadOnly = false } = props;
    const dispatch = useAppDispatch();
    const canEdit = viewerCanEdit(viewerRole);
    const [gridHeight, setGridHeight] = useState<string>('48px');
    const [openClientPartnerTransactionAliasDialog, setOpenClientPartnerTransactionAliasDialog] = useState(false);
    const [selectedClientPartnerTransactionAlias, setSelectedClientPartnerTransactionAlias] = useState<ClientPartnerTransactionAliasModel | undefined>(undefined); 
    const [clientPartnerTransactionAliasRows, setClientPartnerTransactionAliasRows] = useState<GridRowModel[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'alias',
            sort: 'asc',
        },
    ]);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [isSavingOrDeletingClientPartnerTransactionAlias, setIsSavingOrDeletingClientPartnerTransactionAlias] = useState(false);

    const error = useAppSelector(selectError);
    const deleteClientPartnerTransactionAliasStatus = useAppSelector(selectDeleteClientPartnerTransactionAliasStatus);
    const saveClientPartnerTransactionAliasStatus = useAppSelector(selectUpsertClientPartnerTransactionAliasStatus);

    useEffect(() => {
        setClientPartnerTransactionAliasRows(getClientPartnerTransactionAliasRows());
        setGridHeight(getGridHeight());
    }, [clientPartnerTransactionAliases]);

    
    // declare the data refreshing function
    // as async so list will show as loading
    // up until refresh is complete
    const refreshData = async () => {
        if (refreshClientPartnerTransactionData) {
            setIsSavingOrDeletingClientPartnerTransactionAlias(true);
            await refreshClientPartnerTransactionData();
        }
        setIsSavingOrDeletingClientPartnerTransactionAlias(false);
    };

    useEffect(() => {
        if (saveClientPartnerTransactionAliasStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: saveClientPartnerTransactionAliasStatus.message as string,
                severity: ToastSeverity.Success
            }));
            onClientPartnerTransactionAliasDialogClose();
            refreshData();
        } else if (saveClientPartnerTransactionAliasStatus?.result === RequestResult.Fail) {
            setIsSavingOrDeletingClientPartnerTransactionAlias(false);
        }
    }, [saveClientPartnerTransactionAliasStatus?.result]);

    useEffect(() => {
        if (deleteClientPartnerTransactionAliasStatus) {
            if (deleteClientPartnerTransactionAliasStatus.result === RequestResult.Success) {
                onDeleteDialogClose();
                refreshData();
            } else if (deleteClientPartnerTransactionAliasStatus.result === RequestResult.Fail) {
                setDeleteErrorMessage(deleteClientPartnerTransactionAliasStatus?.message as string);
                setIsSavingOrDeletingClientPartnerTransactionAlias(false);
            }
        }
    }, [deleteClientPartnerTransactionAliasStatus?.result]);

    const getSelectedRowClientPartnerTransactionAlias = useCallback((rowId: string) => () => {
        if (rowId && clientPartnerTransactionAliases?.length) {
            let clientPartnerTransactionAlias = clientPartnerTransactionAliases.find(clientPartnerTransactionAlias => clientPartnerTransactionAlias.id === rowId);
            return clientPartnerTransactionAlias;
        }
        return undefined;
    }, [clientPartnerTransactionAliases]);

    const deleteClientPartnerTransactionAliasHandler = useCallback((rowId: string) => () => {
        let clientPartnerTransactionAlias = getSelectedRowClientPartnerTransactionAlias(rowId);
        if (clientPartnerTransactionAlias) {
            setSelectedClientPartnerTransactionAlias(clientPartnerTransactionAlias);
            setOpenDelete(true);
        }
    }, [getSelectedRowClientPartnerTransactionAlias]);

    const editClientPartnerTransactionAliasHandler = useCallback((rowId: string) => () => {
        let clientPartnerTransactionAlias = getSelectedRowClientPartnerTransactionAlias(rowId);
        if (clientPartnerTransactionAlias) {
            setSelectedClientPartnerTransactionAlias(clientPartnerTransactionAlias);
            setOpenClientPartnerTransactionAliasDialog(true);
        }
    }, [getSelectedRowClientPartnerTransactionAlias]);

    const setDefaults = () => {
        setSelectedClientPartnerTransactionAlias(undefined);
    };

    const onAddClientPartnerTransactionAlias = () => {
        setDefaults();
        setOpenClientPartnerTransactionAliasDialog(true);
    };

    const onDialogClose = () => {
        setDefaults();
    };

    const onClientPartnerTransactionAliasDialogClose = () => {
        setOpenClientPartnerTransactionAliasDialog(false);
        // if there was an error and dialog is closed,
        // refresh the data to clear error on parent
        if (error) {
            refreshData();
        }
        dispatch(captureUpsertClientPartnerTransactionAliasStatus());
        onDialogClose();
    };

    const onClientPartnerTransactionAliasDialogSave = (parentId: string, alias: string, aliasId?: string) => {
        if (alias) {
            setIsSavingOrDeletingClientPartnerTransactionAlias(true);
            let inputData: UpsertClientPartnerTransactionAliasInput = {clientPartnerTransactionId: parentId, alias: alias, id: aliasId} 
            dispatch(upsertClientPartnerTransactionAlias(inputData));
        }
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        dispatch(captureDeleteClientPartnerTransactionAliasStatus());
        onDialogClose();
        setDeleteErrorMessage('');
    };

    const onDeleteDialogConfirm = () => {
        // delete the selected clientPartnerTransactionAlias
        if (selectedClientPartnerTransactionAlias) {
            setIsSavingOrDeletingClientPartnerTransactionAlias(true);
            dispatch(deleteClientPartnerTransactionAlias(selectedClientPartnerTransactionAlias.id as string));
        }
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'clientPartnerTransactionAlias-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const getGridHeight = () => {
        // either the grid or its container needs a set height to render properly
        // the container only has maxHeight so that it doesn't display a lot of 
        // white space when there are no clientPartnerTransactionAliases, so need to calculate a height
        // based on the number of rows since want a scroll bar instead of paging
        if (clientPartnerTransactionAliases) {
            if (clientPartnerTransactionAliases.length === 1) {
                return '108px';
            } else if (clientPartnerTransactionAliases.length === 2) {
                return '156px';
            } else if (clientPartnerTransactionAliases.length >= 3) {
                return '208px';
            }
        }
        return '48px';
    };

    const getClientPartnerTransactionAliasRows = () => {
        if (clientPartnerTransactionAliases && clientPartnerTransactionAliases.length > 0) {
            return clientPartnerTransactionAliases.map((clientPartnerTransactionAlias) => {
                const { id, alias } = clientPartnerTransactionAlias;
                return {
                    _raw: clientPartnerTransactionAlias,
                    id,
                    alias,
                 } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const clientPartnerTransactionAliasColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'alias',
                headerName: 'ALIAS',
                flex: 1,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild",
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                resizable: false,
                headerName: '',
                minWidth: 100,
                headerAlign: 'center',
                align: 'center',
                hide: isReadOnly || !canEdit, // hide column for readonly mode and reader role
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editClientPartnerTransactionAliasHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        onClick={deleteClientPartnerTransactionAliasHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],

            },
        ],
        [deleteClientPartnerTransactionAliasHandler, editClientPartnerTransactionAliasHandler, canEdit, isReadOnly],
    );

    return (
        <DialogDynamicListContainer>

            <DialogDynamicListToolbar justify="space-between" noborder={+true}>
                <DialogDynamicListHeader>Aliases</DialogDynamicListHeader>
                {(canEdit && !isReadOnly) &&
                    <CreateNewButton
                        text="Add Alias"
                        tooltip="Click here to add another Transaction Alias"
                        variant="text"
                        onClick={onAddClientPartnerTransactionAlias}
                        disabled={isSavingOrDeletingClientPartnerTransactionAlias || isLoading}
                        data-cy="add-new-clientPartnerTransactionAlias"
                    />
                }
            </DialogDynamicListToolbar>

            <DialogDynamicListContent>
                {((clientPartnerTransactionAliases !== undefined && clientPartnerTransactionAliases?.length > 0) || isSavingOrDeletingClientPartnerTransactionAlias) &&
                    <DialogDynamicListDataGrid
                        loading={isSavingOrDeletingClientPartnerTransactionAlias || isLoading}
                        height={gridHeight}
                        headerHeight={38}
                        rowHeight={52}
                        aria-label="ClientPartnerTransactionAlias List"
                        hideFooter
                        disableColumnMenu
                        disableColumnFilter
                        disableMultipleSelection
                        rows={clientPartnerTransactionAliasRows}
                        columns={clientPartnerTransactionAliasColumns}
                        sortingOrder={['asc', 'desc']}
                        sortModel={sortModel}
                        onSortModelChange={onSortModelChange}
                        components={{
                            // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                            LoadingOverlay: loadingOverlay,
                        }}
                    />
                }
            </DialogDynamicListContent>

            <ClientPartnerTransactionAliasDialog
                isOpen={openClientPartnerTransactionAliasDialog}
                parentId={parentId}
                transactionAlias={selectedClientPartnerTransactionAlias?.alias}
                transactionAliasId={selectedClientPartnerTransactionAlias?.id}
                onClose={onClientPartnerTransactionAliasDialogClose}
                onSave={onClientPartnerTransactionAliasDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedClientPartnerTransactionAlias?.id ?? ''}
                heading={'Delete Alias'}
                message={'Are you sure you want to delete \'' + selectedClientPartnerTransactionAlias?.alias + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </DialogDynamicListContainer>
    );

}

export default DialogClientPartnerTransactionAliasList;
