import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';

import { UpsertPartnerContactPayload, UpsertAddressInput, UpsertEmailInput, UpsertPhoneInput, UpsertPartnerContactInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_TRADING_PARTNER_CONTACT = gql`
    mutation upsertTradingPartnerContact($input: UpsertPartnerContactInput!){
        upsertPartnerContact(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertTradingPartnerContact = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        partnerId: string,
        addresses?: UpsertAddressInput[],
        description?: string,
        emails?: UpsertEmailInput[],
        id?: string,
        isActive?: boolean,
        isPrimary?: boolean,
        jobTitle?: string,
        name?: string,
        phones?: UpsertPhoneInput[]
    }
): Promise<UpsertPartnerContactPayload | undefined> => {
    const {
        partnerId,
        addresses,
        description,
        emails,
        id,
        isActive,
        isPrimary,
        jobTitle,
        name,
        phones
    } = options as UpsertPartnerContactInput;
    const { data, errors } = await client.mutate<{ upsertPartnerContact: UpsertPartnerContactPayload }>({
        variables: {
            input: {
                partnerId,
                addresses,
                description,
                emails,
                id,
                isActive,
                isPrimary,
                jobTitle,
                name,
                phones
            }
        },
        mutation: MUTATION_UPSERT_TRADING_PARTNER_CONTACT
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the Trading Partner Contact save.');
    }
    if (data && data.upsertPartnerContact) {
        return data.upsertPartnerContact;
    }
    return undefined;
};