import { PagingResultModelOfPortalTransactionModel, PortalTransactionModel } from "../../gql-types.generated";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    portalTransactionPagingResult?: PagingResultModelOfPortalTransactionModel;
    portalTransactionList?: PortalTransactionModel[];
    portalTransactionRequestsInFlight: number;
};

const initialState: SliceState = {
    error: undefined,
    portalTransactionPagingResult: undefined,
    portalTransactionList: undefined,
    portalTransactionRequestsInFlight: 0
};

export const slice = createSlice({
    name: 'portalTransactions',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.portalTransactionList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.portalTransactionRequestsInFlight = 0;
            // eslint-disable-next-line no-param-reassign
            state.portalTransactionPagingResult = undefined;
        },

        fetchPortalTransactionListSuccess: (state, action: PayloadAction<PagingResultModelOfPortalTransactionModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.portalTransactionPagingResult = action.payload;
            let transactions = state.portalTransactionList;
            if (!transactions || !state.portalTransactionPagingResult.cursor?.previousPage) {
                transactions = [];
            }
            if (action.payload.nodes) {
                action.payload.nodes.map(node => {
                    if (!transactions?.some(i => i.id === node?.id)) {
                        transactions?.push(node as PortalTransactionModel)
                    }
                    return node;
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.portalTransactionList = transactions;
        },

        incrementPortalTransactionRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            state.portalTransactionRequestsInFlight += 1;
        },

        decrementPortalTransactionRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.portalTransactionRequestsInFlight > 0) state.portalTransactionRequestsInFlight -= 1;
        },
    },
});

export const selectError = (state: RootState): Error | undefined => state.portalTransactions.error;
export const selectPortalTransactionList = (state: RootState): PortalTransactionModel[] | undefined => state.portalTransactions.portalTransactionList;
export const selectRequestsInFlight = (state: RootState): number => state.portalTransactions.portalTransactionRequestsInFlight;
export const selectPortalTransactionPagingResult = (state: RootState): PagingResultModelOfPortalTransactionModel | undefined => state.portalTransactions.portalTransactionPagingResult;

export const {
    clearError,
    fetchError,
    clearState,
    fetchPortalTransactionListSuccess,
    incrementPortalTransactionRequestsInFlight,
    decrementPortalTransactionRequestsInFlight
} = slice.actions;

export default slice.reducer;
