import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { GridColumns, GridRowModel, GridOverlay, GridRowParams, GridActionsCellItem, GridSortModel } from '@mui/x-data-grid-pro';
import { CircularProgress, Typography, Tooltip } from '@mui/material';
import { TabContainer, TabContent, TabToolbar, TabDataGridNoRowHover } from '../../util/SharedStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { PipelineItemModel, RequestResult, UpsertPipelineItemInput, UserRole } from "../../gql-types.generated";
import { viewerCanEdit, viewerCanAddDelete } from "../../util/ViewerUtility";
import NoRecordsMessage from '../NoRecordsMessage';
import CreateNewButton from '../buttons/CreateNewButton';
import DeleteDialog from "../dialogs/DeleteDialog";
import { captureDeletePipelineItemStatus, clearError, selectUpsertPipelineItemStatus, selectDeletePipelineItemStatus, selectError, captureUpsertPipelineItemStatus } from "../../features/PipelineDetails/PipelineDetailsSlice";
import { deletePipelineItem, upsertPipelineItem } from "../../features/PipelineDetails/PipelineDetailsActions";
import PipelineItemDialog from "../dialogs/PipelineItemDialog";
import { setToastConfig } from "../../features/EDIContainer/EDIContainerSlice";
import { ToastSeverity } from '../../util/Constants';


interface PipelineItemsListProps {
    viewerRole: UserRole | undefined;
    pipelineId: string;
    pipelineItems: PipelineItemModel[] | undefined;
    isLegacy?: boolean;
    refreshPipelineData: () => void;
}

const PipelineItemsList: React.FC<PipelineItemsListProps> = (props: PipelineItemsListProps) => {
    const { viewerRole, pipelineId, pipelineItems, isLegacy=false, refreshPipelineData } = props;
    const dispatch = useAppDispatch();
    const canEdit = viewerCanEdit(viewerRole);
    const canAddOrDelete = viewerCanAddDelete(viewerRole);
    const [pipelineRows, setPipelineRows] = useState<GridRowModel[] | undefined>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'sortOrder',
            sort: 'asc',
        },
    ]);

    const [selectedPipelineItem, setSelectedPipelineItem] = useState<PipelineItemModel | undefined>();
    const [openDeletePipelineItem, setOpenDeletePipelineItem] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [openEditPipelineItem, setOpenEditPipelineItem] = useState(false);

    //Temp
    const error = useAppSelector(selectError);
    const upsertPipelineItemStatus = useAppSelector(selectUpsertPipelineItemStatus);
    const deletePipelineItemStatus = useAppSelector(selectDeletePipelineItemStatus);

    useEffect(() => {
        setPipelineRows(getPipelineRows());
    }, [pipelineItems]);

    useEffect(() => {
        if (upsertPipelineItemStatus?.result === RequestResult.Success || deletePipelineItemStatus?.result === RequestResult.Success) {
            if (upsertPipelineItemStatus?.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: upsertPipelineItemStatus.message as string,
                    severity: ToastSeverity.Success
                }));
                // remove upsert status and close dialog
                dispatch(captureUpsertPipelineItemStatus());
                editDialogClose();
            }
            if (deletePipelineItemStatus?.result === RequestResult.Success) {
                deleteDialogCloseHandler();
                dispatch(setToastConfig({
                    message: deletePipelineItemStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }
            refreshPipelineData();
        }
        else if (deletePipelineItemStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deletePipelineItemStatus.message as string);
        }
    }, [upsertPipelineItemStatus?.result, deletePipelineItemStatus?.result]);

    const onAddProcessClick = () => {
        // Clear error and open dialog
        setSelectedPipelineItem(undefined);
        setOpenEditPipelineItem(true);
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'pipeline-items-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <Typography variant="body2">
                        Unable to load data. Please try again later.
                    </Typography>
                )}
                {!error && pipelineItems?.length === 0 && (
                    <NoRecordsMessage topMargin={6} message="" />
                )}
            </GridOverlay>
        );
    };

    const getSelectedRowItem = useCallback((rowId: string) => () => {
        if (rowId && pipelineItems?.length) {
            let pipeItem = pipelineItems.find(pipelineItem => pipelineItem.id === rowId);
            return pipeItem;
        }
        return undefined;
    }, [pipelineItems]);

    const deletePipelineItemHandler = useCallback((rowId: string) => () => {
        let pipeItem = getSelectedRowItem(rowId);
        if (pipeItem) {
            setSelectedPipelineItem(pipeItem);
            setOpenDeletePipelineItem(true);
        }
    }, [getSelectedRowItem]);

    const editPipelineItem = useCallback((rowId: string) => () => {
        let pipeItem = getSelectedRowItem(rowId);
        if (pipeItem) {
            setSelectedPipelineItem(pipeItem);
            setOpenEditPipelineItem(true);
        }
    }, [getSelectedRowItem]);

    const pipelineItemsColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'sortOrder',
                headerName: 'SORT ORDER',
                minWidth: 280,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild",

            },
            {
                field: 'ediProcessClassName',
                headerName: 'PROCESS CLASS',
                hide: isLegacy,
                flex: 1,
            },
            {
                field: 'ediProcess',
                headerName: 'PROCESS',
                hide: !isLegacy,
                flex: 1,
            }, {
                field: 'description',
                headerName: 'DESCRIPTION',
                flex: 1,
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                resizable: false,
                headerName: '',
                minWidth: 100,
                headerAlign: 'center',
                align: 'right',
                hide: !canEdit, // hide column for reader role
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editPipelineItem(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        hidden={!canAddOrDelete}
                        onClick={deletePipelineItemHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],

            },
        ], [editPipelineItem, deletePipelineItemHandler, canEdit, canAddOrDelete, isLegacy]
    );

    const getPipelineRows = () => {
        return pipelineItems
            ? (pipelineItems.map((pipelineItem: PipelineItemModel) => {
                const { id, configuration, sortOrder, ediProcessClassName, description } = pipelineItem;
                const process = configuration?.ediProcess;
                const configDescription = configuration?.description;
                return {
                    _raw: pipelineItem,
                    id,
                    sortOrder,
                    description: isLegacy ? configDescription : description,
                    ediProcess: process,
                    ediProcessClassName
                } as GridRowModel;
            }) as GridRowModel[])
            : [];
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const editDialogClose = () => {
        setOpenEditPipelineItem(false);
        onDialogClose();
    };

    const editDialogSave = (upsertData: UpsertPipelineItemInput) => {
        if (upsertData) {
            dispatch(upsertPipelineItem(upsertData));
        }
    };

    const deleteDialogCloseHandler = () => {
        setOpenDeletePipelineItem(false);
        onDialogClose();
        dispatch(captureDeletePipelineItemStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear selectedRow and error on close.
        setSelectedPipelineItem(undefined);
        clearError();
    };

    const deleteDialogConfirmHandler = () => {
        // delete the item
        dispatch(deletePipelineItem(selectedPipelineItem?.id as string));
    }

    return (
        <TabContainer>
            <TabToolbar justify="flex-end">
                {canAddOrDelete &&
                    <CreateNewButton
                        text="Add Process"
                        onClick={onAddProcessClick}
                        data-cy="add-process"
                    />
                }
            </TabToolbar>
            <TabContent>
                <TabDataGridNoRowHover
                    loading={false}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="Pipeline Items List"
                    hideFooter
                    disableSelectionOnClick
                    disableColumnMenu
                    rows={pipelineRows ?? []}
                    columns={pipelineItemsColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />
            </TabContent>
            <PipelineItemDialog
                isOpen={openEditPipelineItem}
                viewerRole={viewerRole}
                pipelineId={pipelineId}
                pipelineItem={selectedPipelineItem}
                isLegacy={isLegacy}
                onClose={editDialogClose}
                onSave={editDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDeletePipelineItem}
                id={selectedPipelineItem?.id ?? ''}
                heading={'Delete Pipeline Item'}
                message={`Are you sure you want to remove this Pipeline Item from this Pipeline?`}
                onConfirm={deleteDialogConfirmHandler}
                onReject={deleteDialogCloseHandler}
                errorMessage={deleteErrorMessage}
            />
        </TabContainer>
    );
}

export default PipelineItemsList;