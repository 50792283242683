import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CircularProgress, Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridOverlay, GridRowModel, GridSortModel, GridRowParams } from '@mui/x-data-grid-pro';
import { DialogDynamicListToolbar, DialogDynamicListContainer, DialogDynamicListContent, DialogDynamicListHeader, DialogDynamicListDataGrid } from '../../util/SharedStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { PartnerAkaModel, RequestResult, UpsertPartnerAkaInput } from "../../gql-types.generated";
import { selectError, selectDeletePartnerAkaStatus, selectUpsertPartnerAkaStatus, captureDeletePartnerAkaStatus, captureUpsertPartnerAkaStatus } from "../../features/TradingPartnerDetails/TradingPartnerDetailsSlice";
import { upsertPartnerAKA, deletePartnerAKA } from '../../features/TradingPartnerDetails/TradingPartnerDetailsActions';
import CreateNewButton from '../buttons/CreateNewButton';
import DeleteDialog from '../dialogs/DeleteDialog';
import AkaDialog from '../dialogs/AkaDialog';
import { setToastConfig } from '../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../util/Constants';

interface PartnerAkaListProps {
    parentId: string;
    partnerAkas: PartnerAkaModel[] | undefined;
    isLoading?: boolean;
    isPartnerFormDirty?: boolean;
    preSavePartnerData: () => void;
    refreshPartnerData?: () => void;
}

const DialogPartnerAkaList: React.FC<PartnerAkaListProps> = props => {
    const { parentId, partnerAkas, refreshPartnerData, preSavePartnerData, isLoading = false, isPartnerFormDirty = false } = props;
    const dispatch = useAppDispatch();
    const [gridHeight, setGridHeight] = useState<string>('48px');
    const [openPartnerAkaDialog, setOpenPartnerAkaDialog] = useState(false);
    const [selectedPartnerAka, setSelectedPartnerAka] = useState<PartnerAkaModel | undefined>(undefined); 
    const [partnerAkaRows, setPartnerAkaRows] = useState<GridRowModel[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'partnerCode',
            sort: 'asc',
        },
    ]);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [isSavingOrDeletingPartnerAka, setIsSavingOrDeletingPartnerAka] = useState(false);

    const error = useAppSelector(selectError);
    const deletePartnerAkaStatus = useAppSelector(selectDeletePartnerAkaStatus);
    const savePartnerAkaStatus = useAppSelector(selectUpsertPartnerAkaStatus);

    useEffect(() => {
        setPartnerAkaRows(getPartnerAkaRows());
        setGridHeight(getGridHeight());
    }, [partnerAkas]);

    
    // declare the data refreshing function
    // as async so list will show as loading
    // up until refresh is complete
    const refreshData = async () => {
        if (refreshPartnerData) {
            setIsSavingOrDeletingPartnerAka(true);
            await refreshPartnerData();
        }
        setIsSavingOrDeletingPartnerAka(false);
    };

    useEffect(() => {
        if (savePartnerAkaStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: savePartnerAkaStatus.message as string,
                severity: ToastSeverity.Success
            }));
            onPartnerAkasDialogClose();
            refreshData();
        } else if (savePartnerAkaStatus?.result === RequestResult.Fail) {
            setIsSavingOrDeletingPartnerAka(false);
        }
        
    }, [savePartnerAkaStatus?.result]);

    useEffect(() => {
        if (deletePartnerAkaStatus) {
            if (deletePartnerAkaStatus.result === RequestResult.Success) {
                onDeleteDialogClose();
                refreshData();
            } else if (deletePartnerAkaStatus.result === RequestResult.Fail) {
                setDeleteErrorMessage(deletePartnerAkaStatus?.message as string);
                setIsSavingOrDeletingPartnerAka(false);
            }
        }
    }, [deletePartnerAkaStatus?.result]);

    const getSelectedRowPartnerAka = useCallback((rowId: string) => () => {
        if (rowId && partnerAkas?.length) {
            let partnerAka = partnerAkas.find(partnerAka => partnerAka.id === rowId);
            return partnerAka;
        }
        return undefined;
    }, [partnerAkas]);

    const deletePartnerAkaHandler = useCallback((rowId: string) => () => {
        let partnerAka = getSelectedRowPartnerAka(rowId);
        if (partnerAka) {
            setSelectedPartnerAka(partnerAka);
            setOpenDelete(true);
        }
    }, [getSelectedRowPartnerAka]);

    const editPartnerAkaHandler = useCallback((rowId: string) => () => {
        let partnerAka = getSelectedRowPartnerAka(rowId);
        if (partnerAka) {
            setSelectedPartnerAka(partnerAka);
            setOpenPartnerAkaDialog(true);
        }
    }, [getSelectedRowPartnerAka]);

    const setDefaults = () => {
        setSelectedPartnerAka(undefined);
    };

    const onAddPartnerAka = () => {
        setDefaults();
        setOpenPartnerAkaDialog(true);
    };

    const onDialogClose = () => {
        setDefaults();
    };

    const onPartnerAkasDialogClose = () => {
        setOpenPartnerAkaDialog(false);
        // if there was an error and dialog is closed,
        // refresh the data to clear error on parent
        if (error) {
            refreshData();
        }
        dispatch(captureUpsertPartnerAkaStatus());
        onDialogClose();
    };

    const onPartnerAkasDialogSave = (parentId: string, akaCode: string, isaQualifierCode: string, akaId?: string) => {
        if (akaCode && isaQualifierCode) {
            let timeDelay = 0;
            let inputData: UpsertPartnerAkaInput = {partnerId: parentId, partnerCode: akaCode, isaQualifierCode, id: akaId} 
            
            if (isPartnerFormDirty) {
                // save the partner form first and give a time delay
                // to allow the partner to save before saving the partnerAka
                timeDelay = 1000;
                preSavePartnerData();
            } 

            setTimeout(() => {
                setIsSavingOrDeletingPartnerAka(true);
                dispatch(upsertPartnerAKA(inputData));
            }, timeDelay);
        }
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        dispatch(captureDeletePartnerAkaStatus());
        onDialogClose();
        setDeleteErrorMessage('');
    };

    const onDeleteDialogConfirm = () => {
        // delete the selected partnerAka
        if (selectedPartnerAka) {
            let timeDelay = 0;
                            
            if (isPartnerFormDirty) {
                // save the partner form first and give a time delay
                // to allow the partner to save before deleting the partnerAka
                timeDelay = 1000;
                preSavePartnerData();
            } 

            setTimeout(() => {
                setIsSavingOrDeletingPartnerAka(true);
                dispatch(deletePartnerAKA(selectedPartnerAka.id as string));
            }, timeDelay);
        }
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'partnerAka-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const getGridHeight = () => {
        // either the grid or its container needs a set height to render properly
        // the container only has maxHeight so that it doesn't display a lot of 
        // white space when there are no partnerAkas, so need to calculate a height
        // based on the number of rows since want a scroll bar instead of paging
        if (partnerAkas) {
            if (partnerAkas.length === 1) {
                return '108px';
            } else if (partnerAkas.length === 2) {
                return '156px';
            } else if (partnerAkas.length >= 3) {
                return '208px';
            }
        }
        return '48px';
    };

    const getPartnerAkaRows = () => {
        if (partnerAkas && partnerAkas.length > 0) {
            return partnerAkas.map((partnerAka) => {
                const { id, partnerCode, isaQualifierCode, isaQualifier } = partnerAka;
                const isaQualifierCodeAndDescription = `${isaQualifierCode} - ${isaQualifier?.description}`;
                return {
                    _raw: partnerAka,
                    id,
                    partnerCode,
                    isaQualifierCodeAndDescription
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const partnerAkaColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'partnerCode',
                headerName: 'CODE',
                flex: 1,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild",
            }, {
                field: 'isaQualifierCodeAndDescription',
                headerName: 'ISA QUALIFIER',
                flex: 2,
                sortable: true,
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                resizable: false,
                headerName: '',
                minWidth: 100,
                headerAlign: 'center',
                align: 'center',
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editPartnerAkaHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        onClick={deletePartnerAkaHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],

            },
        ],
        [deletePartnerAkaHandler, editPartnerAkaHandler],
    );

    return (
        <DialogDynamicListContainer>

            <DialogDynamicListToolbar justify="space-between" noborder={+true}>
                <DialogDynamicListHeader>Additional Codes</DialogDynamicListHeader>
                
                    <CreateNewButton
                        text="Add Code"
                        tooltip="Click here to add another Partner Code"
                        variant="text"
                        onClick={onAddPartnerAka}
                        disabled={isSavingOrDeletingPartnerAka || isLoading}
                        data-cy="add-new-partnerAka"
                    />
               
            </DialogDynamicListToolbar>

            <DialogDynamicListContent>
                {((partnerAkas !== undefined && partnerAkas?.length > 0) || isSavingOrDeletingPartnerAka) &&
                    <DialogDynamicListDataGrid
                        loading={isSavingOrDeletingPartnerAka || isLoading}
                        height={gridHeight}
                        headerHeight={38}
                        rowHeight={52}
                        aria-label="PartnerAkas List"
                        hideFooter
                        disableColumnMenu
                        disableColumnFilter
                        disableMultipleSelection
                        rows={partnerAkaRows}
                        columns={partnerAkaColumns}
                        sortingOrder={['asc', 'desc']}
                        sortModel={sortModel}
                        onSortModelChange={onSortModelChange}
                        components={{
                            // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                            LoadingOverlay: loadingOverlay,
                        }}
                    />
                }
            </DialogDynamicListContent>

            <AkaDialog
                isOpen={openPartnerAkaDialog}
                parentId={parentId}
                parentName="Partner"
                akaCode={selectedPartnerAka?.partnerCode}
                qualifierCode={selectedPartnerAka?.isaQualifierCode}
                akaId={selectedPartnerAka?.id}
                onClose={onPartnerAkasDialogClose}
                onSave={onPartnerAkasDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedPartnerAka?.id ?? ''}
                heading={'Delete Partner Code'}
                message={'Are you sure you want to delete \'' + selectedPartnerAka?.partnerCode + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </DialogDynamicListContainer>
    );

}

export default DialogPartnerAkaList;
