import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import { RequestResult, GetStandardExchangeFormatInput, PagingResultModelOfBlobStorageModel, BlobUploadRequestInput, BlobDownloadRequestInput, BlobDocumentType, BlobDocumentFormat } from '../../gql-types.generated';
import { queryStandardExchangeFormatFileList } from '../../gql/QueryStandardExchangeFormatFileList';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';
import { 
    captureDeleteSefFileStatus, 
    captureUploadSefFileStatus, 
    fetchError, 
    fetchSefFileListSuccess,
    incrementSefFileRequestsInFlight,
    decrementSefFileRequestsInFlight,
    captureDownloadSefFileStatus
} from './StandardExchangeFormatFilesSlice';
import { MutationUploadBlogStorage } from '../../gql/MutationExecuteBlobStorageUpload';
import { MutationDownloadBlogStorage } from '../../gql/MutationExecuteBlobStorageDownload';

export const fetchSefFileList = (
    filters?: GetStandardExchangeFormatInput
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementSefFileRequestsInFlight());
    try {
        const fileList = await queryStandardExchangeFormatFileList(gqlClient, filters);
        if (fileList) {
            dispatch(fetchSefFileListSuccess(fileList as PagingResultModelOfBlobStorageModel))
        }
        dispatch(decrementSefFileRequestsInFlight());
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementSefFileRequestsInFlight());
    }
};

export const uploadStandardExchangeFormatFile = (fileUploadData: BlobUploadRequestInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const uploadStatus = await MutationUploadBlogStorage(gqlClient, fileUploadData);
           
            if (uploadStatus) {
                if (uploadStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: uploadStatus.message } as Error));
                    getErrorMessage(uploadStatus.message as string, uploadStatus.errors, true, true);
                }
                dispatch(captureUploadSefFileStatus(uploadStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

    export const downloadStandardExchangeFormatFile = (blobDocumentId: string, blobFileName?: string): AppThunk =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const fileDownloadData: BlobDownloadRequestInput = {
                    blobDocumentId,
                    blobFileName,
                    blobDocumentType: BlobDocumentType.Sef,
                    blobDocumentFormat: BlobDocumentFormat.Base64,
                };
                const downloadStatus = await MutationDownloadBlogStorage(gqlClient, fileDownloadData);
               
                if (downloadStatus) {
                    if (downloadStatus.result === RequestResult.Fail) {
                        dispatch(fetchError({ message: downloadStatus.message } as Error));
                        getErrorMessage(downloadStatus.message as string, downloadStatus.errors, true, true);
                    }
                    dispatch(captureDownloadSefFileStatus(downloadStatus));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

export const deleteStandardExchangeFormatFile = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "BlobStorage",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteSefFileStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };