import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';

import { GetClientPartnerByIdQuery, ClientPartnerModel, GetClientPartnersInput, Scalars } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_CLIENT_PARTNER_BY_ID = gql`
query getClientPartnerById($input: GetClientPartnersInput!) {
    clientPartners (input: $input){
        nodes {
            id
            createdTime
            productionDate
            defaultCurrencyCode
            partner {
                id
                name
                website
                statusCode
                partnerCode
                ediStandards {
                    id
                    name
                }
            }
            client {
                id
                name
                defaultCurrencyCode
            }
            clientPartnerTransactions {
                id
                effectivePartnerInterchangeId
                destinationPath
                isPipelineInherited
                functionalReconciliationMode
                skipTargetDocumentCreation
                outboundAcknowledgementOption
                partnerTransactionId
                partnerTransaction {
                    id
                    pipeline {
                        description
                        id
                    }
                    transaction {
                        direction
                    }
                }
                pipelineId
                pipeline {
                    description
                    id
                }
                clientPartnerTransactionMaps {
                    id 
                    transactionMapId
                    transactionMap {
                        id                    
                        description
                        transactionId
                        documentString
                        sourceFileName
                    }
                }
                clientPartnerTransactionPostProcessings {
                    ediProcessName
                    id
                    postProcessingBlob {
                        description
                    }
                    postProcessingBlobId
                    processArguments
                    processName
                    sortOrder
                }
                clientPartnerTransactionSchemas {
                    id
                    partnerTransactionSchemaId
                    partnerTransactionSchema {
                        id
                        description
                        standardVersion
                        sourceFileName
                    }
                }
                clientPartnerTransactionAliases {
                    id
                    alias
                }
            }
            partnerTransactions {
                id
                transaction {
                    id
                    name
                    description
                    direction
                    isActive
                }
                pipeline {
                    description
                    id
                }
                pipelineId
                transactionMaps {
                    id
                    description
                    transactionId
                }
                partnerTransactionSchemas {
                    id
                    description
                    standardVersion
                    sourceFileName
                }
            }
            attachments {
                id
                fileName
                isLink
                description
                createdBy
                createdByName
                createdTime
                lastModifiedBy
                lastModifiedByName
                lastModifiedTime
                fileLastModifiedTime
            }
        }
        
    }
}`;

export const queryClientPartnerById = async (
    client: ApolloClient<NormalizedCacheObject>,
    id: Scalars['UUID']
): Promise<ClientPartnerModel | undefined> => {
    const { data, errors } = await client.query<GetClientPartnerByIdQuery>({
        variables: {
            input: {
                id: id
            }
        },
        query: QUERY_CLIENT_PARTNER_BY_ID,
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    // backend query is written more generic to return an array, 
    // but we only want a single clientPartner
    if (data?.clientPartners && data?.clientPartners.nodes && data?.clientPartners?.nodes?.length > 0) {
        return data?.clientPartners.nodes[0] as ClientPartnerModel;
    }
    return undefined;
};