import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BlobStorageModel, ActionPayload, PagingResultModelOfBlobStorageModel} from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    blobStoragePagingResult?: PagingResultModelOfBlobStorageModel;
    firstVersionFile?: BlobStorageModel;
    uploadFileVersionStatus?: ActionPayload;
    fileVersions?: BlobStorageModel[];
}

const initialState: SliceState = {
    error: undefined,
    blobStoragePagingResult: undefined,
    firstVersionFile: undefined,
    uploadFileVersionStatus: undefined,
    fileVersions: undefined,
};

export const slice = createSlice({
    name: 'standardExchangeFormatFileDetails',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.blobStoragePagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.firstVersionFile = undefined;
            // eslint-disable-next-line no-param-reassign
            state.fileVersions = undefined;
            // eslint-disable-next-line no-param-reassign
            state.uploadFileVersionStatus = undefined;
        },

        fetchFileVersionsSuccess: (state, action: PayloadAction<PagingResultModelOfBlobStorageModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.blobStoragePagingResult = action.payload;
            
            if (action.payload.nodes && action.payload.nodes.length > 0) {
                let files = action.payload.nodes;
                // eslint-disable-next-line no-param-reassign
                state.fileVersions = files as BlobStorageModel[];

                // filter files to get the first version file
                // not just taking the first record as it may not be the first version
                const firstVersion = files.reduce((prev: BlobStorageModel, current: BlobStorageModel) => {
                    return (current.version < prev.version) ? current : prev;
                }); 
                // eslint-disable-next-line no-param-reassign
                state.firstVersionFile = firstVersion;
                
            } else {
                // eslint-disable-next-line no-param-reassign
                state.fileVersions = [];
            }
        },

        captureUploadFileVersionStatus: (state, action: PayloadAction<ActionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.uploadFileVersionStatus = action.payload;
        },
    }
});

export const selectError = (state: RootState): Error | undefined => state.standardExchangeFormatFileDetails.error;
export const selectFirstVersionFile = (state: RootState): BlobStorageModel | undefined => state.standardExchangeFormatFileDetails.firstVersionFile;
export const selectFileVersions = (state: RootState): BlobStorageModel[] | undefined => state.standardExchangeFormatFileDetails.fileVersions;
export const selectUploadFileVersionStatus = (state: RootState): ActionPayload | undefined => state.standardExchangeFormatFileDetails.uploadFileVersionStatus;

export const {
    clearError,
    clearState,
    fetchError,
    fetchFileVersionsSuccess,
    captureUploadFileVersionStatus
} = slice.actions;

export default slice.reducer;