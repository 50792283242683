import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { ActionPayload, BlobDownloadRequestInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_DOWNLOAD_BLOB_STORAGE = gql`
    mutation executeBlobStorageDownload ($input: BlobDownloadRequestInput!){
        executeBlobStorageDownload(input: $input) {
            message
            errors {
                code
                message
            }
            result
            data
        }
    }
`;

export const MutationDownloadBlogStorage = async (
    client: ApolloClient<NormalizedCacheObject>,
    storageInput: BlobDownloadRequestInput
    ): Promise<ActionPayload | undefined> => {
        const { 
            blobDocumentFormat, 
            blobDocumentType, 
            blobDocumentId, 
            blobFileName, 
        } = storageInput as BlobDownloadRequestInput;
        const {data, errors } = await client.mutate<{executeBlobStorageDownload: ActionPayload}>({
            variables: {
                input: {
                    blobDocumentFormat, 
                    blobDocumentType, 
                    blobDocumentId, 
                    blobFileName, 
                }
            },
            mutation: MUTATION_DOWNLOAD_BLOB_STORAGE
        });
        if (errors && errors.length > 0) {
            errors.forEach(error => {
                // Log error details in the console
                console.error(error);
            });
            // Friendly error to active user
            throw new Error('An error has occurred trying to execute blob storage download.');
        }
        if (data && data.executeBlobStorageDownload) {
            return data.executeBlobStorageDownload;
        }
    
        return undefined;
    };