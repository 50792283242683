import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Viewer } from "../../util/Constants";

import FilterBar from "./FilterBar";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../util/ViewerUtility';

interface BillingFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    onClose: () => void;
    onFilterChanged: (filterCurrency: string | undefined, filterDescription: string | undefined) => void;
}

const BillingFilterBar: React.FC<BillingFilterBarProps> = props => {
    const { id, loading = false, visible = false, viewer, onFilterChanged } = props;
    const [filterCurrency, setFilterCurrency] = useState<string | undefined>();
    const [filterDescription, setFilterDescription] = useState<string | undefined>();
    const fieldsDisabled = visible && loading;

    useEffect(() => {
        // filters from local storage to be remembered across sessions
        let currency = getLocalStorageItem(viewer, "billingFilterCurrency");
        if (currency != null) {
            setFilterCurrency(currency);
        }
        let desc = getLocalStorageItem(viewer, "billingFilterDescription");
        if (desc != null) {
            setFilterDescription(desc);
        }

        // because Billing are not paged lets send out an initial state to kick a fetch 
        onFilterChanged(currency as string | undefined, desc as string | undefined);
    }, []);

    const onSearchClick = () => {
        onFilterChanged(filterCurrency, filterDescription);
    };

    const onCloseClick = () => {
        clearFilters();
        props.onClose();
        // trigger a new search with filters undefined since state of filter values might not be updated yet
        onFilterChanged(undefined, undefined);
    };

    const clearFilters = () => {
        clearCurrencyFilter();
        clearDescriptionFilter();
    };

    const clearCurrencyFilter = () => {
        removeLocalStorageItem(viewer, "billingFilterCurrency");
        setFilterCurrency(undefined);
    };

    const clearDescriptionFilter = () => {
        removeLocalStorageItem(viewer, "billingFilterDescription");
        setFilterDescription(undefined);
    };

    const onCurrencyFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            setLocalStorageItem(viewer, "billingFilterCurrency", changedValue);
        }
        else {
            removeLocalStorageItem(viewer, "billingFilterCurrency");
        }
        setFilterCurrency(changedValue);
    };

    const onDescriptionFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            setLocalStorageItem(viewer, "billingFilterDescription", changedValue);
        }
        else {
            removeLocalStorageItem(viewer, "billingFilterDescription");
        }
        setFilterDescription(changedValue);
    };

    const currencyFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear currency filter"
                    onClick={clearCurrencyFilter}
                    disabled={fieldsDisabled}
                >
                    {filterCurrency && filterCurrency.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const descriptionFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear description filter"
                    onClick={clearDescriptionFilter}
                    disabled={fieldsDisabled}
                >
                    {filterDescription && filterDescription.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick} onSearch={onSearchClick}>
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="billing-filter-description"
                    fullWidth
                    value={filterDescription ?? ''}
                    label="Description"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'description', 'maxLength': 50, }}
                    InputProps={descriptionFilterProps}
                    onChange={onDescriptionFilterChanged}
                    autoComplete="off"
                    data-cy="billing-description-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="billing-filter-currency"
                    fullWidth
                    value={filterCurrency ?? ''}
                    label="Currency"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'currency', 'maxLength': 50, }}
                    InputProps={currencyFilterProps}
                    onChange={onCurrencyFilterChanged}
                    autoComplete="off"
                    data-cy="billing-name-filter"
                    variant="standard"
                />
            </Grid>
        </FilterBar>
    );
};

export default BillingFilterBar;