import { ChangeEvent, useEffect, useState } from "react";
import { Viewer } from "../../util/Constants";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "../../util/ViewerUtility";
import { useAppSelector } from "../../app/hooks";
import CloseIcon from '@mui/icons-material/Close';
import { selectCounterList } from "../../features/CounterTable/CounterTableSlice";
import { FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import FilterBar from "./FilterBar";
import { CounterSegmentType } from "../../gql-types.generated";
import { BlankMenuItem } from "../../util/SharedStyles";

interface CounterFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    onClose: () => void;
    onFilterChanged: (
        filterSenderId: string | undefined, 
        filterControlNumber: string | undefined,
        filterSegment: CounterSegmentType | undefined,
        filterMachine: string | undefined) => void;
}

const CounterFilterBar: React.FC<CounterFilterBarProps> = props => { 
    const { id, loading = false, visible = false, viewer, onFilterChanged } = props;
    const [filterSenderId, setFilterSenderId] = useState<string | undefined>();
    const [filterControlNumber, setFilterControlNumber] = useState<string | undefined>();
    const [filterSegment, setFilterSegment] = useState<CounterSegmentType | undefined>();
    const [filterMachine, setFilterMachine] = useState<string | undefined>();
    const fieldsDisabled = visible && loading;
    const searchFieldMinLength = 3;

    useEffect(() => {
        // filters from local storage to be remembered across sessions
        let counterSenderId = getLocalStorageItem(viewer, "counterFilterSenderId");
        if (counterSenderId != null) {
            setFilterSenderId(counterSenderId);
        }
        let counterControlNumber = getLocalStorageItem(viewer, "counterFilterControlNumber");
        if (counterControlNumber != null) {
            setFilterControlNumber(counterControlNumber);
        }
        let counterSegment = getLocalStorageItem(viewer, "counterFilterSegment");
        if (counterSegment != null) {
            setFilterSegment(counterSegment as CounterSegmentType);
        }
        let counterMachine = getLocalStorageItem(viewer, "counterFilterMachine");
        if (counterMachine != null) {
            setFilterSenderId(counterMachine);
        }
        // because cross references are currently not paged lets send out an initial state to kick a fetch 
        onFilterChanged(
            counterSenderId as string | undefined, 
            counterControlNumber as string | undefined,
            counterSegment as CounterSegmentType | undefined,
            counterMachine as string | undefined,
        );
    }, []);

    const counters = useAppSelector(selectCounterList);

    const onSearchClick = () => {
        onFilterChanged(filterSenderId, filterControlNumber, filterSegment, filterMachine);
    };

    const onCloseClick = () => {
        clearFilters();
        props.onClose();
        // trigger a new search with filters undefined since state of filter values might not be updated yet
        onFilterChanged(undefined, undefined, undefined, undefined);
    };

    const clearFilters = () => {
        clearSenderIdFilter();
        clearControlNumberFilter();
        clearSegmentFilter();
        clearMachineFilter();
    };

    const clearSenderIdFilter = () => {
        removeLocalStorageItem(viewer, "counterFilterSenderId");
        setFilterSenderId(undefined);
    };

    const clearControlNumberFilter = () => {
        removeLocalStorageItem(viewer, "counterFilterControlNumber");
        setFilterControlNumber(undefined);
    };

    const clearSegmentFilter = () => {
        removeLocalStorageItem(viewer, "counterFilterSegment");
        setFilterSegment(undefined);
    };

    const clearMachineFilter = () => {
        removeLocalStorageItem(viewer, "counterFilterMachine");
        setFilterMachine(undefined);
    };

    // on Filter Change Handlers
    const onSenderIdFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            // only set local storage if minimum is met
            if (changedValue?.length >= event.target.minLength) {
                setLocalStorageItem(viewer, "counterFilterSenderId", changedValue);
            }
        }
        else {
            removeLocalStorageItem(viewer, "counterFilterSenderId");
        }        
        setFilterSenderId(changedValue);
    };

    const onControlNumberFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            // only set local storage if minimum is met
            if (changedValue?.length >= event.target.minLength) {
                setLocalStorageItem(viewer, "counterFilterControlNumber", changedValue);
            }
        }
        else {
            removeLocalStorageItem(viewer, "counterFilterControlNumber");
        }        
        setFilterControlNumber(changedValue);
    };

    const onSegmentFilterChanged = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'counterFilterSegment', value);
        }
        else {
            removeLocalStorageItem(viewer, 'counterFilterSegment');
        }

        let currentValue = value?.length > 0 ? value as CounterSegmentType : undefined;
        
        setFilterSegment(currentValue);
    };
    
    const onMachineFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            // only set local storage if minimum is met
            if (changedValue?.length >= event.target.minLength) {
                setLocalStorageItem(viewer, "counterFilterMachine", changedValue);
            }
        }
        else {
            removeLocalStorageItem(viewer, "counterFilterMachine");
        }        
        setFilterMachine(changedValue);
    };

    const segmentTypes = Object.values(CounterSegmentType);
    
    const getSegmentTypeDropList = () => {
        if (segmentTypes && segmentTypes.length) {
            let items = [];
            const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
            const mappedItems = (
                segmentTypes.map((type: CounterSegmentType) => (
                    <MenuItem
                        key={type}
                        value={type}
                    >
                        {type.toUpperCase()}
                    </MenuItem>
                ))
            );
            items.push(blankItem);
            items.push(...mappedItems);
            return items;
        }
        return null;
    };

    // FIlter Object Props
    const senderIdFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear senderId filter"
                    onClick={clearSenderIdFilter}
                    disabled={fieldsDisabled}
                >
                    {filterSenderId && filterSenderId.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };
    const controlNumberFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear controlNumber filter"
                    onClick={clearControlNumberFilter}
                    disabled={fieldsDisabled}
                >
                    {filterControlNumber && filterControlNumber.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };
    const machineFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear machine filter"
                    onClick={clearMachineFilter}
                    disabled={fieldsDisabled}
                >
                    {filterMachine && filterMachine.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick} onSearch={onSearchClick}>            
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="counter-filter-senderId"
                    fullWidth
                    value={filterSenderId ?? ''}
                    label="Sender ID"
                    title="Enter at least 3 characters"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'senderId', 'maxLength': 50, 'minLength': {minLengthSearchField: searchFieldMinLength} }}
                    InputProps={senderIdFilterProps}
                    onChange={onSenderIdFilterChanged}
                    autoComplete="off"
                    data-cy="counter-senderId-filter"
                    variant="standard"
                />
            </Grid>   
            <Grid item xs={2}>
                <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                    <InputLabel id="counter-filter-segment-label">Segment</InputLabel>
                    <Select
                        labelId="counter-filter-segment-label"
                        aria-labelledby="counter-filter-segment-label"
                        id="counter-filter-segment"
                        value={filterSegment ?? ''}
                        MenuProps={{
                            'aria-label': 'segment',
                        }}
                        onChange={onSegmentFilterChanged}
                        data-cy="counter-filter-segment"
                    >
                        {getSegmentTypeDropList()}
                    </Select>
                </FormControl>
            </Grid>       
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="counter-filter-controlNumber"
                    fullWidth
                    value={filterControlNumber ?? ''}
                    label="ControlNumber"
                    title="Enter at least 3 characters"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'controlNumber', 'maxLength': 100, 'minLength': {minLengthSearchField: searchFieldMinLength} }}
                    InputProps={controlNumberFilterProps}
                    onChange={onControlNumberFilterChanged}
                    autoComplete="off"
                    data-cy="counter-controlNumber-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="counter-filter-machine"
                    fullWidth
                    value={filterMachine ?? ''}
                    label="Machine"
                    title="Enter at least 3 characters"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'machine', 'maxLength': 10, 'minLength': {minLengthSearchField: searchFieldMinLength} }}
                    InputProps={machineFilterProps}
                    onChange={onMachineFilterChanged}
                    autoComplete="off"
                    data-cy="counter-machine-filter"
                    variant="standard"
                />
            </Grid>
            
        </FilterBar>
    );
};

export default CounterFilterBar;