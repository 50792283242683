import { Checkbox, Grid, FormControl, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { capitalize } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector } from "../../app/hooks";
import CloseIcon from '@mui/icons-material/Close';
import { BusinessAreaModel, ErpProductModel, ClientStatus } from '../../gql-types.generated';
import { selectBusinessAreaList, selectErpProductList } from '../../features/EDIContainer/EDIContainerSlice';
import { Viewer } from '../../util/Constants';
import { getLocalStorageItem, getLocalStorageItemAsBoolean, setLocalStorageItem, removeLocalStorageItem } from '../../util/ViewerUtility';

import FilterBar from './FilterBar';

interface ClientsFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    onClose: () => void;
    onFilterChanged: (filterName: string | undefined, filterBusinessAreaIds: string[], filterProductIds: string[], filterStatuses: string[], filterCity: string | undefined) => void;
}

const ClientsFilterBar: React.FC<ClientsFilterBarProps> = props => {
    const { id, loading = false, visible = false, viewer, onFilterChanged } = props;
    const [filterName, setFilterName] = useState<string | undefined>(undefined);
    const [filterStatuses, setFilterStatuses] = useState<string[]>();
    const [filterBusinessAreaNames, setFilterBusinessAreaNames] = useState<string[]>([]);
    const [filterBusinessAreaIds, setFilterBusinessAreaIds] = useState<string[]>();
    const [filterProductNames, setFilterProductNames] = useState<string[]>([]);
    const [filterProductIds, setFilterProductIds] = useState<string[]>();
    const [filterCity, setFilterCity] = useState<string | undefined>(undefined);
    const fieldsDisabled = visible && loading;
    let defaultStatus = capitalize(ClientStatus.Active.toString());
        
    useEffect(() => {
        // store the filters in localStorage to remember the preference across sessions 
        let name = getLocalStorageItem(viewer, "clientFilterName");
        if (name != null) {
            setFilterName(name);
        }

        let areaIds = getLocalStorageItem(viewer, "clientFilterBusinessAreaIds");
        let parsedAreaIds = [];
        if (areaIds != null) {
            parsedAreaIds = JSON.parse(areaIds);
            setFilterBusinessAreaIds(parsedAreaIds);
        }

        let areaNames = getLocalStorageItem(viewer, "clientFilterBusinessAreaNames");
        if (areaNames != null && areaNames.length > 0) {
            let parsedNames = JSON.parse(areaNames);
            setFilterBusinessAreaNames(parsedNames);
        }

        let productIds = getLocalStorageItem(viewer, "clientFilterProductIds");
        let parsedProductIds = [];
        if (productIds != null) {
            parsedProductIds = JSON.parse(productIds);
            setFilterProductIds(parsedProductIds);
        }

        let productNames = getLocalStorageItem(viewer, "clientFilterProductNames");
        if (productNames != null && productNames.length > 0) {
            let parsedProductNames = JSON.parse(productNames);
            setFilterProductNames(parsedProductNames);
        }

        let city = getLocalStorageItem(viewer, "clientFilterCity");
        if (city != null) {
            setFilterCity(city);
        }

        let statusFilterIsDismissed = getLocalStorageItemAsBoolean(viewer, "clientDefaultStatusFilterDismissed");
        let statuses = getLocalStorageItem(viewer, "clientFilterStatuses");
        let statusFilter = [];
        if (statuses != null && statuses.length > 0) {
            let parsedStatuses = JSON.parse(statuses);
            statusFilter = parsedStatuses;
        }
        else if (!statusFilterIsDismissed) {
            statusFilter = [defaultStatus];
            resetFilterStatusDefault();
        }
        else {
            statusFilter = [];
        }
        setFilterStatuses(statusFilter);

        // send out an initial state to kick a fetch 
        onFilterChanged(name as string | undefined, parsedAreaIds, parsedProductIds, statusFilter, city as string | undefined);
    }, []);
    
    // get the list of business areas, products for the filter dropdowns
    const businessAreas = useAppSelector(selectBusinessAreaList);
    const products = useAppSelector(selectErpProductList);
    const statuses = Object.values(ClientStatus); 

    const onSearchClick = () => {
        onFilterChanged(filterName, filterBusinessAreaIds ?? [], filterProductIds ?? [], filterStatuses ?? [], filterCity);
    };

    const onCloseClick = () => {
        // remove any filters
        clearFilters();

        // call parent to hide bar
        props.onClose();

        // trigger a new search with filters undefined since state of filter values might not be updated yet
        onFilterChanged(undefined, [], [], [defaultStatus], undefined);
    };

    const clearFilters = () => {
        clearNameFilter();
        removeLocalStorageItem(viewer, 'clientFilterBusinessAreaNames');
        setFilterBusinessAreaNames([]);
        removeLocalStorageItem(viewer, 'clientFilterBusinessAreaIds');
        setFilterBusinessAreaIds([]);
        removeLocalStorageItem(viewer, 'clientFilterProductNames');
        setFilterProductNames([]);
        removeLocalStorageItem(viewer, 'clientFilterProductIds');
        setFilterProductIds([]);
        removeLocalStorageItem(viewer, 'clientFilterStatuses');
        clearCityFilter();
        resetFilterStatusDefault();
    };

    const clearNameFilter = () => {
        removeLocalStorageItem(viewer, 'clientFilterName');
        setFilterName(undefined);
    }
    const clearCityFilter = () => {
        removeLocalStorageItem(viewer, 'clientFilterCity');
        setFilterCity(undefined);
    }

    // when a user clicks the clear button, restore back to having a default Active status
    const resetFilterStatusDefault = () => {
        removeLocalStorageItem(viewer, "clientDefaultStatusFilterDismissed");
        setFilterStatuses([defaultStatus]);
    };

    const getBusinessAreasFilterList = () => {
        if (businessAreas && businessAreas.length) {
            return (
                businessAreas.map((businessArea: BusinessAreaModel) => (
                    <MenuItem
                        key={businessArea.id}
                        value={businessArea.name}
                        disabled={fieldsDisabled}
                    >
                        <Checkbox checked={filterBusinessAreaNames && filterBusinessAreaNames.indexOf(businessArea.name) > -1} />
                        <ListItemText primary={businessArea.name} />
                    </MenuItem>
                ))
            );
        }
        return null;
    };

    const getProductsFilterList = () => {
        if (products && products.length) {
            return (
                products.map((product: ErpProductModel) => (
                    <MenuItem
                        key={product.id}
                        value={product.name}
                        disabled={fieldsDisabled}
                    >
                        <Checkbox checked={filterProductNames && filterProductNames.indexOf(product.name) > -1} />
                        <ListItemText primary={product.name} />
                    </MenuItem>
                ))
            );
        }
        return null;
    };

    const getStatusFilterList = () => {
        if (statuses && statuses.length) {
            return (
                statuses.map((status: ClientStatus) => (
                    <MenuItem
                        key={status}
                        value={capitalize(status)}
                        disabled={fieldsDisabled}
                    >
                        <Checkbox checked={filterStatuses && filterStatuses.indexOf(capitalize(status)) > -1} />
                        <ListItemText primary={capitalize(status !== ClientStatus.Inprocess ? status : "IN-PROCESS")} />
                    </MenuItem>
                ))
            );
        }
        return null;
    };

    const onNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {

        let nameValue = event.target.value;
        if (nameValue?.length > 0) {
            setLocalStorageItem(viewer, 'clientFilterName', nameValue);
        }
        else {
            removeLocalStorageItem(viewer, 'clientFilterName');
        }

        setFilterName(nameValue);
    };

    const onCityFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let cityValue = event.target.value;
        if (cityValue?.length > 0) {
            setLocalStorageItem(viewer, 'clientFilterCity', cityValue);
        } else {
            removeLocalStorageItem(viewer, 'clientFilterCity');
        }
        setFilterCity(cityValue);
    };

    const onBusinessAreaFilterChanged = (event: SelectChangeEvent<typeof filterBusinessAreaNames>) => {
        const {
            target: { value },
        } = event;
        // On autofill we get a stringified value.
        let nameArray = typeof value === 'string' ? value.split(',') : value;

        if (nameArray.length > 0) {
            setLocalStorageItem(viewer, 'clientFilterBusinessAreaNames', JSON.stringify(nameArray));
        }
        else {
            removeLocalStorageItem(viewer, 'clientFilterBusinessAreaNames');
        }
        setFilterBusinessAreaNames(nameArray);

        // the array from the multiselect only contains the names
        // get the associated ids to actually send as a filter to the query
        let ids: string[] = [];
        let areas = businessAreas?.filter(b => nameArray.includes(b.name));
        if (areas && areas.length > 0) {
            areas.forEach(area => {
                ids.push(area.id);
            });
        }
        if (ids.length > 0) {
            setLocalStorageItem(viewer, 'clientFilterBusinessAreaIds', JSON.stringify(ids));
        }
        else {
            removeLocalStorageItem(viewer, 'clientFilterBusinessAreaIds');
        }

        setFilterBusinessAreaIds(ids);
    };

    const onProductFilterChanged = (event: SelectChangeEvent<typeof filterProductNames>) => {
        const {
            target: { value },
        } = event;
        // On autofill we get a stringified value.
        let nameArray = typeof value === 'string' ? value.split(',') : value;

        if (nameArray.length > 0) {
            setLocalStorageItem(viewer, 'clientFilterProductNames', JSON.stringify(nameArray));
        }
        else {
            removeLocalStorageItem(viewer, 'clientFilterProductNames');
        }
        setFilterProductNames(nameArray);

        // the array from the multiselect only contains the names
        // get the associated ids to actually send as a filter to the query
        let ids: string[] = [];
        let selectedProducts = products?.filter(b => nameArray.includes(b.name));
        if (selectedProducts && selectedProducts.length > 0) {
            selectedProducts.forEach(product => {
                ids.push(product.id);
            });
        }
        if (ids.length > 0) {
            setLocalStorageItem(viewer, 'clientFilterProductIds', JSON.stringify(ids));
        }
        else {
            removeLocalStorageItem(viewer, 'clientFilterProductIds');
        }

        setFilterProductIds(ids);
    };

    const onStatusFilterChanged = (event: SelectChangeEvent<typeof filterStatuses>) => {
        const {
            target: { value },
        } = event;
        // On autofill we get a stringified value.
        let nameArray = typeof value === 'string' ? value.split(',') : value;

        if (nameArray && nameArray.length > 0) {
            setLocalStorageItem(viewer, 'clientFilterStatuses', JSON.stringify(nameArray));
        }
        else {
            removeLocalStorageItem(viewer, 'clientFilterStatuses');
            // since array is now empty, a user manually chose to remove the status default
            // store this dismissal to be retained across sessions
            setLocalStorageItem(viewer, "clientDefaultStatusFilterDismissed", "true");
        }
        setFilterStatuses(nameArray);
    };

    const DropdownMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '300px',
            },
        },
    };

    const nameFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear name filter"
                    onClick={clearNameFilter}
                    disabled={fieldsDisabled}
                >
                    {filterName && filterName.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const cityFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear city filter"
                    onClick={clearCityFilter}
                    disabled={fieldsDisabled}
                >
                    {filterCity && filterCity.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick} onSearch={onSearchClick}>
            <Grid item xs={2}>
                <TextField
                    itemID="client-filter-name"
                    fullWidth
                    value={filterName ?? ''}
                    label="Name"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'name', 'maxLength': 50, }}
                    InputProps={nameFilterProps}
                    onChange={onNameFilterChange}
                    autoComplete="off"
                    data-cy="client-name-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    itemID="client-filter-city"
                    fullWidth
                    value={filterCity ?? ''}
                    label="City"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'city', 'maxLength': 50, }}
                    InputProps={cityFilterProps}
                    onChange={onCityFilterChange}
                    autoComplete="off"
                    data-cy="client-city-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
                <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                    <InputLabel id="client-filter-bus-area-label">Business Area</InputLabel>
                    <Select
                        labelId="client-filter-bus-area-label"
                        aria-labelledby="client-filter-bus-area-label"
                        id="client-filter-bus-area"
                        multiple
                        value={filterBusinessAreaNames ?? []}
                        MenuProps={DropdownMenuProps}
                        onChange={onBusinessAreaFilterChanged}
                        renderValue={(selected) => selected.join(', ')}
                        data-cy="client-business-area-filter"
                    >
                        {getBusinessAreasFilterList()}
                    </Select>

                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                    <InputLabel id="client-filter-product-label">ERP Product</InputLabel>
                    <Select
                        labelId="client-filter-product-label"
                        aria-labelledby="client-filter-product-label"
                        id="client-filter-product"
                        multiple
                        value={filterProductNames ?? []}
                        MenuProps={DropdownMenuProps}
                        onChange={onProductFilterChanged}
                        renderValue={(selected) => selected.join(', ')}
                        data-cy="client-product-filter"
                    >
                        {getProductsFilterList()}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={1} md={2}>
                <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                    <InputLabel id="client-filter-status-label">Status</InputLabel>
                    <Select
                        labelId="client-filter-status-label"
                        aria-labelledby="client-filter-status-label"
                        id="client-filter-status"
                        multiple
                        value={filterStatuses ?? []}
                        MenuProps={DropdownMenuProps}
                        onChange={onStatusFilterChanged}
                        renderValue={(selected) => selected.join(', ')}
                        data-cy="client-status-filter"
                    >
                        {getStatusFilterList()}
                    </Select>
                </FormControl>
            </Grid>
        </FilterBar>
    );
};

export default ClientsFilterBar;