import { useEffect, useState } from "react";
import { Grid } from '@mui/material';

import AddEditDialog from "./AddEditDialog";
import NoteField from "../fields/NoteField";
import { ExceptionStatus } from "../../gql-types.generated";

interface ErrorLogResolveDetailsDialogProps {
    isOpen: boolean;
    logStatus: ExceptionStatus;
    onCancel: () => void;
    onSave: (
        note: string,
    ) => void;
}

const ErrorLogResolveDetailsDialog: React.FC<ErrorLogResolveDetailsDialogProps> = props => {
    const { isOpen, logStatus, onCancel, onSave } = props;
    const [isDirty, setIsDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [noteContent, setNoteContent] = useState('');
    
    
    useEffect(() => {
        if (!isOpen) {
            setDefaults();
        }
    }, [isOpen]);

    const setDefaults = () => {
        setIsDirty(false);
        setSubmitted(false);
        setNoteContent('');
    };

    const saveNote = () => {
        if (IsFormValid()) {
            setSubmitted(true);
            onSave(noteContent);
        }
    };

    const onError = () => {
        setSubmitted(false);
    }

    const IsFormValid = () => {
        return isDirty && noteContent.length > 0;
    }

    const onNoteChange = (newValue: string) => {
        setIsDirty(true);
        setNoteContent(newValue);
    }

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            entityName={`${logStatus === ExceptionStatus.ResolvedError ? 'Unresolve' : 'Resolve'} Details`}
            titlePrefix="Enter"
            hideTitleCloseButton={true}
            onClose={onCancel}
            onSave={saveNote}
            validate={IsFormValid}
            onError={onError}
            maxWidth='md'
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <NoteField
                        value={noteContent}
                        placeholder="Must enter a note before updating the status..."
                        disabled={false}
                        onChange={onNoteChange}
                    />
                </Grid>
            </Grid>
        </AddEditDialog>
    );
}

export default ErrorLogResolveDetailsDialog;