import { Grid, IconButton, Typography, Stack, SelectChangeEvent, MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { capitalize } from "lodash";
import EditIcon from '@mui/icons-material/Edit';

import { OutboundAcknowledgementType, PartnerTransactionModel, PartnerTransactionSchemaModel, PipelineModel, TransactionDirection, UpdatePartnerTransactionInput } from "../../gql-types.generated";
import { DialogDynamicListHeader, DialogDataGridWrapper } from "../../util/SharedStyles";
import AddEditDialog from "./AddEditDialog";
import TransactionPipelineDialog from "./TransactionPipelineDialog";
import DialogPartnerTransactionSchemaList from "../lists/transactions/DialogPartnerTransactionSchemaList";

interface EditPartnerTransactionDialogProps {
    isOpen: boolean;
    partnerTransaction: PartnerTransactionModel | undefined;
    transactionSchemas?: PartnerTransactionSchemaModel[] | undefined;
    onClose: () => void;
    onSave: (
        inputData: UpdatePartnerTransactionInput
    ) => void;
    refreshPartnerData: () => void;
    error?: Error | undefined;
}

const EditPartnerTransactionDialog: React.FC<EditPartnerTransactionDialogProps> = props => {
    const { isOpen, partnerTransaction, transactionSchemas, onClose, onSave, refreshPartnerData, error } = props;
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [partnerTransactionId, setPartnerTransactionId] = useState<string>('');
    const [transactionDirection, setTransactionDirection] = useState<TransactionDirection | undefined>(undefined);
    const [pipelineIdValue, setPipelineIdValue] = useState<string>('');
    const [pipelineDescription, setPipelineDescription] = useState<string>('');
    const [pipelineDialogOpen, setPipelineDialogOpen] = useState(false);
    const [outboundAcknowledgementOption, setOutboundAcknowledgementOption] = useState<OutboundAcknowledgementType | undefined>(OutboundAcknowledgementType.Normal);
        
    useEffect(() => {
        if (!isOpen) {
            setSubmitted(false);
            setIsFormDirty(false);
            setPipelineIdValue('');
            setPipelineDescription('');
            setOutboundAcknowledgementOption(undefined);
        } 
    }, [isOpen]);

    useEffect(() => {
        if (partnerTransaction) {
            setPartnerTransactionId(partnerTransaction.id);
            setTransactionDirection(partnerTransaction.transaction?.direction as TransactionDirection);
            if (partnerTransaction.pipeline) {
                setPipelineIdValue(partnerTransaction.pipeline.id);
                setPipelineDescription(partnerTransaction.pipeline.description as string);
            }
            if (partnerTransaction.outboundAcknowledgementOption) {
                setOutboundAcknowledgementOption(partnerTransaction.outboundAcknowledgementOption);
            }
        }
    }, [partnerTransaction]);

    const isInboundTransaction = transactionDirection === TransactionDirection.Inbound;

    const submitForm = () => {
        if (isFormDirty) {
            setSubmitted(true);
            onSave(
                {
                    id: partnerTransactionId,
                    pipelineId: pipelineIdValue,
                    outboundAcknowledgementOption: outboundAcknowledgementOption
                } as UpdatePartnerTransactionInput
            );
        }
    };

    const closeEditDialog = () => {
        onClose();
    };

    const validation = () => {
        if (isFormDirty) {
            return true;
        }
        return false;
    };

    const onError = () => {
        setSubmitted(false);
    };

    const onPipelineDialogClose = () => {
        setPipelineDialogOpen(false);
    };

    const onEditPipeline = () => {
        setPipelineDialogOpen(true);
    };

    const savePipelineFromDialogPicker = (selectedPipeline?: PipelineModel) => {
        setIsFormDirty(true);
        if (selectedPipeline) {
            setPipelineIdValue(selectedPipeline.id);
            setPipelineDescription(selectedPipeline.description as string)
        } else {
            setPipelineIdValue('');
            setPipelineDescription('');
        }
        // close the dialog after setting values
        onPipelineDialogClose();
    };

    const onOutboundAcknowledgementOptionChange = (event: SelectChangeEvent<string | null>) => {
        setIsFormDirty(true);
        if (event.target.value) {
            setOutboundAcknowledgementOption(event.target.value as OutboundAcknowledgementType);
        } else {
            setOutboundAcknowledgementOption(undefined);
        }
    };

    const outboundAcknowledgementOptions = Object.values(OutboundAcknowledgementType);

    const getOutboundAcknowledgementOptions = () => {
        if (outboundAcknowledgementOptions && outboundAcknowledgementOptions.length) {
            let items = [];
            const mappedItems = (
                outboundAcknowledgementOptions.map((option: OutboundAcknowledgementType) => (
                    <MenuItem
                        key={option}
                        value={option}
                    >
                        {capitalize(option)}
                    </MenuItem>
                ))
            );
            items.push(...mappedItems);
            return items;
        }
        return null;
    };
    
    const getPipelineContent = () => {
        let pipelineText = pipelineDescription ? pipelineDescription : "Pipeline Not Set";
        
        return (
            <Grid>
                <DialogDynamicListHeader aria-label='selected-pipeline' justifyContent='flex-start'>Selected Pipeline</DialogDynamicListHeader>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Typography variant='body1' >{pipelineText}</Typography>
                    <IconButton color="primary" aria-label="edit-Pipeline" onClick={onEditPipeline}>
                        <EditIcon />
                    </IconButton>
                </Stack>
            </Grid>
        );
    };

    const getOutboundAcknowledgementContent = () => {
        return (
            <Grid item xs={4}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="edit-outbound-acknowledgement-option-label">Outbound Acknowledgement Option</InputLabel>
                    <Select
                        labelId="edit-outbound-acknowledgement-option-label"
                        aria-labelledby="edit-outbound-acknowledgement-option-label"
                        value={outboundAcknowledgementOption ?? OutboundAcknowledgementType.Normal}
                        onChange={onOutboundAcknowledgementOptionChange}
                        disabled={submitted}
                        MenuProps={{
                            'aria-label': 'outbound acknowledgement option',
                        }}
                        data-cy="dialog-edit-partner-transaction-outbound-acknowledgement-option"
                    >
                        {getOutboundAcknowledgementOptions()}
                    </Select>
                </FormControl>
            </Grid>
        );
    };

    const getSchemaContent = () => {
        return (
            <DialogPartnerTransactionSchemaList 
                parentId={partnerTransactionId ?? ''}
                schemas={transactionSchemas} 
                isLoading={submitted}
                isParentTransactionInbound={isInboundTransaction}
                refreshPartnerData={refreshPartnerData}
            />
        );
    };
   
    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={partnerTransactionId}
            entityName="Partner Transaction"
            maxWidth='md'
            validate={validation}
            onClose={closeEditDialog}
            onSave={submitForm}
            onError={onError}
            error={error}
        >
            <Grid container spacing={2}>
                <DialogDataGridWrapper container>
                    {getPipelineContent()}
                </DialogDataGridWrapper>
                {isInboundTransaction && (
                <DialogDataGridWrapper container mt={2} mb={2}>
                    {getOutboundAcknowledgementContent()}
                </DialogDataGridWrapper>
                )}
                <DialogDataGridWrapper container>
                    {getSchemaContent()}
                </DialogDataGridWrapper>
            </Grid>    
            <TransactionPipelineDialog
                isOpen={pipelineDialogOpen}
                pipeline={partnerTransaction?.pipeline as PipelineModel}
                onClose={onPipelineDialogClose}
                onSave={savePipelineFromDialogPicker}
                error={error}
            />
        </AddEditDialog>
    );
}

export default EditPartnerTransactionDialog;