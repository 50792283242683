import { FormControlLabel, Grid, Stack, Switch, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from 'react';
import { ClientContactModel, PartnerContactModel, DeleteByIdPayload, AddressModel, EmailModel, NoteModel, PhoneModel, RequestResult } from '../../gql-types.generated';

import { ActiveStatusChip, StatusChip, DialogGridColumn, DialogGridWithTopMargin, ReadOnlyGridColumn } from "../../util/SharedStyles";

import { getPrimaryAddressFromArray, getPrimaryEmailFromArray, getPrimaryPhoneFromArray } from '../../util/PrimaryFromModelArray';
import { ModelInputsSubmission, Viewer } from "../../util/Constants";
import { isEmailValid } from "../../util/Validation";
import { AddressToDisplayString } from '../../util/Common';
import AddressFields from "../fields/AddressFields";
import PhoneFields from "../fields/PhoneFields";
import AddEditDialog from "./AddEditDialog";
import DialogNotesList from "../lists/DialogNotesList";

interface ContactDialogProps {
    isOpen: boolean;
    contactInfo?: ClientContactModel | PartnerContactModel;
    contactNotes?: NoteModel[] | undefined;
    isReadOnly?: boolean;
    isRefreshing?: boolean;
    viewer?: Viewer | undefined;
    onClose: () => void;
    onCancel: () => void;
    onSave: (
        address?: AddressModel,
        description?: string,
        email?: EmailModel,
        id?: string,
        isActive?: boolean,
        isPrimary?: boolean,
        jobTitle?: string,
        name?: string,
        phone?: PhoneModel
    ) => void;
    onSaveNote: (
        note: NoteModel,
    ) => void;
    onDeleteNote: (
        id: string,
    ) => void;
    saveResult?: RequestResult | undefined;
    saveNoteResult?: RequestResult | undefined;
    deleteNoteStatus?: DeleteByIdPayload;
    error?: Error | undefined;
    clearError: () => void;
}

const ContactDialog: React.FC<ContactDialogProps> = props => {
    const { isOpen, isRefreshing, contactInfo, contactNotes, onClose, onCancel, onSave, onSaveNote, onDeleteNote, saveResult, saveNoteResult, deleteNoteStatus, error, clearError, isReadOnly = false, viewer } = props;
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [name, setName] = useState<string>('');
    const [isActive, setIsActive] = useState(false);
    const [isPrimary, setIsPrimary] = useState(false);
    const [description, setDescription] = useState<string>('');
    const [jobTitle, setJobTitle] = useState<string>('');
    const [address, setAddress] = useState<AddressModel | undefined>(undefined);
    const [email, setEmail] = useState<EmailModel | undefined>(undefined);
    const [phone, setPhone] = useState<PhoneModel | undefined>(undefined);
    const [notes, setNotes] = useState<NoteModel[] | undefined>([]);
    const [contactId, setContactId] = useState<string | undefined>(undefined);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');
    const [isViewMode, setIsViewMode] = useState(false);
    
    const viewerRole = viewer?.role;

    useEffect(() => {
        setNotes(contactNotes);
    }, [contactNotes]);

    useEffect(() => {
        // upon successful save during an edit from viewMode, 
        // need to set submitted back to false and revert back to viewMode
        if (saveResult === RequestResult.Success) {
            if (isReadOnly) {
                setSubmitted(false);
                setIsFormDirty(false);
                setIsViewMode(true);
            }
        }
    }, [saveResult, isReadOnly]);

    useEffect(() => {
        // set initial mode
        setIsViewMode(isReadOnly);
    }, [isReadOnly]);

    useEffect(() => {
        if (contactInfo) {
            setName(contactInfo.name as string || '');

            let contactInfoId = contactInfo.id;
            if (contactInfoId) {
                setContactId(contactInfoId);
            }
            setPhoneOrDefault(contactInfoId);
            setEmailOrDefault(contactInfoId);
            setAddressOrDefault(contactInfoId);

            if (contactInfo.description) {
                setDescription(contactInfo.description);
            }
            if (contactInfo.jobTitle) {
                setJobTitle(contactInfo.jobTitle);
            }
            if (contactInfo.hasOwnProperty('isActive')) {
                setIsActive(contactInfo.isActive ?? false);
            }
            if (contactInfo.hasOwnProperty('isPrimary')) {
                setIsPrimary(contactInfo.isPrimary ?? false);
            }
        } else {
            setToDefaults();
        }
    }, [contactInfo]);

    useEffect(() => {
        if (!isOpen) {
            setToDefaults();
            setSubmitted(false);
            setIsFormDirty(false);
        }
    }, [isOpen]);

    const setAddressDefaults = (parentId: string | undefined) => {
        setAddress({
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            addressLine4: '',
            city: '',
            countryCode: '',
            postalCode: '',
            stateId: '',
            isPrimary: true,
            parentId: parentId,
        } as AddressModel);
    };

    const setEmailDefaults = (parentId: string | undefined) => {
        setEmail({
            email: '',
            parentId: parentId,
            isPrimary: true,
        } as EmailModel);
    };

    const setPhoneDefaults = (parentId: string | undefined) => {
        setPhone({
            phoneNumber: '',
            description: '',
            parentId: parentId,
            isPrimary: true,
        } as PhoneModel);
    };

    const setToDefaults = () => {
        setName('');
        setContactId(undefined);
        setPhoneDefaults(undefined);
        setEmailDefaults(undefined);
        setAddressDefaults(undefined);
        setDescription('');
        setJobTitle('');
        setNotes([]);
        setIsActive(false);
        setIsPrimary(false);
        setSubmitted(false);
        setIsFormDirty(false);
    };

    const setAddressOrDefault = (parentId: string | undefined) => {
        if (contactInfo?.addresses) {
            const primary = getPrimaryAddressFromArray(contactInfo.addresses as AddressModel[]);
            if (primary) {
                if (primary.isPrimary === undefined) {
                    // primary is not extensible, since it wasn't returned with this property, we can not add it
                    // make a clone and set the property before setting to the state
                    const newPrimary = Object.assign({}, primary, { isPrimary: true, parentId: parentId });
                    setAddress(newPrimary);
                } else {
                    setAddress(primary);
                }
            } else {
                setAddressDefaults(parentId);
            }
        } else {
            setAddressDefaults(parentId);
        }
    };

    const setPhoneOrDefault = (parentId: string | undefined) => {
        if (contactInfo?.phones) {
            const primary = getPrimaryPhoneFromArray(contactInfo.phones as PhoneModel[]);
            if (primary) {
                if (primary.isPrimary === undefined) {
                    // primary is not extensible, since it wasn't returned with this property, we can not add it
                    // make a clone and set the property before setting to the state
                    const newPrimary = Object.assign({}, primary, { isPrimary: true, parentId: parentId });
                    setPhone(newPrimary);
                } else {
                    setPhone(primary);
                }
            } else {
                setPhoneDefaults(parentId);
            }
        } else {
            setPhoneDefaults(parentId);
        }
    };

    const setEmailOrDefault = (parentId: string | undefined) => {
        if (contactInfo?.emails) {
            const primary = getPrimaryEmailFromArray(contactInfo.emails as EmailModel[]);
            if (primary) {
                if (primary.isPrimary === undefined) {
                    // primary is not extensible, since it wasn't returned with this property, we can not add it
                    // make a clone and set the property before setting to the state
                    const newPrimary = Object.assign({}, primary, { isPrimary: true, parentId: parentId });
                    validateAndSetEmail(newPrimary.email as string, newPrimary);
                } else {
                    validateAndSetEmail(primary.email as string, primary);
                }
            } else {
                setEmailDefaults(parentId);
            }
        } else {
            setEmailDefaults(parentId);
        }
    };

    const checkAddressModelForData = () => {
        if (address?.addressLine1 !== '' ||
            address?.addressLine1 !== '' ||
            address?.addressLine2 !== '' ||
            address?.addressLine3 !== '' ||
            address?.addressLine4 !== '' ||
            address?.city !== '' ||
            address?.countryCode !== '' ||
            address?.postalCode !== '' ||
            address?.stateId !== '') {
            return address;
        } else {
            return undefined;
        }
    };

    const getModelInputsData = () => {
        const emailVal = email?.email as string;
        const phoneVal = phone?.phoneNumber as string;
        let ret = { email: undefined, phone: undefined, address: checkAddressModelForData() } as ModelInputsSubmission;
        if (emailVal !== '') {
            ret.email = email;
        }
        if (phoneVal !== '') {
            ret.phone = phone;
        }
        return ret;
    };

    const validateAndSetEmail = (toValidate: string, clientEmail?: EmailModel) => {
        if (toValidate || toValidate === "") {
            const isValid = isEmailValid(toValidate);
            setEmailInvalid(!isValid);
            if (isValid) {
                // id clientEmail exists, we are in initial set, so don't want the form dirty
                if (clientEmail === undefined) {
                    setIsFormDirty(true);
                }
                setEmailErrorText('');
            } else {
                setEmailErrorText('Invalid format.  Enter a valid email address');
            }
            // assign the passed in email (initial set only), or the stored email
            let currEmail = clientEmail || email;
            if (currEmail) {
                const justMail = { email: toValidate };
                let newMail = {};
                Object.assign(newMail, currEmail, justMail);
                setEmail(newMail as EmailModel);
            } else {
                setEmail({
                    email: toValidate,
                    isPrimary: true,
                    parentId: contactId,
                } as EmailModel);
            }
        }
    };

    const isFormValid = () => {
        // name and valid email are required
        let requiredCheck = (isFormDirty && name?.trim().length > 0);
        if (requiredCheck) {
            if (email)
            {
                requiredCheck = (email.email !== null && email.email !== undefined && email.email.length > 0);
                return (!emailInvalid && requiredCheck);
            } else {
                requiredCheck = false;
            }
        }
        return requiredCheck;
    };

    const onError = () => {
        setSubmitted(false);
    };

    const submitForm = () => {
        if (isFormValid()) {
            const models = getModelInputsData();
            setSubmitted(true);
            onSave(
                models.address,
                description,
                models.email,
                contactId,
                isActive,
                isPrimary,
                jobTitle,
                name,
                models.phone
            );
        }
    };

    const viewModeEditForm = () => {
        // turn off viewMode to allow for editing
        setIsViewMode(false);
    };

    const onEditCancel = () => {
        if (isReadOnly) {
            // revert back to viewMode instead of closing
            setIsViewMode(true);
            // refresh the data from the parent in case any field changed during edit
            onCancel();
        }
        else {
            // close as usual
            onClose();
        }
    };

    const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setName(event.target.value);
    };
    const onJobTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setJobTitle(event.target.value);
    };
    const onPhoneNumberChange = (phoneModelObj: PhoneModel) => {
        setIsFormDirty(true);
        setPhone(phoneModelObj);
    };
    const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        validateAndSetEmail(event.target.value);
    };
    const onAddressChange = (address: AddressModel) => {
        setIsFormDirty(true);
        setAddress(address);
    };
    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };
    const onIsPrimaryChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setIsPrimary(event.target.checked);
    };
    const onIsActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setIsActive(event.target.checked);
    };

    const emailProps = {
        'aria-label': 'email',
        'maxLength': 255,
    };
    const isActiveProps = {
        'aria-label': 'isActive',
    };
    const isPrimaryProps = {
        'aria-label': 'isPrimary'
    };
    const nameProps = {
        'aria-label': 'name',
        'maxLength': 50,
    };
    const descriptionProps = {
        'aria-label': 'notes',
        'maxLength': 255,
    };
    const jobTitleProps = {
        'aria-label': 'jobTitle',
        'maxLength': 50,
    };

    const addressForViewMode = AddressToDisplayString(address, true);
    const phoneForViewMode = `${phone?.description ? phone.description + ': ' : ''}${phone?.phoneNumber ? phone?.phoneNumber : ''}`;
    let primaryChip: any;
    if (isPrimary) {
        primaryChip = <ActiveStatusChip label="Primary" />;
    }
    let statusChip = <StatusChip label="Inactive" />;
    if (isActive) {
        statusChip = <ActiveStatusChip label="Active" />;
    }

    const getNotesList = () => {
        return (
            <DialogNotesList 
                viewer={viewer} 
                parentId={contactId}
                notes={notes} 
                isLoading={submitted || isRefreshing}
                saveNote={onSaveNote}
                deleteNote={onDeleteNote} 
                saveNoteResult={saveNoteResult}
                deleteNoteStatus={deleteNoteStatus}
                error={error}
                clearError={clearError} 
            />
        );
    };

    // readOnly fields are needed when in viewMode
    const getViewFields = () => {
        return (
            <Grid container spacing={2} item xs={12}>
                <DialogGridWithTopMargin container item columns={{ xs: 1, sm: 2 }} spacing={2} xs={12}>
                    <DialogGridColumn id="detailsColumn" container item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <ReadOnlyGridColumn item >
                                    <Typography variant='caption' aria-label='name'>Name</Typography>
                                    <Typography variant='body1' >{name}</Typography>
                                </ReadOnlyGridColumn>
                                <ReadOnlyGridColumn item >
                                    <Typography variant='caption' aria-label='title'>Job Title</Typography>
                                    <Typography variant='body1' >{jobTitle}</Typography>
                                </ReadOnlyGridColumn>
                                <ReadOnlyGridColumn item >
                                    <Typography variant='caption' aria-label='phone'>Phone</Typography>
                                    <Typography variant='body1' >{phoneForViewMode} </Typography>
                                </ReadOnlyGridColumn>
                            </Stack>
                        </Grid>
                    </DialogGridColumn>
                    <DialogGridColumn id="addressColumn" container item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <ReadOnlyGridColumn item >
                                    <Typography variant='caption' aria-label='email'>Email</Typography>
                                    <Typography variant='body1' >{email?.email}</Typography>        
                                </ReadOnlyGridColumn>
                                <ReadOnlyGridColumn item >
                                    <Typography variant='caption' aria-label='address'>Address</Typography>
                                    <Typography variant='body1' >{addressForViewMode}</Typography>
                                </ReadOnlyGridColumn>
                            </Stack>
                        </Grid>
                    </DialogGridColumn>
                </DialogGridWithTopMargin>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='description'>Description</Typography>
                    <Typography variant='body1' >{description}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {getNotesList()}
                </Grid>
                <Grid item xs={12}>
                    <Grid container item xs={12} direction="row" gap={"12px"}>
                        {isPrimary && (
                            <Grid item >
                                {primaryChip}
                            </Grid>
                        )}
                        <Grid item >
                            {statusChip}
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>
        );
    };

    const getAddEditFields = () => {
        return (
            <Grid container spacing={2} item xs={12}>
                <DialogGridWithTopMargin container item columns={{ xs: 1, sm: 2 }} spacing={2} xs={12}>
                    <DialogGridColumn id="detailsColumn" container item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <Grid item >
                                    <TextField
                                        itemID="dialog-contact-name"
                                        fullWidth
                                        disabled={submitted}
                                        autoFocus
                                        value={name}
                                        label="Name"
                                        inputProps={nameProps}
                                        onChange={onNameChange}
                                        autoComplete="off"
                                        required
                                        data-cy="dialog-contact-name"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField
                                        itemID="dialog-contact-jobTitle"
                                        fullWidth
                                        disabled={submitted}
                                        value={jobTitle}
                                        label="Job Title"
                                        inputProps={jobTitleProps}
                                        onChange={onJobTitleChange}
                                        autoComplete="off"
                                        data-cy="dialog-contact-jobTitle"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField
                                        itemID="dialog-contact-email"
                                        fullWidth
                                        error={emailInvalid}
                                        helperText={emailErrorText}
                                        disabled={submitted}
                                        value={email?.email}
                                        label="Email"
                                        inputProps={emailProps}
                                        onChange={onEmailChange}
                                        autoComplete="off"
                                        required
                                        data-cy="dialog-contact-email"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item >
                                    <PhoneFields
                                        phone={phone}
                                        parentId={contactId}
                                        disabled={submitted}
                                        onChange={onPhoneNumberChange}
                                        data-cy="dialog-contact-phone"
                                    />
                                </Grid>
                            </Stack>
                        </Grid>
                    </DialogGridColumn>
                    <DialogGridColumn id="addressColumn" container item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <AddressFields
                                parentId={contactId}
                                address={address}
                                onChange={onAddressChange}
                                disabled={submitted}
                                data-cy="dialog-contact-address-fields"
                            />
                        </Grid>
                    </DialogGridColumn>
                </DialogGridWithTopMargin>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-contact-description"
                        fullWidth
                        disabled={submitted}
                        value={description}
                        label="Description"
                        multiline
                        maxRows={2}
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        autoComplete="off"
                        data-cy="dialog-contact-description"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    { contactId && (
                        getNotesList()
                    )}
                </Grid>
                <DialogGridColumn item xs={3}>
                    <FormControlLabel
                        control={
                            <Switch
                                itemID="dialog-contact-isPrimary"
                                disabled={submitted}
                                checked={isPrimary}
                                inputProps={isPrimaryProps}
                                onChange={onIsPrimaryChange}
                                data-cy="dialog-contact-isPrimary"
                            />
                        }
                        label="Primary Contact"
                    />
                </DialogGridColumn>
                <DialogGridColumn item xs={3}>
                    <FormControlLabel
                        control={
                            <Switch
                                itemID="dialog-contact-isActive"
                                disabled={submitted}
                                checked={isActive}
                                inputProps={isActiveProps}
                                onChange={onIsActiveChange}
                                data-cy="dialog-contact-isActive"
                            />
                        }
                        label="Active Contact"
                    />
                </DialogGridColumn>
            </Grid>
        );
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            isReadOnly={isReadOnly}
            viewerRole={viewerRole}
            id={contactId}
            entityName="Contact"
            maxWidth='md'
            onClose={onClose}
            onCancel={onEditCancel}
            onSave={submitForm}
            onViewModeEdit={viewModeEditForm}
            validate={isFormValid}
            onError={onError}
            error={error}
        >
            {isViewMode && (
                getViewFields()
            )}
            {!isViewMode && (
                getAddEditFields()
            )}
        </AddEditDialog>
    );

};

export default ContactDialog;