import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CircularProgress, Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridOverlay, GridRowModel, GridSortModel, GridRowParams } from '@mui/x-data-grid-pro';
import { DialogDynamicListToolbar, DialogDynamicListContainer, DialogDynamicListContent, DialogDynamicListHeader, DialogDynamicListDataGrid } from '../../util/SharedStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ClientAkaModel, RequestResult, UpsertClientAkaInput } from "../../gql-types.generated";
import { selectError, selectDeleteClientAkaStatus, selectUpsertClientAkaStatus, captureDeleteClientAkaStatus, captureUpsertClientAkaStatus } from "../../features/ClientDetails/ClientDetailsSlice";
import { upsertClientAKA, deleteClientAKA } from '../../features/ClientDetails/ClientDetailsActions';
import CreateNewButton from '../buttons/CreateNewButton';
import DeleteDialog from '../dialogs/DeleteDialog';
import AkaDialog from '../dialogs/AkaDialog';
import { setToastConfig } from '../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../util/Constants';

interface ClientAkaListProps {
    parentId: string;
    clientAkas: ClientAkaModel[] | undefined;
    isLoading?: boolean;
    isClientFormDirty?: boolean;
    preSaveClientData: () => void;
    refreshClientData?: () => void;
}

const DialogClientAkaList: React.FC<ClientAkaListProps> = props => {
    const { parentId, clientAkas, refreshClientData, preSaveClientData, isLoading = false, isClientFormDirty = false } = props;
    const dispatch = useAppDispatch();
    const [gridHeight, setGridHeight] = useState<string>('48px');
    const [openClientAkaDialog, setOpenClientAkaDialog] = useState(false);
    const [selectedClientAka, setSelectedClientAka] = useState<ClientAkaModel | undefined>(undefined); 
    const [clientAkaRows, setClientAkaRows] = useState<GridRowModel[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'clientCode',
            sort: 'asc',
        },
    ]);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [isSavingOrDeletingClientAka, setIsSavingOrDeletingClientAka] = useState(false);

    const error = useAppSelector(selectError);
    const deleteClientAkaStatus = useAppSelector(selectDeleteClientAkaStatus);
    const saveClientAkaStatus = useAppSelector(selectUpsertClientAkaStatus);

    useEffect(() => {
        setClientAkaRows(getClientAkaRows());
        setGridHeight(getGridHeight());
    }, [clientAkas]);

    
    // declare the data refreshing function
    // as async so list will show as loading
    // up until refresh is complete
    const refreshData = async () => {
        if (refreshClientData) {
            setIsSavingOrDeletingClientAka(true);
            await refreshClientData();
        }
        setIsSavingOrDeletingClientAka(false);
    };

    useEffect(() => {
        if (saveClientAkaStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: saveClientAkaStatus.message as string,
                severity: ToastSeverity.Success
            }));
            onClientAkasDialogClose();
            refreshData();
        } else if (saveClientAkaStatus?.result === RequestResult.Fail) {
            setIsSavingOrDeletingClientAka(false);
        }
    }, [saveClientAkaStatus?.result]);

    useEffect(() => {
        if (deleteClientAkaStatus) {
            if (deleteClientAkaStatus.result === RequestResult.Success) {
                onDeleteDialogClose();
                refreshData();
            } else if (deleteClientAkaStatus.result === RequestResult.Fail) {
                setDeleteErrorMessage(deleteClientAkaStatus?.message as string);
                setIsSavingOrDeletingClientAka(false);
            }
        }
    }, [deleteClientAkaStatus?.result]);

    const getSelectedRowClientAka = useCallback((rowId: string) => () => {
        if (rowId && clientAkas?.length) {
            let clientAka = clientAkas.find(clientAka => clientAka.id === rowId);
            return clientAka;
        }
        return undefined;
    }, [clientAkas]);

    const deleteClientAkaHandler = useCallback((rowId: string) => () => {
        let clientAka = getSelectedRowClientAka(rowId);
        if (clientAka) {
            setSelectedClientAka(clientAka);
            setOpenDelete(true);
        }
    }, [getSelectedRowClientAka]);

    const editClientAkaHandler = useCallback((rowId: string) => () => {
        let clientAka = getSelectedRowClientAka(rowId);
        if (clientAka) {
            setSelectedClientAka(clientAka);
            setOpenClientAkaDialog(true);
        }
    }, [getSelectedRowClientAka]);

    const setDefaults = () => {
        setSelectedClientAka(undefined);
    };
    const onAddClientAka = () => {
        setDefaults();
        setOpenClientAkaDialog(true);
    };

    const onDialogClose = () => {
        setDefaults();
    };

    const onClientAkasDialogClose = () => {
        setOpenClientAkaDialog(false);
        // if there was an error and dialog is closed,
        // refresh the data to clear error on parent
        if (error) {
            refreshData();
        }
        dispatch(captureUpsertClientAkaStatus());
        onDialogClose();
    };

    const onClientAkasDialogSave = (parentId: string, akaCode: string, isaQualifierCode: string, akaId?: string) => {
        if (akaCode && isaQualifierCode) {
            let timeDelay = 0;
            let inputData: UpsertClientAkaInput = {clientId: parentId, clientCode: akaCode, isaQualifierCode, id: akaId} 
                
            if (isClientFormDirty) {
                // save the client form first and give a time delay
                // to allow the client to save before saving the clientAka
                timeDelay = 1000;
                preSaveClientData();
            } 

            setTimeout(() => {
                setIsSavingOrDeletingClientAka(true);
                dispatch(upsertClientAKA(inputData));
            }, timeDelay);
        }
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        dispatch(captureDeleteClientAkaStatus());
        onDialogClose();
        setDeleteErrorMessage('');
    };

    const onDeleteDialogConfirm = () => {
        // delete the selected clientAka
        if (selectedClientAka) {
            let timeDelay = 0;
                
            if (isClientFormDirty) {
                // save the client form first and give a time delay
                // to allow the client to save before deleting the clientAka
                timeDelay = 1000;
                preSaveClientData();
            } 

            setTimeout(() => {
                setIsSavingOrDeletingClientAka(true);
                dispatch(deleteClientAKA(selectedClientAka.id as string));
            }, timeDelay);
        }
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'clientAka-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const getGridHeight = () => {
        // either the grid or its container needs a set height to render properly
        // the container only has maxHeight so that it doesn't display a lot of 
        // white space when there are no clientAkas, so need to calculate a height
        // based on the number of rows since want a scroll bar instead of paging
        if (clientAkas) {
            if (clientAkas.length === 1) {
                return '108px';
            } else if (clientAkas.length === 2) {
                return '156px';
            } else if (clientAkas.length >= 3) {
                return '208px';
            }
        }
        return '48px';
    };

    const getClientAkaRows = () => {
        if (clientAkas && clientAkas.length > 0) {
            return clientAkas.map((clientAka) => {
                const { id, clientCode, isaQualifierCode, isaQualifier } = clientAka;
                const isaQualifierCodeAndDescription = `${isaQualifierCode} - ${isaQualifier?.description}`;
                return {
                    _raw: clientAka,
                    id,
                    clientCode,
                    isaQualifierCodeAndDescription
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const clientAkaColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'clientCode',
                headerName: 'CODE',
                flex: 1,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild",
            }, {
                field: 'isaQualifierCodeAndDescription',
                headerName: 'ISA QUALIFIER',
                flex: 2,
                sortable: true,
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                resizable: false,
                headerName: '',
                minWidth: 100,
                headerAlign: 'center',
                align: 'center',
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editClientAkaHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        onClick={deleteClientAkaHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],

            },
        ],
        [deleteClientAkaHandler, editClientAkaHandler],
    );

    return (
        <DialogDynamicListContainer>

            <DialogDynamicListToolbar justify="space-between" noborder={+true}>
                <DialogDynamicListHeader>Additional Codes</DialogDynamicListHeader>
                
                    <CreateNewButton
                        text="Add Code"
                        tooltip="Click here to add another Client Code"
                        variant="text"
                        onClick={onAddClientAka}
                        disabled={isSavingOrDeletingClientAka || isLoading}
                        data-cy="add-new-clientAka"
                    />
               
            </DialogDynamicListToolbar>

            <DialogDynamicListContent>
                {((clientAkas !== undefined && clientAkas?.length > 0) || isSavingOrDeletingClientAka) &&
                    <DialogDynamicListDataGrid
                        loading={isSavingOrDeletingClientAka || isLoading}
                        height={gridHeight}
                        headerHeight={38}
                        rowHeight={52}
                        aria-label="ClientAkas List"
                        hideFooter
                        disableColumnMenu
                        disableColumnFilter
                        disableMultipleSelection
                        rows={clientAkaRows}
                        columns={clientAkaColumns}
                        sortingOrder={['asc', 'desc']}
                        sortModel={sortModel}
                        onSortModelChange={onSortModelChange}
                        components={{
                            // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                            LoadingOverlay: loadingOverlay,
                        }}
                    />
                }
            </DialogDynamicListContent>

            <AkaDialog
                isOpen={openClientAkaDialog}
                parentId={parentId}
                parentName="Client"
                akaCode={selectedClientAka?.clientCode}
                qualifierCode={selectedClientAka?.isaQualifierCode}
                akaId={selectedClientAka?.id}
                onClose={onClientAkasDialogClose}
                onSave={onClientAkasDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedClientAka?.id ?? ''}
                heading={'Delete Client Code'}
                message={'Are you sure you want to delete \'' + selectedClientAka?.clientCode + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </DialogDynamicListContainer>
    );

}

export default DialogClientAkaList;
