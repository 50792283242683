import { Checkbox, Grid, FormControl, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import CloseIcon from '@mui/icons-material/Close';
import { TransactionModel } from '../../gql-types.generated';
import { Viewer } from "../../util/Constants";
import { selectTransactionList } from '../../features/EDIContainer/EDIContainerSlice';

import FilterBar from "./FilterBar";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../util/ViewerUtility';

interface MapsFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    onClose: () => void;
    onFilterChanged: (filterVersion: string | undefined, filterDescription: string | undefined, filterTransactionIds: string[]) => void;
}

const MapsFilterBar: React.FC<MapsFilterBarProps> = props => {
    const { id, loading = false, visible = false, viewer, onFilterChanged } = props;
    const [filterVersion, setFilterVersion] = useState<string | undefined>();
    const [filterDescription, setFilterDescription] = useState<string | undefined>();
    const [filterTransactionNames, setFilterTransactionNames] = useState<string[]>([]);
    const [filterTransactionIds, setFilterTransactionIds] = useState<string[]>();
    const fieldsDisabled = visible && loading;

    useEffect(() => {
        // filters from local storage to be remembered across sessions
        let hasLocalFilters = false;
        let version = getLocalStorageItem(viewer, "mapFilterVersion");
        if (version != null) {
            hasLocalFilters = true;
            setFilterVersion(version);
        }
        let desc = getLocalStorageItem(viewer, "mapFilterDescription");
        if (desc != null) {
            hasLocalFilters = true;
            setFilterDescription(desc);
        }

        let transactionIds = getLocalStorageItem(viewer, "mapFilterTransactionIds");
        let parsedTransactionIds;
        if (transactionIds != null) {
            hasLocalFilters = true;
            parsedTransactionIds = JSON.parse(transactionIds);
            setFilterTransactionIds(parsedTransactionIds);
        }

        let transactionNames = getLocalStorageItem(viewer, "mapFilterTransactionNames");
        if (transactionNames != null && transactionNames.length > 0) {
            let parsedTransactionNames = JSON.parse(transactionNames);
            setFilterTransactionNames(parsedTransactionNames);
        }

        // if there are no saved filters, need to send out an initial fetch 
        if (!hasLocalFilters) {
            onFilterChanged(version as string | undefined, desc as string | undefined, parsedTransactionIds ?? []);
        }
        
    }, []);

    const transactions = useAppSelector(selectTransactionList);

    const onSearchClick = () => {
        onFilterChanged(filterVersion, filterDescription, filterTransactionIds ?? []);
    };

    const onCloseClick = () => {
        // remove any filters
        clearFilters();

        // call parent to hide bar
        props.onClose();

        // trigger a new search with filters undefined since state of filter values might not be updated yet
        onFilterChanged(undefined, undefined, []);
    };

    const clearFilters = () => {
        clearVersionFilter();
        clearDescriptionFilter();
        removeLocalStorageItem(viewer, "mapFilterTransactionNames");
        setFilterTransactionNames([]);
        removeLocalStorageItem(viewer, "mapFilterTransactionIds");
        setFilterTransactionIds([]);
    };

    const clearVersionFilter = () => {
        removeLocalStorageItem(viewer, "mapFilterVersion");
        setFilterVersion(undefined);
    };

    const clearDescriptionFilter = () => {
        removeLocalStorageItem(viewer, "mapFilterDescription");
        setFilterDescription(undefined);
    };

    const SecondaryMenuItemProps = {
        display: 'block',
        width: '240px',
        textOverflow: "ellipsis",
        overflow: 'hidden',
    };

    const getTransactionsFilterList = () => {
        if (transactions && transactions.length) {
            return (
                transactions.map((transaction: TransactionModel) => (
                    <MenuItem
                        key={transaction.id}
                        value={transaction.name}
                        disabled={fieldsDisabled}
                    >
                        <Checkbox checked={filterTransactionNames && filterTransactionNames.indexOf(transaction.name) > -1} />
                        <ListItemText primary={transaction.name} secondary={transaction.description} secondaryTypographyProps={SecondaryMenuItemProps} />
                    </MenuItem>
                ))
            );
        }
        return null;
    };

    const onVersionFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            setLocalStorageItem(viewer, "mapFilterVersion", changedValue);
        }
        else {
            removeLocalStorageItem(viewer, "mapFilterVersion");
        }
        setFilterVersion(changedValue);
    };

    const onDescriptionFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            setLocalStorageItem(viewer, "mapFilterDescription", changedValue);
        }
        else {
            removeLocalStorageItem(viewer, "mapFilterDescription");
        }
        setFilterDescription(changedValue);
    };

    const onTransactionFilterChanged = (event: SelectChangeEvent<typeof filterTransactionNames>) => {
        const {
            target: { value },
        } = event;
        // On autofill we get a stringified value.
        let nameArray = typeof value === 'string' ? value.split(',') : value;

        if (nameArray.length > 0) {
            setLocalStorageItem(viewer, "mapFilterTransactionNames", JSON.stringify(nameArray));
        }
        else {
            removeLocalStorageItem(viewer, "mapFilterTransactionNames");
        }
        setFilterTransactionNames(nameArray);

        // the array from the multiselect only contains the names
        // get the associated ids to actually send as a filter to the query
        let ids: string[] = [];
        let selectedTransactions = transactions?.filter(b => nameArray.includes(b.name));
        if (selectedTransactions && selectedTransactions.length > 0) {
            selectedTransactions.forEach(transaction => {
                ids.push(transaction.id);
            });
        }
        if (ids.length > 0) {
            setLocalStorageItem(viewer, "mapFilterTransactionIds", JSON.stringify(ids));
        }
        else {
            removeLocalStorageItem(viewer, 'mapFilterTransactionIds');
        }

        setFilterTransactionIds(ids);
    };

    const dropDownMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '300px',
            },
        },
    };

    const versionFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear version filter"
                    onClick={clearVersionFilter}
                    disabled={fieldsDisabled}
                >
                    {filterVersion && filterVersion.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const descriptionFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear description filter"
                    onClick={clearDescriptionFilter}
                    disabled={fieldsDisabled}
                >
                    {filterDescription && filterDescription.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick} onSearch={onSearchClick}>
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="map-filter-description"
                    fullWidth
                    value={filterDescription ?? ''}
                    label="Description"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'description', 'maxLength': 50, }}
                    InputProps={descriptionFilterProps}
                    onChange={onDescriptionFilterChanged}
                    autoComplete="off"
                    data-cy="map-description-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="map-filter-version"
                    fullWidth
                    value={filterVersion ?? ''}
                    label="Version"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'version', 'maxLength': 50, }}
                    InputProps={versionFilterProps}
                    onChange={onVersionFilterChanged}
                    autoComplete="off"
                    data-cy="map-name-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
                <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                    <InputLabel id="map-filter-transaction-label">Transaction</InputLabel>
                    <Select
                        labelId="map-filter-transaction-label"
                        aria-labelledby="map-filter-transaction-label"
                        id="map-filter-transaction"
                        multiple
                        value={filterTransactionNames ?? []}
                        MenuProps={dropDownMenuProps}
                        onChange={onTransactionFilterChanged}
                        renderValue={(selected) => selected.join(', ')}
                        data-cy="map-transaction-filter"
                    >
                        {getTransactionsFilterList()}
                    </Select>
                </FormControl>
            </Grid>
        </FilterBar>
    );
};

export default MapsFilterBar;