import { Checkbox, Grid, FormControl, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { capitalize } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector } from "../../app/hooks";
import CloseIcon from '@mui/icons-material/Close';
import { PartnerStatus, EdiStandardModel, BusinessAreaModel } from '../../gql-types.generated';
import { Viewer } from '../../util/Constants';
import { selectBusinessAreaList, selectEdiStandardList } from '../../features/EDIContainer/EDIContainerSlice';
import { getLocalStorageItem, getLocalStorageItemAsBoolean, setLocalStorageItem, removeLocalStorageItem } from '../../util/ViewerUtility';

import FilterBar from './FilterBar';

interface PartnersFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    onClose: () => void;
    onFilterChanged: (filterName: string | undefined, filterStandardIds: string[], filterStatuses: string[], filterPartnerCode: string | undefined, filterBusinessAreas: string[]) => void;
}

const TradingPartnersFilterBar: React.FC<PartnersFilterBarProps> = props => {
    const { id, loading = false, visible = false, viewer, onFilterChanged } = props;
    const [filterName, setFilterName] = useState<string | undefined>();
    const [filterStatuses, setFilterStatuses] = useState<string[]>();
    const [filterStandardNames, setFilterStandardNames] = useState<string[]>([]);
    const [filterStandardIds, setFilterStandardIds] = useState<string[]>();
    const [filterPartnerCode, setFilterPartnerCode] = useState<string | undefined>();
    const [filterBusinessAreaNames, setFilterBusinessAreaNames] = useState<string[]>([]);
    const [filterBusinessAreaIds, setFilterBusinessAreaIds] = useState<string[]>();
    const fieldsDisabled = visible && loading;
    let defaultStatus = capitalize(PartnerStatus.Active.toString());

    useEffect(() => {

        // store the filters in localStorage to remember the preference across sessions 
        let name = getLocalStorageItem(viewer, "partnerFilterName");
        if (name != null) {
            setFilterName(name);
        }

        let standardIds = getLocalStorageItem(viewer, "partnerFilterStandardIds");
        let parsedStandardIds = [];
        if (standardIds != null) {
            parsedStandardIds = JSON.parse(standardIds);
            setFilterStandardIds(parsedStandardIds);
        }

        let standardNames = getLocalStorageItem(viewer, "partnerFilterStandardNames");
        if (standardNames != null && standardNames.length > 0) {
            let parsedStandardNames = JSON.parse(standardNames);
            setFilterStandardNames(parsedStandardNames);
        }

        let partnerCode = getLocalStorageItem(viewer, "partnerFilterPartnerCode");
        if (partnerCode != null) {
            setFilterPartnerCode(partnerCode);
        }

        let businessAreaNames = getLocalStorageItem(viewer, "partnerFilterBusinessAreaNames");
        if (businessAreaNames != null && businessAreaNames.length > 0) {
            let parsedBusinessAreaNames = JSON.parse(businessAreaNames);
            setFilterBusinessAreaNames(parsedBusinessAreaNames);
        }

        let businessAreaIds = getLocalStorageItem(viewer, "partnerFilterBusinessAreaIds");
        let parsedBusinessAreaIds = [];
        if (businessAreaIds != null && businessAreaIds.length > 0) {
            parsedBusinessAreaIds = JSON.parse(businessAreaIds);
            setFilterBusinessAreaIds(parsedBusinessAreaIds);
        }

        let statusFilterIsDismissed = getLocalStorageItemAsBoolean(viewer, "partnerDefaultStatusFilterDismissed");
        let statuses = getLocalStorageItem(viewer, "partnerFilterStatuses");
        let statusFilter = [];
        if (statuses != null && statuses.length > 0) {
            let parsedStatuses = JSON.parse(statuses);
            statusFilter = parsedStatuses;
        }
        else if (!statusFilterIsDismissed) {
            statusFilter = [defaultStatus];
            resetFilterStatusDefault();
        }
        else {
            statusFilter = [];
        }
        setFilterStatuses(statusFilter);

        // send out an initial state to kick a fetch 
        onFilterChanged(name as string | undefined, parsedStandardIds, statusFilter, partnerCode as string | undefined, parsedBusinessAreaIds);
    }, []);

    // get the list of standards, statuses for the filter dropdowns
    const standards = useAppSelector(selectEdiStandardList);
    const businessAreas = useAppSelector(selectBusinessAreaList);
    const statuses = [PartnerStatus.Active, PartnerStatus.Inactive, PartnerStatus.New];

    const onSearchClick = () => {
        onFilterChanged(filterName, filterStandardIds ?? [], filterStatuses ?? [], filterPartnerCode, filterBusinessAreaIds ?? []);
    };

    const onCloseClick = () => {
        // remove any filters
        clearFilters();

        // call parent to hide bar
        props.onClose();

        // trigger a new search with filters undefined since state of filter values might not be updated yet
        onFilterChanged(undefined, [], [defaultStatus], undefined, []);
    };

    const clearFilters = () => {
        clearNameFilter();
        removeLocalStorageItem(viewer, 'partnerFilterStandardNames');
        setFilterStandardNames([]);
        removeLocalStorageItem(viewer, 'partnerFilterStandardIds');
        setFilterStandardIds([]);
        removeLocalStorageItem(viewer, 'partnerFilterStatuses');
        clearPartnerCodeFilter();
        removeLocalStorageItem(viewer, 'partnerFilterBusinessAreaNames');
        setFilterBusinessAreaNames([]);
        removeLocalStorageItem(viewer, 'partnerFilterBusinessAreaIds');
        setFilterBusinessAreaIds([]);
        resetFilterStatusDefault();
    };

    const clearNameFilter = () => {
        removeLocalStorageItem(viewer, 'partnerFilterName');
        setFilterName(undefined);
    }

    // when a user clicks the clear button, restore back to having a default Active status
    const resetFilterStatusDefault = () => {
        removeLocalStorageItem(viewer, "clientDefaultStatusFilterDismissed");
        let defaultStatus = capitalize(PartnerStatus.Active.toString());
        setFilterStatuses([defaultStatus]);
    };

    const clearPartnerCodeFilter = () => {
        removeLocalStorageItem(viewer, 'partnerFilterPartnerCode');
        setFilterPartnerCode(undefined);
    }

    const getStandardsFilterList = () => {
        if (standards && standards.length) {
            return (
                standards.map((standard: EdiStandardModel) => (
                    <MenuItem
                        key={standard.id}
                        value={standard.name}
                        disabled={fieldsDisabled}
                    >
                        <Checkbox checked={filterStandardNames && filterStandardNames.indexOf(standard.name) > -1} />
                        <ListItemText primary={standard.name} />
                    </MenuItem>
                ))
            );
        }
        return null;
    };

    // generate status filter list 
    const getStatusFilterList = () => {
        if (statuses && statuses.length) {
            return (
                statuses.map((status: PartnerStatus) => (
                    <MenuItem
                        key={status}
                        value={capitalize(status)}
                        disabled={fieldsDisabled}
                    >
                        <Checkbox checked={filterStatuses && filterStatuses.indexOf(capitalize(status)) > -1} />
                        <ListItemText primary={capitalize(status)} />
                    </MenuItem>
                ))
            );
        }
        return null;
    };

    const getBusinessAreaList = () => {
        if (businessAreas && businessAreas.length) {
            return (
                businessAreas.map((area: BusinessAreaModel) => (
                    <MenuItem
                        key={area.id}
                        value={area.name}
                        disabled={fieldsDisabled}
                    >
                        <Checkbox checked={filterBusinessAreaNames && filterBusinessAreaNames.indexOf(area.name) > -1} />
                        <ListItemText primary={area.name} />
                    </MenuItem>
                ))
            );
        }
    };

    const onNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {

        let nameValue = event.target.value;
        if (nameValue?.length > 0) {
            setLocalStorageItem(viewer, 'partnerFilterName', nameValue);
        }
        else {
            removeLocalStorageItem(viewer, 'partnerFilterName');
        }

        setFilterName(nameValue);
    };

    const onStandardFilterChanged = (event: SelectChangeEvent<typeof filterStandardNames>) => {
        const {
            target: { value },
        } = event;
        // On autofill we get a stringified value.
        let nameArray = typeof value === 'string' ? value.split(',') : value;

        if (nameArray.length > 0) {
            setLocalStorageItem(viewer, 'partnerFilterStandardNames', JSON.stringify(nameArray));
        }
        else {
            removeLocalStorageItem(viewer, 'partnerFilterStandardNames');
        }
        setFilterStandardNames(nameArray);

        // the array from the multiselect only contains the names
        // get the associated ids to actually send as a filter to the query
        let ids: string[] = [];
        let selectedStandards = standards?.filter(b => nameArray.includes(b.name));
        if (selectedStandards && selectedStandards.length > 0) {
            selectedStandards.forEach(standard => {
                ids.push(standard.id);
            });
        }
        if (ids.length > 0) {
            setLocalStorageItem(viewer, 'partnerFilterStandardIds', JSON.stringify(ids));
        }
        else {
            removeLocalStorageItem(viewer, 'partnerFilterStandardIds');
        }

        setFilterStandardIds(ids);
    };

    const onStatusFilterChanged = (event: SelectChangeEvent<typeof filterStatuses>) => {
        const {
            target: { value },
        } = event;
        // On autofill we get a stringified value.
        let nameArray = typeof value === 'string' ? value.split(',') : value;

        if (nameArray && nameArray.length > 0) {
            setLocalStorageItem(viewer, 'partnerFilterStatuses', JSON.stringify(nameArray));
        }
        else {
            removeLocalStorageItem(viewer, 'partnerFilterStatuses');
            // since array is now empty, a user manually chose to remove the status default
            // store this dismissal to be retained across sessions
            setLocalStorageItem(viewer, "partnerDefaultStatusFilterDismissed", "true");
        }
        setFilterStatuses(nameArray);
    };

    const onPartnerCodeFilterChange = (event: ChangeEvent<HTMLInputElement>) => {

        let partnerCodeValue = event.target.value;
        if (partnerCodeValue?.length > 0) {
            setLocalStorageItem(viewer, 'partnerFilterPartnerCode', partnerCodeValue);
        }
        else {
            removeLocalStorageItem(viewer, 'partnerFilterPartnerCode');
        }

        setFilterPartnerCode(partnerCodeValue);
    };

    const onBuisnessAreaFilterChanged = (event: SelectChangeEvent<typeof filterBusinessAreaNames>) => {
        const {
            target: { value },
        } = event;
        // On autofill we get a stringified value.
        let nameArray = typeof value === 'string' ? value.split(',') : value;

        if (nameArray.length > 0) {
            setLocalStorageItem(viewer, 'partnerFilterBusinessAreaNames', JSON.stringify(nameArray));
        }
        else {
            removeLocalStorageItem(viewer, 'partnerFilterBusinessAreaNames');
        }
        setFilterBusinessAreaNames(nameArray);

        // the array from the multiselect only contains the names
        // get the associated ids to actually send as a filter to the query
        let ids: string[] = [];
        let selectedBusinessAreas = businessAreas?.filter(b => nameArray.includes(b.name));
        if (selectedBusinessAreas && selectedBusinessAreas.length > 0) {
            selectedBusinessAreas.forEach(businessArea => {
                ids.push(businessArea.id);
            });
        }
        if (ids.length > 0) {
            setLocalStorageItem(viewer, 'partnerFilterBusinessAreaIds', JSON.stringify(ids));
        }
        else {
            removeLocalStorageItem(viewer, 'partnerFilterBusinessAreaIds');
        }

        setFilterBusinessAreaIds(ids);
    };

    const DropdownMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '300px',
            },
        },
    };

    const nameFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear name filter"
                    onClick={clearNameFilter}
                    disabled={fieldsDisabled}
                >
                    {filterName && filterName.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const partnerCodeFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear city filter"
                    onClick={clearPartnerCodeFilter}
                    disabled={fieldsDisabled}
                >
                    {filterPartnerCode && filterPartnerCode.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick} onSearch={onSearchClick}>
            <Grid item xs={2}>
                <TextField
                    itemID="partner-filter-name"
                    fullWidth
                    value={filterName ?? ''}
                    label="Name"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'name', 'maxLength': 50, }}
                    InputProps={nameFilterProps}
                    onChange={onNameFilterChange}
                    autoComplete="off"
                    data-cy="partner-name-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
                <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                    <InputLabel id="partner-filter-standard-label">EDI Standard</InputLabel>
                    <Select
                        labelId="partner-filter-standard-label"
                        aria-labelledby="partner-filter-standard-label"
                        id="partner-filter-standard"
                        multiple
                        value={filterStandardNames ?? []}
                        MenuProps={DropdownMenuProps}
                        onChange={onStandardFilterChanged}
                        renderValue={(selected) => selected.join(', ')}
                        data-cy="partner-standard-filter"
                    >
                        {getStandardsFilterList()}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={1} md={2}>
                <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                    <InputLabel id="partner-filter-status-label">Status</InputLabel>
                    <Select
                        labelId="partner-filter-status-label"
                        aria-labelledby="partner-filter-status-label"
                        id="partner-filter-status"
                        multiple
                        value={filterStatuses ?? []}
                        MenuProps={DropdownMenuProps}
                        onChange={onStatusFilterChanged}
                        renderValue={(selected) => selected.join(', ')}
                        data-cy="partner-status-filter"
                    >
                        {getStatusFilterList()}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <TextField
                    itemID="partner-filter-partner-code"
                    fullWidth
                    value={filterPartnerCode ?? ''}
                    label="Partner Code"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'partner code', 'maxLength': 50, }}
                    InputProps={partnerCodeFilterProps}
                    onChange={onPartnerCodeFilterChange}
                    autoComplete="off"
                    data-cy="partner-partner-code-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
                <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                    <InputLabel id="partner-filter-business-area-label">Business Area</InputLabel>
                    <Select
                        labelId="partner-filter-business-area-label"
                        aria-labelledby="partner-filter-business-area-label"
                        id="partner-filter-business-area"
                        multiple
                        value={filterBusinessAreaNames ?? []}
                        MenuProps={DropdownMenuProps}
                        onChange={onBuisnessAreaFilterChanged}
                        renderValue={(selected) => selected.join(', ')}
                        data-cy="partner-business-area-filter"
                    >
                        {getBusinessAreaList()}
                    </Select>
                </FormControl>
            </Grid>
        </FilterBar>
    );
};

export default TradingPartnersFilterBar;