import { ChangeEvent, useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { UserRole, BlobStorageModel, BlobDocumentType, BlobUploadRequestInput, BlobDocumentFormat } from "../../gql-types.generated";
import AddEditDialog from "./AddEditDialog";
import FileDropZone from '../FileDropZone';
import { maxFileSize } from "../../util/Constants";
import { getBase64 } from "../../util/Common";

interface DialogProps {
    isOpen: boolean;
    storageFile?: BlobStorageModel | null | undefined;
    viewerRole?: UserRole | undefined;
    onClose: () => void;
    onSave: (requestData: BlobUploadRequestInput) => void;
    error?: Error | undefined;
}

const StandardExchangeFormatFileDialog: React.FC<DialogProps> = props => {
    const { isOpen, storageFile, viewerRole, onClose, onSave, error } = props;
    const id = storageFile?.id;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [comment, setComment] = useState<string | undefined>(undefined);
    const [blobDocumentId, setBlobDocumentId] = useState<string | undefined>(undefined);
    const [fileName, setFileName] = useState<string>('');
    const [fileContent, setFileContent] = useState<string>('');
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form
    
    useEffect(() => {
        if (!isOpen) {
            resetInitialState();
        } else {
            setFromModel();
        }
    }, [isOpen]);

    useEffect(() => {
        if (storageFile) {
            setFromModel();
        } else {
            resetInitialState();
        }
    }, [storageFile]);

    const setFromModel = () => {
        if (storageFile) {
            // if (storageFile.comment) {
            //     setComment(storageFile.comment);
            // }
            if (storageFile.blobStorageReference?.blobDocumentId) {
                setBlobDocumentId(storageFile.blobStorageReference.blobDocumentId);
            }
            if (storageFile.fileName) {
                setFileName(storageFile.fileName);
            }
        }
    };

    const resetInitialState = () => {
        setSubmitted(false);
        setComment(undefined);
        setBlobDocumentId(undefined);
        setFileName('');
        setIsFormDirty(false);
    }

    const onEditCancel = () => {
        onClose();
    };


    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        let requiredCheck = fileName?.trim().length > 0 && fileContent?.trim().length > 0;
        return isFormDirty && requiredCheck;
    }

    const submitForm = () => {
        setSubmitted(true);
        onSave({
            comment: comment,
            blobDocumentId: blobDocumentId,
            id: id,
            isActive: true,
            isFirstVersion: blobDocumentId ? false : true,
            fileContent: fileContent,
            fileNameWithExtension: fileName,
            blobDocumentType: BlobDocumentType.Sef,
            blobDocumentFormat: BlobDocumentFormat.Base64,
        } as BlobUploadRequestInput);
    }

    const onFileNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setFileName(event.target.value);
    };

    const onCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setComment(event.target.value);
    };

    const getFileContent = async (file: File) => {

        let fileContentBase64 = "";

        // convert file to base64 string to pass to server
        await getBase64(file).then(result => {
            let resultParts = result?.split(',');
            if (resultParts) {
                fileContentBase64 = resultParts[1] ?? '';
            }
        });

        return fileContentBase64;
    };

    const onFileUploadChange = async (filesToUpload: File[]) => {
        setIsFormDirty(true);
        if (filesToUpload && filesToUpload.length > 0) {
            // only 1 file allowed, so just get contents of first file
            let fileToUpload = filesToUpload[0];
            
            let fileString = await getFileContent(fileToUpload);
            setFileContent(fileString);

            // if not a version file, set the file name to the file name
            if (storageFile?.fileName === undefined) {
                let fileName = fileToUpload?.name;
                setFileName(fileName);
            }
        } else {
            // when no file, make sure any previous file fields are cleared            
            setFileContent('');
            if (storageFile?.fileName === undefined) {
                setFileName('');
            }
        }
    };

    const fileNameProps = {
        'aria-label': 'file name',
        'maxLength': 255,
    };

    const commentProps = {
        'aria-label': 'comment',
        'maxLength': 255,
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id}
            titlePrefix="Upload New"
            entityName={`SEF File ${storageFile ? 'Version' : ''} `}
            maxWidth='sm'
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
            onCancel={onEditCancel}
            viewerRole={viewerRole}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <FileDropZone
                        label="SEF File *"
                        maxFileSize={maxFileSize}
                        maxNumberFiles={1}
                        dropInstructionText="Drop your SEF file here"
                        onChange={onFileUploadChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-sef-file-name"
                        fullWidth
                        disabled={submitted || storageFile?.fileName !== undefined}
                        value={fileName}
                        label="File Name"
                        inputProps={fileNameProps}
                        onChange={onFileNameChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-sef-file-name"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-sef-file-comment"
                        fullWidth
                        disabled={submitted}
                        value={comment}
                        label="Comment"
                        inputProps={commentProps}
                        onChange={onCommentChange}
                        autoComplete="off"
                        data-cy="dialog-sef-file-description"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                checked={true}
                                disabled={true}
                            />
                        } 
                        label="Active Version" 
                    />
                </Grid>
            </Grid>
        </AddEditDialog>
    )
}

export default StandardExchangeFormatFileDialog;