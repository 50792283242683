import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { ErrorLogCategoryListQuery } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_TRANSACTION_ERROR_CATEGORY_LIST = gql`
  query errorLogCategoryList {
    transactionErrorCategories {
      nodes {
          id 
          name
          description
    }
    }
  }
`;

export const queryErrorLogCategoryList = async (client: ApolloClient<NormalizedCacheObject>) => {
    
    const { data, errors } = await client.query<ErrorLogCategoryListQuery>({
        query: QUERY_TRANSACTION_ERROR_CATEGORY_LIST,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.transactionErrorCategories?.nodes;
};