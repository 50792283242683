import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { PagingResultModelOfTransactionErrorModel, GetTransactionErrorsInput, RequestResult, ExceptionStatus } from '../../gql-types.generated';
import { queryErrorLogList } from "../../gql/QueryErrorLogList";
import { captureUpdateLogsStatus, decrementErrorLogRequestsInFlight, fetchError, fetchErrorLogListSuccess, incrementErrorLogRequestsInFlight } from './ErrorLogsSlice';
import { getErrorMessage } from '../../util/Common';
import { mutationUpdateTransactionErrorStatus } from '../../gql/MutationUpdateTransactionErrorStatus';

export const fetchErrorLogList = (
    filters?: GetTransactionErrorsInput
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementErrorLogRequestsInFlight());
    try {
        const eventLogList = await queryErrorLogList(gqlClient, filters);
        if (eventLogList) {
            dispatch(fetchErrorLogListSuccess(eventLogList as PagingResultModelOfTransactionErrorModel))
        }
        dispatch(decrementErrorLogRequestsInFlight());
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementErrorLogRequestsInFlight());
    }
};

export const updateTransactionErrorStatus = (ids: string[], status: ExceptionStatus, note: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const updateStatus = await mutationUpdateTransactionErrorStatus(gqlClient, {
               transactionErrorIds: ids, 
               status, 
               note
            });
            if (updateStatus) {
                if (updateStatus.result === RequestResult.Fail) {
                    getErrorMessage(updateStatus.message as string, updateStatus.errors, true, true);
                }
                dispatch(captureUpdateLogsStatus(updateStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

