import { useEffect, useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";
import { TransactionErrorCategoryModel, TransactionErrorModel, UpdateTransactionErrorCategoryInput } from "../../gql-types.generated";
import AddEditDialog from "./AddEditDialog";
import { BlankMenuItem } from "../../util/SharedStyles";
import { useAppSelector } from "../../app/hooks";
import { selectTransactionErrorCategoryList } from "../../features/EDIContainer/EDIContainerSlice";

export const GridContainer = styled(Grid)((props) => ({
    width: '440px', 
}));

interface ErrorLogDialogProps {
    isOpen: boolean;
    errorLogInfo?: TransactionErrorModel;
    onClose: () => void;
    onSave: (
        categoryInput: UpdateTransactionErrorCategoryInput
    ) => void;
    error?: Error | undefined;
}

const EditErrorLogDialog: React.FC<ErrorLogDialogProps> = props => {
    const { isOpen, errorLogInfo, onClose, onSave, error } = props;
    const [isDirty, setIsDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [errorLogId, setErrorLogId] = useState<string>('');
    
    // Form Fields
    const [errorLogCategoryId, setErrorLogCategoryId] = useState<string | undefined>(undefined);
      
    const categories = useAppSelector(selectTransactionErrorCategoryList);

    useEffect(() => {
        if (errorLogInfo) {
            setFromErrorLogInfo();
        } else {
            setToDefaults();
        }
    }, [errorLogInfo]);

    const setFromErrorLogInfo = () => {
        if (errorLogInfo) {
            if (errorLogInfo.id) {
                setErrorLogId(errorLogInfo.id);
            }

            if (errorLogInfo.category && errorLogInfo.category.id) {
                setErrorLogCategoryId(errorLogInfo.category.id);
            }
        }
    };

    useEffect(() => {
        if (!isOpen) {
            setToDefaults();
            setSubmitted(false);
            setIsDirty(false);
        } else {
            setFromErrorLogInfo();
        }
    }, [isOpen]);

    const setToDefaults = () => {
        setErrorLogId('');
        setErrorLogCategoryId(undefined);
    };
    
    const saveLogCategory = () => {
        if (isFormValid()) {
            setSubmitted(true);
            onSave({transactionErrorId: errorLogId, categoryId: errorLogCategoryId});
        }
    };

    const onError = () => {
        setSubmitted(false);
    };

    const isFormValid = () => {
        return isDirty && errorLogId?.length > 0;
    };
    
    const onCategoryChange = (event: SelectChangeEvent<string | null>) => {
        setIsDirty(true);
        if (event.target.value) {
            setErrorLogCategoryId(event.target.value);
        } else {
            setErrorLogCategoryId(undefined);
        }
    };

    const getCategoryOptions = () => {
        if (categories && categories.length) {
            let items = [];
            const blankItem = <BlankMenuItem key="0" value={''}></BlankMenuItem>;
            const dynamicItems = categories.map((category: TransactionErrorCategoryModel) => (
                <MenuItem
                    key={category.id}
                    value={category.id}
                >
                {category.name}
                </MenuItem>
            ));
            items.push(blankItem);
            items.push(...dynamicItems);
            return items;
        }
        return null;
    };
    
    const categoryProps = {
        'aria-label': 'category',
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            entityName="Error Category"
            id={errorLogId}
            onCancel={onClose}
            onClose={onClose}
            onSave={saveLogCategory}
            validate={isFormValid}
            onError={onError}
            error={error}
            maxWidth='md'
        >
            <GridContainer container spacing={2}>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="dialog-error-log-category-label">Category</InputLabel>
                        <Select
                            labelId="dialog-error-log-category-label"
                            aria-labelledby="dialog-error-log-category-label"
                            value={errorLogCategoryId ?? ''}
                            onChange={onCategoryChange}
                            disabled={submitted}
                            MenuProps={categoryProps}
                            data-cy="dialog-error-log-category"
                        >
                            {getCategoryOptions()}
                        </Select>
                    </FormControl>
                </Grid>
            </GridContainer>
        </AddEditDialog>
    );
}

export default EditErrorLogDialog;