import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { ActionPayload, BlobUploadRequestInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPLOAD_BLOB_STORAGE = gql`
    mutation executeBlobStorageUpload ($input: BlobUploadRequestInput!){
        executeBlobStorageUpload(input: $input) {
            message
            errors {
                code
                message
            }
            result
            data
        }
    }
`;

export const MutationUploadBlogStorage = async (
    client: ApolloClient<NormalizedCacheObject>,
    storageInput: BlobUploadRequestInput
    ): Promise<ActionPayload | undefined> => {
        const { 
            blobDocumentFormat, 
            blobDocumentType, 
            blobDocumentId, 
            blobFileName, 
            comment, 
            fileContent, 
            fileNameWithExtension, 
            isActive, 
            isFirstVersion 
        } = storageInput as BlobUploadRequestInput;
        const {data, errors } = await client.mutate<{executeBlobStorageUpload: ActionPayload}>({
            variables: {
                input: {
                    blobDocumentFormat, 
                    blobDocumentType, 
                    blobDocumentId, 
                    blobFileName, 
                    comment, 
                    fileContent, 
                    fileNameWithExtension, 
                    isActive, 
                    isFirstVersion 
                }
            },
            mutation: MUTATION_UPLOAD_BLOB_STORAGE
        });
        if (errors && errors.length > 0) {
            errors.forEach(error => {
                // Log error details in the console
                console.error(error);
            });
            // Friendly error to active user
            throw new Error('An error has occurred trying to execute blob storage upload.');
        }
        if (data && data.executeBlobStorageUpload) {
            return data.executeBlobStorageUpload;
        }
    
        return undefined;
    };