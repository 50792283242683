import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { styled, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridFilterModel, GridLinkOperator, GridRowModel, GridOverlay, GridSortModel, GridRowParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { 
    ClientNotificationRecipientsModel, 
    PartnerNotificationRecipientsModel, 
    NotificationType,
    DeleteByIdPayload,
    UpsertClientNotificationRecipientsPayload,
    UpsertPartnerNotificationRecipientsPayload,
    RequestResult,
    UserRole
 } from "../../gql-types.generated";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateNewButton from '../buttons/CreateNewButton';
import DeleteDialog from '../dialogs/DeleteDialog';
import NoRecordsMessage from '../NoRecordsMessage';
import { setToastConfig } from '../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../util/Constants';
import { IconAvatar, TabContainer, TabContent, TabDataGridNoRowHover, TabToolbar } from "../../util/SharedStyles";
import { viewerCanEdit } from "../../util/ViewerUtility";

import NotificationRecipientsDialog from "../dialogs/NotificationRecipientsDialog";

const EmailTypeIcon = styled(EmailIcon)(({ theme }) => ({
    color: theme.palette.secondary.main
}));

const SmsTypeIcon = styled(SmsIcon)(({ theme }) => ({
    color: theme.palette.primary.dark
}));

interface NotificationsRecipientsListProps {
    viewerRole: UserRole | undefined;
    notificationRecipients: ClientNotificationRecipientsModel[] | PartnerNotificationRecipientsModel[];
    deleteStatus?: DeleteByIdPayload | undefined;
    upsertStatus?: UpsertClientNotificationRecipientsPayload | UpsertPartnerNotificationRecipientsPayload | undefined;
    error?: Error | undefined;
    onRecipientsDialogSave: (
        notificationType: NotificationType,
        description?: string,
        receivesErrors?: boolean,
        receivesNotifications?: boolean,
        recipients?: string,
        id?: string
    ) => void;
    onRecipientsDialogClose: () => void;
    onDeleteDialogConfirm: (selectedRecipientId: string) => void;
    onDeleteDialogClose: () => void;
    clearError: () => void;
    refreshList: () => void;
}

const NotificationsRecipientsList: React.FC<NotificationsRecipientsListProps> = props => {
    const { viewerRole, notificationRecipients, deleteStatus, upsertStatus, error } = props;
    const dispatch = useAppDispatch();
    const canEdit = viewerCanEdit(viewerRole);
    const [recipientsRows, setRecipientsRows] = useState<GridRowModel[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'description',
            sort: 'desc'
        }
    ]);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [filterShowError, setFilterShowError] = useState(true);
    const [filterShowGeneral, setFilterShowGeneral] = useState(true);
    const [selectedRecipient, setSelectedRecipient] = useState<ClientNotificationRecipientsModel | PartnerNotificationRecipientsModel | undefined>(undefined);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
        

    useEffect(() => {
        setRecipientsRows(getRecipientsRows());
    }, [notificationRecipients]);

    useEffect(() => {
        if (deleteStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: deleteStatus.message as string,
                severity: ToastSeverity.Success
            }));
            deleteDialogCloseHandler();
            // Refresh list to bring in potential updates
            props.refreshList();
        }
        else if (deleteStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteStatus?.message as string);
        }
    }, [deleteStatus?.result]);

    useEffect(() => {
        if (upsertStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertStatus.message as string,
                severity: ToastSeverity.Success
            }));
            recipientsDialogCloseHandler();
            // Refresh list to bring in potential updates
            props.refreshList();
        }
    }, [upsertStatus?.result]);
    
    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const onGeneralFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterShowGeneral(event.target.checked);
        onFilterChange(event.target.checked, filterShowError);
    };

    const onErrorFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterShowError(event.target.checked);
        onFilterChange(filterShowGeneral, event.target.checked);
    };

    const onFilterChange = (notifications: boolean, errors: boolean) => {
        let items = [];
        if (notifications) {
            items.push({ id: 1, columnField: 'receivesNotifications', operatorValue: 'is', value: 'true' });
        }
        if (errors) {
            items.push({ id: 2, columnField: 'receivesErrors', operatorValue: 'is', value: 'true' });
        }
        // create a new filter model using the new search value
        let newFilterModel: GridFilterModel = {
            items: items,
            linkOperator: GridLinkOperator.Or
        };
        // setting the new model as the filterModel here will trigger the datagrid to apply the new filters
        setFilterModel(newFilterModel);
    };

    const getRecipientsRows = () => {
        if (notificationRecipients && notificationRecipients.length > 0) {
            return notificationRecipients.map((row: ClientNotificationRecipientsModel | PartnerNotificationRecipientsModel) => {
                const { id, description, notificationType, recipients, receivesNotifications, receivesErrors } = row;

                return {
                    _raw: row,
                    id,
                    description,
                    notificationType,
                    recipients,
                    receivesNotifications,
                    receivesErrors,
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const getSelectedRowItem = useCallback((rowId: string) => () => {
        if (rowId && notificationRecipients?.length) {
            let item: ClientNotificationRecipientsModel | PartnerNotificationRecipientsModel | undefined;
            notificationRecipients.forEach((recipient)=>{
                if (recipient.id === rowId){
                    item = recipient;
                    return;
                }
            });
            return item;
        }
        return undefined;
    }, [notificationRecipients]);

    const deleteItemHandler = useCallback((rowId: string) => () => {
        let item = getSelectedRowItem(rowId);
        if (item) {
            setSelectedRecipient(item);
            setOpenDelete(true);
        }
    }, [getSelectedRowItem]);

    const editItemHandler = useCallback((rowId: string) => () => {
        let item = getSelectedRowItem(rowId);
        if (item) {
            setSelectedRecipient(item);
            props.clearError();
            setOpenModify(true);
        }
    }, [getSelectedRowItem, dispatch]);

    const onAddRecipientClick = () => {
        // make sure we don't pass old item info
        setSelectedRecipient(undefined);
        setOpenModify(true);
    };

    const recipientsColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'recipients',
                headerName: 'CONTACT INFO',
                minWidth: 280,
                flex: 1,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild ediDataGridWrapCellInnerContent",
                renderCell: (params: GridRenderCellParams) => {
                    const { value, row } = params;
                    const type = row.notificationType;
                    let typeIcon = <EmailTypeIcon />
                    if (type === NotificationType.Sms) {
                        typeIcon = <SmsTypeIcon />
                    }
                    return (
                        <Grid container item direction="row" gap={'8px'} alignItems="center">
                            <Tooltip title={type}>
                                <IconAvatar aria-label={value}>
                                    {typeIcon}
                                </IconAvatar>
                            </Tooltip>
                            <Typography>
                                {value}
                            </Typography>
                        </Grid>
                    )
                }
            }, {
                field: 'description',
                headerName: 'DESCRIPTION',
                minWidth: 180,
                sortable: true,
            }, {
                field: 'receivesNotifications',
                headerName: 'NOTIFICATIONS',
                sortable: true,
                type: 'boolean',
                width: 150,
                align: 'center',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    if (value) {
                        return <div style={{ width: '60px' }}><CheckCircleIcon color="success" /></div>
                    }
                    return (<div></div>);
                }
            }, {
                field: 'receivesErrors',
                headerName: 'ERRORS',
                sortable: true,
                type: 'boolean',
                width: 150,
                align: 'center',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    if (value) {
                        return <div style={{ width: '60px' }}><CheckCircleIcon color="success" /></div>
                    }
                    return (<div></div>);
                }
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                resizable: false,
                headerName: '',
                width: 120,
                headerAlign: 'center',
                align: 'right',
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editItemHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        onClick={deleteItemHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],
            },
        ],
        [editItemHandler, deleteItemHandler]
    );

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'recipients-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <Typography variant="body2">
                        Unable to load data. Please try again later.
                    </Typography>
                )}
                {!error && recipientsRows?.length === 0 && (
                    <NoRecordsMessage topMargin={6} message="" />
                )}
            </GridOverlay>
        );
    };

    const onDialogClose = () => {
        // Clear selectedRow and error on close.
        setSelectedRecipient(undefined);
        props.clearError();
    }

    const recipientsDialogCloseHandler = () => {
        setOpenModify(false);
        onDialogClose();
        props.onRecipientsDialogClose();
    }

    const recipientsDialogSaveHandler = (
        notificationType: NotificationType,
        description?: string,
        receivesErrors?: boolean,
        receivesNotifications?: boolean,
        recipients?: string,
        id?: string
    ) => {
        props.onRecipientsDialogSave(
            notificationType, 
            description, 
            receivesErrors, 
            receivesNotifications, 
            recipients,
            id
        );
    }

    const deleteDialogCloseHandler = () => {
        setOpenDelete(false);
        onDialogClose();
        props.onDeleteDialogClose();
        setDeleteErrorMessage('');
    };

    const deleteDialogConfirmHandler = () => {
        if (selectedRecipient) {
            props.onDeleteDialogConfirm(selectedRecipient.id);
        }
    };

    return (
        <TabContainer>
            <TabToolbar justify="space-between">
                <Stack direction="row">
                    <FormControlLabel control={<Checkbox checked={filterShowGeneral} onChange={onGeneralFilterChange} />} label="Notifications" />
                    <FormControlLabel control={<Checkbox checked={filterShowError} onChange={onErrorFilterChange} />} label="Errors" />
                </Stack>
                {canEdit &&
                    <CreateNewButton
                        text="New Notification Recipient"
                        onClick={onAddRecipientClick}
                        data-cy="add-new-recipient"
                    />
                }
            </TabToolbar>
            <TabContent>
                <TabDataGridNoRowHover
                    loading={notificationRecipients === undefined}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="Recipients List"
                    hideFooter
                    disableSelectionOnClick
                    disableColumnMenu
                    rows={recipientsRows}
                    columns={recipientsColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    filterMode="client"
                    filterModel={filterModel}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />
            </TabContent>
            <NotificationRecipientsDialog
                isOpen={openModify}
                viewerRole={viewerRole}
                notificationRecipients={selectedRecipient}
                onClose={recipientsDialogCloseHandler}
                onSave={recipientsDialogSaveHandler}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedRecipient?.id ?? ''}
                heading={'Delete Notification Recipient'}
                message={`Are you sure you want to delete ${selectedRecipient?.recipients}?`}
                onConfirm={deleteDialogConfirmHandler}
                onReject={deleteDialogCloseHandler}
                errorMessage={deleteErrorMessage}
            />
        </TabContainer>
    )
}

export default NotificationsRecipientsList;