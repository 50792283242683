import { ChangeEvent, useEffect, useState } from 'react';
import { Grid, IconButton, InputAdornment, TextField, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Viewer } from '../../util/Constants';
import FilterBar from './FilterBar';
import { DateTime } from 'luxon';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

const DatePickerWrapper = styled('div')((props) => ({
    width: '100%',
}));

interface SEFFilesFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    onClose: () => void;
    onFilterChanged: (
        filterFileName: string | undefined, 
        filterModifiedDateFrom?: DateTime | undefined,
        filterModifiedDateTo?: DateTime | undefined,
    ) => void;
}

const StandardExchangeFormatFilesFilterBar: React.FC<SEFFilesFilterBarProps> = props => {
    const { id, loading = false, visible = false, viewer, onFilterChanged } = props;
    const [filterFileName, setFilterFileName] = useState<string | undefined>(undefined);
    const [filterDateFrom, setFilterDateFrom] = useState<DateTime | undefined>(undefined);
    const [filterDateTo, setFilterDateTo] = useState<DateTime | undefined>(undefined);
    
    const fieldsDisabled = visible && loading;

    useEffect(() => {
        // need to send out an initial fetch 
        onSearchClick();
    }, []);

    const onSearchClick = () => {
        onFilterChanged(filterFileName, filterDateFrom, filterDateTo);
    };

    const onCloseClick = () => {
        // remove any filters
        clearFilters();

        // call parent to hide bar
        props.onClose();

        // trigger a new search with filters undefined since state of filter values might not be updated yet
        onFilterChanged(undefined, undefined, undefined);
    };

    const clearFilters = () => {
        clearFileNameFilter();
        setFilterDateFrom(undefined);
        setFilterDateTo(undefined);
    };

    const clearFileNameFilter = () => {
        setFilterFileName(undefined);
    };
      
    const onFileNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {

        let nameValue = event.target.value;
        setFilterFileName(nameValue);
    };

    const onDateFromFilterChange = (value: DateTime | null) => {
        let newDateFrom = value ?? undefined;
        setFilterDateFrom(newDateFrom);
        setFilterDateTo(newDateFrom?.endOf('month') ?? undefined);
    };
    
    const onDateToFilterChange = (value: DateTime | null) => {
        setFilterDateTo(value ?? undefined);
    };
   
    const fileNameFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear file name filter"
                    onClick={clearFileNameFilter}
                    disabled={fieldsDisabled}
                >
                    {filterFileName && filterFileName.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };
   
    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick} onSearch={onSearchClick}>
            <Grid item xs={2}>
                <TextField
                    itemID="sef-files-filter-file-name"
                    fullWidth
                    value={filterFileName ?? ''}
                    label="File Name"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'file name', 'maxLength': 50, }}
                    InputProps={fileNameFilterProps}
                    onChange={onFileNameFilterChange}
                    autoComplete="off"
                    data-cy="sef-files-filter-file-name"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePickerWrapper>
                        <DateTimePicker
                            label="Last Modified Date From"
                            value={filterDateFrom ?? null}
                            views={['year','month','day','hours','minutes']}
                            openTo={'month'}
                            disabled={fieldsDisabled}
                            onChange={onDateFromFilterChange}
                            slotProps={{
                                textField: {
                                    variant: "standard",
                                    fullWidth: true,
                                    inputProps: {'aria-label': 'date from'}
                                },
                            }}
                            data-cy="sef-files-filter-date-from"
                        ></DateTimePicker>
                    </DatePickerWrapper>
                </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePickerWrapper>
                        <DateTimePicker
                            label="Last Modified Date To"
                            value={filterDateTo ?? null}
                            views={['year','month','day','hours','minutes']}
                            openTo={'day'}
                            minDateTime={filterDateFrom}
                            disabled={fieldsDisabled}
                            onChange={onDateToFilterChange}
                            slotProps={{
                                textField: {
                                    variant: "standard",
                                    fullWidth: true,
                                    inputProps: {'aria-label': 'date to'}
                                },
                            }}
                            data-cy="sef-files-filter-date-to"
                        ></DateTimePicker>
                    </DatePickerWrapper>
                </LocalizationProvider>
            </Grid>   
        </FilterBar>
    );
};

export default StandardExchangeFormatFilesFilterBar;