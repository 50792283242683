import { styled } from '@mui/material';
import { useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditorContainer = styled('div')<EditorContainerProps>((props) => ({
    ...(props.disabled) && {
        '& .ql-toolbar': {
            display: 'none'
        },
        '& .ql-container.ql-disabled': {
            borderTop: '1px solid rgb(204,204,204) !important'
        },
    },
    ...(props.border !== true) && {
        '& .ql-container': {
            border: 'none !important',
        },
        '& .ql-container.ql-disabled': {
            border: 'none !important'
        },
    },
    '& .ql-container': {
        maxHeight: props.maxheight,
        minHeight: '150px',
        overflowY: 'auto',
    },
    '& .ql-snow .ql-tooltip': {
        position: 'unset !important' // fixes tooltip positioning by removing absolute to stay in view, ie. when adding a hyperlink
    },
    
}));

interface EditorContainerProps {
    disabled: boolean;
    maxheight: string;
    border: boolean;
}

interface NoteFieldProps {
    value: string;
    disabled: boolean;
    placeholder?: string;
    maxHeight?: string;
    isBorderVisible?: boolean;
    onChange: (newValue: string) => void;
}

const NoteField: React.FC<NoteFieldProps> = props => {
    const { value, disabled, placeholder = 'Take a Note...', maxHeight = "500px", isBorderVisible = true, onChange } = props;

    const modules = useMemo(() => {
        return {
            toolbar: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image'],
                ['clean']
            ]
        }
    }, []);

    return (
        <EditorContainer disabled={disabled} maxheight={maxHeight} border={isBorderVisible}>
            <ReactQuill
                theme="snow"
                value={value}
                onChange={onChange}
                modules={modules}
                readOnly={disabled}
                placeholder={placeholder}
            />
        </EditorContainer>
    );
};

export default NoteField;