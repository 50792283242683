import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import { AttachmentType, PagingResultModelOfClientModel, RequestResult, UpsertAddressInput, UpsertEmailInput, UpsertPhoneInput, UpsertNoteInput, UpsertAttachmentInput, PagingResultModelOfAttachmentModel, UpsertEdiXmlConfigInput, ClientTransactionModel, UpsertClientTransactionInput, TransactionModel, UpsertClientAkaInput, UpsertClientInput, BillingAggregatesModel, ClientNotificationRecipientsModel, UpsertClientNotificationRecipientsInput, NotificationFormatterModel, PortalUserModel } from '../../gql-types.generated';
import { queryClientById } from '../../gql/QueryClientById';
import { queryAttachments } from '../../gql/QueryAttachment';
import { captureDeleteClientContactStatus, captureUpsertClientContactStatus, captureUpsertClientStatus, captureDeleteClientStatus, captureAddClientPartnerStatus, fetchClientByIdSuccess, fetchError, captureUpsertClientNoteStatus, captureDeleteClientNoteStatus, captureUpsertClientContactNoteStatus, captureDeleteClientContactNoteStatus, captureDeleteClientAttachmentStatus, captureUpsertClientAttachmentsStatus, fetchClientAttachmentSuccess, captureDeleteEdiXmlConfigStatus, captureUpsertEdiXmlConfigSuccess, fetchClientTransactionsSuccess, captureDeleteClientTransactionStatus, captureUpsertClientTransactionStatus, fetchTransactionsSuccess, captureDeleteClientAkaStatus, captureUpsertClientAkaStatus, captureDeleteClientPartnerStatus, fetchBillingAggregatesSuccess, fetchClientNotificationRecipientsSuccess, captureUpsertClientNotificationRecipientsStatus, captureDeleteClientNotificationRecipientsStatus, fetchNotificationFormattersSuccess, captureOnboardingCompleteStatus, fetchPortalUsersSuccess } from './ClientDetailsSlice';
import { mutationDeleteClientContact } from '../../gql/MutationDeleteClientContact';
import { mutationUpsertClientContact } from '../../gql/MutationUpsertClientContact';
import { mutationUpsertClient } from '../../gql/MutationUpsertClient';
import { mutationAddClientPartner } from '../../gql/MutationAddClientPartner';
import { mutationUpsertClientAttachments } from '../../gql/MutationUpsertClientAttachments';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';
import { mutationUpsertClientAKA } from '../../gql/MutationUpsertClientAKA';
import { mutationUpsertEdiXmlConfig } from '../../gql/MutationUpsertEdiXmlConfig';
import { queryClientTransactionsByClientId } from '../../gql/QueryClientTransactionsByClientId';
import { mutationUpsertClientTransaction } from '../../gql/MutationUpsertClientTransaction';
import { queryTransactionList } from '../../gql/QueryTransactionsList';
import { queryClientBillingAggregatesByClientKey } from '../../gql/QueryClientBillingAggregatesByClientKey';
import { queryClientNotificationRecipients } from '../../gql/QueryClientNotificationRecipients';
import { mutationUpsertClientNotificationRecipients } from '../../gql/MutationUpsertClientNotificationRecipients';
import { queryNotificationFormatterTransactionsByTransactionId } from '../../gql/QueryNotificationFormatterTransactionsByTransactionId';
import { mutationExecuteEdiActionSvcAction } from '../../gql/MutationExecuteEdiActionSvcAction';
import { ApolloError } from '@apollo/client';
import { mutationUpsertClientContactNote } from '../../gql/MutationUpsertClientContactNote';
import { mutationUpsertClientNote } from '../../gql/MutationUpsertClientNote';
import { queryClientPortalUsers } from '../../gql/QueryClientPortalUsers';

export const fetchClientById =
    (id: string) =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const client = await queryClientById(gqlClient, id);
                if (client) {
                    dispatch(fetchClientByIdSuccess(client as PagingResultModelOfClientModel));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

export const deleteClient = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteClientStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "Client"
            });
            if (deleteClientStatus) {
                if (deleteClientStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteClientStatus.message as string, deleteClientStatus.errors, true, true);
                }
                dispatch(captureDeleteClientStatus(deleteClientStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };
export const deleteClientContact = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteClientContactStatus = await mutationDeleteClientContact(gqlClient, {
                id,
            });
            if (deleteClientContactStatus) {
                if (deleteClientContactStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteClientContactStatus.message as string, deleteClientContactStatus.errors, true, true);
                }
                dispatch(captureDeleteClientContactStatus(deleteClientContactStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

export const upsertClientContact = (
    clientId: string,
    addresses?: UpsertAddressInput[],
    description?: string,
    emails?: UpsertEmailInput[],
    id?: string,
    isActive?: boolean,
    isPrimary?: boolean,
    jobTitle?: string,
    name?: string,
    phones?: UpsertPhoneInput[]
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertClientContactStatus = await mutationUpsertClientContact(gqlClient, {
                clientId,
                addresses,
                description,
                emails,
                id,
                isActive,
                isPrimary,
                jobTitle,
                name,
                phones
            });
            if (upsertClientContactStatus) {
                if (upsertClientContactStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertClientContactStatus.message } as Error));
                    getErrorMessage(upsertClientContactStatus.message as string, upsertClientContactStatus.errors, true, true);
                }
                dispatch(captureUpsertClientContactStatus(upsertClientContactStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertClientContactNote = (
    upsertNoteData: UpsertNoteInput,
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertNoteStatus = await mutationUpsertClientContactNote(gqlClient, {
                upsertNoteData
            });
            if (upsertNoteStatus) {
                if (upsertNoteStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertNoteStatus.message } as Error));
                    getErrorMessage(upsertNoteStatus.message as string, upsertNoteStatus.errors, true, true);
                }
                dispatch(captureUpsertClientContactNoteStatus(upsertNoteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteClientContactNote = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteNoteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "ClientContactNote"
            });
            if (deleteNoteStatus) {
                if (deleteNoteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteNoteStatus.message as string, deleteNoteStatus.errors, true, true);
                }
                dispatch(captureDeleteClientContactNoteStatus(deleteNoteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

export const upsertClient = (clientInput: UpsertClientInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertClientStatus = await mutationUpsertClient(gqlClient, {
                clientInput
            });
            if (upsertClientStatus) {
                if (upsertClientStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertClientStatus.message } as Error));
                    getErrorMessage(upsertClientStatus.message as string, upsertClientStatus.errors, true, true);
                }
                dispatch(captureUpsertClientStatus(upsertClientStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertClientNote = (
    upsertNoteData: UpsertNoteInput,
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertNoteStatus = await mutationUpsertClientNote(gqlClient, {
                upsertNoteData
            });
            if (upsertNoteStatus) {
                if (upsertNoteStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertNoteStatus.message } as Error));
                    getErrorMessage(upsertNoteStatus.message as string, upsertNoteStatus.errors, true, true);
                }
                dispatch(captureUpsertClientNoteStatus(upsertNoteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteClientNote = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteClientNoteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "ClientNote"
            });
            if (deleteClientNoteStatus) {
                if (deleteClientNoteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteClientNoteStatus.message as string, deleteClientNoteStatus.errors, true, true);
                }
                dispatch(captureDeleteClientNoteStatus(deleteClientNoteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

export const addClientPartner = (
    clientId: string,
    partnerId: string,
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const addPartnerStatus = await mutationAddClientPartner(gqlClient, {
                clientId,
                partnerId,
            });
            if (addPartnerStatus) {
                if (addPartnerStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: addPartnerStatus.message } as Error));
                    getErrorMessage(addPartnerStatus.message as string, addPartnerStatus.errors, true, true);
                }
                dispatch(captureAddClientPartnerStatus(addPartnerStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertClientAttachments = (
    clientId: string,
    attachments?: UpsertAttachmentInput[],
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertClientAttachments(gqlClient, {
                attachments,
                clientId
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertClientAttachmentsStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };


export const deleteClientAttachment = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "ClientAttachment",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteClientAttachmentStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchClientAttachment =
    (id: string) =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const attachment = await queryAttachments(gqlClient, [id], AttachmentType.Client);
                if (attachment) {
                    dispatch(fetchClientAttachmentSuccess(attachment as PagingResultModelOfAttachmentModel));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

export const upsertEdiXmlConfig = (upsertEdiXmlConfigData: UpsertEdiXmlConfigInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertEdiXmlConfig(gqlClient, {
                upsertEdiXmlConfigData
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertEdiXmlConfigSuccess(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteEdiXmlConfig = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "EdiXmlConfig",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteEdiXmlConfigStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchClientTransactionsByClientId =
    (id: string) =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const clientTransactions = await queryClientTransactionsByClientId(gqlClient, id);
                if (clientTransactions) {
                    dispatch(fetchClientTransactionsSuccess(clientTransactions as ClientTransactionModel[]));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

export const fetchEligibleTransactions = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            // only want active transactions that match the given ediStandards
            const transactionList = await queryTransactionList(gqlClient, { isActive: true });
            if (transactionList) {
                dispatch(fetchTransactionsSuccess(transactionList as TransactionModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertClientTransaction = (upsertClientTransactionData: UpsertClientTransactionInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertClientTransaction(gqlClient, {
                upsertClientTransactionData
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertClientTransactionStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteClientTransaction = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "ClientTransaction",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteClientTransactionStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertClientAKA = (clientAkaInputData: UpsertClientAkaInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertClientAKA(gqlClient, {
                clientAkaInputData
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertClientAkaStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteClientAKA = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "ClientAKA",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteClientAkaStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteClientPartnerData = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteClientPartnerStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "ClientPartner",
            });
            if (deleteClientPartnerStatus) {
                if (deleteClientPartnerStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteClientPartnerStatus.message as string, deleteClientPartnerStatus.errors, true, true);
                }
                dispatch(captureDeleteClientPartnerStatus(deleteClientPartnerStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchBillingAggregatesByClientKey =
    (internalClientCode: string) =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const aggregatesList = await queryClientBillingAggregatesByClientKey(gqlClient, internalClientCode);
                if (aggregatesList) {
                    dispatch(fetchBillingAggregatesSuccess(aggregatesList as BillingAggregatesModel[]));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

export const fetchClientNotificationRecipients = (clientId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const notificationRecipients = await queryClientNotificationRecipients(gqlClient, { clientId: clientId });
            if (notificationRecipients) {
                dispatch(fetchClientNotificationRecipientsSuccess(notificationRecipients as ClientNotificationRecipientsModel[]));
            }
        } catch (error) {
            dispatch(fetchError(error as Error));
        }
    };

export const upsertClientNotificationRecipients = (upsertClientNotificationRecipientsData: UpsertClientNotificationRecipientsInput) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertClientNotificationRecipients(gqlClient, {
                input: upsertClientNotificationRecipientsData
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertClientNotificationRecipientsStatus(upsertStatus));
            }

        } catch (error) {
            dispatch(fetchError(error as Error));
        }
    };

export const deleteClientNotificationRecipients = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "ClientNotificationRecipients",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteClientNotificationRecipientsStatus(deleteStatus));
            }
        } catch (error) {
            dispatch(fetchError(error as Error));
        }
    };

export const fetchEligibleNotificationFormatters = (transactionId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            // get all formatters within formatterTransactions for given transactionId
            const formatterTransactions = await queryNotificationFormatterTransactionsByTransactionId(gqlClient, transactionId);
            if (formatterTransactions) {
                let formatters : NotificationFormatterModel[] = [];
                formatterTransactions.map(ft => {
                    if (ft.notificationFormatter) {
                        formatters.push(ft.notificationFormatter as NotificationFormatterModel);
                    }
                    return ft;
                });
                
                dispatch(fetchNotificationFormattersSuccess(formatters));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchClientPortalUsers =
    (tenantId: string) =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const portalUsers = await queryClientPortalUsers(gqlClient, tenantId);
                if (portalUsers) {
                    dispatch(fetchPortalUsersSuccess(portalUsers as PortalUserModel[]));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

export const completeOnboarding = (tenantId: string) : AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            let actionArguments = `{ClientTenantId : '${tenantId}'}`;
            const actionServiceStatus = await mutationExecuteEdiActionSvcAction(gqlClient,{
                actionName: "OnboardingCompleteAction",
                actionArguments: actionArguments
            });
            if (actionServiceStatus) {
                if (actionServiceStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({message: actionServiceStatus.message} as ApolloError));
                    getErrorMessage(actionServiceStatus.message as string, actionServiceStatus.errors, true, true);
                }
                dispatch(captureOnboardingCompleteStatus(actionServiceStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as ApolloError));
        }
    };