import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ToastSeverity, Viewer } from "../../util/Constants";
import { GetPostProcessingBlobInput, OrderDirection, PostProcessingBlobModel, PostProcessingBlobOrderByInput, RequestResult, UpsertPostProcessingBlobInput } from "../../gql-types.generated";
import { useAppDispatch } from "../../app/hooks";
import { GridActionsCellItem, GridColumns, GridOverlay, GridRowModel, GridRowParams, GridSelectionModel, GridSortItem, GridSortModel, useGridApiContext } from "@mui/x-data-grid-pro";
import { viewerCanAddDelete, viewerCanEdit } from "../../util/ViewerUtility";
import { captureDeletePostProcessingBlobStatus, captureUpsertPostProcessingBlobStatus, clearError, clearState, selectDeletePostProcessingblobsStatus, selectError, selectPostProcessingblobs, selectPostProcessingblobsPagingResult, selectPostProcessingblobsRequestsInFlight, selectUpsertPostProcessingblobsStatus } from "./PostProcessingBlobsSlice";
import { useSelector } from "react-redux";
import { useTitle } from "../../util/Common";
import { defaultPageSize } from "../../util/Constants";
import { debounce } from "lodash";
import { deletePostProcessingBlob, fetchPostProcessingBlobs, upsertPostProcessingBlob } from "./PostProcessingBlobsActions";
import ErrorMessage from "../../components/ErrorMessage";
import { CircularProgress, Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MainDataGridLoadingSkeleton from "../../components/MainDataGridLoadingSkeleton";
import NoResultsMessage from "../../components/NoResultsMessage";
import CreateNewButton from '../../components/buttons/CreateNewButton';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import { DataGridListScrollBox, MainContentBox, MainDataGridNoRowHover, PageTitleToolbarGrid } from "../../util/SharedStyles";
import PageTitleBar from "../../components/PageTitleBar";
import FiltersButton from "../../components/buttons/FiltersButton";
import PostProcessingBlobsFilterBar from "../../components/filters/PostProcessingBlobsFilterBar";
import PostProcessingBlobDialog from "../../components/dialogs/PostProcessingBlobDialog";
import { setToastConfig } from "../EDIContainer/EDIContainerSlice";
import { getFileDataString, downloadBlobAsFile } from "../../util/Common";

interface PostProssingBlobsProps {
    viewer: Viewer | undefined;
}

const PostProcessingBlobs: React.FC<PostProssingBlobsProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [contentAreaHeight, setContentAreaHeight] = useState('200px'); //need an actual default height so grid doesn't error on load
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [selectedBlob, setSelectedBlob] = useState<PostProcessingBlobModel | undefined>(undefined);
    const [filtersOpen, setFiltersOpen] = useState(true);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterDescription, setFilterDescription] = useState<string | undefined>(undefined);
    const [filterCount, setFilterCount] = useState(0);
    const [postProcessingBlobRows, setPostProcessingBlobRows] = useState<GridRowModel[] | undefined>(undefined);
    const [debounceOn, setDebounceOn] = useState(false);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
    const [sortFirstLoad, setSortFirstLoad] = useState(true);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'description',
            sort: 'asc',
        },
    ]);
    const canEdit = viewerCanEdit(viewer);
    const canAddOrDelete = viewerCanAddDelete(viewer);
    const [serverSortModel, setServerSortModel] = useState<PostProcessingBlobOrderByInput[] | undefined>([{
        description: OrderDirection.Asc
    } as PostProcessingBlobOrderByInput]);

    const pageSize = defaultPageSize;

    const error = useSelector(selectError);
    const requestsInFlight = useSelector(selectPostProcessingblobsRequestsInFlight);
    const postProcessingBlobs = useSelector(selectPostProcessingblobs);
    const pagingResult = useSelector(selectPostProcessingblobsPagingResult);
    const upsertBlobStatus = useSelector(selectUpsertPostProcessingblobsStatus);
    const deleteBlobStatus = useSelector(selectDeletePostProcessingblobsStatus);

    useTitle("Post Process Assemblies");

    const debounceOnSortingChanged = useRef(
        debounce((servSortModel) => {
            onSortModelChanged(servSortModel);
        }, 1000)
    ).current;

    useEffect(() => {
        dispatch(clearState());
        setTimeout(() => {
            setDebounceOn(true);
        }, 200);
    },[]);

    useEffect(() => {
        setPostProcessingBlobRows(getPostProcessingBlobsRows());
        if (!postProcessingBlobs && requestsInFlight > 0) {
            setIsLoading(true);
        } else if (requestsInFlight > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [postProcessingBlobs, requestsInFlight])

    // content size
    useEffect(() => {
        // we have content, so lets properly size that content area        
        const titleHeight = document.getElementById('post-processing-blobs-title-comp')?.clientHeight || 0;
        // if filters are open, include that in the calculation
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('post-processing-blobs-filter-bar')?.clientHeight || 0;
        }
        let totalHeaderAreaHeight = titleHeight + filterBarHeight;
        if (totalHeaderAreaHeight > 0) {
            setContentAreaHeight(`calc(100% - ${totalHeaderAreaHeight}px)`);
        }
    }, [postProcessingBlobs, filtersOpen]);

    // upsert status
    useEffect(() => {
        if (upsertBlobStatus?.result === RequestResult.Success) {
            onPostProcessingBlobDialogClose();
            dispatch(setToastConfig({
                message: upsertBlobStatus.message as string,
                severity: ToastSeverity.Success
            }));
            dispatch(captureUpsertPostProcessingBlobStatus());
        }
    }, [upsertBlobStatus?.result]);

    // delete status

    useEffect(() => {
        if (deleteBlobStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteBlobStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteBlobStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteBlobStatus.message as string);
        }
    }, [deleteBlobStatus?.result]);

    useEffect(() => {
        if (postProcessingBlobs) {
            let numFilters =0;
            if (filterDescription && filterDescription.length > 0) {
                numFilters += 1;
            }
            setFilterCount(numFilters);
        }
    }, [postProcessingBlobs, filterDescription]);

    useEffect(() => {
        return () => {
            debounceOnSortingChanged.cancel();
        };
    }, [debounceOnSortingChanged]);

    const getSortRefreshInput = () => {
        return {
            after: undefined,
            limit: pageSize,
            description: filterDescription,
            order: serverSortModel,
        } as GetPostProcessingBlobInput
    }

    useEffect(() => {
        if (!sortFirstLoad) {
            const sortRefreshData = getSortRefreshInput();
            if (debounceOn !== true) {
                onSortModelChanged(sortRefreshData);
            } else {
                debounceOnSortingChanged(sortRefreshData);
            }
        } else {
            setSortFirstLoad(false);
        }
    }, [serverSortModel]);

    const onSortModelChanged = (refreshInput: GetPostProcessingBlobInput) => {
        dispatch(clearState());
        dispatch(fetchPostProcessingBlobs(refreshInput));
    };

    const onSelectionModelChange = (currentSelectionModel: GridSelectionModel) => {
        setSelectionModel(currentSelectionModel);
    };


    const GetApiRef = () => {
        return useGridApiContext();
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the Post Process Assemblies' error={error}></ErrorMessage>
                )}
                {!error && (postProcessingBlobs && postProcessingBlobs.length > 0 && requestsInFlight > 0) && (
                    <CircularProgress aria-label={'progress spinner'} key={'logs-spinner'} size={42} sx={{ zIndex: 1 }} />
                )}
                {!error && (!postProcessingBlobs) && (
                    <MainDataGridLoadingSkeleton apiRef={GetApiRef()} rowBottomMargin={6} />
                )}
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the Post Process Assemblies' error={error}></ErrorMessage>
                )}
                {!error && (postProcessingBlobs && postProcessingBlobs.length <= 0 && filterCount > 0) && (
                    <NoResultsMessage topMargin={6} message="Try different filters to see results." />
                )}
            </GridOverlay>
        );
    };

    const getSelectedRowPostProcessingBlob = useCallback((rowId: string) => () => {
        if (rowId && postProcessingBlobs?.length) {
            let blob = postProcessingBlobs.find(blob => blob.id === rowId);
            return blob;
        }
        return undefined;
    }, [postProcessingBlobs]);

    const downloadPostProcessingBlobHandler = useCallback((rowId: string) => () => {
        let pPBlob = getSelectedRowPostProcessingBlob(rowId);
        if (pPBlob && pPBlob() !== undefined) {
            let blobString = pPBlob()?.processBlobString;
            if (blobString !== undefined && blobString !== null) {
                // file is assumed to be a dll
                const fileData = getFileDataString(true, 'application/octet-stream');
                // the fileName is not stored, so just use a generic default
                const fileName = "Post-Processing-Assembly.dll";
                // now that have prefix and fileName, download the assembly
                // Due to it being a dll with generic mime type, Chrome flags it as
                // untrusted, forcing user to ensure they wanted it downloaded
                fetch(fileData + "," + blobString)
                    .then(res => res.blob())
                    .then(blob => {
                        downloadBlobAsFile(blob, fileName);
                    });
            }
        }
    }, [getSelectedRowPostProcessingBlob]);

    const editPostProcessingBlobHandler = useCallback((rowId: string) => () => {
        let pPBlob = getSelectedRowPostProcessingBlob(rowId);
        if (pPBlob) {
            setSelectedBlob(pPBlob);
            dispatch(clearError());
            setOpenModify(true);
        }
    }, [getSelectedRowPostProcessingBlob, dispatch]);

    const deletePostProcessingBlobHandler = useCallback((rowId: string) => () => {
        let pPBlob = getSelectedRowPostProcessingBlob(rowId);
        if (pPBlob) {
            setSelectedBlob(pPBlob);
            dispatch(captureDeletePostProcessingBlobStatus());
            setDeleteErrorMessage('');
            setOpenDelete(true);
        }
    }, [getSelectedRowPostProcessingBlob, dispatch]);

    const getPostProcessingBlobsRows = () => {
        if (postProcessingBlobs && postProcessingBlobs.length > 0) {
            return postProcessingBlobs.map((pPBlob) => {
                const {
                    id,
                    description,
                } = pPBlob;
                const upperCaseId = (id as string).toUpperCase();
                return {
                    _raw: pPBlob,
                    id,
                    description,
                    displayId: upperCaseId
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return[];
        }
    };

    const postProcessingBlobsColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                headerName: "ID",
                field: 'displayId',
                minWidth: 180,                
                flex: 1,
                sortable: false,
            },
            {
                // Description
                headerName: "DESCRIPTION",
                field: 'description',
                minWidth: 200,                
                flex: 2,
                sortable: true,
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                resizable: false,
                headerName: '',
                width: 100,
                headerAlign: 'right',
                align: 'center',
                hide: !canEdit, // hide column for reader role
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        label="Download"
                        color="primary"
                        onClick={downloadPostProcessingBlobHandler(params.row.id)}
                        icon={<Tooltip title="Download Assembly"><DownloadIcon /></Tooltip>}
                    />,
                   <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editPostProcessingBlobHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        hidden={!canAddOrDelete}
                        onClick={deletePostProcessingBlobHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],
            }
        ],
        [editPostProcessingBlobHandler, deletePostProcessingBlobHandler],
    );

    const getServerSortEntryFromGridSortEntry = (entry: GridSortItem) => {
        let newModel = {} as PostProcessingBlobOrderByInput;
        switch (entry.field) {            
            case 'description':
                newModel.description = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
        }
        return newModel;
    };

    const createdAndSetServerSortModel = (model: GridSortModel) => {
        if (model && model.length > 0) {
            let newArray = [] as PostProcessingBlobOrderByInput[];
            let i = 0;
            for (i; i < model.length; ++i) {
                newArray.push(getServerSortEntryFromGridSortEntry(model[i]));
            }
            setServerSortModel(newArray);
        } else {
            setServerSortModel(undefined);
        }

    };

    const onSortModelChange = (model: GridSortModel) => {
        createdAndSetServerSortModel(model);
        setSortModel(model);
    };

    const onFiltersClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onAddPostProcessingBlobClick = () => {
        // Clear error and open dialog
        dispatch(clearError());
        // ensure no previously selected standard is set
        if (selectedBlob) {
            setSelectedBlob(undefined);
        }
        setOpenModify(true);
    };

    const onPostProcessingBlobDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeletePostProcessingBlobStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        dispatch(clearError());
        setSelectedBlob(undefined);
        dispatch(fetchPostProcessingBlobs({
            after: undefined,
            limit: pageSize,
            description: filterDescription,
            order: serverSortModel
        }));
    };

    const onPostProcessingBlobDialogSave = (
        upsertPostProcessingData: UpsertPostProcessingBlobInput
    ) => {
        dispatch(upsertPostProcessingBlob(upsertPostProcessingData));
    };

    const onDeleteDialogConfirm = (id: string) => {
        dispatch(deletePostProcessingBlob(id));
    }

    const onFilterBarClose = () => {
        // set filters as cleared
        setFiltersCleared(true);
        setFilterCount(0);
        // hide filter bar
        setFiltersOpen(false);
    };

    const loadPage = (endEdge: string) => {
        dispatch(fetchPostProcessingBlobs({
            after: endEdge,
            limit: pageSize,
            description: filterDescription,
            order: serverSortModel
        }));
    };

    const handlePageLoad = () => {
        if (!pagingResult) {
            return;
        }
        if (!pagingResult.cursor?.nextPage) {
            return;
        }
        loadPage(pagingResult.cursor.nextPage);
    };

    const refreshFilters = (
        filterDescription: string | undefined) => {
        setFilterDescription(filterDescription);
        dispatch(clearState());
        dispatch(fetchPostProcessingBlobs({
            after: undefined,
            limit: pageSize,
            description: filterDescription,
            order: serverSortModel
        }));
    };

    return (
        <MainContentBox>
            <PageTitleBar text='Post Processing Assemblies' id="post-processing-blobs-title-comp">
                <PageTitleToolbarGrid item>
                    <FiltersButton 
                        onClick={onFiltersClick}
                        filterCount={filterCount}
                        filtersCleared={filtersCleared}
                        disabled={isLoading}
                        aria-label="filter button"
                        data-cy="filters"
                    />
                    {canAddOrDelete &&
                        <CreateNewButton
                            text="New Post Processing Assembly"
                            onClick={onAddPostProcessingBlobClick}
                            data-cy="add-new-post-processing-assembly"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <PostProcessingBlobsFilterBar
                id="post-processing-blobs-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}
            />
            <DataGridListScrollBox scrollheight={contentAreaHeight}>
                <MainDataGridNoRowHover
                    loading={isLoading}
                    rowHeight={52}
                    aria-label="Post Processing Assemblies"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    rows={postProcessingBlobRows ?? []}
                    columns={postProcessingBlobsColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    selectionModel={selectionModel}
                    onSelectionModelChange={onSelectionModelChange}
                    onRowsScrollEnd={handlePageLoad}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />
            </DataGridListScrollBox>
            <PostProcessingBlobDialog
                isOpen={openModify}
                postProcessingBlob={selectedBlob}
                onClose={onPostProcessingBlobDialogClose}
                onSave={onPostProcessingBlobDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedBlob?.id}
                heading={'Delete Post Processing Assembly'}
                message={'Are you sure you want to delete \'' + selectedBlob?.description + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </MainContentBox>
    )
};

export default PostProcessingBlobs;