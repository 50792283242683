import { gqlClient } from '../../components/AppProvider';
import { AppDispatch } from '../../store';
import { PagingResultModelOfPortalTransactionModel, GetPortalTransactionsInput } from '../../gql-types.generated';
import { queryPortalTransactionList } from "../../gql/QueryPortalTransactionList";
import { decrementPortalTransactionRequestsInFlight, fetchError, fetchPortalTransactionListSuccess, incrementPortalTransactionRequestsInFlight } from './PortalTransactionsSlice';

export const fetchPortalTransactionList = (
    filters?: GetPortalTransactionsInput
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementPortalTransactionRequestsInFlight());
    try {
        const portalTransactionList = await queryPortalTransactionList(gqlClient, filters);
        if (portalTransactionList) {
            dispatch(fetchPortalTransactionListSuccess(portalTransactionList as PagingResultModelOfPortalTransactionModel))
        }
        dispatch(decrementPortalTransactionRequestsInFlight());
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementPortalTransactionRequestsInFlight());
    }
};
