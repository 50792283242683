import { ChangeEvent, useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { Grid, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { BlankMenuItem, FilterBarCheckbox } from '../../util/SharedStyles';
import CloseIcon from '@mui/icons-material/Close';
import { UserRole } from '../../gql-types.generated';
import { Viewer } from '../../util/Constants';
import FilterBar from './FilterBar';

interface UsersFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    onClose: () => void;
    onFilterChanged: (
        filterFirstName: string | undefined, 
        filterLastName: string | undefined, 
        filterEmail: string | undefined, 
        filterRole: UserRole | undefined, 
        filterIsActive?: boolean | undefined,
    ) => void;
}

const UsersFilterBar: React.FC<UsersFilterBarProps> = props => {
    const { id, loading = false, visible = false, viewer, onFilterChanged } = props;
    const [filterFirstName, setFilterFirstName] = useState<string | undefined>(undefined);
    const [filterLastName, setFilterLastName] = useState<string | undefined>(undefined);
    const [filterEmail, setFilterEmail] = useState<string | undefined>(undefined);
    const [filterRole, setFilterRole] = useState<UserRole | undefined>(undefined);
    const [filterIsActive, setFilterIsActive] = useState<boolean | undefined>(undefined);
    
    const fieldsDisabled = visible && loading;

    useEffect(() => {
        // default to inactive hidden
        setFilterIsActive(true);
        
        // send out an initial state to kick a fetch 
        onFilterChanged(filterFirstName, filterLastName, filterEmail, filterRole, true);
    }, []);

    // get the list of business areas, products for the filter dropdowns
    const roles = Object.values(UserRole); 

    const onSearchClick = () => {
        onFilterChanged(filterFirstName, filterLastName, filterEmail, filterRole, filterIsActive);
    };

    const onCloseClick = () => {
        // remove any filters
        clearFilters();

        // call parent to hide bar
        props.onClose();

        // trigger a new search with filters undefined since state of filter values might not be updated yet
        onFilterChanged(undefined, undefined, undefined, undefined, undefined);
    };

    const clearFilters = () => {
        clearFirstNameFilter();
        clearLastNameFilter();
        clearEmailFilter();
        setFilterRole(undefined);
        setFilterIsActive(undefined);
    };

    const clearFirstNameFilter = () => {
        setFilterFirstName(undefined);
    };
    const clearLastNameFilter = () => {
        setFilterLastName(undefined);
    };
    const clearEmailFilter = () => {
        setFilterEmail(undefined);
    };
  
    const getRoleFilterList = () => {
        if (roles && roles.length) {
            let items = [];
            const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
            const mappedItems = (
                roles.map((role: UserRole) => (
                    <MenuItem
                        key={role}
                        value={role}
                        disabled={fieldsDisabled}
                    >
                        {capitalize(role)}
                    </MenuItem>
                ))
            );
            items.push(blankItem);
            items.push(...mappedItems);
            return items;
        }
        return null;
    };
    
    const onFirstNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {

        let nameValue = event.target.value;
        setFilterFirstName(nameValue);
    };

    const onLastNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {

        let nameValue = event.target.value;
        setFilterLastName(nameValue);
    };

    const onEmailFilterChange = (event: ChangeEvent<HTMLInputElement>) => {

        let emailValue = event.target.value;
        setFilterEmail(emailValue);
    };

    const onRoleFilterChange = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        
        let currentValue = value?.length > 0 ? value as UserRole : undefined;
        
        setFilterRole(currentValue);
    };
    
    const onIsActiveFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let currentValue = event.target.checked ? true : undefined;
        setFilterIsActive(currentValue);
    };

    const firstNameFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear first name filter"
                    onClick={clearFirstNameFilter}
                    disabled={fieldsDisabled}
                >
                    {filterFirstName && filterFirstName.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const lastNameFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear last name filter"
                    onClick={clearLastNameFilter}
                    disabled={fieldsDisabled}
                >
                    {filterLastName && filterLastName.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const emailFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear email filter"
                    onClick={clearEmailFilter}
                    disabled={fieldsDisabled}
                >
                    {filterEmail && filterEmail.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };
   
    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick} onSearch={onSearchClick}>
            <Grid item xs={2}>
                <TextField
                    itemID="user-filter-first-name"
                    fullWidth
                    value={filterFirstName ?? ''}
                    label="First Name"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'first name', 'maxLength': 50, }}
                    InputProps={firstNameFilterProps}
                    onChange={onFirstNameFilterChange}
                    autoComplete="off"
                    data-cy="user-first-name-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    itemID="user-filter-last-name"
                    fullWidth
                    value={filterLastName ?? ''}
                    label="Last Name"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'last name', 'maxLength': 50, }}
                    InputProps={lastNameFilterProps}
                    onChange={onLastNameFilterChange}
                    autoComplete="off"
                    data-cy="user-last-name-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    itemID="user-filter-email"
                    fullWidth
                    value={filterEmail ?? ''}
                    label="Email"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'email', 'maxLength': 50, }}
                    InputProps={emailFilterProps}
                    onChange={onEmailFilterChange}
                    autoComplete="off"
                    data-cy="user-email-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={1} lg={2}>
                <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                    <InputLabel id="user-filter-role-label">Role</InputLabel>
                    <Select
                        labelId="user-filter-role-label"
                        aria-labelledby="user-filter-role-label"
                        id="user-filter-role"
                        value={filterRole ?? ''}
                        MenuProps={{
                            'aria-label': 'user role',
                        }}
                        onChange={onRoleFilterChange}
                        data-cy="user-filter-role"
                    >
                        {getRoleFilterList()}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={1} lg={2} display={"inline-flex"} alignItems={"flex-end"} alignSelf={"flex-end"}>
                <FormControlLabel 
                    control={<FilterBarCheckbox checked={filterIsActive ?? false} onChange={onIsActiveFilterChange} />} 
                    label="Hide Inactive" 
                    disabled={fieldsDisabled}
                />
            </Grid>
        </FilterBar>
    );
};

export default UsersFilterBar;