import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { GridColumns, GridRowModel, GridRowParams, GridRenderCellParams, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { Grid, IconButton, Stack, Tooltip } from '@mui/material';
import { IconAvatar } from '../../../util/SharedStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OutboundIcon from '../../../icons/outbound.svg';
import InboundIcon from '../../../icons/inbound.svg';
import { ClientPartnerTransactionModel, PartnerTransactionModel, UserRole, DeleteByIdPayload, RequestResult, TransactionDirection, TransactionMapModel, PipelineModel, PartnerTransactionSchemaModel, UpdateClientPartnerTransactionInput } from '../../../gql-types.generated';
import { viewerCanEdit } from '../../../util/ViewerUtility';
import { setToastConfig } from '../../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../../util/Constants';
import {
    selectError,
    clearError,
    captureDeleteClientPartnerTransactionStatus,
    captureAddClientPartnerTransactionsStatus,
    selectDeleteClientPartnerTransactionStatus,
    selectAddClientPartnerTransactionsStatus,
    captureUpdateClientPartnerTransactionStatus,
    selectUpdateClientPartnerTransactionStatus,
    selectAddTransactionMapToClientPartnerTransactionStatus,
    captureAddTransactionMapToClientPartnerTransactionStatus,
    selectDeleteTransactionMapFromClientPartnerTransactionStatus,
    captureDeleteClientPartnerTransactionMapStatus,
    selectAddPartnerTransactionSchemaToClientPartnerTransactionStatus,
    captureAddPartnerTransactionSchemaToClientPartnerTransactionStatus,
    selectDeleteClientPartnerTransactionSchemaStatus,
    captureDeleteClientPartnerTransactionSchemaStatus,
    selectPostProcessingBlobs,
} from '../../../features/ClientPartnerDetails/ClientPartnerDetailsSlice';
import { deleteClientPartnerTransaction, addClientPartnerTransactions, updateClientPartnerTransaction, addTransactionMapToClientPartnerTransaction, deleteTransactionMapFromClientPartnerTransaction, addPartnerTransactionSchemaToClientPartnerTransaction, deleteClientPartnerTransactionSchema, fetchPostProcessingBlobs, upsertClientPartnerTransactionPostProcessing, deleteClientPartnerTransactionPostProcessing } from '../../../features/ClientPartnerDetails/ClientPartnerDetailsActions';
import TransactionList from './TransactionList';
import EditClientPartnerTransactionDialog from '../../dialogs/EditClientPartnerTransactionDialog';
import TransactionPipelineDialog from '../../dialogs/TransactionPipelineDialog';

interface ClientPartnerTransactionListProps {
    viewerRole: UserRole | undefined;
    clientPartnerId: string;
    clientPartnerTransactions: ClientPartnerTransactionModel[] | undefined;
    partnerTransactions: PartnerTransactionModel[] | undefined;
    refreshClientPartnerData: () => void;
}

const ClientPartnerTransactionList: React.FC<ClientPartnerTransactionListProps> = (props: ClientPartnerTransactionListProps) => {
    const { viewerRole, clientPartnerId, clientPartnerTransactions, partnerTransactions, refreshClientPartnerData } = props;
    const dispatch = useAppDispatch();
    const canEdit = viewerCanEdit(viewerRole);
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openPipeline, setOpenPipeline] = useState(false);
    const [currentClientPartnerTransactionRows, setCurrentClientPartnerTransactionRows] = useState<GridRowModel[] | undefined>([]);
    const [availablePartnerTransactionRows, setAvailablePartnerTransactionRows] = useState<GridRowModel[]>([]);
    const [deleteStatus, setDeleteStatus] = useState<DeleteByIdPayload | undefined>();
    const [addResult, setAddResult] = useState<RequestResult | undefined>()
    const [selectedClientPartnerTransaction, setSelectedClientPartnerTransaction] = useState<ClientPartnerTransactionModel>();
    const [availableMapsForSCP, setAvailableMapsForSCP] = useState<TransactionMapModel[]>([]);
    const [existingMapsOnSCP, setExistingMapsOnSCP] = useState<string[]>([]);
    const [availableSchemasForSCP, setAvailableSchemasForSCP] = useState<PartnerTransactionSchemaModel[]>([]);
    const [existingSchemasOnSCP, setExistingSchemasOnSCP] = useState<string[]>([]);
    const [selectedRootTransactionId, setSelectedRootTransactionId] = useState<string | undefined>();
    const [transactionIsReadMode, setTransactionIsReadMode] = useState(true);

    const deletePartnerTransactionStatus = useAppSelector(selectDeleteClientPartnerTransactionStatus);
    const addPartnerTransactionsStatus = useAppSelector(selectAddClientPartnerTransactionsStatus);
    const updateTransactionStatus = useAppSelector(selectUpdateClientPartnerTransactionStatus);
    const addTransactionMapToClientPartnerTransactionStatus = useAppSelector(selectAddTransactionMapToClientPartnerTransactionStatus);
    const deleteTransactionMapFromClientPartnerTransactionStatus = useAppSelector(selectDeleteTransactionMapFromClientPartnerTransactionStatus);
    const addPartnerTransactionSchemaToClientPartnerTransactionStatus = useAppSelector(selectAddPartnerTransactionSchemaToClientPartnerTransactionStatus);
    const deleteClientPartnerTransactionSchemaStatus = useAppSelector(selectDeleteClientPartnerTransactionSchemaStatus);
    const availablePostProcessingBlobs = useAppSelector(selectPostProcessingBlobs);
    const error = useAppSelector(selectError);

    useEffect(() => {
        setCurrentClientPartnerTransactionRows(getCurrentTransactionRows());
        if (selectedClientPartnerTransaction) {
            // we need to refresh this, if the user added a map, we want the map list to update on the dialog
            setSelectedClientPartnerTransaction(findExistingClientPartnerTransaction(selectedClientPartnerTransaction.partnerTransactionId));
        }
        // get the assembly/blobs for potential edits
        dispatch(fetchPostProcessingBlobs());
    }, [clientPartnerTransactions]);

    useEffect(() => {
        setAvailablePartnerTransactionRows(getAvailableTransactionRows());
    }, [partnerTransactions]);

    useEffect(() => {
        // save off add result for base component
        if (addPartnerTransactionsStatus && addPartnerTransactionsStatus.result) {
            // set the result to be passed to base component
            setAddResult(addPartnerTransactionsStatus.result);

            if (addPartnerTransactionsStatus.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: addPartnerTransactionsStatus.message as string,
                    severity: ToastSeverity.Success
                }));

                // wrap status removal in a timeout
                // otherwise it gets removed before base component receives the result
                // which causes the add dialog to stay open
                setTimeout(() => {
                    // remove add status
                    dispatch(captureAddClientPartnerTransactionsStatus());
                });
            }
        }
        else {
            setAddResult(undefined);
        }
        if (deletePartnerTransactionStatus && deletePartnerTransactionStatus.result) {
            // set the status to be passed to base component
            setDeleteStatus(deletePartnerTransactionStatus);
            if (deletePartnerTransactionStatus.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: deletePartnerTransactionStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }
        }
        else {
            setDeleteStatus(undefined);
        }
    }, [deletePartnerTransactionStatus?.result, addPartnerTransactionsStatus?.result]);

    useEffect(() => {
        if (updateTransactionStatus && updateTransactionStatus.result) {
            if (updateTransactionStatus.result === RequestResult.Success) {
                dispatch(captureUpdateClientPartnerTransactionStatus());
                onEditTransactionClose();
            }
            refreshClientPartnerData();
        }
    }, [updateTransactionStatus?.result]);

    useEffect(() => {
        if (addTransactionMapToClientPartnerTransactionStatus && addTransactionMapToClientPartnerTransactionStatus.result) {
            if (addTransactionMapToClientPartnerTransactionStatus.result === RequestResult.Success) {
                dispatch(captureAddTransactionMapToClientPartnerTransactionStatus());
                dispatch(setToastConfig({
                    message: addTransactionMapToClientPartnerTransactionStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }
            refreshClientPartnerData();
        }
    }, [addTransactionMapToClientPartnerTransactionStatus])

    useEffect(() => {
        if (deleteTransactionMapFromClientPartnerTransactionStatus && deleteTransactionMapFromClientPartnerTransactionStatus.result) {
            let message = deleteTransactionMapFromClientPartnerTransactionStatus.message as string;
            let severity = ToastSeverity.Error;
            if (deleteTransactionMapFromClientPartnerTransactionStatus.result === RequestResult.Success) {
                severity = ToastSeverity.Success;
            }
            dispatch(captureDeleteClientPartnerTransactionMapStatus());
            dispatch(setToastConfig({
                message: message,
                severity: severity
            }));
            refreshClientPartnerData();
        }
    }, [deleteTransactionMapFromClientPartnerTransactionStatus])

    useEffect(() => {
        if (addPartnerTransactionSchemaToClientPartnerTransactionStatus && addPartnerTransactionSchemaToClientPartnerTransactionStatus.result) {
            if (addPartnerTransactionSchemaToClientPartnerTransactionStatus.result === RequestResult.Success) {
                dispatch(captureAddPartnerTransactionSchemaToClientPartnerTransactionStatus());
                dispatch(setToastConfig({
                    message: addPartnerTransactionSchemaToClientPartnerTransactionStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }
            refreshClientPartnerData();
        }
    }, [addPartnerTransactionSchemaToClientPartnerTransactionStatus])

    useEffect(() => {
        if (deleteClientPartnerTransactionSchemaStatus && deleteClientPartnerTransactionSchemaStatus.result) {
            let message = deleteClientPartnerTransactionSchemaStatus.message as string;
            let severity = ToastSeverity.Error;
            if (deleteClientPartnerTransactionSchemaStatus.result === RequestResult.Success) {
                severity = ToastSeverity.Success;
            }
            dispatch(captureDeleteClientPartnerTransactionSchemaStatus());
            dispatch(setToastConfig({
                message: message,
                severity: severity
            }));
            refreshClientPartnerData();
        }
    }, [deleteClientPartnerTransactionSchemaStatus])

    useEffect(() => {
        if (selectedClientPartnerTransaction) {
            let partnerTransaction = getPartnerTransactionByPartnerTransactionId(selectedClientPartnerTransaction?.partnerTransactionId);
            let maps = partnerTransaction?.transactionMaps as TransactionMapModel[];
            // if clientPartnerTransaction already has maps, collect the ids to disallow selecting them again
            if (selectedClientPartnerTransaction.clientPartnerTransactionMaps && selectedClientPartnerTransaction.clientPartnerTransactionMaps.length > 0) {
                let existingMapIds: string[] = [];
                selectedClientPartnerTransaction.clientPartnerTransactionMaps.forEach(map => {
                    existingMapIds.push(map?.transactionMap?.id);
                });
                setExistingMapsOnSCP(existingMapIds);
            } else {
                // a user might select a map, delete it and then try and select it again all while
                // remaining in the edit dialog, so need to clear array of existing if a delete
                // caused the selected transaction to no longer have any maps
                setExistingMapsOnSCP([]);
            }
            setAvailableMapsForSCP(maps);

            let schemas = partnerTransaction?.partnerTransactionSchemas as PartnerTransactionSchemaModel[];
            // if clientPartnerTransaction already has schemas, collect the ids to disallow selecting them again
            if (selectedClientPartnerTransaction.clientPartnerTransactionSchemas && selectedClientPartnerTransaction.clientPartnerTransactionSchemas.length > 0) {
                let existingSchemaIds: string[] = [];
                selectedClientPartnerTransaction.clientPartnerTransactionSchemas.forEach(schema => {
                    existingSchemaIds.push(schema?.partnerTransactionSchema?.id);
                });
                setExistingSchemasOnSCP(existingSchemaIds);
            } else {
                // a user might select a schema, delete it and then try and select it again all while
                // remaining in the edit dialog, so need to clear array of existing if a delete
                // caused the selected transaction to no longer have any schemas
                setExistingSchemasOnSCP([]);
            }
            setAvailableSchemasForSCP(schemas);
        } else {
            setAvailableMapsForSCP([]);
            setAvailableSchemasForSCP([]);
        }
    }, [selectedClientPartnerTransaction])

    const deleteTransactionHandler = useCallback((rowId: string) => () => {
        onDeleteTransaction(rowId);
        setOpenDelete(true);
    }, [clientPartnerTransactions]);

    const editTransactionHandler = useCallback((params: GridRowParams) => () => {
        const partnerTransactionId = params.row.id;
        const transactionId = params.row.transactionId;
        onEditTransaction(partnerTransactionId, transactionId);
        setOpenEdit(true);
    }, [clientPartnerTransactions]);
    
    const onEditPipeline = (params: GridRenderCellParams) => {
        const partnerTransactionId = params.row.id;
        const transactionId = params.row.transactionId;
        let clientPartnerTransaction = findExistingClientPartnerTransaction(partnerTransactionId);
        if (clientPartnerTransaction) {
            setSelectedClientPartnerTransaction(clientPartnerTransaction);
            setSelectedRootTransactionId(transactionId);
            setOpenPipeline(true);
        }
    };

    // using DataGrid, define each column
    const transactionColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'direction',
                headerName: 'DIRECTION',
                minWidth: 120,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild",
                align: 'left',
                // eslint-disable-next-line react/display-name
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    const directionIcon = (value === TransactionDirection.Inbound) ? <img src={InboundIcon} alt="Inbound" aria-label='inbound'></img> : <img src={OutboundIcon} alt="Outbound" aria-label='outbound'></img>;

                    return (
                        <Tooltip title={value}>
                            <IconAvatar aria-label={value?.toLowerCase()}>
                                {directionIcon}
                            </IconAvatar>
                        </Tooltip>
                    );
                },
            },
            {
                field: 'type',
                headerName: 'NAME',
                minWidth: 100,
                sortable: true,
            },
            {
                field: 'description',
                headerName: 'DESCRIPTION',
                flex: 1,
                minWidth: 150,
                sortable: true,
            },
            {
                field: 'destinationPath',
                headerName: 'DESTINATION PATH',
                flex: 1,
                minWidth: 200,
                sortable: true,
            },
            {
                field: 'pipelineDescription',
                headerName: 'PIPELINE',
                minWidth: 200,
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    const displayString = (value) ? value : "Pipeline Not Set";
                    if (canEdit) {
                        const inherited = params.row.pipelineInherited;
                        if (inherited) {
                            // span is needed and called out in mui docs.  Tooltip wont show on disabled button.
                            return (
                                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                    <div className='ediDataGridCellOverflowHidden' style={{maxWidth:'150px'}}>{displayString}</div>
                                    <Tooltip title="Pipeline Inherited From Partner">
                                        <span>
                                            <IconButton color="primary" disabled={inherited} aria-label="edit-Pipeline" onClick={(e) => {
                                                e.stopPropagation();
                                                onEditPipeline(params);
                                            }}>
                                                <EditIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Stack>
                            );
                        }
                        return (
                            <Stack direction="row" justifyContent="flex-start" alignItems="center" >
                                <div className='ediDataGridCellOverflowHidden' style={{maxWidth:'150px'}}>{displayString}</div>
                                <IconButton color="primary" aria-label="edit-Pipeline" onClick={(e) => {
                                    e.stopPropagation();
                                    onEditPipeline(params);
                                }}>
                                    <EditIcon />
                                </IconButton>
                               
                            </Stack>
                        );
                    } else {
                        return displayString;
                    }

                }
            },
            {
                field: 'hasMaps',
                headerName: 'HAS MAP',
                width: 120,
                align: 'left',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    if (value) {
                        return <div style={{ width: '60px' }}><CheckCircleIcon color="success" /></div>
                    }
                    return (<div></div>);
                }
            },
            {
                field: 'hasSchema',
                headerName: 'HAS SCHEMA',
                width: 120,
                align: 'left',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    if (value) {
                        return <div style={{ width: '80px' }}><CheckCircleIcon color="success" /></div>
                    }
                    return (<div></div>);
                }
            },
            {
                field: 'hasAssembly',
                headerName: 'HAS ASSEMBLY',
                width: 120,
                align: 'left',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    if (value) {
                        return <div style={{ width: '80px' }}><CheckCircleIcon color="success" /></div>
                    }
                    return (<div></div>);
                }
            },
            {
                field: 'actions',
                type: 'actions',
                sortable: false,
                resizable: false,
                minWidth: 100,
                headerAlign: 'center',
                align: 'right',
                hide: !canEdit, // hide column for reader role
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit Transaction"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editTransactionHandler(params)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete Transaction"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        onClick={deleteTransactionHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],
            },
        ],
        [deleteTransactionHandler, editTransactionHandler, canEdit],
    );

    // get the transactions from within PartnerTransaction within ClientPartnerTransaction 
    // and put them into DataGrid rowModel for rendering
    const getCurrentTransactionRows = () => {
        return clientPartnerTransactions
            ? (clientPartnerTransactions?.map((clientPartnerTransaction: ClientPartnerTransactionModel) => {
                const partnerTransactionNode = getPartnerTransactionByPartnerTransactionId(clientPartnerTransaction.partnerTransactionId);
                const transactionNode = partnerTransactionNode?.transaction;
                if (!transactionNode) {
                    return {} as GridRowModel;
                }
                const { id:transactionId, name, direction, description } = transactionNode;
                const pipeline = clientPartnerTransaction.pipeline;
                const pipelineInherited = clientPartnerTransaction.isPipelineInherited;
                const path = clientPartnerTransaction.destinationPath;
                let pipelineDescription = pipeline?.description;
                const hasMaps = (clientPartnerTransaction.clientPartnerTransactionMaps && clientPartnerTransaction.clientPartnerTransactionMaps.length > 0);
                const hasSchema = (clientPartnerTransaction.clientPartnerTransactionSchemas && clientPartnerTransaction.clientPartnerTransactionSchemas.length > 0);
                const hasAssembly = (clientPartnerTransaction.clientPartnerTransactionPostProcessings && clientPartnerTransaction.clientPartnerTransactionPostProcessings.length > 0);
                return {
                    _raw: transactionNode,
                    id: partnerTransactionNode?.id, // has to be partner trans id in order to properly disable already selected rows
                    transactionId,
                    direction,
                    type: name,
                    description,
                    destinationPath: path,
                    pipelineDescription: pipelineDescription,
                    pipelineInherited: pipelineInherited,
                    hasMaps: hasMaps,
                    hasSchema,
                    hasAssembly
                } as GridRowModel;
            }) as GridRowModel[])
            : [];
    };

    const getAvailableTransactionRows = () => {
        return partnerTransactions
            ? (partnerTransactions?.map((partnerTransaction: PartnerTransactionModel) => {
                const transactionNode = partnerTransaction?.transaction;
                if (!transactionNode) {
                    return {} as GridRowModel;
                }
                const { name, description, direction } = transactionNode;
                const existsOnClientPartner = isExistingTransaction(partnerTransaction?.id);
                return {
                    _raw: transactionNode,
                    id: partnerTransaction?.id, // for client partner, need the ids of the partnerTransactions for saving
                    direction,
                    type: name,
                    description,
                    isSelectable: !existsOnClientPartner,
                } as GridRowModel;
            }) as GridRowModel[])
            : [];
    };

    const isExistingTransaction = (partnerTransactionId: string) => {
        // check if this transaction is already a client partner transaction
        let matchFound = findExistingClientPartnerTransaction(partnerTransactionId);
        return matchFound ? true : false;
    }
    const findExistingClientPartnerTransaction = (partnerTransactionId: string) => {
        // check if this transaction is already a client partner transaction
        if (clientPartnerTransactions && partnerTransactionId) {
            let matchFound = clientPartnerTransactions.find((cpt: ClientPartnerTransactionModel) => cpt.partnerTransactionId === partnerTransactionId);
            return matchFound;
        }
        return undefined;
    }
    const getPartnerTransactionByPartnerTransactionId = (partnerTransactionId: string) => {
        if (partnerTransactions && partnerTransactionId) {
            let matchFound = partnerTransactions.find((pt: PartnerTransactionModel) => pt.id === partnerTransactionId);
            return matchFound;
        }
        return undefined;
    }

    // use the selectedRowId to find the parent clientPartnerTransaction and set it as selected
    const onDeleteTransaction = (selectedRowId: string) => {
        let clientPartnerTransaction = findExistingClientPartnerTransaction(selectedRowId);

        if (clientPartnerTransaction) {
            setSelectedClientPartnerTransaction(clientPartnerTransaction);
            // clear the status
            dispatch(captureDeleteClientPartnerTransactionStatus());
        }
    };

    const onEditTransaction = (selectedRowId: string, transactionId: string) => {
        let clientPartnerTransaction = findExistingClientPartnerTransaction(selectedRowId);
        if (clientPartnerTransaction) {
            setSelectedClientPartnerTransaction(clientPartnerTransaction);
            setSelectedRootTransactionId(transactionId);
            setTransactionIsReadMode(false);
        }
    };

    const onRowClick = (params: GridRowParams) => {
        if (params && params.row.id) {
            let clientPartnerTransaction = findExistingClientPartnerTransaction(params.row.id);
            if (clientPartnerTransaction) {
                setSelectedClientPartnerTransaction(clientPartnerTransaction);
                setSelectedRootTransactionId(params.row.transactionId);
                setTransactionIsReadMode(true);
                setOpenEdit(true);
            }
        }
    };

    const onEditTransactionClose = () => {
        // unset selected transaction
        setSelectedClientPartnerTransaction(undefined);
        setSelectedRootTransactionId(undefined);
        // close the dialog
        setOpenEdit(false);
        setOpenPipeline(false);
        setTransactionIsReadMode(true);
    };

    const onEditTransactionSave = (
        updatedClientPartnerTransactionData: UpdateClientPartnerTransactionInput
    ) => {
        if (updatedClientPartnerTransactionData && updatedClientPartnerTransactionData.id) {
            dispatch(updateClientPartnerTransaction(updatedClientPartnerTransactionData));
        }
    };

    const onAddMapToTransaction = (transactionId: string, selectedMapId: string) => {
        if (transactionId && selectedMapId) {
            dispatch(addTransactionMapToClientPartnerTransaction(transactionId, selectedMapId));
        }
    };

    const onDeleteMapFromTransaction = (clientPartnerTransactionMapId: string) => {
        if (clientPartnerTransactionMapId) {
            dispatch(deleteTransactionMapFromClientPartnerTransaction(clientPartnerTransactionMapId));
        }
    };

    const onAddSchemaToTransaction = (transactionId: string, selectedSchemaId: string) => {
        if (transactionId && selectedSchemaId) {
            dispatch(addPartnerTransactionSchemaToClientPartnerTransaction({ clientPartnerTransactionId: transactionId, partnerTransactionSchemaId: selectedSchemaId }));
        }
    };

    const onDeleteSchemaFromTransaction = (clientPartnerTransactionSchemaId: string) => {
        if (clientPartnerTransactionSchemaId) {
            dispatch(deleteClientPartnerTransactionSchema(clientPartnerTransactionSchemaId));
        }
    };

    const onPipelineClose = () => {
        // unset selected transaction
        setSelectedClientPartnerTransaction(undefined);
        setSelectedRootTransactionId(undefined);
        // close the dialog
        setOpenPipeline(false);
    };

    const onPipelineSave = (pipeline?: PipelineModel) => {
        if (selectedClientPartnerTransaction) {
            dispatch(updateClientPartnerTransaction({id: selectedClientPartnerTransaction.id, pipelineId: pipeline?.id}));
        }
    };

    const onDeleteDialogConfirm = () => {
        // delete the selected client partner transaction
        if (selectedClientPartnerTransaction?.id) {
            dispatch(deleteClientPartnerTransaction(selectedClientPartnerTransaction?.id));
        }
    };

    const onDeleteDialogClose = () => {
        // clear the delete status
        dispatch(captureDeleteClientPartnerTransactionStatus());
        // unset selected transaction
        setSelectedClientPartnerTransaction(undefined);
        // close the dialog
        setOpenDelete(false);
    };

    const onAddDialogSave = (selectedPartnerTransactionIds?: string[]) => {
        if (selectedPartnerTransactionIds && selectedPartnerTransactionIds.length > 0) {
            // dispatch save of clientPartner with array of Ids
            dispatch(addClientPartnerTransactions(
                clientPartnerId,
                selectedPartnerTransactionIds
            ));
        }
    };

    const handleClearError = () => {
        dispatch(clearError());
    };

    return (
        <>
            <TransactionList
                viewerRole={viewerRole}
                parentEntityTypeName="Client Partner"
                transactionGridColumns={transactionColumns}
                currentTransactionRows={currentClientPartnerTransactionRows}
                availableTransactionRows={availablePartnerTransactionRows}
                deleteStatus={deleteStatus}
                addResult={addResult}
                onDeleteDialogConfirm={onDeleteDialogConfirm}
                onDeleteDialogClose={onDeleteDialogClose}
                onAddDialogSave={onAddDialogSave}
                openDeleteDialog={openDelete}
                refreshList={refreshClientPartnerData}
                clearError={handleClearError}
                error={error}
                onRowClick={onRowClick}
            />
            <EditClientPartnerTransactionDialog
                isOpen={openEdit}
                viewerRole={viewerRole}
                clientPartnerTransaction={selectedClientPartnerTransaction}
                isReadOnly={transactionIsReadMode}
                availableMaps={availableMapsForSCP}
                existingMapIds={existingMapsOnSCP}
                mapAddedStatus={addTransactionMapToClientPartnerTransactionStatus?.result}
                mapDeletedStatus={deleteTransactionMapFromClientPartnerTransactionStatus?.result}
                availableSchemas={availableSchemasForSCP}
                existingSchemaIds={existingSchemasOnSCP}
                schemaAddedStatus={addPartnerTransactionSchemaToClientPartnerTransactionStatus?.result}
                schemaDeletedStatus={deleteClientPartnerTransactionSchemaStatus?.result}
                availablePostProcessingBlobs={availablePostProcessingBlobs}
                
                onClose={onEditTransactionClose}
                onSave={onEditTransactionSave}
                refreshData={refreshClientPartnerData}
                onMapAdded={onAddMapToTransaction}
                onMapDeleted={onDeleteMapFromTransaction}
                onSchemaAdded={onAddSchemaToTransaction}
                onSchemaDeleted={onDeleteSchemaFromTransaction}
                error={error}
            />
            <TransactionPipelineDialog
                isOpen={openPipeline}
                pipeline={selectedClientPartnerTransaction?.pipeline as PipelineModel}
                onClose={onPipelineClose}
                onSave={onPipelineSave}
                error={error}
            />

        </>

    );

}

export default ClientPartnerTransactionList;