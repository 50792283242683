import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector } from "../../app/hooks";
import { capitalize } from 'lodash';
import { Grid, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { BlankMenuItem, FilterBarCheckbox } from '../../util/SharedStyles';
import CloseIcon from '@mui/icons-material/Close';
import OutboundIcon from '../../icons/outbound.svg';
import InboundIcon from '../../icons/inbound.svg';
import { NotificationFormatterBodyType, TransactionDirection, TransactionModel } from '../../gql-types.generated';
import { selectTransactionList } from '../../features/EDIContainer/EDIContainerSlice';
import { Viewer } from '../../util/Constants';
import FilterBar from './FilterBar';

interface NotificationFormattersFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    onClose: () => void;
    onFilterChanged: (
        filterDescription: string | undefined, 
        filterTransactionId: string | undefined, 
        filterBodyType: NotificationFormatterBodyType | undefined, 
        filterIsForError?: boolean | undefined,
    ) => void;
}

const NotificationFormattersFilterBar: React.FC<NotificationFormattersFilterBarProps> = props => {
    const { id, loading = false, visible = false, viewer, onFilterChanged } = props;
    const [filterDescription, setFilterDescription] = useState<string | undefined>(undefined);
    const [filterTransactionId, setFilterTransactionId] = useState<string | undefined>(undefined);
    const [filterBodyType, setFilterBodyType] = useState<NotificationFormatterBodyType | undefined>(undefined);
    const [filterIsForError, setFilterIsForError] = useState<boolean | undefined>(undefined);
     
    const fieldsDisabled = visible && loading;

    useEffect(() => {
        // need to send out an initial fetch 
        onSearchClick();
    }, []);

    // get the list of body types for the filter dropdown
    const bodyTypes = Object.values(NotificationFormatterBodyType); 

    // get the list of transactions for the dropdown
    const transactions = useAppSelector(selectTransactionList);

    const onSearchClick = () => {
        onFilterChanged(filterDescription, filterTransactionId, filterBodyType, filterIsForError);
    };

    const onCloseClick = () => {
        // remove any filters
        clearFilters();

        // call parent to hide bar
        props.onClose();

        // trigger a new search with filters undefined since state of filter values might not be updated yet
        onFilterChanged(undefined, undefined, undefined);
    };

    const clearFilters = () => {
        clearDescriptionFilter();
        setFilterTransactionId(undefined);
        setFilterBodyType(undefined);
        setFilterIsForError(undefined);
    };

    const clearDescriptionFilter = () => {
        setFilterDescription(undefined);
    };

    const getTransactionsDropList = () => {
        if (transactions && transactions.length) {
            let items = [];
            const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
            const mappedItems = (
                transactions.map((transaction: TransactionModel) => {
                    const direction = transaction.direction;
                    let directionIcon;
                    if (direction) {
                        directionIcon = (direction === TransactionDirection.Inbound) ? <img src={InboundIcon} alt="Inbound" aria-label='inbound'></img> : <img src={OutboundIcon} alt="Outbound" aria-label='outbound'></img>;
                    }
                    return (
                        <MenuItem
                            key={transaction.id}
                            value={transaction.id}
                        >
                            <Grid container item direction="row" gap={'8px'} alignItems="center">
                                {transaction.name}
                                {directionIcon}
                            </Grid>
                        </MenuItem>
                    );
                })
            );
            items.push(blankItem);
            items.push(...mappedItems);
            return items;
        }
        return null;
    };

      
    const getBodyTypeFilterList = () => {
        if (bodyTypes && bodyTypes.length) {
            let items = [];
            const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
            const mappedItems = (
                bodyTypes.map((type: NotificationFormatterBodyType) => (
                    <MenuItem
                        key={type}
                        value={type}
                        disabled={fieldsDisabled}
                    >
                        {type === NotificationFormatterBodyType.Text ? capitalize(type) : type}
                    </MenuItem>
                ))
            );
            items.push(blankItem);
            items.push(...mappedItems);
            return items;
        }
        return null;
    };
    
    const onDescriptionFilterChange = (event: ChangeEvent<HTMLInputElement>) => {

        let nameValue = event.target.value;
        setFilterDescription(nameValue);
    };
    
    const onTransactionFilterChange = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        
        let currentValue = value?.length > 0 ? value : undefined;
        
        setFilterTransactionId(currentValue);
    };

    const onBodyTypeFilterChange = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        
        let currentValue = value?.length > 0 ? value as NotificationFormatterBodyType : undefined;
        
        setFilterBodyType(currentValue);
    };
    
    const onIsForErrorFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let currentValue = event.target.checked ? true : undefined;
        setFilterIsForError(currentValue);
    };

    const descriptionFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear description filter"
                    onClick={clearDescriptionFilter}
                    disabled={fieldsDisabled}
                >
                    {filterDescription && filterDescription.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const transactionsMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '300px',
            },
        },
    };
   
    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick} onSearch={onSearchClick}>
            <Grid item xs={2}>
                <TextField
                    itemID="formatter-filter-description-name"
                    fullWidth
                    value={filterDescription ?? ''}
                    label="Description"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'description', 'maxLength': 50, }}
                    InputProps={descriptionFilterProps}
                    onChange={onDescriptionFilterChange}
                    autoComplete="off"
                    data-cy="formatter-filter-description-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="formatter-filter-transactions-label">Transaction</InputLabel>
                    <Select
                        labelId="formatter-filter-transactions-label"
                        aria-labelledby="formatter-filter-transactions-label"
                        value={filterTransactionId ?? ''}
                        onChange={onTransactionFilterChange}
                        disabled={fieldsDisabled}
                        MenuProps={transactionsMenuProps}
                        data-cy="formatter-filter-transaction"
                    >
                        {getTransactionsDropList()}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={1} lg={2}>
                <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                    <InputLabel id="formatter-filter-body-type-label">Body Type</InputLabel>
                    <Select
                        labelId="formatter-filter-body-type-label"
                        aria-labelledby="formatter-filter-body-type-label"
                        id="formatter-filter-body-type"
                        value={filterBodyType ?? ''}
                        MenuProps={{
                            'aria-label': 'body type',
                        }}
                        onChange={onBodyTypeFilterChange}
                        data-cy="formatter-filter-body-type"
                    >
                        {getBodyTypeFilterList()}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={1} lg={2} display={"inline-flex"} alignItems={"flex-end"} alignSelf={"flex-end"}>
                <FormControlLabel 
                    id="formatter-filter-is-for-error-label"
                    control={<FilterBarCheckbox checked={filterIsForError ?? false} onChange={onIsForErrorFilterChange} aria-labelledby='formatter-filter-is-for-error-label' />} 
                    label="Is For Error" 
                    disabled={fieldsDisabled}
                />
            </Grid>
        </FilterBar>
    );
};

export default NotificationFormattersFilterBar;