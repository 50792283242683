import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { GetPortalTransactionsInput, PortalTransactionListQuery, PagingResultModelOfPortalTransactionModel } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_PORTAL_TRANSACTION_LIST = gql`
    query portalTransactionList($input: GetPortalTransactionsInput!) {
        portalTransactions (input: $input) {
            cursor {
                nextPage
                previousPage
              }
            nodes {
                id
                acknowledgedTime
                acknowledgementStatus
                clientId
                clientTenantId
                partnerId
                currencyCode
                documentDate
                documentId
                documentNumber
                documentReferenceId
                ediDocumentResentCount
                ediDocumentResentTime
                erpOrderNumber
                originalFileName
                internalTransactionId
                isAbandoned
                purchaseOrderNumber
                totalLines
                totalQuantity
                totalValue
                createdTime
                client {
                    id
                    name
                }
                partner {
                    id
                    name
                }
                clientPartner {
                    id
                }
                transaction {
                    id
                    name
                    direction
                    normalizedDocumentType
                }
                transactionErrors {
                    id
                    status
                }
            }
        }
    }
`;

export const queryPortalTransactionList = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters?: GetPortalTransactionsInput
): Promise<PagingResultModelOfPortalTransactionModel | undefined> => {
    const { 
        after, 
        limit, 
        clientName,
        dateFrom, 
        dateTo, 
        documentNumber,
        documentType,
        erpOrderNumber,
        hasException, 
        isDocumentResent,
        normalizedDocumentType,
        order, 
        partnerName, 
        purchaseOrderNumber, 
    } = filters ?? {};
    const { data, errors } = await client.query<PortalTransactionListQuery>({
        variables: {
            input: {
                after,
                limit,
                documentNumber: documentNumber?.trim(),
                documentType: documentType?.trim(),
                order,
                clientName: clientName?.trim(),
                partnerName: partnerName?.trim(),
                purchaseOrderNumber: purchaseOrderNumber?.trim(),
                erpOrderNumber: erpOrderNumber?.trim(),
                dateFrom,
                dateTo,
                hasException,
                isDocumentResent,
                normalizedDocumentType,
            }
        },
        query: QUERY_PORTAL_TRANSACTION_LIST,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.portalTransactions as PagingResultModelOfPortalTransactionModel;
}
