import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { queryErrorLogById } from '../../gql/QueryErrorLogById';
import { fetchError, fetchErrorLogByIdSuccess, captureUpdateLogStatus, captureUpsertErrorLogNoteStatus, captureDeleteErrorLogNoteStatus, captureUpdateErrorLogCategoryStatus } from './ErrorLogDetailsSlice';
import { TransactionErrorModel, RequestResult, UpsertNoteInput, UpdateTransactionErrorCategoryInput, UpdateTransactionErrorsInput } from '../../gql-types.generated';
import { mutationUpsertTransactionErrorNote } from '../../gql/MutationUpsertTransactionErrorNote';
import { getErrorMessage } from '../../util/Common';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';
import { mutationUpdateTransactionErrorCategory } from '../../gql/MutationUpdateTransactionErrorCategory';
import { mutationUpdateTransactionErrorStatus } from '../../gql/MutationUpdateTransactionErrorStatus';

export const fetchErrorLogById = (id: string) => 
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const errorLog = await queryErrorLogById(gqlClient, id);
            if (errorLog) {
                dispatch(fetchErrorLogByIdSuccess(errorLog as TransactionErrorModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    }


export const updateTransactionErrorStatus = (updateData: UpdateTransactionErrorsInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const updateStatus = await mutationUpdateTransactionErrorStatus(gqlClient, updateData);
            if (updateStatus) {
                if (updateStatus.result === RequestResult.Fail) {
                    getErrorMessage(updateStatus.message as string, updateStatus.errors, true, true);
                }
                dispatch(captureUpdateLogStatus(updateStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

export const updateTransactionErrorCategory = (
        categoryData: UpdateTransactionErrorCategoryInput
    ): AppThunk =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const updateStatus = await mutationUpdateTransactionErrorCategory(gqlClient, categoryData);
                if (updateStatus) {
                    if (updateStatus.result === RequestResult.Fail) {
                        dispatch(fetchError({ message: updateStatus.message } as Error));
                        getErrorMessage(updateStatus.message as string, updateStatus.errors, true, true);
                    }
                    dispatch(captureUpdateErrorLogCategoryStatus(updateStatus));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

export const upsertErrorLogNote = (
    upsertNoteData: UpsertNoteInput,
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertNoteStatus = await mutationUpsertTransactionErrorNote(gqlClient, {
                upsertNoteData
            });
            if (upsertNoteStatus) {
                if (upsertNoteStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertNoteStatus.message } as Error));
                    getErrorMessage(upsertNoteStatus.message as string, upsertNoteStatus.errors, true, true);
                }
                dispatch(captureUpsertErrorLogNoteStatus(upsertNoteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteErrorLogNote = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteNoteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "TransactionErrorNote"
            });
            if (deleteNoteStatus) {
                if (deleteNoteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteNoteStatus.message as string, deleteNoteStatus.errors, true, true);
                }
                dispatch(captureDeleteErrorLogNoteStatus(deleteNoteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };