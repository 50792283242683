import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { GetPortalUsersInput , GetClientPortalUsersQuery, PortalUserModel } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_CLIENT_PORTAL_USERS = gql`
    query getClientPortalUsers($input: GetPortalUsersInput !) {
        clientPortalUsers(input: $input) {
            nodes {
                id
                email
                firstName
                lastName
                createdTime
            }
        }
    }
`;

export const queryClientPortalUsers = async (
    client: ApolloClient<NormalizedCacheObject>,
    tenantId: string
): Promise<PortalUserModel[] | undefined> => {
    const { data, errors } = await client.query<GetClientPortalUsersQuery>({
        variables: {
            input: {
                tenantId
            }
        },
        query: QUERY_CLIENT_PORTAL_USERS,
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }
    return data?.clientPortalUsers?.nodes as PortalUserModel[];
}