import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CircularProgress, Tooltip } from "@mui/material";
import { useTitle } from "../../util/Common";
import { ToastSeverity, Viewer, defaultPageSize } from "../../util/Constants";
import { viewerCanAddDelete } from "../../util/ViewerUtility";
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { RequestResult, OrderDirection, GetStandardExchangeFormatInput, StandardExchangeFormatOrderByInput, BlobStorageModel, BlobUploadRequestInput } from '../../gql-types.generated';
import { 
    selectError, 
    clearError, 
    clearState, 
    selectSefFileList, 
    selectRequestsInFlight, 
    selectSefFilePagingResult,
    selectUploadSefFileStatus, 
    captureUploadSefFileStatus, 
    selectDownloadSefFileStatus,
    captureDownloadSefFileStatus
} from './StandardExchangeFormatFilesSlice';
import { fetchSefFileList, uploadStandardExchangeFormatFile, downloadStandardExchangeFormatFile } from './StandardExchangeFormatFilesActions';
import ErrorMessage from '../../components/ErrorMessage';
import { MainContentBox, DataGridListScrollBox, PageTitleToolbarGrid, MainDataGrid } from '../../util/SharedStyles';
import PageTitleBar from '../../components/PageTitleBar';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowModel, GridOverlay, GridSortItem, GridSortModel, GridRowParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { downloadDocument } from "../../util/Common";
import MainDataGridLoadingSkeleton from '../../components/MainDataGridLoadingSkeleton';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import StandardExchangeFormatFileDialog from "../../components/dialogs/StandardExchangeFormatFileDialog";
import FiltersButton from '../../components/buttons/FiltersButton';
import StandardExchangeFormatFilesFilterBar from "../../components/filters/StandardExchangeFormatFilesFilterBar";
import { getShortDateString, getTimeString } from "../../util/DateTimeUtility";
import GridCellDualVert from "../../components/listItems/GridCellDualVert";
import { DateTime } from "luxon";

interface SefFilesProps {
    viewer: Viewer | undefined;
}

const StandardExchangeFormatFiles: React.FC<SefFilesProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [openModify, setOpenModify] = useState(false);
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const [fileRows, setFileRows] = useState<GridRowModel[] | undefined>(undefined);
    const [selectedFile, setSelectedFile] = useState<BlobStorageModel | undefined>(undefined);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterDateFrom, setFilterDateFrom] = useState<DateTime | undefined>(undefined);
    const [filterDateTo, setFilterDateTo] = useState<DateTime | undefined>(undefined);
    const [filterFileName, setFilterFileName] = useState<string | undefined>(undefined);
    const [filterCount, setFilterCount] = useState(0);
    const [sortFirstLoad, setSortFirstLoad] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
        
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'lastModifiedTime',
            sort: 'desc',
        },
    ]);
    const [serverSortModel, setServerSortModel] = useState<StandardExchangeFormatOrderByInput[] | undefined>([{
        lastModifiedTime: OrderDirection.Desc
    } as StandardExchangeFormatOrderByInput]);

    const pageSize = defaultPageSize;

    const fileList = useAppSelector(selectSefFileList);
    const requestsInFlight = useAppSelector(selectRequestsInFlight);
    const filesPagingResult = useAppSelector(selectSefFilePagingResult);
    const uploadFileStatus = useAppSelector(selectUploadSefFileStatus);
    const downloadFileStatus = useAppSelector(selectDownloadSefFileStatus);
    const error = useAppSelector(selectError);
    
    const canAddOrDelete = viewerCanAddDelete(viewer);

    useTitle("SEF Files");

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const titleHeight = document.getElementById('sef-files-title-comp')?.clientHeight || 0;
        // if filters are open, include that in the calculation
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('sef-files-filter-bar')?.clientHeight || 0;
        }
        let totalHeaderAreaHeight = titleHeight + filterBarHeight;
        if (totalHeaderAreaHeight > 0)
            setContentAreaHeight(`calc(100% - ${totalHeaderAreaHeight}px)`);
    }, [fileList, filtersOpen]);

    useEffect(() => {
        if (uploadFileStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: uploadFileStatus.message as string,
                severity: ToastSeverity.Success
            }));
            dispatch(captureUploadSefFileStatus(undefined));
            onAddEditDialogClose();
        }
    }, [uploadFileStatus?.result])

    const onDownloadSuccess = useCallback(() => {
        if (downloadFileStatus?.data) {
            // actionPayload data returns BlobDownloadResponse as a stringified JSON object, so need to parse it
            // to get the actual data and then download
            // BlobDownloadResponse is not a generated type on the frontend, so if would ever change
            // on the backend, we would need to update this code to match the new response
            let parsedData = JSON.parse(downloadFileStatus.data);
            downloadDocument(parsedData.FileContent, parsedData.BlobFileName, "text/plain", true);
        } 
        resetDownloadStatus();
    }, [downloadFileStatus?.data]);

    useEffect(() => {
        if (downloadFileStatus?.result === RequestResult.Success) {
            onDownloadSuccess();
        }
        else if (downloadFileStatus?.result === RequestResult.Fail) {
            dispatch(setToastConfig({
                message: downloadFileStatus.message as string,
                severity: ToastSeverity.Error
            }));
            resetDownloadStatus();        
        }
    }, [downloadFileStatus?.result, onDownloadSuccess])

    useEffect(() => {
        if (fileList) {
            let numFilters = 0;
            if (filterFileName && filterFileName.length > 0) {
                numFilters += 1;
            }
            if (filterDateFrom) {
                numFilters += 1;
            }
            if (filterDateTo) {
                numFilters += 1;
            }
            setFilterCount(numFilters);
        }
    }, [fileList, filterFileName, filterDateFrom, filterDateTo]);

    useEffect(() => {
        setFileRows(getFileRows());
        if (!fileList && requestsInFlight > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [fileList, requestsInFlight]);

    const getSortRefreshInput = () => {
        return {
            after: undefined,
            limit: pageSize,
            dateFrom: filterDateFrom,
            dateTo: filterDateTo,
            fileName: filterFileName,
            order: serverSortModel
        } as GetStandardExchangeFormatInput
    }

    useEffect(() => {
        if (!sortFirstLoad) {
            const sortRefreshData = getSortRefreshInput();
            onServerSortModelChanged(sortRefreshData);
        } else {
            setSortFirstLoad(false);
        }
    }, [serverSortModel]);

    const loadPage = (endEdge: string | undefined) => {
        dispatch(fetchSefFileList({
            after: endEdge,
            limit: pageSize,
        }));
    };

    const handlePageLoad = () => {
        if (!filesPagingResult) {
            return;
        }
        if (!filesPagingResult.cursor?.nextPage) {
            return;
        }

        loadPage(filesPagingResult.cursor.nextPage);
    };

    const resetDownloadStatus = () => {
            // set processing flag back to false
            setIsDownloading(false);
            // set selected file back to undefined
            setSelectedFile(undefined);
            // remove status
            dispatch(captureDownloadSefFileStatus());
    }

    const onFiltersClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onAddClick = () => {
        dispatch(clearError());
        if (selectedFile) {
            setSelectedFile(undefined);
        }
        setOpenModify(true);
    };

    const onAddEditDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDialogClose = () => {
        dispatch(clearError());
        setSelectedFile(undefined);
        dispatch(fetchSefFileList());
    };

    const onAddEditDialogSave = (sefFileDetails: BlobUploadRequestInput) => {
        dispatch(uploadStandardExchangeFormatFile(sefFileDetails));
    };

    const getServerSortEntryFromGridSortEntry = (entry: GridSortItem) => {
        let newModel = {} as StandardExchangeFormatOrderByInput;
        switch (entry.field) {
            case 'lastModifiedTime':
                newModel.lastModifiedTime = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'fileName':
                newModel.fileName = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
        }
        return newModel;
    };

    const createdAndSetServerSortModel = (model: GridSortModel) => {
        if (model && model.length > 0) {
            let newArray = [] as StandardExchangeFormatOrderByInput[];
            let i = 0;
            for (i; i < model.length; ++i) {
                newArray.push(getServerSortEntryFromGridSortEntry(model[i]));
            }
            setServerSortModel(newArray);
        } else {
            setServerSortModel(undefined);
        }

    };

    const onSortModelChange = (model: GridSortModel) => {
        createdAndSetServerSortModel(model);
        setSortModel(model);
    };

    const onServerSortModelChanged = (refreshInput: GetStandardExchangeFormatInput) => {
        dispatch(clearState());
        dispatch(fetchSefFileList(refreshInput));
    };

    const onFilterBarClose = () => {
        // set filters as cleared
        setFiltersCleared(true);
        setFilterCount(0);
        // hide filter bar
        setFiltersOpen(false);
    };

    const refreshFilters = (
        fileNameFilter?: string | undefined, 
        filterModifiedDateFrom?: DateTime | undefined,
        filterModifiedDateTo?: DateTime | undefined
    ) => {
        setFilterDateFrom(filterModifiedDateFrom);
        setFilterDateTo(filterModifiedDateTo);
        setFilterFileName(fileNameFilter);

        dispatch(clearState());

        // filters changed so refresh the list
        dispatch(fetchSefFileList({
            after: undefined,
            limit: pageSize,
            blobStorageFileName: fileNameFilter,
            blobStorageDateFrom: filterModifiedDateFrom,
            blobStorageDateTo: filterModifiedDateTo,
            order: serverSortModel
        }));
    };

    const getSelectedRow = useCallback((rowId: string) => {
        if (rowId && fileList?.length) {
            let file = fileList.find(sef => sef.id === rowId);
            return file;
        }
        return undefined;
    }, [fileList]);

    const editHandler = useCallback((rowId: string) => {
        let sefFile = getSelectedRow(rowId);
        if (sefFile) {
            setSelectedFile(sefFile);
            setOpenModify(true);
        }
    }, [getSelectedRow]);

    const onDownload = (blobDocumentId: string, blobFileName?: string) => {
        setIsDownloading(true);
        dispatch(downloadStandardExchangeFormatFile(blobDocumentId,blobFileName));
    };

    const downloadHandler = useCallback((blobFileName: string, blobDocumentId: string) => {
        if (blobDocumentId?.length && blobFileName?.length) {
            onDownload(blobDocumentId, blobFileName);
        }
    }, []);

    const getFileRows = () => {
        if (fileList && fileList.length > 0) {
            return fileList.map((sef: BlobStorageModel) => {
                const { id, fileName, comment, lastModifiedByName, lastModifiedTime, isActive, version, blobFileName, blobStorageReference } = sef;
                const { blobDocumentId } = blobStorageReference || {};
                
                return {
                    _raw: sef,
                    id,
                    fileName,
                    comment, 
                    lastModifiedByName, 
                    lastModifiedTime, 
                    isActive, 
                    version,
                    blobDocumentId,
                    blobFileName
                }

            }) as GridRowModel[];
        }
    };

    const sefFileColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                headerName: 'FILE NAME',
                field: 'fileName',
                flex: 2,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild",
            },
            {
                headerName: 'COMMENT',
                field: 'comment',
                flex: 2,
                sortable: false,
            },
            {
                headerName: 'ACTIVE VERSION',
                field: 'version',
                flex: 1,
                sortable: false,
            },
            {
                headerName: 'LAST MODIFIED',
                field: 'lastModifiedTime',
                flex: 1,
                sortable: true,
                type: 'dateTime',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let dateValue = value ? getShortDateString(value) : undefined;
                    let timeValue = value ? getTimeString(value, { includeSeconds: true }) : undefined;
                    return (
                        <GridCellDualVert header={dateValue} sub={timeValue} />
                    )
                },
            },
            {
                headerName: 'LAST MODIFIED BY',
                field: 'lastModifiedByName',
                flex: 1,
                sortable: false,
            },
            {
                field: 'id',
                headerName: 'FILE',
                minWidth: 80,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params: GridRenderCellParams) => {
                    const { row } = params;
                    const { blobFileName, blobDocumentId } = row;

                    return (
                        <GridActionsCellItem
                            label="Download"
                            color="primary"
                            onClick={() => downloadHandler(blobFileName, blobDocumentId)}
                            icon={<Tooltip title="Download"><DownloadIcon /></Tooltip>}
                        />
                    );
                }
            },
            {
                field: 'actions',
                type: 'actions',
                sortable: false,
                resizable: false,
                minWidth: 100,
                headerAlign: 'center',
                align: 'right',
                hide: true, // hide always for now !canEdit
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Add File Version"><AddIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={() => editHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],
            },
        ], [editHandler, downloadHandler]
    )

    const onRowClickHandler = (params: GridRowParams<GridRowModel>) => {
        if (params && params.row?.blobDocumentId) {
            let route = "/seffile/" + params.row.blobDocumentId;
            navigate(route);
        }
    };

    const GetApiRef = () => {
        return useGridApiContext();
    }
    const loadingOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the SEF Files' error={error}></ErrorMessage>
                )}
                {!error && (
                    <MainDataGridLoadingSkeleton apiRef={GetApiRef()} rowBottomMargin={6} />
                )}
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the SEF Files' error={error}></ErrorMessage>
                )}
                {!error && (fileList && fileList.length <= 0) && (
                    <NoRecordsMessage topMargin={6} message="" />
                )}
            </GridOverlay>
        );
    };

    return (
        <MainContentBox>
            <PageTitleBar text='SEF Files' id="sef-files-title-comp">
                <PageTitleToolbarGrid item>
                    <FiltersButton
                        onClick={onFiltersClick}
                        filterCount={filterCount}
                        filtersCleared={filtersCleared}
                        disabled={isLoading}
                        aria-label="filter button"
                        data-cy="sef-files-filters"
                    />
                    {canAddOrDelete &&
                        <CreateNewButton
                            text="New File"
                            onClick={onAddClick}
                            data-cy="add-new-file"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <StandardExchangeFormatFilesFilterBar
                id="sef-files-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}
            />
            <DataGridListScrollBox scrollheight={contentAreaHeight}>
                <MainDataGrid
                    loading={isLoading || fileList === undefined}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="SEF File List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    onRowClick={onRowClickHandler}
                    rows={fileRows ?? []}
                    columns={sefFileColumns}
                    sortingOrder={['asc', 'desc']}
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    onRowsScrollEnd={handlePageLoad}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />

            </DataGridListScrollBox>
            <StandardExchangeFormatFileDialog
                isOpen={openModify}
                storageFile={selectedFile}
                onClose={onAddEditDialogClose}
                onSave={onAddEditDialogSave}
                error={error}
                viewerRole={viewer?.role}
            />
            {(isDownloading) &&
                <CircularProgress
                    aria-label={'progress spinner'}
                    size={48}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-24px',
                        marginLeft: '-24px',
                        zIndex: 1500
                    }}
                />
            }
        </MainContentBox>
    )
}

export default StandardExchangeFormatFiles;