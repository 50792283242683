import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { DateTime } from 'luxon';
import { RequestResult } from '../../gql-types.generated';
import { Button, Grid, Typography } from '@mui/material';
import FileIcon from '@mui/icons-material/Description';
import { CardAvatar, CountBox, DetailsTabContainer, DetailsTabHeader, DetailsTabHeaderWrapper, DetailsTabPanelContainer, TabHeader, TabLabel } from '../../util/SharedStyles';

import { useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { getFormattedDateTimeString } from '../../util/DateTimeUtility';
import { viewerCanEdit } from '../../util/ViewerUtility';

import TabPanel from '../../components/TabPanel';
import PricingItemsList from '../../components/lists/PricingItemsList';
import EditButton from '../../components/buttons/EditButton';
import PricingDialog from '../../components/dialogs/PricingDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import DetailsPage from '../../components/DetailsPage';
import SefFileVersionList from '../../components/lists/SefFileVersionList';
import { captureUploadFileVersionStatus, clearError, clearState, selectError, selectFileVersions, selectFirstVersionFile, selectUploadFileVersionStatus } from './StandardExchangeFormatFileDetailsSlice';
import { fetchFileVersionList } from './StandardExchangeFormatFileDetailsActions';

interface SefFileDetailsProps {
    viewer: Viewer | undefined;
}

const StandardExchangeFormatFileDetails: React.FC<SefFileDetailsProps> = (props) => {
    // blobDocumentId will be passed in through router
    const { blobDocumentId } = useParams();
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [createdBy, setCreatedBy] = useState<string | undefined>(undefined);
    const [createdTime, setCreatedTime] = useState<string | undefined>(undefined);

    const [tabValue, setTabValue] = useState(0);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');

    const uploadVersionStatus = useAppSelector(selectUploadFileVersionStatus);
    const error = useAppSelector(selectError);
    const firstVersion = useAppSelector(selectFirstVersionFile);
    const fileVersions = useAppSelector(selectFileVersions);

    const [verticalAlign, setVerticalAlign] = useState<boolean>(false);
    // detailsPageProps
    const [detailsRecordLoaded, setDetailsRecordLoaded] = useState<boolean>(false);
    const [detailsPageTitle, setDetailsPageTitle] = useState<string | undefined>(undefined);
    const [detailsToolbarButtons, setDetailsToolbarButtons] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsAV, setDetailsAV] = useState<JSX.Element | undefined>(undefined);
    const [detailsHeader, setDetailsHeader] = useState<string | undefined>(undefined);
    const [detailsSubHeader, setDetailsSubHeader] = useState<string | undefined>(undefined);
    
    useEffect(() => {
        // use blobDocumentId to get file version Details on render
        if (blobDocumentId) {
            dispatch(fetchFileVersionList(blobDocumentId));
        }
        return () => {
            dispatch(clearState());
        };
    }, []);

    useEffect(() => {
        // refresh details when a successful mutation occurs
        if (uploadVersionStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: uploadVersionStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upload status
            dispatch(captureUploadFileVersionStatus());
            // close the modify dialog
            onPricingDialogClose();
            // we should always have the blobDocumentId if we uploaded, but check to prevent compile error
            if (blobDocumentId) {
                dispatch(fetchFileVersionList(blobDocumentId));
            }
        }
    }, [uploadVersionStatus?.result]);

    useEffect(() => {
        if (firstVersion) {
            if (firstVersion.fileName) {
                setFileName(firstVersion.fileName);
            }
            if (firstVersion.createdByName) {
                setCreatedBy(firstVersion.createdByName);
            }
            if (firstVersion.createdTime) {
                let formattedDate = getFormattedDateTimeString(firstVersion.createdTime, { format: DateTime.DATETIME_FULL });
                setCreatedTime(formattedDate);
            }
        }
    }, [firstVersion]);

    useTitle(fileName);

    const updateAlignment = (verticalAlign: boolean) => {
        setVerticalAlign(verticalAlign);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleClearError = () => {
        dispatch(clearError());
    };

    const onPricingDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDialogClose = () => {
        // Clear error on close.
        handleClearError();
    };

    const refreshDetails = async () => {
        if (blobDocumentId) {
            await dispatch(fetchFileVersionList(blobDocumentId as string));
        }
    };

    const getFileDetails = () => {
        if (firstVersion) {
            return (
                <Grid container alignItems="flex-start">
                    <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Created On</Typography>
                            <Typography variant='body1' >{createdTime}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Created By</Typography>
                            <Typography variant='body1' >{createdBy}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    }

    let countBoxElement;
    if (fileVersions && fileVersions.length > 0) {
        countBoxElement = <CountBox>{fileVersions.length}</CountBox>
    }

    let fileVersionsLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>File Versions</TabLabel>
            {countBoxElement}
        </Grid>
    );

    const getTabbedContent = () => {
        if (firstVersion) {
            return (
                <DetailsTabContainer>
                    <DetailsTabHeaderWrapper>
                        <DetailsTabHeader value={tabValue} onChange={handleTabChange} >
                            <TabHeader label="File Versions" id='vertical-tab-0' />
                        </DetailsTabHeader>
                    </DetailsTabHeaderWrapper>
                    <DetailsTabPanelContainer>
                        <TabPanel verticalAlign={verticalAlign} value={tabValue} index={0}>
                            {
                                <SefFileVersionList
                                    viewerRole={viewer?.role}
                                    blobDocumentId={firstVersion.id}
                                    fileVersions={fileVersions}
                                    refreshFileList={refreshDetails}
                                />
                            }
                        </TabPanel>
                    </DetailsTabPanelContainer>
                </DetailsTabContainer>
            );
        }
    };

    // const getDialogs = () => [<PricingDialog
    //     isOpen={openModify}
    //     pricing={firstVersion}
    //     onClose={onPricingDialogClose}
    //     onSave={onVersionDialogSave}
    //     error={error}
    //     key="pricing-dialog"
    // />,
    // <DeleteDialog
    //     isOpen={openDelete}
    //     heading={'Delete Pricing'}
    //     message={`Are you sure you want to delete '${fileName}'?`}
    //     id={firstVersion?.id as string}
    //     onConfirm={handleDeletePricingConfirm}
    //     onReject={onDeleteDialogClose}
    //     errorMessage={deleteErrorMessage}
    //     key="delete-dialog"
    // />];

    //edit current pricing
    // const onEditClick = () => {
    //     setOpenModify(true);
    // }

    // const canEdit = viewerCanEdit(viewer);
    
    useEffect(() => {
        setDetailsRecordLoaded(!!firstVersion);
        if (firstVersion) {
            setDetailsPageTitle(fileName);
            setDetailsAV(<CardAvatar aria-label="sef file versions" alt="versions" >
                {<FileIcon />}
            </CardAvatar>);
            setDetailsHeader(fileName);
            setDetailsSubHeader(undefined);
            setDetailsToolbarButtons(undefined)
        }
    }, [firstVersion, fileName]);

    return (
        <DetailsPage
            recordLoaded={detailsRecordLoaded}
            pageTitle={detailsPageTitle}
            toolBarButtons={detailsToolbarButtons}
            detailsAV={detailsAV}
            detailsHeader={detailsHeader}
            detailsSubHeader={detailsSubHeader}
            detailsChips={undefined}
            detailsEditButton={undefined}
            detailsFavoriteButton={undefined}
            getDetailsListContainer={getFileDetails}
            getTabbedContainer={getTabbedContent}
            getDialogs={() => undefined}
            error={error}
            updateAlignment={updateAlignment}
        />
    );
};

export default StandardExchangeFormatFileDetails;