import { Box, Grid, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AddressModel, EmailModel, PartnerContactModel, PhoneModel, RequestResult, UpsertNoteInput } from '../../gql-types.generated';

import { convertAddressModelToUpsertAddressInput, convertEmailModelToUpsertEmailInput, convertPhoneModelToUpsertPhoneInput } from '../../util/Common';
import { viewerCanEdit } from '../../util/ViewerUtility';
import { setToastConfig } from '../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity, Viewer } from '../../util/Constants';
import { CardListContentGrid, TabCardListBox, TabToolbar } from '../../util/SharedStyles';
import {
    deleteTradingPartnerContact,
    upsertTradingPartnerContact,
    deletePartnerContactNote,
    upsertPartnerContactNote
} from '../../features/TradingPartnerDetails/TradingPartnerDetailsActions';
import {
    clearError,
    captureUpsertTradingPartnerContactStatus,
    captureDeleteTradingPartnerContactStatus,
    selectError,
    selectDeleteTradingPartnerContactStatus,
    selectUpsertTradingPartnerContactStatus,
    captureUpsertPartnerContactNoteStatus,
    captureDeletePartnerContactNoteStatus,
    selectUpsertPartnerContactNoteStatus,
    selectDeletePartnerContactNoteStatus,
    selectContactNotesByPartnerContactId
} from "../../features/TradingPartnerDetails/TradingPartnerDetailsSlice";
import ContactListItem from '../listItems/ContactListItem';
import DeleteDialog from '../dialogs/DeleteDialog';
import ContactDialog from '../dialogs/ContactDialog';
import NotesDialog from '../dialogs/NotesDialog';
import CreateNewButton from '../buttons/CreateNewButton';
import FilterArray from '../../util/ArrayFilter';
import SearchBar from '../SearchBar';
import NoResultsMessage from '../NoResultsMessage';
import NoRecordsMessage from '../NoRecordsMessage';

const TradingPartnerContactListBox = styled(Box)((props) => ({
    width: '100%',
}));

interface TradingPartnerContactListProps {
    viewer?: Viewer | undefined;
    tradingPartnerContacts: PartnerContactModel[] | undefined;
    tradingPartnerId: string;
    refreshPartnerData: () => void;
}

const TradingPartnerContactList: React.FC<TradingPartnerContactListProps> = props => {
    const { viewer, tradingPartnerContacts, tradingPartnerId, refreshPartnerData } = props;
    const dispatch = useAppDispatch();
    const [selectedContact, setSelectedContact] = useState<PartnerContactModel | undefined>(undefined);
    const [isDataRefreshing, setIsDataRefreshing] = useState<boolean>(false);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openNoteDetails, setOpenNoteDetails] = useState(false);
    const [isContactDialogDisplayOnlyMode, setIsContactDialogDisplayOnlyMode] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [searchText, setSearchText] = useState('');

    const upsertPartnerContactStatus = useAppSelector(selectUpsertTradingPartnerContactStatus);
    const deletePartnerContactStatus = useAppSelector(selectDeleteTradingPartnerContactStatus);
    const upsertContactNoteStatus = useAppSelector(selectUpsertPartnerContactNoteStatus);
    const deleteContactNoteStatus = useAppSelector(selectDeletePartnerContactNoteStatus);

    const error = useAppSelector(selectError);

    // because notes save separately on the contact dialog, need to use
    // a selector to have the notesList on the dialog properly refresh with the updates
    const selectedContactNotes = useAppSelector(selectContactNotesByPartnerContactId(selectedContact?.id));

    useEffect(() => {
        // fetch areas list when a successful mutation occurs
        if (upsertPartnerContactStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertPartnerContactStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status
            dispatch(captureUpsertTradingPartnerContactStatus());
            // close the modify dialog if not reverting back to viewMode
            if (!isContactDialogDisplayOnlyMode) {
                onContactDialogClose();
            }
        }
        if (deletePartnerContactStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deletePartnerContactStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deletePartnerContactStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deletePartnerContactStatus.message as string);
        }
    }, [upsertPartnerContactStatus?.result, deletePartnerContactStatus?.result]);

    useEffect(() => {
        if (upsertContactNoteStatus?.result === RequestResult.Success || deleteContactNoteStatus?.result === RequestResult.Success) {
            if (upsertContactNoteStatus?.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: upsertContactNoteStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }
            if (deleteContactNoteStatus?.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: deleteContactNoteStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }
            dispatch(captureUpsertPartnerContactNoteStatus());
            dispatch(captureDeletePartnerContactNoteStatus());

            setOpenNoteDetails(false);

            // declare the data refreshing function
            // as async so notes list will shoe as loading
            // up until refresh is complete
            const refreshData = async () => {
                setIsDataRefreshing(true);
                await refreshPartnerData();
                setIsDataRefreshing(false);
            };

            // call the refresh function
            refreshData();
        }
    }, [upsertContactNoteStatus?.result, deleteContactNoteStatus?.result]);

    const handleClearError = () => {
        dispatch(clearError());
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const clearSearch = () => {
        setSearchText('');
    }

    const onContactDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteTradingPartnerContactStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and TradingPartnerContact on close.
        handleClearError();
        setSelectedContact(undefined);
        setIsContactDialogDisplayOnlyMode(false);
        // Refresh list to bring in potential updates
        refreshPartnerData();
    };

    const cardClickAction = (id: string | undefined) => {
        if (id) {
            // open the addEditDialog as display only
            setIsContactDialogDisplayOnlyMode(true);
            openEditContactDialog(id);
        }
    };

    const editContact = (id: string) => {
        if (id) {
            // open the addEditDialog for actual edit
            setIsContactDialogDisplayOnlyMode(false);
            openEditContactDialog(id);
        }
    };

    const openEditContactDialog = (id: string) => {
        if (id && tradingPartnerContacts && tradingPartnerContacts.length) {
            let contact = tradingPartnerContacts.find(contactInfo => contactInfo.id === id);
            if (contact) {
                setSelectedContact(contact);
                dispatch(clearError());
                setOpenModify(true);
            }
        }
    };

    const deleteContact = (id: string) => {
        if (id && tradingPartnerContacts && tradingPartnerContacts.length) {
            let contact = tradingPartnerContacts.find(contactInfo => contactInfo.id === id);
            if (contact) {
                setSelectedContact(contact);
                dispatch(captureDeleteTradingPartnerContactStatus());
                setDeleteErrorMessage('');
                setOpenDelete(true);
            }
        }
    };

    const onContactDialogViewEditCancelClick = () => {
        let currentContact = selectedContact;
        // first clear selected
        setSelectedContact(undefined);

        // wrap in a timeout just to separate the reset from the clear
        // otherwise the clear doesn't trigger the dialog useEffect
        setTimeout(() => {
            // then reset to current to refresh
            setSelectedContact(currentContact);
        });
    };

    const onAddContactClick = () => {
        // make sure we don't pass old contact info
        setSelectedContact(undefined);
        setIsContactDialogDisplayOnlyMode(false);
        setOpenModify(true);
    };

    const addContactNote = (id: string) => {
        if (id && tradingPartnerContacts && tradingPartnerContacts.length) {
            let contact = tradingPartnerContacts.find(contactInfo => contactInfo.id === id);
            if (contact) {
                setSelectedContact(contact);
                dispatch(captureUpsertPartnerContactNoteStatus());
                handleClearError();
                setOpenNoteDetails(true);
            }
        }
    };

    const onNotesDialogClose = () => {
        setOpenNoteDetails(false);
        clearError();
    };

    const onNotesDialogSave = (text: string, title: string, noteId?: string | undefined) => {
        onSaveContactNote({ header: title, note: text, parentId: selectedContact?.id, id: noteId } as UpsertNoteInput);
    };

    const onSaveContactNote = (note: UpsertNoteInput) => {
        if (note && note.parentId) {
            dispatch(upsertPartnerContactNote(note));
        }
    };

    const onDeleteContactNote = (id: string) => {
        if (id) {
            dispatch(deletePartnerContactNote(id));
        }
    };

    const onContactDialogSave = (
        address?: AddressModel,
        description?: string,
        email?: EmailModel,
        id?: string,
        isActive?: boolean,
        isPrimary?: boolean,
        jobTitle?: string,
        name?: string,
        phone?: PhoneModel
    ) => {
        let addressInput = convertAddressModelToUpsertAddressInput(address);
        const addresses = addressInput ? [addressInput] : undefined;
        let emailInput = convertEmailModelToUpsertEmailInput(email);
        const emails = emailInput ? [emailInput] : undefined;
        let phoneInput = convertPhoneModelToUpsertPhoneInput(phone);
        const phones = phoneInput ? [phoneInput] : undefined;
        
        dispatch(upsertTradingPartnerContact(
            tradingPartnerId,
            addresses,
            description,
            emails,
            id,
            isActive,
            isPrimary,
            jobTitle,
            name,
            phones
        ));
    };

    const onDeleteDialogConfirm = () => {
        // delete the selected business area
        dispatch(deleteTradingPartnerContact(selectedContact?.id as string));
    };

    const viewerRole = viewer?.role;
    const canEdit = viewerCanEdit(viewerRole);


    const getContent = () => {
        if (tradingPartnerContacts && tradingPartnerContacts.length > 0) {
            const filteredPartnerContacts = FilterArray(tradingPartnerContacts, searchText, ['name']);
            if (filteredPartnerContacts && filteredPartnerContacts.length) {
                return (
                    filteredPartnerContacts.map((tpContact: PartnerContactModel) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} key={tpContact.id}>
                            <ContactListItem
                                viewerRole={viewerRole}
                                contact={tpContact}
                                deleteContact={deleteContact}
                                editContact={editContact}
                                addContactNote={addContactNote}
                                clickAction={cardClickAction}
                            />
                        </Grid>
                    ))
                );
            } else {
                // Filtered too hard
                // Display no results found image/message
                let message = `No Contacts found match '${searchText}'. Remove or modify the keyword search to show results.`;
                return (<NoResultsMessage message={message} />);
            }
        } else {
            let noRecords = (canEdit ?
                <NoRecordsMessage actionButtonText="Add New Contact" actionButtonClick={onAddContactClick} />
                :
                <NoRecordsMessage message="" />
            );
            return noRecords;
        }
    };

    const displaySearchBar = (tradingPartnerContacts && tradingPartnerContacts.length > 0);
    return (
        <TradingPartnerContactListBox>
            <TabToolbar justify={displaySearchBar ? "space-between" : "flex-end"}>
                {displaySearchBar &&
                    <SearchBar
                        searchText={searchText}
                        onSearch={handleSearch}
                        onClearSearch={clearSearch}
                    />
                }
                {canEdit &&
                    <CreateNewButton
                        text="New Contact"
                        onClick={onAddContactClick}
                        data-cy="add-new-contact"
                    />
                }
            </TabToolbar>
            <TabCardListBox>
                <CardListContentGrid container spacing={2}>
                    {getContent()}
                </CardListContentGrid>
            </TabCardListBox>

            <ContactDialog
                isOpen={openModify}
                contactInfo={selectedContact}
                contactNotes={selectedContactNotes}
                isReadOnly={isContactDialogDisplayOnlyMode}
                isRefreshing={isDataRefreshing}
                viewer={viewer}
                onClose={onContactDialogClose}
                onCancel={onContactDialogViewEditCancelClick}
                onSave={onContactDialogSave}
                saveResult={upsertPartnerContactStatus?.result}
                error={error}
                clearError={handleClearError}
                onSaveNote={onSaveContactNote}
                onDeleteNote={onDeleteContactNote}
                saveNoteResult={upsertContactNoteStatus?.result}
                deleteNoteStatus={deleteContactNoteStatus}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedContact?.id ?? ''}
                heading={'Delete Contact'}
                message={'Are you sure you want to delete \'' + selectedContact?.name + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
            <NotesDialog
                isOpen={openNoteDetails}
                note={undefined}
                viewerRole={viewerRole}
                onCancel={onNotesDialogClose}
                onClose={onNotesDialogClose}
                onSave={onNotesDialogSave}
                error={error}
            />
        </TradingPartnerContactListBox>
    );
};

export default TradingPartnerContactList;