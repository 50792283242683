import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { MainContentBox, MainDataGridNoRowHover, DataGridListScrollBox, PageTitleToolbarGrid } from '../../util/SharedStyles';
import { GridRenderCellParams, GridColumns, GridRowModel, GridOverlay, GridSortModel, useGridApiContext, GridSortItem } from '@mui/x-data-grid-pro';
import { CircularProgress, Link } from '@mui/material';
import { debounce } from 'lodash';
import ErrorIcon from '@mui/icons-material/Warning';
import { DateTime } from 'luxon';

import { capitalizeWithUnderscoreRemoval, useTitle } from '../../util/Common';
import { Viewer, defaultPageSize } from '../../util/Constants';
import { getShortDateString, getTimeString } from '../../util/DateTimeUtility';

import PageTitleBar from '../../components/PageTitleBar';
import ErrorMessage from '../../components/ErrorMessage';
import NoResultsMessage from '../../components/NoResultsMessage';
import { clearState, selectError, selectRequestsInFlight, selectPortalTransactionList, selectPortalTransactionPagingResult } from './PortalTransactionsSlice';
import { fetchPortalTransactionList } from './PortalTransactionsActions';
import MainDataGridLoadingSkeleton from '../../components/MainDataGridLoadingSkeleton';
import GridCellDualVert from '../../components/listItems/GridCellDualVert';
import { GetPortalTransactionsInput, PortalTransactionOrderByInput, OrderDirection, NormalizedDocumentType } from '../../gql-types.generated';
import PortalTransactionFilterBar from '../../components/filters/PortalTransactionFilterBar';
import FiltersButton from '../../components/buttons/FiltersButton';
import { getDataGridRowCurrencyDisplayValue } from '../../util/CurrencyUtility';
import theme from '../../Theme';

interface PortalTransactionsProps {
    viewer: Viewer | undefined;
    autoSearch?: boolean;
}

const PortalTransactions: React.FC<PortalTransactionsProps> = (props) => {
    const { viewer, autoSearch=false } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();  
    const [isLoading, setIsLoading] = useState(false);
    const [contentAreaHeight, setContentAreaHeight] = useState('200px');
    const [filtersOpen, setFiltersOpen] = useState(true);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterClientName, setFilterClientName] = useState<string | undefined>(undefined);
    const [filterOrderNumber, setFilterOrderNumber] = useState<string | undefined>(undefined);
    const [filterErpOrderNumber, setFilterErpOrderNumber] = useState<string | undefined>(undefined);
    const [filterPartnerName, setFilterPartnerName] = useState<string | undefined>(undefined);
    const [filterDocumentNumber, setFilterDocumentNumber] = useState<string | undefined>(undefined);
    const [filterDocumentType, setFilterDocumentType] = useState<string | undefined>(undefined);
    const [filterNormalizedDocumentType, setFilterNormalizedDocumentType] = useState<NormalizedDocumentType | undefined>(undefined);
    const [filterHasException, setFilterHasException] = useState<boolean | undefined>(undefined);
    const [filterIsDocumentResent, setFilterIsDocumentResent] = useState<boolean | undefined>(undefined);
    const [filterDateFrom, setFilterDateFrom] = useState<DateTime | undefined>(undefined);
    const [filterDateTo, setFilterDateTo] = useState<DateTime | undefined>(undefined);
    const [filterCount, setFilterCount] = useState(0);
    const [portalTransactionRows, setPortalTransactionRows] = useState<GridRowModel[] | undefined>(undefined);
    const [debounceOn, setDebounceOn] = useState(false);
    const [sortFirstLoad, setSortFirstLoad] = useState(true);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'createdTime',
            sort: 'desc',
        },
    ]);
    const [serverSortModel, setServerSortModel] = useState<PortalTransactionOrderByInput[] | undefined>([{
        createdTime: OrderDirection.Desc
    } as PortalTransactionOrderByInput]);

    const pageSize = defaultPageSize;

    const portalTransactions = useAppSelector(selectPortalTransactionList);
    const error = useAppSelector(selectError);
    const requestsInFlight = useAppSelector(selectRequestsInFlight);
    const portalTransactionsPagingResult = useAppSelector(selectPortalTransactionPagingResult);
    
    useTitle("Portal Transactions");

    const debounceOnSortingChanged = useRef(
        debounce((servSortModel) => {
            onSortModelChanged(servSortModel);
        }, 1000)
    ).current;

    useEffect(() => {
        setTimeout(() => {
            setDebounceOn(true);
        }, 200);
    }, []);

    useEffect(() => {
        setPortalTransactionRows(getPortalTransactionRows());
        if (!portalTransactions && requestsInFlight > 0) {
            setIsLoading(true);
        } else if (requestsInFlight > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [portalTransactions, requestsInFlight]);

    useEffect(() => {
        const titleHeight = document.getElementById('portal-transactions-title-comp')?.clientHeight || 0;
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('portal-transactions-filter-bar')?.clientHeight || 0;
        }
        let totalHeaderAreaHeight = titleHeight + filterBarHeight;
        if (totalHeaderAreaHeight > 0) {
            setContentAreaHeight(`calc(100% - ${totalHeaderAreaHeight}px)`);
        }
    }, [portalTransactions, filtersOpen]);

    useEffect(() => {
        if (portalTransactions) {
            let numFilters = 0;

            if (filterClientName && filterClientName.length > 0) numFilters++;
            if (filterOrderNumber && filterOrderNumber.length > 0) numFilters++;
            if (filterErpOrderNumber && filterErpOrderNumber.length > 0) numFilters++;
            if (filterPartnerName && filterPartnerName.length > 0) numFilters++;
            if (filterDocumentNumber && filterDocumentNumber.length > 0) numFilters++;
            if (filterDocumentType && filterDocumentType.length > 0) numFilters++;
            if (filterNormalizedDocumentType) numFilters++;
            if (filterHasException) numFilters++;
            if (filterIsDocumentResent) numFilters++;
            if (filterDateFrom) numFilters++;
            if (filterDateTo) numFilters++;

            setFilterCount(numFilters);
        }
    }, [portalTransactions, filterClientName, filterOrderNumber, filterErpOrderNumber, filterPartnerName, filterDocumentNumber, filterDocumentType, 
        filterNormalizedDocumentType, filterHasException, filterIsDocumentResent, filterDateFrom, filterDateTo]);

    useEffect(() => {
        return () => {
            debounceOnSortingChanged.cancel();
        };
    }, [debounceOnSortingChanged]);

    const getSortRefreshInput = () => {
        return {
            after: undefined,
            limit: pageSize,
            clientName: filterClientName,
            purchaseOrderNumber: filterOrderNumber,
            erpOrderNumber: filterErpOrderNumber,
            partnerName: filterPartnerName,
            documentNumber: filterDocumentNumber,
            documentType: filterDocumentType,
            normalizedDocumentType: filterNormalizedDocumentType,
            hasException: filterHasException,
            isDocumentResent: filterIsDocumentResent,
            dateFrom: filterDateFrom,
            dateTo: filterDateTo,
            order: serverSortModel
        } as GetPortalTransactionsInput
    }

    useEffect(() => {
        if (!sortFirstLoad) {
            const sortRefreshData = getSortRefreshInput();
            if (debounceOn !== true) {
                onSortModelChanged(sortRefreshData);
            } else {
                debounceOnSortingChanged(sortRefreshData);
            }
        } else {
            setSortFirstLoad(false);
        }
    }, [serverSortModel]);

    const getPortalTransactionRows = () => {
        if (portalTransactions && portalTransactions.length > 0) {
            return portalTransactions.map((portalTransaction) => {
                const {
                    id,
                    documentReferenceId,
                    purchaseOrderNumber,
                    createdTime,
                    originalFileName,
                    documentNumber,
                    currencyCode,
                    totalValue,
                    totalLines,
                    totalQuantity,
                    client,
                    partner,
                    clientPartner,
                    transaction,
                    transactionErrors
                } = portalTransaction;
                
                return {
                    _raw: portalTransaction,
                    id,
                    createdTime,
                    documentNumber,
                    purchaseOrderNumber,
                    documentType: transaction?.name,
                    normalizedDocumentType: transaction?.normalizedDocumentType,
                    referenceId: documentReferenceId,
                    clientName: client?.name,
                    clientId: client?.id,
                    partnerName: partner?.name,
                    partnerId: partner?.id,
                    clientPartnerId: clientPartner?.id,
                    fileName: originalFileName,
                    currencyCode,
                    totalValue,
                    totalLines,
                    totalQuantity,
                    hasExceptions: transactionErrors && transactionErrors?.length > 0
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const onRowClientNameClick = (id: string | undefined) => {
        if (id) {
            let route = "/client/" + id;
            navigate(route);
            dispatch(clearState());
        }
    };

    const onRowPartnerNameClick = (id: string | undefined) => {
        if (id) {
            let route = "/partner/" + id;
            navigate(route);
            dispatch(clearState());
        }
    };

    const onRowDocumentTypeClick = (clientPartnerId: string | undefined) => {
        // navigate to the clientPartner to allow the user to view the associated transaction/documentType
        if (clientPartnerId) {
            let route = "/clientpartner/" + clientPartnerId;
            navigate(route);
            dispatch(clearState());
        }
    };

    const portalTransactionColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                headerName: 'CLIENT',
                field: 'clientName',
                flex: 2,
                cellClassName: "ediDataGridCellFirstChild",
                minWidth: 150,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const { value, row } = params;
                    const clientId = row.clientId;
                    
                    if (clientId) {
                        return (
                            <Link
                                component="button"
                                className="ediDataGridCellFirstChild ediDataGridCellOverflowHidden"
                                color={row.isAbandoned ? theme.palette.warning.light : theme.palette.primary.main}
                                onClick={() => {
                                    onRowClientNameClick(clientId);
                                }}
                            >
                                {value}
                            </Link>
                        );
                    } else {
                        return value;
                    }
                },
            },{
                headerName: 'ORDER #',
                field: 'purchaseOrderNumber',
                flex: 2,
                minWidth: 150,
                sortable: true,
            }, {
                headerName: 'PARTNER',
                field: 'partnerName',
                minWidth: 140,
                flex: 2,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const { value, row } = params;
                    const partnerId = row.partnerId;
                    if (partnerId) {
                        return (
                            <Link
                                component="button"
                                variant="body2"
                                color={row.isAbandoned ? theme.palette.warning.light : theme.palette.primary.main}
                                className="ediDataGridCellOverflowHidden"
                                onClick={() => {
                                    onRowPartnerNameClick(partnerId);
                                }}
                            >
                                {value}
                            </Link>
                        );
                    } else {
                        return value;
                    }
                },
            }, {
                headerName: 'DOCUMENT',
                field: 'normalizedDocumentType',
                flex: 2,
                minWidth: 150,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    return capitalizeWithUnderscoreRemoval(value, ' ');
                },
            }, {
                headerName: 'DOCUMENT #',
                field: 'documentNumber',
                flex: 1,
                minWidth: 150,
                sortable: true,
            }, {
                headerName: 'TYPE',
                field: 'documentType',
                flex: 1,
                minWidth: 120,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const { value, row } = params;
                    const clientPartnerId = row.clientPartnerId;
                    
                    if (clientPartnerId) {
                        return (
                            <Link
                                component="button"
                                variant="body2"
                                className="ediDataGridCellOverflowHidden"
                                onClick={() => {
                                    onRowDocumentTypeClick(clientPartnerId);
                                }}
                            >
                                {value}
                            </Link>
                        );
                    } else {
                        return value;
                    }
                },
            }, {
                headerName: 'VALUE',
                field: 'totalValue',
                flex: 1,
                minWidth: 100,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    // format as currency using specific row currency if have it
                    const { value, row } = params;
                    let displayValue = value && value > 0 ? getDataGridRowCurrencyDisplayValue(value, row?.currencyCode) : '';
                    return displayValue;
                },
            }, {
                headerName: 'DATE',
                field: 'createdTime',
                flex: 1,
                minWidth: 114,
                sortable: true,
                type: 'dateTime',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let dateValue = value ? getShortDateString(value) : undefined;
                    let timeValue = value ? getTimeString(value, { includeSeconds: true }) : undefined;
                    return (
                        <GridCellDualVert header={dateValue} sub={timeValue} />
                    )
                },
            }, {
                headerName: '# LINES',
                field: 'totalLines',
                flex: 1,
                minWidth: 100,
                sortable: false,
                type: 'number',
            }, {
                headerName: 'TOTAL QTY',
                field: 'totalQuantity',
                flex: 1,
                minWidth: 100,
                sortable: false,
                type: 'number',
            }, {
                field: 'hasExceptions',
                width: 60,
                sortable: false,
                renderHeader: () => <ErrorIcon />,
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    return value === true ? <ErrorIcon color="error" /> : '';
                },
            }
        ],
        [],
    );

    const GetApiRef = () => {
        return useGridApiContext();
    }

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load Portal Transactions' error={error}></ErrorMessage>
                )}
                {!error && (portalTransactions && portalTransactions.length > 0 && requestsInFlight > 0) && (
                    <CircularProgress aria-label={'progress spinner'} key={'spinner'} size={42} sx={{ zIndex: 1 }} />
                )}
                {!error && (!portalTransactions) && (
                    <MainDataGridLoadingSkeleton apiRef={GetApiRef()} rowBottomMargin={6} />
                )}
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load Portal Transactions' error={error}></ErrorMessage>
                )}
                {!error && (portalTransactions && portalTransactions.length <= 0 && filterCount > 0) && (
                    <NoResultsMessage topMargin={6} message="Try different filters to see results." />
                )}
            </GridOverlay>
        );
    };

    const getServerSortEntryFromGridSortEntry = (entry: GridSortItem) => {
        let newModel = {} as PortalTransactionOrderByInput;
        switch (entry.field) {
            case 'createdTime':
                newModel.createdTime = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'documentNumber':
                newModel.documentNumber = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'erpOrderNumber':
                newModel.erpOrderNumber = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'purchaseOrderNumber':
                newModel.purchaseOrderNumber = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'totalValue':
                newModel.totalValue = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
        }
        return newModel;
    };

    const createdAndSetServerSortModel = (model: GridSortModel) => {
        if (model && model.length > 0) {
            let newArray = [] as PortalTransactionOrderByInput[];
            let i = 0;
            for (i; i < model.length; ++i) {
                newArray.push(getServerSortEntryFromGridSortEntry(model[i]));
            }
            setServerSortModel(newArray);
        } else {
            setServerSortModel(undefined);
        }
    };

    const onSortModelChange = (model: GridSortModel) => {
        createdAndSetServerSortModel(model);
        setSortModel(model);
    };

    const onFiltersClick = () => {
        setFiltersOpen(!filtersOpen);
        setFiltersCleared(false);
    };

    const onFilterBarClose = () => {
        setFiltersCleared(true);
        setFilterCount(0);
        setFiltersOpen(true);
    };

    const onSortModelChanged = (refreshInput: GetPortalTransactionsInput) => {
        dispatch(clearState());
        dispatch(fetchPortalTransactionList(refreshInput));
    };

    const loadPage = (endEdge: string | undefined) => {
        dispatch(fetchPortalTransactionList({
            after: endEdge,
            limit: pageSize,
            clientName: filterClientName,
            purchaseOrderNumber: filterOrderNumber,
            erpOrderNumber: filterErpOrderNumber,
            partnerName: filterPartnerName,
            documentNumber: filterDocumentNumber,
            documentType: filterDocumentType,
            normalizedDocumentType: filterNormalizedDocumentType,
            hasException: filterHasException,
            isDocumentResent: filterIsDocumentResent,
            dateFrom: filterDateFrom,
            dateTo: filterDateTo,
            order: serverSortModel
        }));
    };

    const handlePageLoad = () => {
        if (!portalTransactionsPagingResult) {
            return;
        }
        if (!portalTransactionsPagingResult.cursor?.nextPage) {
            return;
        }

        loadPage(portalTransactionsPagingResult.cursor.nextPage);
    };

    const refreshFilters = (
        filterClient?: string,
        filterOrderNum?: string,
        filterErpOrderNum?: string,
        filterPartner?: string,
        filterDocNum?: string,
        filterDocType?: string,
        filterNormalizedDocType?: NormalizedDocumentType,
        filterHasExceptions?: boolean,
        filterIsResent?: boolean,
        filterTransDateFrom?: DateTime,
        filterTransDateTo?: DateTime
    ) => {
        setFilterClientName(filterClient);
        setFilterOrderNumber(filterOrderNum);
        setFilterErpOrderNumber(filterErpOrderNum);
        setFilterPartnerName(filterPartner);
        setFilterDocumentNumber(filterDocNum);
        setFilterDocumentType(filterDocType);
        setFilterNormalizedDocumentType(filterNormalizedDocType);
        setFilterHasException(filterHasExceptions);
        setFilterIsDocumentResent(filterIsResent);
        setFilterDateFrom(filterTransDateFrom);
        setFilterDateTo(filterTransDateTo);

        dispatch(clearState());
        dispatch(fetchPortalTransactionList({
            after: undefined,
            limit: pageSize,
            clientName: filterClient,
            purchaseOrderNumber: filterOrderNum,
            erpOrderNumber: filterErpOrderNum,
            partnerName: filterPartner,
            documentNumber: filterDocNum,
            documentType: filterDocType,
            normalizedDocumentType: filterNormalizedDocType,
            hasException: filterHasExceptions,
            isDocumentResent: filterIsResent,
            dateFrom: filterTransDateFrom,
            dateTo: filterTransDateTo,
            order: serverSortModel
        }));
    };

    return (
        <MainContentBox>
            <PageTitleBar text='Portal Transactions' id="portal-transactions-title-comp">
                <PageTitleToolbarGrid item>
                    <FiltersButton
                        onClick={onFiltersClick}
                        filterCount={filterCount}
                        filtersCleared={filtersCleared}
                        disabled={isLoading}
                        aria-label="filter button"
                        data-cy="filters"
                    />
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <PortalTransactionFilterBar
                id="portal-transactions-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                autoSearch={autoSearch}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}
            />
            <DataGridListScrollBox scrollheight={contentAreaHeight}>
                <MainDataGridNoRowHover
                    loading={isLoading}
                    rowHeight={52}
                    aria-label="Portal Transactions List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    rows={portalTransactionRows ?? []}
                    columns={portalTransactionColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    onRowsScrollEnd={handlePageLoad}
                    components={{
                        LoadingOverlay: loadingOverlay,
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />
            </DataGridListScrollBox>
        </MainContentBox>
    );
}

export default PortalTransactions;
