import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import { 
    AttachmentType, 
    PagingResultModelOfPartnerModel, 
    TransactionModel, 
    RequestResult, 
    UpsertAttachmentInput, 
    UpsertAddressInput, 
    UpsertEmailInput, 
    UpsertNoteInput, 
    UpsertPhoneInput, 
    PagingResultModelOfAttachmentModel, 
    UpsertPartnerInput, 
    UpsertPartnerTransactionSchemaInput, 
    UpsertPartnerAkaInput,
    PartnerNotificationRecipientsModel,
    UpsertPartnerNotificationRecipientsInput, 
    UpdatePartnerTransactionInput
} from '../../gql-types.generated';
import { 
    captureDeleteTradingPartnerContactStatus, 
    captureAddTradingPartnerTransactionsStatus, 
    captureDeleteTradingPartnerTransactionStatus, 
    captureUpsertTradingPartnerContactStatus, 
    captureUpsertTradingPartnerStatus, 
    fetchTradingPartnerByIdSuccess, 
    fetchTransactionsSuccess, 
    fetchError, 
    captureDeleteTradingPartnerStatus, 
    captureUpsertTradingPartnerNoteStatus, 
    captureDeleteTradingPartnerNoteStatus, 
    captureUpsertPartnerContactNoteStatus, 
    captureDeletePartnerContactNoteStatus, 
    captureDeleteTradingPartnerAttachmentStatus, 
    captureUpsertTradingPartnerAttachmentsStatus, 
    fetchPartnerAttachmentSuccess, 
    captureUpdatePartnerTransactionStatus, 
    captureUpsertPartnerTransactionSchemaStatus, 
    captureDeletePartnerTransactionSchemaStatus,
    captureUpsertPartnerAkaStatus,
    captureDeletePartnerAkaStatus,
    fetchPartnerNotificationRecipientsSuccess,
    captureDeletePartnerNotificationRecipientsStatus,
    captureUpsertPartnerNotificationRecipientsStatus
} from './TradingPartnerDetailsSlice';
import { queryTradingPartnerById } from '../../gql/QueryTradingPartnerById';
import { mutationUpsertTradingPartnerContact } from '../../gql/MutationUpsertTradingPartnerContact';
import { mutationUpsertTradingPartner } from '../../gql/MutationUpsertTradingPartner';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';
import { mutationAddTradingPartnerTransactions } from '../../gql/MutationAddTradingPartnerTransactions';
import { mutationUpsertTradingPartnerAttachments } from '../../gql/MutationUpsertTradingPartnerAttachments';
import { queryTransactionList } from '../../gql/QueryTransactionsList';
import { queryAttachments } from '../../gql/QueryAttachment';
import { mutationUpdatePartnerTransaction } from '../../gql/MutationUpdatePartnerTransaction';
import { mutationUpsertTradingPartnerTransactionSchema } from '../../gql/MutationUpsertTradingPartnerTransactionSchema';
import { mutationUpsertPartnerAKA } from '../../gql/MutationUpsertPartnerAKA';
import { mutationUpsertPartnerNotificationRecipients } from '../../gql/MutationUpsertPartnerNotificationRecipients';
import { queryPartnerNotificationRecipients } from '../../gql/QueryPartnerNotificationRecipients';
import { mutationUpsertTradingPartnerNote } from '../../gql/MutationUpsertTradingPartnerNote';
import { mutationUpsertTradingPartnerContactNote } from '../../gql/MutationUpsertTradingPartnerContactNote';

export const fetchTradingPartnerById =
    (id: string) =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const tradingPartner = await queryTradingPartnerById(gqlClient, id);
                if (tradingPartner) {
                    dispatch(fetchTradingPartnerByIdSuccess(tradingPartner as PagingResultModelOfPartnerModel))
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

export const fetchEligibleTransactions = (
    ediStandardIds?: string[] | undefined,
) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            // only want active transactions that match the given ediStandards
            const transactionList = await queryTransactionList(gqlClient, { ediStandardIds: ediStandardIds, isActive: true });
            if (transactionList) {
                dispatch(fetchTransactionsSuccess(transactionList as TransactionModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteTradingPartnerContact = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteTradingPartnerContactStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "PartnerContact",
            });
            if (deleteTradingPartnerContactStatus) {
                if (deleteTradingPartnerContactStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteTradingPartnerContactStatus.message as string, deleteTradingPartnerContactStatus.errors, true, true);
                }
                dispatch(captureDeleteTradingPartnerContactStatus(deleteTradingPartnerContactStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteTradingPartnerTransaction = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteTradingPartnerTransactionStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "PartnerTransaction",
            });
            if (deleteTradingPartnerTransactionStatus) {
                if (deleteTradingPartnerTransactionStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteTradingPartnerTransactionStatus.message as string, deleteTradingPartnerTransactionStatus.errors, true, true);
                }
                dispatch(captureDeleteTradingPartnerTransactionStatus(deleteTradingPartnerTransactionStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };
                                                                                     
export const upsertTradingPartnerContact = (
    partnerId: string,
    addresses?: UpsertAddressInput[],
    description?: string,
    emails?: UpsertEmailInput[],
    id?: string,
    isActive?: boolean,
    isPrimary?: boolean,
    jobTitle?: string,
    name?: string,
    phones?: UpsertPhoneInput[]
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertPartnerContactStatus = await mutationUpsertTradingPartnerContact(gqlClient, {
                partnerId,
                addresses,
                description,
                emails,
                id,
                isActive,
                isPrimary,
                jobTitle,
                name,
                phones
            });
            if (upsertPartnerContactStatus) {
                if (upsertPartnerContactStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertPartnerContactStatus.message } as Error));
                    getErrorMessage(upsertPartnerContactStatus.message as string, upsertPartnerContactStatus.errors, true, true);
                }
                dispatch(captureUpsertTradingPartnerContactStatus(upsertPartnerContactStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertTradingPartner = (partnerInput: UpsertPartnerInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertTradingPartnerStatus = await mutationUpsertTradingPartner(gqlClient, {
                partnerInput
            });
            if (upsertTradingPartnerStatus) {
                if (upsertTradingPartnerStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertTradingPartnerStatus.message } as Error));
                    getErrorMessage(upsertTradingPartnerStatus.message as string, upsertTradingPartnerStatus.errors, true, true);
                }
                dispatch(captureUpsertTradingPartnerStatus(upsertTradingPartnerStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteTradingPartnerData = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteTradingPartnerStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "Partner"
            });
            if (deleteTradingPartnerStatus) {
                if (deleteTradingPartnerStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteTradingPartnerStatus.message as string, deleteTradingPartnerStatus.errors, true, true);
                }
                dispatch(captureDeleteTradingPartnerStatus(deleteTradingPartnerStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    }

export const upsertPartnerNote = (
    upsertNoteData: UpsertNoteInput,
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertNoteStatus = await mutationUpsertTradingPartnerNote(gqlClient, {
                upsertNoteData
            });
            if (upsertNoteStatus) {
                if (upsertNoteStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertNoteStatus.message } as Error));
                    getErrorMessage(upsertNoteStatus.message as string, upsertNoteStatus.errors, true, true);
                }
                dispatch(captureUpsertTradingPartnerNoteStatus(upsertNoteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };


export const deleteTradingPartnerNote = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "PartnerNote"
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteTradingPartnerNoteStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

export const upsertPartnerContactNote = (
    upsertNoteData: UpsertNoteInput,
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertNoteStatus = await mutationUpsertTradingPartnerContactNote(gqlClient, {
                upsertNoteData
            });
            if (upsertNoteStatus) {
                if (upsertNoteStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertNoteStatus.message } as Error));
                    getErrorMessage(upsertNoteStatus.message as string, upsertNoteStatus.errors, true, true);
                }
                dispatch(captureUpsertPartnerContactNoteStatus(upsertNoteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deletePartnerContactNote = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteNoteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "PartnerContactNote"
            });
            if (deleteNoteStatus) {
                if (deleteNoteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteNoteStatus.message as string, deleteNoteStatus.errors, true, true);
                }
                dispatch(captureDeletePartnerContactNoteStatus(deleteNoteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

export const addTradingPartnerTransactions = (
    partnerId: string,
    transactionIds: string[],
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const addTransactionsStatus = await mutationAddTradingPartnerTransactions(gqlClient, {
                partnerId,
                transactionIds,
            });
            if (addTransactionsStatus) {
                if (addTransactionsStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: addTransactionsStatus.message } as Error));
                    getErrorMessage(addTransactionsStatus.message as string, addTransactionsStatus.errors, true, true);
                }
                dispatch(captureAddTradingPartnerTransactionsStatus(addTransactionsStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertTradingPartnerAttachments = (
    partnerId: string,
    attachments?: UpsertAttachmentInput[],
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertTradingPartnerAttachments(gqlClient, {
                attachments,
                partnerId
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertTradingPartnerAttachmentsStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };


export const deleteTradingPartnerAttachment = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "PartnerAttachment",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteTradingPartnerAttachmentStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchPartnerAttachment =
    (id: string) =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const attachment = await queryAttachments(gqlClient, [id], AttachmentType.Partner);
                if (attachment) {
                    dispatch(fetchPartnerAttachmentSuccess(attachment as PagingResultModelOfAttachmentModel));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

export const updatePartnerTransaction = (
    partnerTransactionData: UpdatePartnerTransactionInput
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const updateStatus = await mutationUpdatePartnerTransaction(gqlClient, partnerTransactionData);
            if (updateStatus) {
                if (updateStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: updateStatus.message } as Error));
                    getErrorMessage(updateStatus.message as string, updateStatus.errors, true, true);
                }
                dispatch(captureUpdatePartnerTransactionStatus(updateStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

    export const upsertPartnerTransactionSchema = (schemaInputData: UpsertPartnerTransactionSchemaInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertTradingPartnerTransactionSchema(gqlClient, {
                schemaInputData
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertPartnerTransactionSchemaStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deletePartnerTransactionSchema = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "PartnerTransactionSchema",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeletePartnerTransactionSchemaStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

    export const upsertPartnerAKA = (partnerAkaInputData: UpsertPartnerAkaInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertPartnerAKA(gqlClient, {
                partnerAkaInputData
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertPartnerAkaStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deletePartnerAKA = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "PartnerAKA",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeletePartnerAkaStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchPartnerNotificationRecipients = (partnerId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const notificationRecipients = await queryPartnerNotificationRecipients(gqlClient, { partnerId: partnerId });
            if (notificationRecipients) {
                dispatch(fetchPartnerNotificationRecipientsSuccess(notificationRecipients as PartnerNotificationRecipientsModel[]));
            }
        } catch (error) {
            dispatch(fetchError(error as Error));
        }
    };

export const upsertPartnerNotificationRecipients = (upsertPartnerNotificationRecipientsData: UpsertPartnerNotificationRecipientsInput) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertPartnerNotificationRecipients(gqlClient, {
                input: upsertPartnerNotificationRecipientsData
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertPartnerNotificationRecipientsStatus(upsertStatus));
            }

        } catch (error) {
            dispatch(fetchError(error as Error));
        }
    };

export const deletePartnerNotificationRecipients = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "PartnerNotificationRecipients",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeletePartnerNotificationRecipientsStatus(deleteStatus));
            }
        } catch (error) {
            dispatch(fetchError(error as Error));
        }
    };