import { Checkbox, Grid, FormControl, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector } from "../../app/hooks";
import CloseIcon from '@mui/icons-material/Close';
import { EdiStandardModel } from '../../gql-types.generated';
import { Viewer } from "../../util/Constants";
import { selectEdiStandardList } from '../../features/EDIContainer/EDIContainerSlice';

import FilterBar from "./FilterBar";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../util/ViewerUtility';

interface TransactionsFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    onClose: () => void;
    onFilterChanged: (filterName: string | undefined, filterDescription: string | undefined, filterStandardIds: string[]) => void;
}

const TransactionsFilterBar: React.FC<TransactionsFilterBarProps> = props => {
    const { id, loading = false, visible = false, viewer, onFilterChanged } = props;
    const [debounceOn, setDebounceOn] = useState(false);
    const [filterName, setFilterName] = useState<string | undefined>();
    const [filterDescription, setFilterDescription] = useState<string | undefined>();
    const [filterStandardNames, setFilterStandardNames] = useState<string[]>([]);
    const [filterStandardIds, setFilterStandardIds] = useState<string[]>();
    const fieldsDisabled = visible && loading;

    useEffect(() => {
        // filters from local storage to be remembered across sessions
        let name = getLocalStorageItem(viewer, "transactionFilterName");
        if (name != null) {
            setFilterName(name);
        }
        let desc = getLocalStorageItem(viewer, "transactionFilterDescription");
        if (desc != null) {
            setFilterDescription(desc);
        }

        let standardIds = getLocalStorageItem(viewer, "transactionFilterStandardIds");
        let parsedStandardIds;
        if (standardIds != null) {
            parsedStandardIds = JSON.parse(standardIds);
            setFilterStandardIds(parsedStandardIds);
        }

        let standardNames = getLocalStorageItem(viewer, "transactionFilterStandardNames");
        if (standardNames != null && standardNames.length > 0) {
            let parsedStandardNames = JSON.parse(standardNames);
            setFilterStandardNames(parsedStandardNames);
        }

        // because Transactions are not paged lets send out an initial state to kick a fetch 
        onFilterChanged(name as string | undefined, desc as string | undefined, parsedStandardIds ?? []);
    }, []);
 
    const standards = useAppSelector(selectEdiStandardList);

    const onSearchClick = () => {
        onFilterChanged(filterName, filterDescription, filterStandardIds ?? []);
    };

    const onCloseClick = () => {
        clearFilters();
        props.onClose();
        // trigger a new search with filters undefined since state of filter values might not be updated yet
        onFilterChanged(undefined, undefined, []);
    };

    const clearFilters = () => {
        clearNameFilter();
        clearDescriptionFilter();
        removeLocalStorageItem(viewer, "transactionFilterStandardNames");
        setFilterStandardNames([]);
        removeLocalStorageItem(viewer, "transactionFilterStandardIds");
        setFilterStandardIds([]);
    }

    const clearNameFilter = () => {
        removeLocalStorageItem(viewer, "transactionFilterName");
        setFilterName(undefined);
    }

    const clearDescriptionFilter = () => {
        removeLocalStorageItem(viewer, "transactionFilterDescription");
        setFilterDescription(undefined);
    }

    const getStandardsFilterList = () => {
        if (standards && standards.length) {
            return (
                standards.map((standard: EdiStandardModel) => (
                    <MenuItem
                        key={standard.id}
                        value={standard.name}
                        disabled={fieldsDisabled}
                    >
                        <Checkbox checked={filterStandardNames && filterStandardNames.indexOf(standard.name) > -1} />
                        <ListItemText primary={standard.name} />
                    </MenuItem>
                ))
            );
        }
        return null;
    };

    const onNameFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            setLocalStorageItem(viewer, "transactionFilterName", changedValue);
        }
        else {
            removeLocalStorageItem(viewer, "transactionFilterName");
        }
        setFilterName(changedValue);
    };

    const onDescriptionFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            setLocalStorageItem(viewer, "transactionFilterDescription", changedValue);
        }
        else {
            removeLocalStorageItem(viewer, "transactionFilterDescription");
        }
        setFilterDescription(changedValue);
    };

    const onStandardFilterChanged = (event: SelectChangeEvent<typeof filterStandardNames>) => {
        const {
            target: { value },
        } = event;
        // On autofill we get a stringified value.
        let nameArray = typeof value === 'string' ? value.split(',') : value;

        if (nameArray.length > 0) {
            setLocalStorageItem(viewer, "transactionFilterStandardNames", JSON.stringify(nameArray));
        }
        else {
            removeLocalStorageItem(viewer, "transactionFilterStandardNames");
        }
        setFilterStandardNames(nameArray);

        // the array from the multiselect only contains the names
        // get the associated ids to actually send as a filter to the query
        let ids: string[] = [];
        let selectedStandards = standards?.filter(b => nameArray.includes(b.name));
        if (selectedStandards && selectedStandards.length > 0) {
            selectedStandards.forEach(standard => {
                ids.push(standard.id);
            });
        }
        if (ids.length > 0) {
            setLocalStorageItem(viewer, "transactionFilterStandardIds", JSON.stringify(ids));
        }
        else {
            removeLocalStorageItem(viewer, 'transactionFilterStandardIds');
        }

        setFilterStandardIds(ids);
    };

    const dropDownMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '300px',
            },
        },
    };

    const nameFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear name filter"
                    onClick={clearNameFilter}
                    disabled={fieldsDisabled}
                >
                    {filterName && filterName.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const descriptionFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear description filter"
                    onClick={clearDescriptionFilter}
                    disabled={fieldsDisabled}
                >
                    {filterDescription && filterDescription.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick} onSearch={onSearchClick}>
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="transaction-filter-name"
                    fullWidth
                    value={filterName ?? ''}
                    label="Name"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'name', 'maxLength': 50, }}
                    InputProps={nameFilterProps}
                    onChange={onNameFilterChanged}
                    autoComplete="off"
                    data-cy="transaction-name-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="transaction-filter-description"
                    fullWidth
                    value={filterDescription ?? ''}
                    label="Description"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'description', 'maxLength': 50, }}
                    InputProps={descriptionFilterProps}
                    onChange={onDescriptionFilterChanged}
                    autoComplete="off"
                    data-cy="transaction-description-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
                <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                    <InputLabel id="transaction-filter-standard-label">EDI Standard</InputLabel>
                    <Select
                        labelId="transaction-filter-standard-label"
                        aria-labelledby="transaction-filter-standard-label"
                        id="transaction-filter-standard"
                        multiple
                        value={filterStandardNames ?? []}
                        MenuProps={dropDownMenuProps}
                        onChange={onStandardFilterChanged}
                        renderValue={(selected) => selected.join(', ')}
                        data-cy="transaction-standard-filter"
                    >
                        {getStandardsFilterList()}
                    </Select>
                </FormControl>
            </Grid>
        </FilterBar>
    );
};

export default TransactionsFilterBar;