import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CircularProgress, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowModel, GridRenderCellParams, GridOverlay, GridSortModel, GridRowParams } from '@mui/x-data-grid-pro';
import { TabContainer, TabToolbar, TabContent, TabDataGridNoRowHover } from '../../util/SharedStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CreateNewButton from '../buttons/CreateNewButton';
import DeleteDialog from '../dialogs/DeleteDialog';
import PricingItemDialog from '../dialogs/PricingItemDialog';
import NoRecordsMessage from '../NoRecordsMessage';
import { setToastConfig } from '../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../util/Constants';
import { PricingItemModel, RequestResult, UserRole } from '../../gql-types.generated';
import {
    clearError,
    captureUpsertPricingItemStatus,
    captureDeletePricingItemStatus,
    selectError,
    selectDeletePricingItemStatus,
    selectUpsertPricingItemStatus,
} from '../../features/PricingDetails/PricingDetailsSlice';
import { deletePricingItem, upsertPricingItem } from '../../features/PricingDetails/PricingDetailsActions';


interface PricingItemsListProps {
    viewerRole: UserRole | undefined;
    pricingId: string;
    pricingCurrencyCode: string;
    pricingItems: PricingItemModel[] | undefined;
    refreshPricingItems: () => void;
}

const PricingItemsList: React.FC<PricingItemsListProps> = props => {
    const { viewerRole, pricingId, pricingCurrencyCode, pricingItems, refreshPricingItems } = props;
    const dispatch = useAppDispatch();
    const [selectedPricingItem, setSelectedPricingItem] = useState<PricingItemModel | undefined>(undefined);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [pricingItemRows, setPricingItemRows] = useState<GridRowModel[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'maxNumberOfTransactions',
            sort: 'asc',
        },
    ]);

    const upsertPricingItemStatus = useAppSelector(selectUpsertPricingItemStatus);
    const deletePricingItemStatus = useAppSelector(selectDeletePricingItemStatus);

    const error = useAppSelector(selectError);

    useEffect(() => {
        setPricingItemRows(getPricingItemRows());
    }, [pricingItems]);

    useEffect(() => {
        // fetch list when a successful mutation occurs
        if (upsertPricingItemStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertPricingItemStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status and close dialog
            dispatch(captureUpsertPricingItemStatus());
            onPricingItemDialogClose();
        }
        if (deletePricingItemStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deletePricingItemStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deletePricingItemStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deletePricingItemStatus.message as string);
        }
    }, [upsertPricingItemStatus?.result, deletePricingItemStatus?.result]);

    const getSelectedRowItem = useCallback((rowId: string) => () => {
        if (rowId && pricingItems?.length) {
            let item = pricingItems.find(bi => bi.id === rowId);
            return item;
        }
        return undefined;
    }, [pricingItems]);

    const deleteItemHandler = useCallback((rowId: string) => () => {
        let item = getSelectedRowItem(rowId);
        if (item) {
            setSelectedPricingItem(item);
            setOpenDelete(true);
        }
    }, [getSelectedRowItem]);

    const editItemHandler = useCallback((rowId: string) => () => {
        let item = getSelectedRowItem(rowId);
        if (item) {
            setSelectedPricingItem(item);
            dispatch(clearError());
            setOpenModify(true);
        }
    }, [getSelectedRowItem, dispatch]);

    const pricingItemColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'maxNumberOfTransactions',
                headerName: 'MAX TRANSACTIONS',
                minWidth: 180,
                sortable: true,
            }, {
                field: 'feePerDocument',
                headerName: 'FEE PER DOCUMENT',
                minWidth: 180,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    return (
                        value.toFixed(3)
                    );
                },
            }, {
                field: 'feePerLine',
                headerName: 'FEE PER LINE',
                minWidth: 180,
                flex: 1,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    return (
                        value.toFixed(3)
                    );
                },
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                headerName: '',
                resizable: false,
                width: 120,
                headerAlign: 'center',
                align: 'right',
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editItemHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        onClick={deleteItemHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],
            },
        ],
        [editItemHandler, deleteItemHandler],
    );

    const getPricingItemRows = () => {
        if (pricingItems && pricingItems.length > 0) {
            return pricingItems.map((pricingItem: PricingItemModel) => {
                const { id, maxNumberOfTransactions, feePerDocument, feePerLine } = pricingItem;
                return {
                    _raw: pricingItem,
                    id,
                    maxNumberOfTransactions,
                    feePerDocument,
                    feePerLine,
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'pricingItems-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <Typography variant="body2">
                        Unable to load data. Please try again later.
                    </Typography>
                )}
                {!error && pricingItems?.length === 0 && (
                    <NoRecordsMessage topMargin={6} message="" />
                )}
            </GridOverlay>
        );
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const isExistingMaxTransactionNumber = (maxNumTrans: number) => {
        let item = pricingItems?.find(bi => bi.maxNumberOfTransactions === maxNumTrans);
        return item ? true : false;
    };

    const onPricingItemDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeletePricingItemStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and PricingItem on close.
        dispatch(clearError());
        setSelectedPricingItem(undefined);
        // Refresh list from parent to bring in potential updates
        refreshPricingItems();
    };

    const onAddPricingItemClick = () => {
        // make sure we don't pass old item info
        setSelectedPricingItem(undefined);
        setOpenModify(true);
    };

    const onPricingItemDialogSave = (
        maxNumberOfTransactions: number,
        feePerDocument: number,
        feePerLine: number,
        id?: string,
    ) => {

        dispatch(upsertPricingItem(
            pricingId,
            maxNumberOfTransactions,
            feePerDocument,
            feePerLine,
            id,
        ));
    };

    const onDeleteDialogConfirm = () => {
        // delete the selected pricing item
        dispatch(deletePricingItem(selectedPricingItem?.id as string));
    };

    return (
        <TabContainer>
            <TabToolbar justify="flex-end">
                <CreateNewButton
                    text="New Price"
                    onClick={onAddPricingItemClick}
                    data-cy="add-new-price"
                />
            </TabToolbar>
            <TabContent>
                <TabDataGridNoRowHover
                    loading={pricingItems === undefined}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="Transaction Pricing List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={pricingItemRows}
                    columns={pricingItemColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />
            </TabContent>

            <PricingItemDialog
                isOpen={openModify}
                currencyCode={pricingCurrencyCode}
                pricingItem={selectedPricingItem}
                findExistingMaxTransaction={isExistingMaxTransactionNumber}
                onClose={onPricingItemDialogClose}
                onSave={onPricingItemDialogSave}
                error={error}
            />

            <DeleteDialog
                isOpen={openDelete}
                id={selectedPricingItem?.id ?? ''}
                heading={'Delete Price'}
                message={'Are you sure you want to delete this price?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </TabContainer>
    );
}

export default PricingItemsList;