import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CircularProgress, Typography, styled } from '@mui/material';
import { GridColumns, GridRowModel, GridOverlay, GridSortModel } from '@mui/x-data-grid-pro';
import { TabContainer, TabContent, TabDataGridNoRowHover } from '../../util/SharedStyles';
import NoRecordsMessage from '../NoRecordsMessage';
import { PortalUserModel } from '../../gql-types.generated';
import { selectError, selectClientPortalUsers } from '../../features/ClientDetails/ClientDetailsSlice';
import { fetchClientPortalUsers } from '../../features/ClientDetails/ClientDetailsActions';

const TabContainerWithMargin = styled(TabContainer)((props) => ({
    marginTop: '16px'
}));

interface PortalUserListProps {
    tenantId: string;
}

const ClientPortalUserList: React.FC<PortalUserListProps> = props => {
    const { tenantId } = props;
    const dispatch = useAppDispatch();
    const [portalUserRows, setPortalUserRows] = useState<GridRowModel[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
            {
                field: 'lastName',
                sort: 'asc',
            }
        ]);

    const portalUsers = useAppSelector(selectClientPortalUsers);
    
    const error = useAppSelector(selectError);

    useEffect(() => {
        if (tenantId) {
            dispatch(fetchClientPortalUsers(tenantId));  
        }
    }, [tenantId]);

    useEffect(() => {
        setPortalUserRows(getPortalUserRows());
    }, [portalUsers]);

   
    const getPortalUserRows = () => {
        if (portalUsers && portalUsers.length > 0) {
            return portalUsers.map((userRow: PortalUserModel) => {
                const { id, firstName, lastName, email, createdTime } = userRow;
                
                return {
                    _raw: userRow,
                    id,
                    firstName, 
                    lastName,
                    email, 
                    createdTime, 
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const portalUsersColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'firstName',
                headerName: 'FIRST NAME',
                minWidth: 100,
                flex: 1,
                sortable: true,
            }, {
                field: 'lastName',
                headerName: 'LAST NAME',
                minWidth: 100,
                flex: 1,
                sortable: true,
            }, {
                field: 'email',
                headerName: 'EMAIL',
                minWidth: 100,
                flex: 1,
                sortable: true,
            }, 
        ],
        [],
    );

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'portalUsers-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <Typography variant="body2">
                        Unable to load data. Please try again later.
                    </Typography>
                )}
                {!error && portalUsers?.length === 0 && (
                    <NoRecordsMessage topMargin={6} message="" />
                )}
            </GridOverlay>
        );
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    return (
        <TabContainerWithMargin>
            <TabContent>
                <TabDataGridNoRowHover
                    loading={portalUsers === undefined}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="Client Portal User List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={portalUserRows}
                    columns={portalUsersColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />
            </TabContent>

        </TabContainerWithMargin>
    );
}

export default ClientPortalUserList;