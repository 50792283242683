import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Checkbox, CircularProgress, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowModel, GridRenderCellParams, GridOverlay, GridSortModel, GridRowParams } from '@mui/x-data-grid-pro';
import { TabContainer, TabToolbar, TabContent, TabDataGridNoRowHover } from '../../util/SharedStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from "@mui/icons-material/Download";
import CreateNewButton from '../buttons/CreateNewButton';
import NoRecordsMessage from '../NoRecordsMessage';
import { setToastConfig } from '../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../util/Constants';
import { BlobStorageModel, BlobUploadRequestInput, RequestResult, UserRole } from '../../gql-types.generated';
import { captureUploadFileVersionStatus, clearError, selectError, selectUploadFileVersionStatus } from '../../features/StandardExchangeFormatFileDetails/StandardExchangeFormatFileDetailsSlice';
import { downloadStandardExchangeFormatFile } from '../../features/StandardExchangeFormatFiles/StandardExchangeFormatFilesActions';
import StandardExchangeFormatFileDialog from '../dialogs/StandardExchangeFormatFileDialog';
import { getShortDateString, getTimeString } from '../../util/DateTimeUtility';
import GridCellDualVert from '../listItems/GridCellDualVert';
import { captureDownloadSefFileStatus, selectDownloadSefFileStatus } from '../../features/StandardExchangeFormatFiles/StandardExchangeFormatFilesSlice';
import { downloadDocument } from '../../util/Common';
import { uploadFileVersion } from '../../features/StandardExchangeFormatFileDetails/StandardExchangeFormatFileDetailsActions';



interface SefFileVersionListProps {
    viewerRole: UserRole | undefined;
    blobDocumentId: string;
    fileVersions: BlobStorageModel[] | undefined;
    refreshFileList: () => void;
}

const SefFileVersionList: React.FC<SefFileVersionListProps> = props => {
    const { viewerRole, blobDocumentId, fileVersions, refreshFileList } = props;
    const dispatch = useAppDispatch();
    const [selectedFileVersion, setSelectedFileVersion] = useState<BlobStorageModel | undefined>(undefined);
    const [openModify, setOpenModify] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [fileVersionRows, setFileVersionRows] = useState<GridRowModel[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'lastModifiedDate',
            sort: 'desc',
        },
    ]);

    const uploadFileVersionStatus = useAppSelector(selectUploadFileVersionStatus);
    const downloadFileStatus = useAppSelector(selectDownloadSefFileStatus);
    
    const error = useAppSelector(selectError);

    useEffect(() => {
        setFileVersionRows(getFileVersionRows());
    }, [fileVersions]);

    useEffect(() => {
        // fetch list when a successful mutation occurs
        if (uploadFileVersionStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: uploadFileVersionStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status and close dialog
            dispatch(captureUploadFileVersionStatus());
            onFileVersionDialogClose();
        }
    }, [uploadFileVersionStatus?.result]);

    const onDownloadSuccess = useCallback(() => {
        if (downloadFileStatus?.data) {
            // actionPayload data returns BlobDownloadResponse as a stringified JSON object, so need to parse it
            // to get the actual data and then download
            // BlobDownloadResponse is not a generated type on the frontend, so if would ever change
            // on the backend, we would need to update this code to match the new response
            let parsedData = JSON.parse(downloadFileStatus.data);
            downloadDocument(parsedData.FileContent, parsedData.BlobFileName, "text/plain", true);
        } 
        resetDownloadStatus();
    }, [downloadFileStatus?.data]);

    useEffect(() => {
        if (downloadFileStatus?.result === RequestResult.Success) {
            onDownloadSuccess();
        }
        else if (downloadFileStatus?.result === RequestResult.Fail) {
            dispatch(setToastConfig({
                message: downloadFileStatus.message as string,
                severity: ToastSeverity.Error
            }));
            resetDownloadStatus();        
        }
    }, [downloadFileStatus?.result, onDownloadSuccess])

    const resetDownloadStatus = () => {
        // set processing flag back to false
        setIsDownloading(false);
        // set selected file back to undefined
        setSelectedFileVersion(undefined);
        // remove status
        dispatch(captureDownloadSefFileStatus());
    };

    const getSelectedRowItem = useCallback((rowId: string) => () => {
        if (rowId && fileVersions?.length) {
            let item = fileVersions.find(bi => bi.id === rowId);
            return item;
        }
        return undefined;
    }, [fileVersions]);    

    const editItemHandler = useCallback((rowId: string) => () => {
        let item = getSelectedRowItem(rowId);
        if (item) {
            setSelectedFileVersion(item);
            dispatch(clearError());
            setOpenModify(true);
        }
    }, [getSelectedRowItem, dispatch]);

    const isActiveCheckboxHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>, rowId: string) => () => {
        const isChecked = event.target.checked;
        if (isChecked) {
            let item = getSelectedRowItem(rowId);
            if (item) {
                setSelectedFileVersion(item);
                // TODO: add logic to update the active version once the API is available
            }
        }
        
    }, [getSelectedRowItem, dispatch]);

    const onDownload = (blobDocumentId: string, blobFileName?: string) => {
        setIsDownloading(true);
        dispatch(downloadStandardExchangeFormatFile(blobDocumentId,blobFileName));
    };

    const downloadHandler = useCallback((blobFileName: string, blobDocumentId: string) => {
        if (blobDocumentId?.length && blobFileName?.length) {
            onDownload(blobDocumentId, blobFileName);
        }
    }, []);
    
    const getFileVersionRows = () => {
        if (fileVersions && fileVersions.length > 0) {
            return fileVersions.map((fileVersion: BlobStorageModel) => {
                const { id, version, isActive, comment, lastModifiedTime, lastModifiedByName, blobFileName, blobStorageReference } = fileVersion;
                return {
                    _raw: fileVersion,
                    id,
                    version, 
                    isActive,
                    comment, 
                    lastModifiedTime, 
                    lastModifiedByName,
                    blobFileName,
                    blobDocumentId: blobStorageReference?.blobDocumentId,
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const fileVersionColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                headerName: 'VERSION',
                field: 'version',
                flex: 1,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild",
            },
            {
                headerName: 'COMMENT',
                field: 'comment',
                flex: 3,
                sortable: false,
            },
            {
                headerName: 'LAST MODIFIED',
                field: 'lastModifiedTime',
                flex: 1,
                sortable: true,
                type: 'dateTime',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let dateValue = value ? getShortDateString(value) : undefined;
                    let timeValue = value ? getTimeString(value, { includeSeconds: true }) : undefined;
                    return (
                        <GridCellDualVert header={dateValue} sub={timeValue} />
                    )
                },
            },
            {
                headerName: 'LAST MODIFIED BY',
                field: 'lastModifiedByName',
                flex: 1,
                sortable: false,
            },
            {
                headerName: 'IS ACTIVE',
                field: 'isActive',
                flex: 1,
                headerAlign: 'center',
                align: 'center',
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const { value, row } = params;
                    if (value) {
                        return <div ><CheckCircleIcon color="success" /></div>
                    }
                    return (<div></div>);
                    // TODO: add logic to update the active version once the API is available
                    // return (
                    //     <Checkbox
                    //         checked={Boolean(value)}
                    //         onChange={(event) => isActiveCheckboxHandler(event, row.id)()}
                    //         disabled={value === true}
                    //         aria-label={`Is Active checkbox for row ${row.id}`}
                    //     />
                    // );
                },

            },
            {
                field: 'id',
                headerName: 'FILE',
                minWidth: 80,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params: GridRenderCellParams) => {
                    const { row } = params;
                    const { blobFileName, blobDocumentId } = row;

                    return (
                        <GridActionsCellItem
                            label="Download"
                            color="primary"
                            onClick={() => downloadHandler(blobFileName, blobDocumentId)}
                            icon={<Tooltip title="Download"><DownloadIcon /></Tooltip>}
                        />
                    );
                }
            },
        ],
        [editItemHandler],
    );

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'fileVersions-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <Typography variant="body2">
                        Unable to load data. Please try again later.
                    </Typography>
                )}
                {!error && fileVersions?.length === 0 && (
                    <NoRecordsMessage topMargin={6} message="" />
                )}
            </GridOverlay>
        );
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const onAddFileVersionClick = () => {
        // make sure we don't pass old item info
        setSelectedFileVersion(undefined);
        setOpenModify(true);
    };

    const onFileVersionDialogSave = (versionFileDetails: BlobUploadRequestInput) => {
        dispatch(uploadFileVersion(versionFileDetails));
    };

    const onFileVersionDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDialogClose = () => {
        // Clear error and FileVersion on close.
        dispatch(clearError());
        setSelectedFileVersion(undefined);
        // Refresh list from parent to bring in potential updates
        refreshFileList();
    };

    return (
        <TabContainer>
            <TabToolbar justify="flex-end">
                <CreateNewButton
                    text="New Version"
                    onClick={onAddFileVersionClick}
                    data-cy="add-new-version"
                />
            </TabToolbar>
            <TabContent>
                <TabDataGridNoRowHover
                    loading={fileVersions === undefined}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="SEF File Versions List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={fileVersionRows}
                    columns={fileVersionColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />
            </TabContent>

            <StandardExchangeFormatFileDialog
                isOpen={openModify}
                storageFile={fileVersions ? fileVersions[0] : undefined}
                onClose={onFileVersionDialogClose}
                onSave={onFileVersionDialogSave}
                error={error}
                viewerRole={viewerRole}
            />
            {(isDownloading) &&
                <CircularProgress
                    aria-label={'progress spinner'}
                    size={48}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-24px',
                        marginLeft: '-24px',
                        zIndex: 1500
                    }}
                />
            }
        </TabContainer>
    );
}

export default SefFileVersionList;