import { useCallback, useEffect, useState } from 'react'; 
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { styled, Button, Grid, Link, Typography } from '@mui/material';
import { Viewer } from "../../util/Constants";
import { useTitle } from '../../util/Common';
import { clearState, selectError, selectErrorLog, captureUpdateLogStatus, selectUpdateLogStatus, selectErrorLogNotes, selectUpsertErrorLogNoteStatus, selectDeleteErrorLogNoteStatus, clearError, captureUpsertErrorLogNoteStatus, captureDeleteErrorLogNoteStatus, selectUpdateLogCategoryStatus, captureUpdateErrorLogCategoryStatus } from './ErrorLogDetailsSlice';
import { deleteErrorLogNote, fetchErrorLogById, updateTransactionErrorCategory, updateTransactionErrorStatus, upsertErrorLogNote } from './ErrorLogDetailsActions';
import { setLocalStorageItem, viewerCanEdit } from '../../util/ViewerUtility';
import { getFormattedDateTimeString } from '../../util/DateTimeUtility';
import { DetailsTabContainer, DetailsTabHeader, DetailsTabHeaderWrapper, DetailsTabPanelContainer, CardAvatar, TabContainer, TabHeader, TabLabel, CountBox, PaddedDialogContent } from '../../util/SharedStyles';
import TabPanel from '../../components/TabPanel';
import DetailsPage from '../../components/DetailsPage';
import ErrorIcon from '@mui/icons-material/Error';
import { ExceptionStatus, NoteModel, RequestResult, UpdateTransactionErrorCategoryInput, UpsertNoteInput } from '../../gql-types.generated';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../util/Constants';
import NotesList from '../../components/lists/NotesList';
import EditErrorLogDialog from '../../components/dialogs/EditErrorLogDialog';
import EditButton from '../../components/buttons/EditButton';
import ErrorLogResolveDetailsDialog from '../../components/dialogs/ErrorLogResolveDetailsDialog';

const TabContainerWithPadding = styled(TabContainer)(() => ({
    padding: '20px',
    overflowY: 'auto',
}));

const TypographyWithReturns = styled(Typography)((props) => ({
    whiteSpace: 'pre-line',
}));

interface ErrorLogDetailsProps {
    viewer: Viewer | undefined;
}

const ErrorLogDetails: React.FC<ErrorLogDetailsProps> = (props) => {
    // errorId will be passed in through router
    const { errorId: errorLogId } = useParams();
    const {viewer} = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const [verticalAlign, setVerticalAlign] = useState<boolean>(false);
    const [openModify, setOpenModify] = useState(false);
    const [openResolveNoteDialog, setOpenResolveNoteDialog] = useState(false);
    
    // detailsPageProps
    const [detailsRecordLoaded, setDetailsRecordLoaded] = useState<boolean>(false);
    const [detailsPageTitle, setDetailsPageTitle] = useState<string | undefined>(undefined);
    const [detailsAV, setDetailsAV] = useState<JSX.Element | undefined>(undefined);
    const [detailsToolbarButtons, setDetailsToolbarButtons] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsHeader, setDetailsHeader] = useState<string | undefined>(undefined);
    const [detailsEditButton, setDetailsEditButton] = useState<JSX.Element | undefined>(undefined);
    
    const error = useAppSelector(selectError);
    const errorLog = useAppSelector(selectErrorLog);
    const errorLogNotes = useAppSelector(selectErrorLogNotes);
    const upsertNoteStatus = useAppSelector(selectUpsertErrorLogNoteStatus);
    const deleteNoteStatus = useAppSelector(selectDeleteErrorLogNoteStatus);
    const updateStatus = useAppSelector(selectUpdateLogStatus);
    const updateCategoryStatus = useAppSelector(selectUpdateLogCategoryStatus);

    const canEdit = viewerCanEdit(viewer);

    const localStorageTabIndexName = "errorLogDetailsTabIndex_" + errorLogId;

    useEffect(() => {
        if (errorLogId) {
            dispatch(fetchErrorLogById(errorLogId));
        }
        return () => {
            dispatch(clearState());
        }
    },[]);

    useEffect(() => {
        // refresh log when a successful mutation occurs
        if (updateCategoryStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: updateCategoryStatus.message as string,
                severity: ToastSeverity.Success
            }));
            
            // close the modify dialog
            onEditDialogClose();
            
            refreshLogData();
        }
    }, [updateCategoryStatus?.result]);

    useTitle(detailsPageTitle);
 

    const getErrorAV = useCallback(() => () => {
        if (errorLog?.status) {
            if (errorLog.status === ExceptionStatus.Error) {
                return (
                    <CardAvatar aria-label="error" alt="error">
                        <ErrorIcon />
                    </CardAvatar>
                );
            }
        }
    }, [errorLog?.status]);

    const getToolbarButtons = useCallback(() => () => {
        if (errorLog?.status && canEdit) {
            let buttonText = errorLog.status === ExceptionStatus.Error ? 'Resolve Error' : 'Unresolve Error';
            return (
                [<Button
                    variant="outlined"
                    color="primary"
                    onClick={onUpdateErrorStatus}
                    key="resolve-error-button"
                >
                    {buttonText}
                </Button>]
            );
        }
        return undefined;
    }, [errorLog?.status, canEdit]);

    useEffect(() => {
        setDetailsRecordLoaded(!!errorLog);        
        if (errorLog) {
            if (errorLog.status) {
                setDetailsAV(getErrorAV());                
                setDetailsHeader(errorLog.status.replaceAll('_', ' ')); 
                setDetailsToolbarButtons(getToolbarButtons());
            }
            setDetailsPageTitle(errorLogId);
            if (canEdit) {
                setDetailsEditButton(<EditButton
                    text="Edit Category"
                    title="Modify the Category"
                    onClick={onEditClick}
                    ariaLabel="Edit Category button"
                    data-cy="edit-category-button"
                />);
            } else {
                setDetailsEditButton(undefined);
            }
        }
    }, [errorLog, errorLogId, error, canEdit, getErrorAV, getToolbarButtons]);

    useEffect(() => {
        if (updateStatus?.result) {
            if (updateStatus.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: updateStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            } else if (updateStatus.result === RequestResult.Fail) {
                dispatch(setToastConfig({
                    message: updateStatus.message as string,
                    severity: ToastSeverity.Error
                }));
            }
            dispatch(captureUpdateLogStatus());
            // close the note dialog and refresh
            setOpenResolveNoteDialog(false);
            refreshLogData();
        }
    }, [updateStatus?.result]);

    useEffect(() => {
        if (upsertNoteStatus?.result === RequestResult.Success || deleteNoteStatus?.result === RequestResult.Success) {
            if (upsertNoteStatus?.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: upsertNoteStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }
            if (deleteNoteStatus?.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: deleteNoteStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }
            dispatch(captureUpsertErrorLogNoteStatus());
            dispatch(captureDeleteErrorLogNoteStatus());
            refreshLogData();
        }
    }, [upsertNoteStatus?.result, deleteNoteStatus?.result]);

    const handleClearError = () => {
        dispatch(clearError());
    };

    const refreshLogData = () => {
        dispatch(fetchErrorLogById(errorLogId as string));
    };

    const onUpdateErrorStatus = () => {
        setOpenResolveNoteDialog(true);
    };

    const onResolveNoteDialogSave = (note: string) => {
        dispatch(captureUpdateLogStatus());
        if (errorLogId && errorLog && note) {
            // a note was provided, meaning valid for updating status now
            let updateStatus = errorLog.status === ExceptionStatus.Error ? ExceptionStatus.ResolvedError : ExceptionStatus.Error;
            dispatch(updateTransactionErrorStatus({transactionErrorIds: [errorLogId], status: updateStatus, note: note}));
        }
    };

    const onResolveNoteDialogCancel = () => {
        // if entering resolve details is canceled, close the dialog
        // and don't update the status
        setOpenResolveNoteDialog(false);
        handleClearError();
    };

    const onNoteUpsert = (note: NoteModel) => {
        if (note && errorLogId) {
            dispatch(
                upsertErrorLogNote(
                    {
                        parentId: errorLogId,
                        id: note.id,
                        header: note.header,
                        note: note.note
                    } as UpsertNoteInput
                )
            );
        }
    };

    const onNoteDelete = (id: string) => {
        if (id) {
            dispatch(deleteErrorLogNote(id));
        }
    };

    const updateAlignment = (verticalAlign: boolean) => {
        setVerticalAlign(verticalAlign);
    };

    const handleTabChange = (error: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        // save off the latest selected tab so that if user navigates to another
        // screen and comes back, their last active tab is remembered
        setLocalStorageItem(viewer, localStorageTabIndexName, newValue.toString());
    };

    const onClientNameClick = (id: string | undefined) => {
        if (id) {
            let route = "/client/" + id;
            navigate(route);
        }
    };

    const onPartnerNameClick = (id: string | undefined) => {
        if (id) {
            let route = "/partner/" + id;
            navigate(route);
        }
    };

    const onDocumentTypeClick = (clientPartnerId: string | undefined) => {
        // navigate to the clientPartner to allow the user to view the associated transaction/documentType
        if (clientPartnerId) {
            let route = "/clientpartner/" + clientPartnerId;
            navigate(route);
        }
    };

    //edit category of current log
    const onEditClick = () => {
        setOpenModify(true);
    };

    const onEditDialogSave = (
        categoryInput: UpdateTransactionErrorCategoryInput
    ) => {
        dispatch(
            updateTransactionErrorCategory(categoryInput)
        );
    };

    const onEditDialogClose = () => {
        setOpenModify(false);
        handleClearError();
        dispatch(captureUpdateErrorLogCategoryStatus());
    };

    const getLogError = () => {
        if (errorLog && errorLog.rawMessage) {
            let parsed = JSON.parse(errorLog.rawMessage);
            if (parsed) {
                let parsedEntries = Object.entries(parsed);
                if (parsedEntries) {
                    return (
                        <TabContainerWithPadding>
                            <Grid container alignItems="flex-start">
                                <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                                    {parsedEntries.map(([key, value], index) => (
                                        <Grid item xs={1} key={`error-property-${index}`}>
                                            <Typography variant='caption' >{key}</Typography>
                                            <TypographyWithReturns variant='body1' >{value as string}</TypographyWithReturns>
                                        </Grid>
                                    ))}
                                </ Grid>
                            </ Grid>
                        </TabContainerWithPadding>
                    );
                }
            }
        }
    };

    const getDocumentTypeDisplay = () => {
        let docType = errorLog?.transaction?.name;
        if (errorLog && docType) {
            let clientPartnerId = errorLog.clientPartner?.id;
            if (clientPartnerId) {
                return (
                    <div>
                        <Link
                            component="button"
                            variant="body1"
                            onClick={() => {
                                onDocumentTypeClick(clientPartnerId);
                            }}
                        >
                            {docType}
                        </Link>
                    </div>
                );
                
            } else {
                return (
                    <Typography variant='body1' >{docType}</Typography>
                )
            }
        }
    };

    const getLogDetails = () => {
        if (errorLog) {
            let clientName = errorLog.client?.name;
            let partnerName = errorLog.partner?.name;
            let direction = errorLog.transaction?.direction;
            let documentType = errorLog.transaction?.name;
            return (
                <Grid container alignItems="flex-start">
                    <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                        {clientName && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Client</Typography>
                                <div>
                                    <Link
                                        component="button"
                                        variant="body1"
                                        onClick={() => {
                                            onClientNameClick(errorLog.client?.id);
                                        }}
                                    >
                                        {clientName}
                                    </Link>
                                </div>
                            </Grid>
                        )}
                        {partnerName && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Partner</Typography>
                                <div>
                                    <Link
                                        component="button"
                                        variant="body1"
                                        onClick={() => {
                                            onPartnerNameClick(errorLog.partner?.id);
                                        }}
                                    >
                                        {partnerName}
                                    </Link>
                                </div>
                            </Grid>
                        )}
                        {errorLog.createdTime && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Time Reported</Typography>
                                <Typography variant='body1' >{getFormattedDateTimeString(errorLog.createdTime)}</Typography>
                            </Grid>
                        )}
                        {errorLog.resolvedTime && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Time Resolved</Typography>
                                <Typography variant='body1' >{getFormattedDateTimeString(errorLog.resolvedTime)}</Typography>
                            </Grid>
                        )}
                        {errorLog.resolvedByName && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Resolved By</Typography>
                                <Typography variant='body1' >{errorLog.resolvedByName}</Typography>
                            </Grid>
                        )}
                        {direction && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Direction</Typography>
                                <Typography variant='body1' >{direction}</Typography>
                            </Grid>
                        )}
                       {errorLog.processName && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Process</Typography>
                                <Typography variant='body1' >{errorLog.processName}</Typography>
                            </Grid>
                        )}
                        {errorLog.description && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Description</Typography>
                                <Typography variant='body1' >{errorLog.description}</Typography>
                            </Grid>
                        )}
                        {errorLog.documentId && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Document Id</Typography>
                                <Typography variant='body1' >{errorLog.documentId}</Typography>
                            </Grid>
                        )}
                        {documentType && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Document Type</Typography>
                                <Typography variant='body1' >{getDocumentTypeDisplay()}</Typography>
                            </Grid>
                        )}
                        {errorLog.documentReferenceId && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Document Reference Id</Typography>
                                <Typography variant='body1' >{errorLog.documentReferenceId}</Typography>
                            </Grid>
                        )}
                        {errorLog.originalFileName && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Original File Name</Typography>
                                <Typography variant='body1' >{errorLog.originalFileName}</Typography>
                            </Grid>
                        )}
                        {errorLog.purchaseOrderNumber && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Purchase Order(s)</Typography>
                                <Typography variant='body1' >{errorLog.purchaseOrderNumber}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )
        }
    }

    let notesLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Notes</TabLabel>
            {errorLogNotes && errorLogNotes.length > 0 && (
                <CountBox>{errorLogNotes.length}</CountBox>
            )}
        </Grid>
    );

    const getDialogs = () => [
        <EditErrorLogDialog
            isOpen={openModify}
            errorLogInfo={errorLog}
            onClose={onEditDialogClose}
            onSave={onEditDialogSave}
            error={error}
            key="edit-error-log-dialog"
        />,
        <ErrorLogResolveDetailsDialog
            isOpen={openResolveNoteDialog} 
            logStatus={errorLog?.status === ExceptionStatus.ResolvedError ? ExceptionStatus.ResolvedError : ExceptionStatus.Error} 
            onCancel={onResolveNoteDialogCancel} 
            onSave={onResolveNoteDialogSave} 
        />
    ];

    

    const getTabbedContainer = () => {
        return (
            <DetailsTabContainer>
                <DetailsTabHeaderWrapper>
                    <DetailsTabHeader
                        value={tabValue}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable error tabs"
                    >
                        <TabHeader label={errorLog?.category?.name ?? 'Error'} id='vertical-tab-error-log-details-error' />
                        <TabHeader label={notesLabel} id='vertical-tab-error-log-details-notes' />
                    </DetailsTabHeader>
                </DetailsTabHeaderWrapper>
                <DetailsTabPanelContainer>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={0}>
                        {getLogError()}
                    </TabPanel>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={1}>
                        <NotesList
                            viewer={viewer}
                            notes={errorLogNotes}
                            saveNote={onNoteUpsert}
                            deleteNote={onNoteDelete}
                            upsertNoteResult={upsertNoteStatus?.result}
                            deleteNoteStatus={deleteNoteStatus}
                            error={error}
                            clearError={handleClearError}
                        />
                    </TabPanel>
                </DetailsTabPanelContainer>
            </DetailsTabContainer>
        );
    }

    return (
        <DetailsPage
            recordLoaded={detailsRecordLoaded}
            pageTitle={detailsPageTitle}
            detailsAV={detailsAV}
            detailsHeader={detailsHeader}
            detailsSubHeader={undefined}
            detailsChips={undefined}
            getDetailsListContainer={getLogDetails}
            getTabbedContainer={getTabbedContainer}
            error={error}
            updateAlignment={updateAlignment}
            toolBarButtons={detailsToolbarButtons}
            detailsEditButton={detailsEditButton}
            detailsFavoriteButton={undefined}
            getDialogs={getDialogs}
        />
    );
}

export default ErrorLogDetails;