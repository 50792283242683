import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpdateTransactionErrorsInput, UpdateTransactionErrorsPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPDATE_TRANSACTION_ERROR_STATUS = gql`
    mutation updateTransactionErrors($input: UpdateTransactionErrorsInput!){
        updateTransactionErrors(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpdateTransactionErrorStatus = async (
    client: ApolloClient<NormalizedCacheObject>,
    updateData: UpdateTransactionErrorsInput
): Promise<UpdateTransactionErrorsPayload | undefined> => {
    const { transactionErrorIds, status, note } = updateData;

    const { data, errors } = await client.mutate<{ updateTransactionErrors: UpdateTransactionErrorsPayload }>({
        variables: {
            input: {
                transactionErrorIds,
                status,
                note
            }
        },
        mutation: MUTATION_UPDATE_TRANSACTION_ERROR_STATUS
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the updating of the transaction error(s) and saving the note.');
    }
    if (data && data.updateTransactionErrors) {
        return data.updateTransactionErrors;
    }
    return undefined;
};