import { TransactionErrorModel, ActionPayload, NoteModel, DeleteByIdPayload, UpsertNotePayload, UpdateTransactionErrorCategoryPayload, UpdateTransactionErrorsPayload } from "../../gql-types.generated";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface SliceState {
    errorLog?: TransactionErrorModel;
    errorLogNotes?: NoteModel[];
    error?: Error;
    updateLogStatus?: UpdateTransactionErrorsPayload;
    updateLogCategoryStatus?: UpdateTransactionErrorCategoryPayload;
    upsertLogNoteStatus?: UpsertNotePayload;
    deleteLogNoteStatus?: DeleteByIdPayload;
}

const initialState: SliceState = {
    errorLog: undefined,
    errorLogNotes: undefined,
    error: undefined,
    updateLogStatus: undefined,
    updateLogCategoryStatus: undefined,
    upsertLogNoteStatus: undefined,
    deleteLogNoteStatus: undefined,
};

export const slice = createSlice({
    name: 'errorLogDetails',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.errorLog = undefined;
            // eslint-disable-next-line no-param-reassign
            state.errorLogNotes = undefined;
            // eslint-disable-next-line no-param-reassign
            state.updateLogStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.updateLogCategoryStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertLogNoteStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteLogNoteStatus = undefined;
        },

        fetchErrorLogByIdSuccess: (state, action: PayloadAction<TransactionErrorModel[]>) => {
            if (action.payload && action.payload[0]) {  
                let log = action.payload[0];        
                // eslint-disable-next-line no-param-reassign
                state.errorLog = log;

                // eslint-disable-next-line no-param-reassign
                state.errorLogNotes = log?.notes as NoteModel[];
            }
        },

        captureUpdateLogStatus: (state, action: PayloadAction<UpdateTransactionErrorsPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.updateLogStatus = action.payload;
        },

        captureUpdateErrorLogCategoryStatus: (state, action: PayloadAction<UpdateTransactionErrorCategoryPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.updateLogCategoryStatus = action.payload;
        },

        captureUpsertErrorLogNoteStatus: (state, action: PayloadAction<UpsertNotePayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertLogNoteStatus = action.payload;
        },

        captureDeleteErrorLogNoteStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteLogNoteStatus = action.payload;
        },
    }
});

export const selectError = (state: RootState): Error | undefined => state.errorLogDetails.error;
export const selectErrorLog = (state: RootState): TransactionErrorModel | undefined => state.errorLogDetails.errorLog;
export const selectErrorLogNotes = (state: RootState): NoteModel[] | undefined => state.errorLogDetails.errorLogNotes;
export const selectUpdateLogStatus = (state: RootState): UpdateTransactionErrorsPayload | undefined => state.errorLogDetails.updateLogStatus;
export const selectUpsertErrorLogNoteStatus = (state: RootState): UpsertNotePayload | undefined => state.errorLogDetails.upsertLogNoteStatus;
export const selectDeleteErrorLogNoteStatus = (state: RootState): DeleteByIdPayload | undefined => state.errorLogDetails.deleteLogNoteStatus;
export const selectUpdateLogCategoryStatus = (state: RootState): UpdateTransactionErrorCategoryPayload | undefined => state.errorLogDetails.updateLogCategoryStatus;

export const {
    fetchError,
    clearError,
    clearState,
    fetchErrorLogByIdSuccess,
    captureUpdateLogStatus,
    captureUpsertErrorLogNoteStatus,
    captureDeleteErrorLogNoteStatus,
    captureUpdateErrorLogCategoryStatus
} = slice.actions;

export default slice.reducer;