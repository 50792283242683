import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { DateTime } from 'luxon';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from "@mui/x-date-pickers";
import { Stack, Grid, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Checkbox, FormControlLabel, styled } from '@mui/material';
import { BlankMenuItem, FilterBarCheckbox } from '../../util/SharedStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Viewer } from "../../util/Constants";
import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } from '../../util/ViewerUtility';
import { fetchTradingPartnerNameSearchResults } from '../../features/TradingPartners/TradingPartnersActions';
import { fetchTradingPartnerNameSearchResultsSuccess, selectTradingPartnerNameSearchResults } from '../../features/TradingPartners/TradingPartnersSlice';
import FilterBar from "./FilterBar";
import AutocompleteFilterField from '../fields/AutocompleteFilterField';
import { NormalizedDocumentType } from '../../gql-types.generated';
import { capitalizeWithUnderscoreRemoval } from '../../util/Common';
import { fetchClientNameSearchResultsSuccess, selectClientNameSearchResults } from '../../features/Clients/ClientsSlice';
import { fetchClientNameSearchResults } from '../../features/Clients/ClientsActions';

const DatePickerWrapper = styled('div')((props) => ({
    width: '100%',
}));

interface PortalTransactionFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    autoSearch?: boolean;
    onClose: () => void;
    onFilterChanged: (
        filterClient?: string,
        filterOrderNum?: string,
        filterErpOrderNum?: string,
        filterPartner?: string,
        filterDocNum?: string,
        filterDocType?: string,
        filterNormalizedDocType?: NormalizedDocumentType,
        filterHasExceptions?: boolean,
        filterIsResent?: boolean,
        filterTransDateFrom?: DateTime,
        filterTransDateTo?: DateTime
    ) => void;
}

const PortalTransactionFilterBar: React.FC<PortalTransactionFilterBarProps> = props => {
    const { id, loading = false, visible = false, viewer, autoSearch = false, onFilterChanged, onClose } = props;
    const dispatch = useAppDispatch();
    const [filterClientName, setFilterClientName] = useState<string | undefined>();
    const [searchClientName, setSearchClientName] = useState<string>('');
    const [filterOrderNumber, setFilterOrderNumber] = useState<string | undefined>(undefined);
    const [filterErpOrderNumber, setFilterErpOrderNumber] = useState<string | undefined>(undefined);
    const [filterPartnerName, setFilterPartnerName] = useState<string | undefined>(undefined);
    const [searchPartnerName, setSearchPartnerName] = useState<string>('');
    const [filterDocumentNumber, setFilterDocumentNumber] = useState<string | undefined>(undefined);
    const [filterDocumentType, setFilterDocumentType] = useState<string | undefined>(undefined);
    const [filterNormalizedDocumentType, setFilterNormalizedDocumentType] = useState<NormalizedDocumentType | undefined>(undefined);
    const [filterHasException, setFilterHasException] = useState<string | undefined>(undefined);
    const [filterIsDocumentResent, setFilterIsDocumentResent] = useState<boolean | undefined>(undefined);
    const [filterDateFrom, setFilterDateFrom] = useState<DateTime | undefined>(undefined);
    const [filterDateTo, setFilterDateTo] = useState<DateTime | undefined>(undefined);
    
    const fieldsDisabled = visible && loading;

    const clientNameSearchResults = useAppSelector(selectClientNameSearchResults);
    const partnerNameSearchResults = useAppSelector(selectTradingPartnerNameSearchResults);

    useEffect(() => {
        setFromLocalStorage();
    }, [])

    const setFromLocalStorage = () => {
        clearFilters();
        let haveLocalStoredFilters = false;

        let clientName = getLocalStorageItem(viewer, "portalTransactionFilterClientName");
        if (clientName != null) {
            setFilterClientName(clientName);
            haveLocalStoredFilters = true;
        }

        let orderNum = getLocalStorageItem(viewer, "portalTransactionFilterOrderNum");
        if (orderNum != null) {
            setFilterOrderNumber(orderNum);
            haveLocalStoredFilters = true;
        }

        let erpOrderNum = getLocalStorageItem(viewer, "portalTransactionFilterErpOrderNum");
        if (erpOrderNum != null) {
            setFilterErpOrderNumber(erpOrderNum);
            haveLocalStoredFilters = true;
        }

        let docNum = getLocalStorageItem(viewer, "portalTransactionFilterDocNum");
        if (docNum != null) {
            setFilterDocumentNumber(docNum);
            haveLocalStoredFilters = true;
        }

        let partnerName = getLocalStorageItem(viewer, "portalTransactionFilterPartnerName");
        if (partnerName != null) {
            setFilterPartnerName(partnerName);
            haveLocalStoredFilters = true;
        }

        let type = getLocalStorageItem(viewer, "portalTransactionFilterDocType");
        if (type != null) {
            setFilterDocumentType(type);
            haveLocalStoredFilters = true;
        }

        let normalizedType = getLocalStorageItem(viewer, "portalTransactionFilterNormalizedDocType");
        let normalizedDocType = undefined;
        if (normalizedType != null) {
            normalizedDocType = normalizedType as NormalizedDocumentType;
            setFilterNormalizedDocumentType(normalizedDocType);
            haveLocalStoredFilters = true;
        }

        let hasExceptionsStorage = getLocalStorageItem(viewer, "portalTransactionFilterHasExceptions");
        let hasExceptions = undefined;
        if (hasExceptionsStorage != null) {
            hasExceptions = hasExceptionsStorage === "true" ? true : undefined
            setFilterHasException(hasExceptionsStorage);
            haveLocalStoredFilters = true;
        }

        let isResentStorage = getLocalStorageItem(viewer, "portalTransactionFilterIsResent");
        let isResent = undefined;
        if (isResentStorage != null) {
            // only want to set the filter if it is true, otherwise set to undefined to not filter
            isResent = isResentStorage === 'true' ? true : undefined
            setFilterIsDocumentResent(isResent);
            haveLocalStoredFilters = true;
        }

        let dateFrom = getLocalStorageItem(viewer, "portalTransactionFilterDateFrom");
        let dateFromAsDateTime = undefined;
        if (dateFrom != null) {
            dateFromAsDateTime = DateTime.fromISO(dateFrom);
            setFilterDateFrom(dateFromAsDateTime);
            haveLocalStoredFilters = true;
        }

        let dateTo = getLocalStorageItem(viewer, "portalTransactionFilterDateTo");
        let dateToAsDateTime = undefined;
        if (dateTo != null) {
            dateToAsDateTime = DateTime.fromISO(dateTo);
            setFilterDateTo(dateToAsDateTime);
            haveLocalStoredFilters = true;
        }

        if (haveLocalStoredFilters || autoSearch) {
            onFilterChanged(
                clientName ?? undefined,
                orderNum ?? undefined,
                erpOrderNum ?? undefined,
                partnerName ?? undefined,
                docNum ?? undefined,
                type ?? undefined,
                normalizedDocType,
                hasExceptions,
                isResent,
                dateFromAsDateTime,
                dateToAsDateTime
            );
        }
    };

    const clearAndRemoveLocalFilters = () => {
        removeLocalStorageItem(viewer, 'portalTransactionFilterClientName');
        removeLocalStorageItem(viewer, 'portalTransactionFilterOrderNum');
        removeLocalStorageItem(viewer, 'portalTransactionFilterErpOrderNum');
        removeLocalStorageItem(viewer, 'portalTransactionFilterDocNum');
        removeLocalStorageItem(viewer, 'portalTransactionFilterPartnerName');
        removeLocalStorageItem(viewer, 'portalTransactionFilterDocType');
        removeLocalStorageItem(viewer, 'portalTransactionFilterNormalizedDocType');
        removeLocalStorageItem(viewer, 'portalTransactionFilterHasExceptions');
        removeLocalStorageItem(viewer, 'portalTransactionFilterIsResent');
        removeLocalStorageItem(viewer, 'portalTransactionFilterDateFrom');
        removeLocalStorageItem(viewer, 'portalTransactionFilterDateTo');

        clearFilters();
    };

    const clearFilters = () => {
        clearClientNameFilter();
        clearOrderNumFilter();
        clearErpOrderNumFilter();
        clearDocNumFilter();
        clearPartnerNameFilter();
        clearDocTypeFilter();
        setFilterNormalizedDocumentType(undefined);
        setFilterHasException(undefined);
        setFilterIsDocumentResent(undefined);
        setFilterDateFrom(undefined);
        setFilterDateTo(undefined);
    };

    const clearOrderNumFilter = () => {
        setFilterOrderNumber(undefined);
    };

    const clearErpOrderNumFilter = () => {
        setFilterErpOrderNumber(undefined);
    };

    const clearDocNumFilter = () => {
        setFilterDocumentNumber(undefined);
    };

    const clearDocTypeFilter = () => {
        setFilterDocumentType(undefined);
    };

    const clearClientNameFilter = () => {
        setFilterClientName(undefined);
        setSearchClientName('');
        dispatch(fetchClientNameSearchResultsSuccess());
    };

    const clearPartnerNameFilter = () => {
        setFilterPartnerName(undefined);
        setSearchPartnerName('');
        dispatch(fetchTradingPartnerNameSearchResultsSuccess());
    };

    // Manual clears by adornment needs to adjust the local storage
    const clearOrderNumFilterByX = () => {
        setFilterOrderNumber(undefined);
        removeLocalStorageItem(viewer, 'portalTransactionFilterOrderNum');
    };

    const clearErpOrderNumFilterByX = () => {
        setFilterErpOrderNumber(undefined);
        removeLocalStorageItem(viewer, 'portalTransactionFilterErpOrderNum');
    };

    const clearDocNumFilterByX = () => {
        setFilterDocumentNumber(undefined);
        removeLocalStorageItem(viewer, 'portalTransactionFilterDocNum');
    };

    const clearPartnerNameFilterByX = () => {
        clearPartnerNameFilter();
        removeLocalStorageItem(viewer, 'portalTransactionFilterPartnerName');
    };

    const clearClientNameFilterByX = () => {
        clearClientNameFilter();
        removeLocalStorageItem(viewer, 'portalTransactionFilterClientName');
    };

    const clearDocTypeFilterByX = () => {
        setFilterDocumentType(undefined);
        removeLocalStorageItem(viewer, 'portalTransactionFilterDocType');
    };

    const onSearchClick = () => {
        onFilterChanged(
            filterClientName,
            filterOrderNumber,
            filterErpOrderNumber,
            filterPartnerName,
            filterDocumentNumber,
            filterDocumentType,
            filterNormalizedDocumentType,
            filterHasException === undefined ? undefined : filterHasException === 'true',
            filterIsDocumentResent === true ? true : undefined,
            filterDateFrom,
            filterDateTo
        );
    };

    const onCloseClick = () => {
        clearAndRemoveLocalFilters();
        onClose();
        onFilterChanged();
    };

    const onOrderNumFilterChange = (error: ChangeEvent<HTMLInputElement>) => {
        let value = error.target.value;
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'portalTransactionFilterOrderNum', value);
        }
        else {
            removeLocalStorageItem(viewer, 'portalTransactionFilterOrderNum');
        }
        setFilterOrderNumber(value);
    };

    const onErpOrderNumFilterChange = (error: ChangeEvent<HTMLInputElement>) => {
        let value = error.target.value;
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'portalTransactionFilterErpOrderNum', value);
        }
        else {
            removeLocalStorageItem(viewer, 'portalTransactionFilterErpOrderNum');
        }
        setFilterErpOrderNumber(value);
    };

    const onDocNumFilterChange = (error: ChangeEvent<HTMLInputElement>) => {
        let value = error.target.value;
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'portalTransactionFilterDocNum', value);
        }
        else {
            removeLocalStorageItem(viewer, 'portalTransactionFilterDocNum');
        }
        setFilterDocumentNumber(value);
    };

    const onClientNameSearch = (searchValue: string) => {
        // once the delay time has elapsed in the autocomplete component
        // the search will be triggered here to fetch client name search results
        dispatch(fetchClientNameSearchResults(searchValue));
    };

    const onClientNameSearchValueChange = (value: string): void => {
            setSearchClientName(value);
    };

    const onClientNameFilterChange = (value: string): void => {
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'portalTransactionFilterClientName', value);
        }
        else {
            removeLocalStorageItem(viewer, 'portalTransactionFilterClientName');
        }
        setFilterClientName(value);
    };

    const onClientNameFilterBlur = (): void => {
        // filterClientName will only be populated if a user selects a search result,
        // so if it doesn't have a value or hasn't been updated to the search value, 
        // set it to the searchClientName value if it has one
        if (searchClientName && filterClientName !== searchClientName) {
            onClientNameFilterChange(searchClientName);
        }
    };

    const onPartnerNameSearch = (searchValue: string) => {
        // once the delay time has elapsed in the autocomplete component
        // the search will be triggered here to fetch partner name search results
        dispatch(fetchTradingPartnerNameSearchResults(searchValue));
    };

    const onPartnerNameSearchValueChange = (value: string): void => {
        setSearchPartnerName(value);
    };

    const onPartnerNameFilterChange = (value: string): void => {
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'portalTransactionFilterPartnerName', value);
        }
        else {
            removeLocalStorageItem(viewer, 'portalTransactionFilterPartnerName');
        }
        setFilterPartnerName(value);
    };

    const onPartnerNameFilterBlur = (): void => {
        // filterPartnerName will only be populated if a user selects a search result,
        // so if it doesn't have a value or hasn't been updated to the search value, 
        // set it to the searchPartnerName value if it has one
        if (searchPartnerName && filterPartnerName !== searchPartnerName) {
            onPartnerNameFilterChange(searchPartnerName);
        }
    };

    const onDateFromFilterChange = (value: DateTime | null) => {
        let newDateFrom = value ?? undefined;
        if (newDateFrom) {
            setLocalStorageItem(viewer, 'portalTransactionFilterDateFrom', newDateFrom.toISO());
        }
        else {
            removeLocalStorageItem(viewer, 'portalTransactionFilterDateFrom');
        }
        setFilterDateFrom(newDateFrom);

        let newDateTo = newDateFrom?.endOf('month') ?? undefined;
        if (newDateTo) {
            setLocalStorageItem(viewer, 'portalTransactionFilterDateTo', newDateTo.toISO());
        }
        else {
            removeLocalStorageItem(viewer, 'portalTransactionFilterDateTo');
        }
        setFilterDateTo(newDateTo);
    };

    const onDateToFilterChange = (value: DateTime | null) => {
        if (value) {
            setLocalStorageItem(viewer, 'portalTransactionFilterDateTo', value.toISO());
        }
        else {
            removeLocalStorageItem(viewer, 'portalTransactionFilterDateTo');
        }
        setFilterDateTo(value ?? undefined);
    };

    const onDocTypeFilterChange = (error: ChangeEvent<HTMLInputElement>) => {
        let typeValue = error.target.value;
        if (typeValue?.length > 0) {
            setLocalStorageItem(viewer, 'portalTransactionFilterDocType', typeValue);
        }
        else {
            removeLocalStorageItem(viewer, 'portalTransactionFilterDocType');
        }
        setFilterDocumentType(typeValue);
    };

    const onNormalizedDocTypeFilterChange = (error: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = error;
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'portalTransactionFilterNormalizedDocType', value);
        }
        else {
            removeLocalStorageItem(viewer, 'portalTransactionFilterNormalizedDocType');
        }
        let currentValue = value?.length > 0 ? value as NormalizedDocumentType : undefined;
        setFilterNormalizedDocumentType(currentValue);
    };

    const onHasExceptionsFilterChange = (error: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = error;
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'portalTransactionFilterHasExceptions', value);
        }
        else {
            removeLocalStorageItem(viewer, 'portalTransactionFilterHasExceptions');
        }
        setFilterHasException(value ?? undefined);
    };

    const onIsResentFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        if (checked === true) {
            setLocalStorageItem(viewer, 'portalTransactionFilterIsResent', checked.toString());
        }
        else {
            removeLocalStorageItem(viewer, 'portalTransactionFilterIsResent');
        }
        setFilterIsDocumentResent(checked === true ? true : undefined);
    };

    const orderNumFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear order number filter"
                    onClick={clearOrderNumFilterByX}
                    disabled={fieldsDisabled}
                >
                    {filterOrderNumber && filterOrderNumber.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const erpOrderNumFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear erp order number filter"
                    onClick={clearErpOrderNumFilterByX}
                    disabled={fieldsDisabled}
                >
                    {filterErpOrderNumber && filterErpOrderNumber.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const docNumFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear document number filter"
                    onClick={clearDocNumFilterByX}
                    disabled={fieldsDisabled}
                >
                    {filterDocumentNumber && filterDocumentNumber.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const docTypeFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear document type filter"
                    onClick={clearDocTypeFilterByX}
                    disabled={fieldsDisabled}
                >
                    {filterDocumentType && filterDocumentType.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const getNormalizedDocTypeFilterList = () => {
        let items = [];
        const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
        items.push(blankItem);
        
        let types = Object.values(NormalizedDocumentType);
        types.forEach((type, index) => {
            if (type === NormalizedDocumentType.Unknown) return;
            items.push(
                <MenuItem key={index + 1} value={type}>
                    {capitalizeWithUnderscoreRemoval(type, ' ')}
                </MenuItem>
            );
        });
        
        return items;
    };

    const getHasExceptionsFilterList = () => {
        return [
            <BlankMenuItem key="0" value=""></BlankMenuItem>,
            <MenuItem key="1" value="true">Yes</MenuItem>,
            <MenuItem key="2" value="false">No</MenuItem>
        ];
    };

    const normalizedDocTypeMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',
            },
        },
        'aria-label': 'Normalized Document Type',
    };

    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick} onSearch={onSearchClick} barHeight="140px">
            <Stack direction={'column'} justifyContent="center" rowGap={2} width="100%" >
                <Grid container item direction={'row'} ml={{xs:1, sm:3, md:6, lg:10, xl:14}} columnGap={2} justifyContent="flex-start" xs={12}>
                    <Grid item xs={2}>
                        <AutocompleteFilterField
                            fieldId="portal-transaction-filter-client-name"
                            searchTerm={searchClientName}
                            onSearchTermValueChange={onClientNameSearchValueChange}
                            filterValue={filterClientName ?? ''}
                            onFilterValueChange={onClientNameFilterChange}
                            clearIndicatorOnClick={clearClientNameFilterByX}
                            searchResults={clientNameSearchResults}
                            autoFocus={true}
                            label="Client Name"
                            disabled={fieldsDisabled}
                            onFieldBlur={onClientNameFilterBlur}
                            search={onClientNameSearch}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePickerWrapper>
                                <DatePicker
                                    label="Date From"
                                    value={filterDateFrom ?? null}
                                    views={['year','month','day']}
                                    openTo={'month'}
                                    maxDate={filterDateTo}
                                    disabled={fieldsDisabled}
                                    onChange={onDateFromFilterChange}
                                    slotProps={{
                                        textField: {
                                            variant: "standard",
                                            fullWidth: true,
                                            inputProps: {'aria-label': 'date from'}
                                        },
                                    }}
                                />
                            </DatePickerWrapper>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePickerWrapper>
                                <DatePicker
                                    label="Date To"
                                    value={filterDateTo ?? null}
                                    views={['year','month','day']}
                                    openTo={'day'}
                                    minDate={filterDateFrom}
                                    disabled={fieldsDisabled}
                                    onChange={onDateToFilterChange}
                                    slotProps={{
                                        textField: {
                                            variant: "standard",
                                            fullWidth: true,
                                            inputProps: {'aria-label': 'date to'}
                                        },
                                    }}
                                />
                            </DatePickerWrapper>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            itemID="portal-transaction-filter-order-num"
                            fullWidth
                            value={filterOrderNumber ?? ''}
                            label="Order Number"
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': 'order number', 'maxLength': 50, }}
                            InputProps={orderNumFilterProps}
                            onChange={onOrderNumFilterChange}
                            autoComplete="off"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            itemID="portal-transaction-filter-erp-order-num"
                            fullWidth
                            value={filterErpOrderNumber ?? ''}
                            label="ERP Order Number"
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': 'erp order number', 'maxLength': 50, }}
                            InputProps={erpOrderNumFilterProps}
                            onChange={onErpOrderNumFilterChange}
                            autoComplete="off"
                            variant="standard"
                        />
                    </Grid>
                </Grid>
                <Grid container item direction={'row'} ml={{xs:1, sm:3, md:6, lg:10, xl:14}} columnGap={2} xs={12} justifyContent="flex-start">
                    <Grid item xs={2}>
                        <AutocompleteFilterField
                            fieldId="portal-transaction-filter-partner-name"
                            searchTerm={searchPartnerName}
                            onSearchTermValueChange={onPartnerNameSearchValueChange}
                            filterValue={filterPartnerName ?? ''}
                            onFilterValueChange={onPartnerNameFilterChange}
                            clearIndicatorOnClick={clearPartnerNameFilterByX}
                            searchResults={partnerNameSearchResults}
                            label="Trading Partner Name"
                            disabled={fieldsDisabled}
                            onFieldBlur={onPartnerNameFilterBlur}
                            search={onPartnerNameSearch}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            itemID="portal-transaction-filter-doc-num"
                            fullWidth
                            value={filterDocumentNumber ?? ''}
                            label="Document Number"
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': 'document number', 'maxLength': 50, }}
                            InputProps={docNumFilterProps}
                            onChange={onDocNumFilterChange}
                            autoComplete="off"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                            <InputLabel id="portal-transaction-filter-normalized-doc-type-label">Document</InputLabel>
                            <Select
                                labelId="portal-transaction-filter-normalized-doc-type-label"
                                id="portal-transaction-filter-normalized-doc-type"
                                value={filterNormalizedDocumentType ?? ''}
                                MenuProps={normalizedDocTypeMenuProps}
                                onChange={onNormalizedDocTypeFilterChange}
                            >
                                {getNormalizedDocTypeFilterList()}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            itemID="portal-transaction-filter-doc-type"
                            fullWidth
                            value={filterDocumentType ?? ''}
                            label="Type"
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': 'document type', 'maxLength': 50, }}
                            InputProps={docTypeFilterProps}
                            onChange={onDocTypeFilterChange}
                            autoComplete="off"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                            <InputLabel id="portal-transaction-filter-has-exceptions-label">Has Exceptions</InputLabel>
                            <Select
                                labelId="portal-transaction-filter-has-exceptions-label"
                                id="portal-transaction-filter-has-exceptions"
                                value={filterHasException ?? ''}
                                onChange={onHasExceptionsFilterChange}
                            >
                                {getHasExceptionsFilterList()}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2} display={"inline-flex"} alignItems={"flex-end"}>
                        <FormControlLabel 
                            control={
                                <FilterBarCheckbox 
                                    checked={filterIsDocumentResent ?? false} 
                                    onChange={onIsResentFilterChange} 
                                    disabled={fieldsDisabled}
                                />} 
                            label="Document Resent" 
                            disabled={fieldsDisabled}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </FilterBar>
    );
};

export default PortalTransactionFilterBar;
