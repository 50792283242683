import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CircularProgress, Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridOverlay, GridRowModel, GridSortModel, GridRowParams } from '@mui/x-data-grid-pro';
import { DialogDynamicListToolbar, DialogDynamicListContainer, DialogDynamicListContent, DialogDynamicListHeader, DialogDynamicListDataGrid } from '../../../util/SharedStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ClientPartnerTransactionPostProcessingModel, RequestResult, UserRole, UpsertClientPartnerTransactionPostProcessingInput, PostProcessingBlobModel } from "../../../gql-types.generated";
import { selectError, selectDeleteClientPartnerTransactionPostProcessingStatus, selectUpsertClientPartnerTransactionPostProcessingStatus, captureDeleteClientPartnerTransactionPostProcessingStatus, captureUpsertClientPartnerTransactionPostProcessingStatus } from "../../../features/ClientPartnerDetails/ClientPartnerDetailsSlice";
import { upsertClientPartnerTransactionPostProcessing, deleteClientPartnerTransactionPostProcessing } from '../../../features/ClientPartnerDetails/ClientPartnerDetailsActions';
import CreateNewButton from '../../buttons/CreateNewButton';
import DeleteDialog from '../../dialogs/DeleteDialog';
import TransactionPostProcessingDialog from '../../dialogs/TransactionPostProcessDialog';
import { setToastConfig } from '../../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../../util/Constants';
import { viewerCanEdit } from '../../../util/ViewerUtility';

interface ClientPartnerTransactionPostProcessingListProps {
    parentId: string;
    postProcessings: ClientPartnerTransactionPostProcessingModel[] | undefined;
    viewerRole: UserRole | undefined;
    availablePostProcessingBlobs?: PostProcessingBlobModel[];
    isLoading?: boolean;
    isReadOnly?: boolean;    
    refreshClientPartnerTransactionData?: () => void;
}

const DialogClientPartnerTransactionPostProcessingList: React.FC<ClientPartnerTransactionPostProcessingListProps> = props => {
    const { parentId, postProcessings, viewerRole, availablePostProcessingBlobs, refreshClientPartnerTransactionData, isLoading = false, isReadOnly = false } = props;
    const dispatch = useAppDispatch();
    const canEdit = viewerCanEdit(viewerRole);
    const [gridHeight, setGridHeight] = useState<string>('48px');
    const [openTransactionPostProcessingDialog, setOpenTransactionPostProcessingDialog] = useState(false);
    const [selectedPostProcessing, setSelectedPostProcessing] = useState<ClientPartnerTransactionPostProcessingModel | undefined>(undefined); 
    const [postProcessingRows, setPostProcessingRows] = useState<GridRowModel[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'alias',
            sort: 'asc',
        },
    ]);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [isSavingOrDeleting, setIsSavingOrDeleting] = useState(false);

    const error = useAppSelector(selectError);
    const deletePostProcessingStatus = useAppSelector(selectDeleteClientPartnerTransactionPostProcessingStatus);
    const savePostProcessingStatus = useAppSelector(selectUpsertClientPartnerTransactionPostProcessingStatus);

    useEffect(() => {
        setPostProcessingRows(getPostProcessingRows());
        setGridHeight(getGridHeight());
    }, [postProcessings]);

    
    // declare the data refreshing function
    // as async so list will show as loading
    // up until refresh is complete
    const refreshData = async () => {
        if (refreshClientPartnerTransactionData) {
            setIsSavingOrDeleting(true);
            await refreshClientPartnerTransactionData();
        }
        setIsSavingOrDeleting(false);
    };

    useEffect(() => {
        if (savePostProcessingStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: savePostProcessingStatus.message as string,
                severity: ToastSeverity.Success
            }));
            onTransactionPostProcessingDialogClose();
            refreshData();
        } else if (savePostProcessingStatus?.result === RequestResult.Fail) {
            setIsSavingOrDeleting(false);
        }
    }, [savePostProcessingStatus?.result]);

    useEffect(() => {
        if (deletePostProcessingStatus) {
            if (deletePostProcessingStatus.result === RequestResult.Success) {
                onDeleteDialogClose();
                refreshData();
            } else if (deletePostProcessingStatus.result === RequestResult.Fail) {
                setDeleteErrorMessage(deletePostProcessingStatus?.message as string);
                setIsSavingOrDeleting(false);
            }
        }
    }, [deletePostProcessingStatus?.result]);

    const getSelectedRowClientPartnerTransactionPostProcessing = useCallback((rowId: string) => () => {
        if (rowId && postProcessings?.length) {
            let postProcessing = postProcessings.find(postProcessing => postProcessing.id === rowId);
            return postProcessing;
        }
        return undefined;
    }, [postProcessings]);

    const deletePostProcessingHandler = useCallback((rowId: string) => () => {
        let postProcessing = getSelectedRowClientPartnerTransactionPostProcessing(rowId);
        if (postProcessing) {
            setSelectedPostProcessing(postProcessing);
            setOpenDelete(true);
        }
    }, [getSelectedRowClientPartnerTransactionPostProcessing]);

    const editPostProcessingHandler = useCallback((rowId: string) => () => {
        let postProcessing = getSelectedRowClientPartnerTransactionPostProcessing(rowId);
        if (postProcessing) {
            setSelectedPostProcessing(postProcessing);
            setOpenTransactionPostProcessingDialog(true);
        }
    }, [getSelectedRowClientPartnerTransactionPostProcessing]);

    const setDefaults = () => {
        setSelectedPostProcessing(undefined);
    };

    const onAddPostProcessing = () => {
        setDefaults();
        setOpenTransactionPostProcessingDialog(true);
    };

    const onDialogClose = () => {
        setDefaults();
    };

    const onTransactionPostProcessingDialogClose = () => {
        setOpenTransactionPostProcessingDialog(false);
        // if there was an error and dialog is closed,
        // refresh the data to clear error on parent
        if (error) {
            refreshData();
        }
        dispatch(captureUpsertClientPartnerTransactionPostProcessingStatus());
        onDialogClose();
    };

    const onTransactionPostProcessingDialogSave = (postProcessingInput: UpsertClientPartnerTransactionPostProcessingInput) => {
        if (postProcessingInput) {
            setIsSavingOrDeleting(true);
            dispatch(upsertClientPartnerTransactionPostProcessing(postProcessingInput));
        }
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        dispatch(captureDeleteClientPartnerTransactionPostProcessingStatus());
        onDialogClose();
        setDeleteErrorMessage('');
    };

    const onDeleteDialogConfirm = () => {
        // delete the selected postProcessing
        if (selectedPostProcessing) {
            setIsSavingOrDeleting(true);
            dispatch(deleteClientPartnerTransactionPostProcessing(selectedPostProcessing.id as string));
        }
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'postProcessing-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const getGridHeight = () => {
        // either the grid or its container needs a set height to render properly
        // the container only has maxHeight so that it doesn't display a lot of 
        // white space when there are no postProcessings, so need to calculate a height
        // based on the number of rows since want a scroll bar instead of paging
        if (postProcessings) {
            if (postProcessings.length === 1) {
                return '108px';
            } else if (postProcessings.length === 2) {
                return '156px';
            } else if (postProcessings.length === 3) {
                return '208px';
            } else if (postProcessings.length >= 4) {
                return '350px';
            }
        }
        return '48px';
    };

    const getPostProcessingRows = () => {
        if (postProcessings && postProcessings.length > 0) {
            return postProcessings.map((postProcess: ClientPartnerTransactionPostProcessingModel) => {
                const {id, sortOrder, ediProcessName, processName, postProcessingBlob } = postProcess;
                const blobDescription = postProcessingBlob?.description;
                return {
                    _raw: postProcess,
                    id,
                    sortOrder,
                    ediProcessName,
                    processName,
                    blobDescription
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const postProcessingColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'sortOrder',
                headerName: 'SORT ORDER',
                minWidth: 150,
                cellClassName: "ediDataGridCellFirstChild",
                sortable: false,
            },
            {
                field: 'processName',
                headerName: 'PROCESS',
                minWidth: 150,
                flex: 1,
                sortable: false,
            },
            {
                field: 'ediProcessName',
                headerName: 'EDI PROCESS',
                minWidth: 150,
                flex: 1,
                sortable: false,
            },
            {
                field: 'blobDescription',
                headerName: 'ASSEMBLY',
                minWidth: 150,
                flex: 1,
                sortable: false,
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                resizable: false,
                headerName: '',
                minWidth: 100,
                headerAlign: 'center',
                align: 'center',
                hide: isReadOnly || !canEdit, // hide column for readonly mode and reader role
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editPostProcessingHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        onClick={deletePostProcessingHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],

            },
        ],
        [deletePostProcessingHandler, editPostProcessingHandler, canEdit, isReadOnly],
    );

    return (
        <DialogDynamicListContainer>

            <DialogDynamicListToolbar justify="space-between" noborder={+true}>
                <DialogDynamicListHeader>Post Processing Assemblies</DialogDynamicListHeader>
                {(canEdit && !isReadOnly) &&
                    <CreateNewButton
                        text="Add Post Processing Assembly"
                        tooltip="Click here to add a Post Processing Assembly"
                        variant="text"
                        onClick={onAddPostProcessing}
                        disabled={isSavingOrDeleting || isLoading}
                        data-cy="add-new-post-processing"
                    />
                }
            </DialogDynamicListToolbar>

            <DialogDynamicListContent>
                {((postProcessings !== undefined && postProcessings?.length > 0) || isSavingOrDeleting) &&
                    <DialogDynamicListDataGrid
                        loading={isSavingOrDeleting || isLoading}
                        height={gridHeight}
                        headerHeight={38}
                        rowHeight={52}
                        aria-label="ClientPartnerTransactionPostProcessing List"
                        hideFooter
                        disableColumnMenu
                        disableColumnFilter
                        disableMultipleSelection
                        rows={postProcessingRows}
                        columns={postProcessingColumns}
                        sortingOrder={['asc', 'desc']}
                        sortModel={sortModel}
                        onSortModelChange={onSortModelChange}
                        components={{
                            // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                            LoadingOverlay: loadingOverlay,
                        }}
                    />
                }
            </DialogDynamicListContent>

            <TransactionPostProcessingDialog
                isOpen={openTransactionPostProcessingDialog}
                clientPartnerTransactionId={parentId}
                currentPostProcess={selectedPostProcessing}
                availablePostProcessingBlobs={availablePostProcessingBlobs}
                onClose={onTransactionPostProcessingDialogClose}
                onSave={onTransactionPostProcessingDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedPostProcessing?.id ?? ''}
                heading={'Delete Post Processing Assembly from Transaction'}
                message={'Are you sure you want to delete \'' + selectedPostProcessing?.processName + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </DialogDynamicListContainer>
    );

}

export default DialogClientPartnerTransactionPostProcessingList;
